import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./BrandsOnboarded.scss"
import BlinkitIcon from '../../../../assets/svgs/BlinkitIcon';
import ZeptoIcon from '../../../../assets/svgs/ZeptoIcon';
import InstamartIcon from '../../../../assets/svgs/InstamartIcon';
import { toast } from 'react-toastify';
import { BASE_URL_NEW } from '../../../../appConstant';
import axios from 'axios';
import LoaderSkeletonTable from '../../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import { useAppSelector } from '../../../../redux/hook';
import defaultLogo from '../../../../assets/defaultLogo.jpg';

const BrandsOnboarded = () => {
  const userId = useAppSelector(state => state.user._id);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([])


  useEffect(() => {
    setIsLoading(true);
    axios.get(`${BASE_URL_NEW}/onboard?user-id=${userId}`)
      .then((res: any) => {
        const data = res.data;
        // Transform data to the desired format
        const transformedData = data.map((item: any) => ({
          id: item.id,
          name: item.brand_name, // Default if brand_name is missing
          logo: item.image_url, // Default placeholder
          subBrands: item.sub_brands
            ? item.sub_brands.split("|").map((s: string) => s.trim())
            : [],
          platforms: item.platform
            ? item.platform.split("|").map((p: string) => p.trim())
            : [],
          membersCount: item.membersCount || 0, // Default if member count is not provided
          date: new Date(item.created_at).toLocaleDateString(), // Format date as YYYY-MM-DD
        }));

        // Log the transformed data for debugging
        // console.log("Transformed Data:", transformedData);
        setData(transformedData)

        // toast('fetched successful')
        // console.log('data for status screen',data)

      })
      .catch((err: any) => {
        console.log(err)
        // toast(' not fetched')
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, [])




  const formatSubBrands = (subBrands: string[]) => {
    if (subBrands.length <= 1) return subBrands.join(', ');
    return `${subBrands[0]} +${subBrands.length - 1} more`;
  };

  const displayIcon = (platform: string) => {
    if (platform.toLowerCase() === 'blinkit') return <BlinkitIcon width='40' height='12' />;
    else if (platform.toLowerCase() === 'zepto') return <ZeptoIcon width='40' height='12' />;
    else if (platform.toLowerCase() === 'instamart') return <InstamartIcon width='70' height='20' />;
  }

  return (
    isLoading ? <LoaderSkeletonTable /> : (
      <div className="brands-onboarded">
        <h3>Brands Onboarded</h3>
        <div className="brand-onboarding-container">
          {/* {uploadedBrands.map((brand) => ( */}
          {data.length===0?<div><h6>No Brands Onboarded.</h6></div> :
          data.map((brand: any) => (
            <div key={brand.id} className="brand-card">
              <div className="brand-header">
                <img src={brand.logo || defaultLogo} alt={`${brand.name} Logo`} className="brand-logo" />
                <div className="brand-details">
                  <h4>Brand Name : {brand.name}</h4>
                </div>
              </div>
              <div className="brand-meta">
                {brand.subBrands.length > 0 && brand.subBrands[0] !== "" &&
                  <p>Sub-brand: {formatSubBrands(brand.subBrands)}</p>}
                <p>
                  Platforms:{' '}
                  {brand.platforms.map((platform: any, idx: any) => (

                    <span>
                      {displayIcon(platform)}
                      &nbsp;
                    </span>

                  ))}
                </p>
                <div className="brand-members">
                  <p></p>
                  <p className="brand-date">On: {new Date(brand.date).toLocaleDateString()}</p>
                </div>
              </div>
              <hr style={{ margin: '0px', height: '3px', backgroundColor: '#000000' }} />
              <button
                className="status-button"
                // onClick={() => navigate(`/home/brandOnboarding/status/${brand.id}`)}
                onClick={() => navigate(`/home/brandsOnboarded/StatusScreen/${brand.id}`)}
              >Click to check status</button>
            </div>
          ))
        }
        </div>
        <div className="add-more-brands">
          <p style={{ color: '#06486A' }} onClick={() => navigate('/home/brandOnboarding')} className="add-more-link">
            Want to add more brands?{' '}
            {/* <span onClick={() => navigate('/home/brandOnboarding')} className="add-more-link">
            Click here
          </span> */}
          </p>
        </div>
      </div>
    )

  );
};

export default BrandsOnboarded;


