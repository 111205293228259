import React from 'react'

const ProductMasterIcon = (props) => {
    return (
        <>
            {
                props.tab === 'product' ?
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.4 16C1.73333 16 1.16667 15.7407 0.7 15.2222C0.233333 14.7037 0 14.0741 0 13.3333C0 12.5926 0.233333 11.963 0.7 11.4444C1.16667 10.9259 1.73333 10.6667 2.4 10.6667H13.6C14.2667 10.6667 14.8333 10.9259 15.3 11.4444C15.7667 11.963 16 12.5926 16 13.3333C16 14.0741 15.7667 14.7037 15.3 15.2222C14.8333 15.7407 14.2667 16 13.6 16H2.4ZM2.4 14.2222H13.6C13.8267 14.2222 14.0167 14.137 14.17 13.9667C14.3233 13.7963 14.4 13.5852 14.4 13.3333C14.4 13.0815 14.3233 12.8704 14.17 12.7C14.0167 12.5296 13.8267 12.4444 13.6 12.4444H2.4C2.17333 12.4444 1.98333 12.5296 1.83 12.7C1.67667 12.8704 1.6 13.0815 1.6 13.3333C1.6 13.5852 1.67667 13.7963 1.83 13.9667C1.98333 14.137 2.17333 14.2222 2.4 14.2222ZM6.4 8.88889C6.17333 8.88889 5.98333 8.8037 5.83 8.63333C5.67667 8.46296 5.6 8.25185 5.6 8V0.888889C5.6 0.637037 5.67667 0.425926 5.83 0.255556C5.98333 0.0851852 6.17333 0 6.4 0H12.8C13.0267 0 13.2167 0.0851852 13.37 0.255556C13.5233 0.425926 13.6 0.637037 13.6 0.888889V8C13.6 8.25185 13.5233 8.46296 13.37 8.63333C13.2167 8.8037 13.0267 8.88889 12.8 8.88889H6.4ZM7.2 7.11111H12V1.77778H7.2V7.11111ZM0 7.06667V5.35556H3.94V7.06667H0ZM8 4.44444H11.2V2.68889H8V4.44444ZM1.6 4.44444H3.94V2.68889H1.6V4.44444Z" fill="#06486A" />
                    </svg> : <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.4 16C1.73333 16 1.16667 15.7407 0.7 15.2222C0.233333 14.7037 0 14.0741 0 13.3333C0 12.5926 0.233333 11.963 0.7 11.4444C1.16667 10.9259 1.73333 10.6667 2.4 10.6667H13.6C14.2667 10.6667 14.8333 10.9259 15.3 11.4444C15.7667 11.963 16 12.5926 16 13.3333C16 14.0741 15.7667 14.7037 15.3 15.2222C14.8333 15.7407 14.2667 16 13.6 16H2.4ZM2.4 14.2222H13.6C13.8267 14.2222 14.0167 14.137 14.17 13.9667C14.3233 13.7963 14.4 13.5852 14.4 13.3333C14.4 13.0815 14.3233 12.8704 14.17 12.7C14.0167 12.5296 13.8267 12.4444 13.6 12.4444H2.4C2.17333 12.4444 1.98333 12.5296 1.83 12.7C1.67667 12.8704 1.6 13.0815 1.6 13.3333C1.6 13.5852 1.67667 13.7963 1.83 13.9667C1.98333 14.137 2.17333 14.2222 2.4 14.2222ZM6.4 8.88889C6.17333 8.88889 5.98333 8.8037 5.83 8.63333C5.67667 8.46296 5.6 8.25185 5.6 8V0.888889C5.6 0.637037 5.67667 0.425926 5.83 0.255556C5.98333 0.0851852 6.17333 0 6.4 0H12.8C13.0267 0 13.2167 0.0851852 13.37 0.255556C13.5233 0.425926 13.6 0.637037 13.6 0.888889V8C13.6 8.25185 13.5233 8.46296 13.37 8.63333C13.2167 8.8037 13.0267 8.88889 12.8 8.88889H6.4ZM7.2 7.11111H12V1.77778H7.2V7.11111ZM0 7.06667V5.35556H3.94V7.06667H0ZM8 4.44444H11.2V2.68889H8V4.44444ZM1.6 4.44444H3.94V2.68889H1.6V4.44444Z" fill="#5B6871" />
                    </svg>
            }
        </>
    )
}

export default ProductMasterIcon