
import React, { useEffect, useMemo, useRef, useState } from 'react'
import './toolsStyle.scss';
import '../../../utils/commonComponents/customSelect3/CustomSelect3.css';
import { AgGridReact } from 'ag-grid-react';
import { AG_AR, BASE_URL } from '../../../appConstant'
import axios from 'axios';
import { getLast7Days, getLast30Days, getTminus3date, formatSingleDateFnWithYear } from '../../../utils/commonFunction';
import { useAppSelector } from '../../../redux/hook';
import Loader from '../../../utils/commonComponents/Loader/Loader';
import { toast } from 'react-toastify';
import ReportsErrorPage from '../../../utils/commonComponents/errorFallback/ReportsErrorPage';
import Tippy from '@tippyjs/react';
import InfoIcon from '../../../assets/svgs/InfoIcon';

const KeywordCategorization: React.FC = () => {
  const client = useAppSelector(state => state.client);
  const { current_brand: { client_profile_id, main_category } } = client;
  const cat: any = main_category?.split('|');

  const popupRef = useRef<any | null>(null);
  const [toggle, setToggle] = useState(false);


  const [rowData, setRowData] = useState<any[]>([]);
  const [dateType, setDateType] = useState('Daily');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [dateTypeArr, setDateTypeArr] = useState([
    { name: 'Daily', status: true },
    { name: 'Weekly', status: false },
    { name: 'Monthly', status: false },
  ])

  const [keywordCategories, setKeywordCategories] = useState([])
  const [category, setCategory] = useState()

  const dateFn = (dateType: any) => {
    if (dateType === 'Daily') {
      return getTminus3date()
    } else if (dateType === 'Weekly') {
      return getLast7Days()
    } else if (dateType === 'Monthly') {
      return getLast30Days()
    }
  }

  const token = localStorage.getItem('token');

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef.current && !popupRef.current?.contains(event.target)) {
        setToggle(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setKeywordCategories(cat);
    setCategory(cat[0])
  }, [client_profile_id])

  useEffect(() => {
    setIsLoading(true);
    if (!category) {
      setRowData([]);
      setIsLoading(false);
      // throw Error('No category mapped with this brand.')
      return;
    }
    axios.post(`${BASE_URL}keywordCategorization`,
      {
        main_category: category,
        time_stamp: dateFn(dateType),
        // time_stamp: '2024-02-09T00:00:00.000+00:00',
        check_column: 'added_search_terms'
      },
      {
        headers: {
          token
        }
      }).then((res) => {
        const data = res.data.data.keywordCatData;
        // console.log(data);
        if (res.status === 200 && data.length === 0) {
          setRowData(data);
          throw Error('No categorization for this category for today. Please see weekly data.')
        }
        setError(false);
        setRowData(data);
      }).catch((err) => {
        console.log(err);
        setError(err.message)
      }).finally(() => {
        setIsLoading(false);
      })
  }, [category, dateType])


  const handleDWMChange = (e: any) => {
    setDateType(e);
  }

  const handleCatChange = (el: any) => {
    setCategory(el);
  }

  const rowClassRules = {
    'rag-red-outer': (params: any) => params.data.approval === 'Yes'
  }

  const editFn = () => {
    if (dateType === 'Daily') {
      return true;
    }
    return false;
  }

  const DateComponentWithYear = (props: any) => {
    const { time_stamp } = props.data;
    const date = time_stamp.split('T')[0];
    return (
      <div>{formatSingleDateFnWithYear(date)}</div>
    )
  }

  const defaultColDef = useMemo(() => ({ sortable: true, resizable: true, singleClickEdit: true }), []);
  const columnDefs = useMemo(() => ([
    { headerName: 'Date', field: 'time_stamp', cellRenderer: DateComponentWithYear,width:150 },
    { headerName: 'Search Term', field: 'search_term', width: 250 },
    { headerName: 'Brand', field: 'brand', editable: editFn },
    // { headerName: 'Approval', field: 'approval' },
    // { headerName: 'Main Category', field: 'main_category' },
    { headerName: 'Last Node', field: 'last_node' },
    { headerName: 'Keyword Rank', field: 'keyword_rank' },
    { headerName: 'Search Volume', field: 'search_volume' },
  ]), [])

  const onCellValueChanged = (event: any) => {
    // console.log(event);
    const { data, colDef, newValue } = event;
    const updatedData = { ...data, [colDef.field]: newValue };

    const approval = 'No';
    updatedData['approval'] = approval;

    // console.log(updatedData);
    setIsLoading(true);
    axios.patch(`${BASE_URL}keywordCategorization/${data._id}`, {
      brand: updatedData.brand
    }, {
      headers: {
        token
      }
    }).then((res) => {
      //console.log(res.data);
      const updatedAllData = rowData.map((el) => el._id === updatedData._id ? updatedData : el)
      setRowData(updatedAllData);
    }).catch((err) => {
      console.log(err);
      toast.error('Unable to update data')
    }).finally(() => {
      setIsLoading(false);
    })
  }

  // const handleSubmit = async () => {
  //   setIsLoading(true);
  //   try {
  //     const res = await axios.post(`${BASE_URL}keywordCategorization/submit`, {
  //       // client_profile_id: client_profile_id
  //       client_profile_id: '4422554122982285'
  //     }, {
  //       headers: {
  //         token
  //       }
  //     })
  //     setIsLoading(false);
  //     if (res.status === 200) {
  //       toast.success('Data Successfully Submited')
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.log(error);
  //     toast.error('Unable to Submit Data')
  //   }
  // }

  const tippyContent = () => {
    return (
      <div>
        <h6>Instructions</h6>
        <div>
          <ul style={{ color: '#b8b6b6', fontSize: "12px", margin: 0 }}>
            <li>You can edit the <strong>Brand column</strong> value by double click to active cell and after putting the value press enter to save it.</li>
            <li>Please Submit your saved value by clicking the <strong>Submit</strong> button down below.</li>
            <li>Mention the <strong>Brand Name</strong> according to the actual name, be specific for the brand's name.</li>
            <li>If the search term is Generic, do not use <strong>"generic", "genric"</strong> etc.</li>
          </ul>
        </div>
      </div>
    )
  }


  return (
    <>
      <div>
        <div className='keyword_cat_container-content' >
          <div style={{ display: 'flex', gap: '1rem' }}>
            <div ref={popupRef} onClick={() => setToggle((prev) => !prev)} className='custom-select-container3'>
              <span className='custom-select3'>{category}</span>
              <span className='arrow3'><i className='bi bi-chevron-down'></i></span>
              {toggle &&
                <div className='box_3'>
                  {keywordCategories?.map((option, i) => {
                    return (
                      <div key={i} onClick={() => handleCatChange(option)}>
                        <span>{option}</span>
                      </div>
                    )
                  })}
                </div>
              }
            </div>

            <Tippy content={tippyContent()} placement='right-end' theme='custom-theme' arrow={false}>
              <span><InfoIcon /></span>
            </Tippy>
          </div>
          <div className='dateType'>
            {
              dateTypeArr?.map((el) => {
                return (
                  <span className={dateType === el.name ? 'dateType-buttonActive':'dateType-button'} key={el.name} onClick={()=> handleDWMChange(el.name)}>{el.name}</span>
                )
              })
            }
          </div>
        </div>

        <div className="ag-theme-alpine table_card_new" style={{ height: '86vh', width: '100%' }}>
          {error ? <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
            <AgGridReact
              // ref={gridRef}
              rowData={rowData}
              rowHeight={AG_AR.rH}
              headerHeight={AG_AR.hH}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              suppressNoRowsOverlay={true}
              suppressDragLeaveHidesColumns={true}
              rowClassRules={rowClassRules}
              onCellValueChanged={onCellValueChanged}
              suppressMenuHide={true}
            >
            </AgGridReact>
          }
          {
            isLoading && <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} >< Loader /></div>
          }
        </div>
        {/* <div className='button-contain'>
          <button className='keyword-button' onClick={handleSubmit}>Submit</button>
        </div> */}
      </div>
    </>
  )
}

export default KeywordCategorization;

