
import { useEffect, useState } from "react";
import axios from "axios";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { BASE_URL_NEW } from "../../../../appConstant";
import { KPI } from "../../types/type";
import { formatSingleDateFn, getColorComparativeMS, getColorComparativeMSLight } from '../../../../utils/commonFunction';
import LoaderSkeletonGraph from "../../../../utils/commonComponents/Loader/LoaderSkeletonGraph";
import { useAppSelector } from "../../../../redux/hook";

interface Props {
    intervalRange: any;
    kpi: KPI | any
}

const ComparisonGraph: React.FC<Props> = (props) => {
    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

    const client = useAppSelector(state => state.client);
    const { current_brand: { client_id } } = client;

    const { intervalRange, kpi } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<any>({
        labels: Array.from({ length: 24 }, (_, i) => i), // 0 to 23
        datasets: []
    });

    useEffect(() => {
        if (intervalRange && Object.keys(intervalRange).length > 0) {
            const { former_start_date, former_end_date, later_start_date, later_end_date } = intervalRange;
            setIsLoading(true);
            axios.get(`${BASE_URL_NEW}/marketing-stream/${client_id}/comparativeGraph?firstStartDate=${former_start_date}&firstEndDate=${former_end_date}&secondStartDate=${later_start_date}&secondEndDate=${later_end_date}`)
                .then(response => {
                    const responseData = response.data;

                    // console.log('response data comparison',responseData);

                    const firstRangeData = responseData.filter((item: any) => item.date_range === `${former_start_date} - ${former_end_date}`);
                    const secondRangeData = responseData.filter((item: any) => item.date_range === `${later_start_date} - ${later_end_date}`);

                    setData({
                        labels: Array.from({ length: 24 }, (_, i) => i+1),
                        datasets: [
                            {
                                label: `${formatSingleDateFn(former_start_date)} - ${formatSingleDateFn(former_end_date)}`,
                                data: firstRangeData.map((item: any) => item[kpi?.metric]),
                                backgroundColor: `${getColorComparativeMSLight(kpi?.metric)}`,
                                borderRadius: 10,
                                borderSkipped: false,
                                barThickness: 15,
                            },
                            {
                                label: `${formatSingleDateFn(later_start_date)} - ${formatSingleDateFn(later_end_date)}`,
                                data: secondRangeData.map((item: any) => item[kpi?.metric]),
                                backgroundColor: `${getColorComparativeMS(kpi?.metric)}`,
                                borderRadius: 10,
                                borderSkipped: false,
                                barThickness: 15,
                            }
                        ]
                    });
                    setIsLoading(false);
                }).catch(error => {
                    console.error("Error fetching comparison graph data:", error);
                    setIsLoading(false);
                });
        }
    }, [intervalRange, kpi, client_id]);

    const options: any = {
        responsive: true,
        // tooltip: {
        //     mode: 'index',
        //     intersect: false,
        //     usePointStyle: true,
        //     backgroundColor: 'rgba(255, 255, 255,1)',
        //     bodyColor: 'rgba(0, 0, 0 , 0.8)',
        //     titleColor: 'rgba(0, 0, 0 , 0.6)',
        //     titleFont: {
        //         size: 14,
        //         weight: 'bold',
        //         family: 'Inter'
        //     },
        //     callbacks: {
        //         label: function (tooltipItem: any, chartData: any) {
        //             const dataset1 = chartData.datasets[0].data[tooltipItem.index];
        //             const dataset2 = chartData.datasets[1].data[tooltipItem.index];
        //             console.log(dataset1, dataset2);
        //             const total = dataset1 + dataset2;
        //             return `Total: ${total}`;
        //         },
        //     },
        // },
        plugins: {
            legend: {
                position: 'bottom',
                align: 'end',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle',
                },
            },
            title: {
                display: false,
            },
            datalabels: {
                display: false,
            },
        },
        categoryPercentage: 0.8,
        barPercentage: 0.5,

    };

    const isDataAvailable = data.datasets.length > 0 && data.datasets.some((dataset: any) => dataset.data.length > 0);

    return (
        <div>
            {isLoading ? (
                <LoaderSkeletonGraph />
            ) : (
                <div className='table_card_new'>
                    {isDataAvailable ? (
                        <Bar data={data} options={options} height={80} />
                    ) : (
                        <div style={{ padding: '5rem 0', textAlign: 'center', color: '#999', fontSize: '1.2rem' }}>
                            No Data Available
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default ComparisonGraph;
