import React from 'react';
import { DataTable } from 'primereact/datatable';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import { PrimeReactProvider } from 'primereact/api';
import { Column } from 'primereact/column';
import LoaderSkeletonTable from '../../../../utils/commonComponents/Loader/LoaderSkeletonTable';

interface DataTableProps {
    isLoading: boolean;
    data: any[];
}

const StaticDataTable: React.FC<DataTableProps> = ({ isLoading, data }) => {

    //     const headerGroup = (
    //     <Column header="" />
    // );

    const calculateTotalL30DaySales = () => {
        return data.reduce((total, row) => total + row.l30DaySales, 0);
    };

    const total = {
        asin: 'Total',
        category: '',
        productName: '',
        authorization: '',
        seller: '',
        l30DaySales: `₹${calculateTotalL30DaySales()}`,
    };


    return (
        <div className='table_card_new'>
            {isLoading ? (
                <LoaderSkeletonTable length='8' height='58vh' />
            ) : (
                <PrimeReactProvider>
                    <div>
                        <DataTable
                            value={[...data, total]}
                            size='small'
                            resizableColumns
                            // headerColumnGroup={headerGroup}
                            showGridlines
                            removableSort
                            scrollable
                            scrollHeight="60vh"
                            sortIcon
                            editMode="cell"

                        >
                            <Column style={{ width: '100px' ,fontSize:'0.85rem' }} header="ASIN" headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize:'0.85rem', fontWeight: 'bold' }} field='asin' />
                            <Column style={{ width: '100px', fontSize:'0.85rem' }} header="Category" headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.85rem', fontWeight: 'bold' }}field='category' />
                            <Column style={{ width: '100px', fontSize:'0.85rem' }} header="Product Name" headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.85rem', fontWeight: 'bold' }} field='productName' />
                            <Column style={{ width: '100px', fontSize:'0.85rem' }} header="Authorization" headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.85rem', fontWeight: 'bold' }} field='authorization' />
                            <Column style={{ width: '100px', fontSize:'0.85rem' }} header="Seller" headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.85rem', fontWeight: 'bold' }} field='seller' />
                            <Column style={{ width: '100px', fontSize:'0.85rem' }} header="L30 Day Sales" headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.85rem', fontWeight: 'bold' }} field='l30DaySales' />
                        </DataTable>
                    </div>
                </PrimeReactProvider>
            )}
        </div>
    );
};

export default StaticDataTable;

