import axios from 'axios';
import { PrimeReactProvider } from 'primereact/api'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useEffect, useState } from 'react';
import { BASE_URL_NEW } from '../../../../appConstant';
import { useAppSelector } from '../../../../redux/hook';
import { formatDate, getMontheInText, isValidDate } from '../../../../utils/commonFunction';
import LoaderSkeletonTable from '../../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import { saveAs } from 'file-saver'

const TableTwo = ({ clickedKPI, dates }: any) => {
    const client = useAppSelector(state => state.client);
    const { current_brand: { client_id } } = client
    const [rowData, setRowData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false)



    const isRowDataEmpty = rowData.length === 0 || rowData.every(item => Object.keys(item).length === 0);

    // console.log(clickedKPI);
    useEffect(() => {

        if (isValidDate(dates[0]) && isValidDate(dates[1]) && clickedKPI) {
            setIsLoading(true)
            axios.get(`${BASE_URL_NEW}/marketing-stream/${client_id}/hourly_comparative?hour=${clickedKPI?.hour - 1}&startDate=${formatDate(dates[0])}&endDate=${formatDate(dates[1])}`)
                .then((res) => {
                    // console.log(res.data);
                    res?.data?.forEach((row: any) => row.hour += 1)
                    const asinSet: any = {};
                    res.data.forEach((item: any) => {
                        const asin: any = item['asin'];
                        if (!asinSet[asin]) {
                            asinSet[asin] = [];
                        }
                        asinSet[asin].push(item);
                    });


                    let temp: any = [];
                    Object.keys(asinSet).forEach((asin) => {

                        const obj: any = {
                            asin: asin,
                        }
                        asinSet[asin].forEach((item: any) => {
                            if (clickedKPI?.metric === 'ctr' || clickedKPI?.metric === 'acos' || clickedKPI?.metric === 'cpc' || clickedKPI?.metric === 'roas') {
                                obj[item.bucket] = Math.round(item[clickedKPI?.metric] * 100) / 100;
                            } else {
                                obj[item.bucket] = Math.round(item[clickedKPI?.metric]);
                            }
                            obj['pname'] = item.pname;
                            obj['range'] = item.range;
                            obj['internal_category'] = item.internal_category
                            obj['hour'] = item.hour
                            obj['discount'] = Math.round(item.discount)
                        })
                        temp.push(obj)
                    })

                    // console.log(temp);

                    setRowData(temp)
                }).catch((error) => {
                    console.log(error);

                }).finally(() => {
                    setIsLoading(false)
                })
        }
    }, [client_id, clickedKPI, dates])


    const getThreeMonthRange = () => {
        let current = new Date();
        let prevMonth = new Date(current);
        let prevToPrevMonth = new Date(current);
        prevMonth.setMonth(prevMonth.getMonth() - 1);
        prevToPrevMonth.setMonth(prevToPrevMonth.getMonth() - 2);
        return [prevToPrevMonth, prevMonth, current];
    }

    const headers = [
        { field: 'asin', header: 'ASIN' },
        { field: 'hour', header: 'Hour' },
        { field: 'pname', header: 'Name' },
        { field: 'range', header: 'Range' },
        { field: 'discount', header: 'Discount' },
        { field: 'internal_category', header: 'Category' },
        { field: `${new Date().getMonth() - 1}_MONTHLY_AVERAGE`, header: getMontheInText(new Date(getThreeMonthRange()[0]).getMonth() + 1) },
        { field: `${new Date().getMonth()}_MONTHLY_AVERAGE`, header: getMontheInText(new Date(getThreeMonthRange()[1]).getMonth() + 1) },
        { field: `${new Date().getMonth() + 1}_MONTHLY_AVERAGE`, header: getMontheInText(new Date(getThreeMonthRange()[2]).getMonth() + 1) },
        { field: 'BAU_AVERAGE', header: 'BAU' },
        { field: 'FREEDOM_SALE', header: 'EVENT' },
        { field: 'CURRENT_HOUR', header: 'CURRENT_HOUR' }
    ]

    const exportToCSV = () => {
        let csvContent = '';
        const headerLine = headers.map(({ header }) => header).join(',');
        csvContent += headerLine + '\n';

        rowData.forEach((row: any) => {
            const rowData = headers.map(({ field }) => {
                return row[field]
            }).join(',');
            csvContent += rowData + '\n';
        });

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'cumulative_hourly_asin_report_upto(hour).csv');
    };


    return (
        <div style={{ marginTop: '1rem' }} className='table_card_new'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <button className='table_btn' onClick={exportToCSV}><i className='bi bi-download'></i></button>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '1rem' }}>
                    <span className='table_kpi_name'>Cumulative Hourly Asin Report Upto (Hour)</span>
                </div>
            </div>
            <PrimeReactProvider>
                {isLoading ? (<LoaderSkeletonTable height='350px' />) :
                    isRowDataEmpty ? (
                        <div style={{ padding: '5rem 0', textAlign: 'center', color: '#999', fontSize: '1.2rem' }}>
                            No Data Available
                        </div>
                    ) : (

                        <DataTable
                            value={rowData}
                            size='small'
                            columnResizeMode="expand"
                            resizableColumns
                            scrollable scrollHeight="350px"
                            sortIcon="bi bi-chevron-expand stroke"
                        // frozenValue={totalRow}
                        >
                            {headers.map(({ field, header }) => (
                                <Column key={field} field={field} header={header}
                                    style={{ width: '100px' }}
                                    sortable
                                    headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                                    bodyStyle={{ color: 'rgba(24, 24, 24, 1)', fontSize: '0.7rem', fontWeight: 500, textTransform: 'capitalize' }}
                                />
                            ))}
                        </DataTable>
                    )}
            </PrimeReactProvider>
        </div>
    )
}

export default TableTwo

