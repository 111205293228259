import React from 'react'

const DailyReportsIcon = (props) => {
    return (
        <>
            {
                props.tab === 'dailyReports' ?
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.64444 12.5111H4.97778V7.95556H3.64444V12.5111ZM11.0222 12.5111H12.3556V3.17778H11.0222V12.5111ZM7.33333 12.5111H8.66667V9.88889H7.33333V12.5111ZM7.33333 7.95556H8.66667V6.62222H7.33333V7.95556ZM1.33333 16C0.977778 16 0.666667 15.8667 0.4 15.6C0.133333 15.3333 0 15.0222 0 14.6667V1.33333C0 0.977778 0.133333 0.666667 0.4 0.4C0.666667 0.133333 0.977778 0 1.33333 0H14.6667C15.0222 0 15.3333 0.133333 15.6 0.4C15.8667 0.666667 16 0.977778 16 1.33333V14.6667C16 15.0222 15.8667 15.3333 15.6 15.6C15.3333 15.8667 15.0222 16 14.6667 16H1.33333ZM1.33333 14.6667H14.6667V1.33333H1.33333V14.6667Z" fill="#06486A" />
                    </svg> : <svg width="16" height="16" viewBox="0 0 16 16" fill="none" style={props.style} xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.64444 12.5111H4.97778V7.95556H3.64444V12.5111ZM11.0222 12.5111H12.3556V3.17778H11.0222V12.5111ZM7.33333 12.5111H8.66667V9.88889H7.33333V12.5111ZM7.33333 7.95556H8.66667V6.62222H7.33333V7.95556ZM1.33333 16C0.977778 16 0.666667 15.8667 0.4 15.6C0.133333 15.3333 0 15.0222 0 14.6667V1.33333C0 0.977778 0.133333 0.666667 0.4 0.4C0.666667 0.133333 0.977778 0 1.33333 0H14.6667C15.0222 0 15.3333 0.133333 15.6 0.4C15.8667 0.666667 16 0.977778 16 1.33333V14.6667C16 15.0222 15.8667 15.3333 15.6 15.6C15.3333 15.8667 15.0222 16 14.6667 16H1.33333ZM1.33333 14.6667H14.6667V1.33333H1.33333V14.6667Z" fill="#5B6871" />
                    </svg>
            }
        </>

    )
}

export default DailyReportsIcon