import React, { useEffect, useState } from 'react'
import "../../pages/amc.scss"
import HistoryIcon from '../../../../assets/svgs/HistoryIcon'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { PrimeReactProvider } from 'primereact/api';
import LoaderSkeletonTable from '../../../../utils/commonComponents/Loader/LoaderSkeletonTable'
import { toast } from 'react-toastify'
import CalendarIcon from '../../../../assets/svgs/CalendarIcon'
import { useNavigate } from 'react-router-dom'
import { BASE_URL, BASE_URL_NEW } from '../../../../appConstant'
import axios from 'axios'
import { useAppSelector } from '../../../../redux/hook'
import Loader from '../../../../utils/commonComponents/Loader/Loader'

const HistoryAMC = () => {
  const brand = useAppSelector(state => state.client)
  const { current_brand } = brand;
  const token = localStorage.getItem('token');


  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [text, setText] = useState('')
  const [sql, setSql] = useState('')
  const [data, setData] = useState<any[]>([]);
  const [rowdata, setRowdata] = useState<any>(null)
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${BASE_URL}/amc/${current_brand?.client_id}`)
      .then((res: any) => {
        // console.log(res.data);

        setData(res.data);
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        setIsLoading(false);
      })

  }, [current_brand?.client_id])


  const renderSQLBody = (rowData: any, { rowIndex }: any) => {
    // console.log(rowData);
    
    return (
      <div>
        {rowData?.query?.substring(0, 10)}...
        <span
          onClick={() => { setRowdata(rowData); setSql(rowData?.query); }}
          style={{ background: 'none', border: 'none', cursor: 'pointer', marginLeft: '.5rem', fontStyle: 'bold', fontSize: 'bold' }}
        >
          <i className="bi bi-arrows-angle-expand"></i>
        </span>
      </div>
    );
  };

  const handleGeneratePresignedUrlSubmit = (bucketName: any, objectKey: any, index: any) => {
    setIsLoading(true)
    axios.post(`${BASE_URL_NEW}/amc/generatePresignedUrl`,
      { bucketName: bucketName, objectKey: objectKey },
      {
        headers: {
          token
        }
      }
    ).then((res: any) => {
      // console.log(res);

      function downloadFile(url: string) {
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = '';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }

      downloadFile(res.data.data.presigned_url);
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      setIsLoading(false);
    })
  }

  const renderResultBody = (props: any, options: any) => {
    const { _id, bucketName, objectKey } = props
    // console.log(options.rowIndex);

    return (
      <button className="download-history-button" onClick={() => handleGeneratePresignedUrlSubmit(bucketName, objectKey, _id)} >
        <i className="bi bi-download" style={{ marginRight: '.6rem' }} ></i> Download
      </button>
    )
  }

  const dateBody = (props: any) => {
    const { createdAt } = props;
    return (
      <div>
        {createdAt?.split('T')[0]}
      </div>
    )
  }

  const timeBody = (props: any) => {
    const { createdAt } = props;
    return (
      <div>
        {createdAt?.split('T')[1]}
      </div>
    )
  }

  const [columns, setColumns] = useState<any[]>([
    { header: 'Date', field: '', body: dateBody },
    { header: 'Time', field: '', body: timeBody },
    { header: 'Frequency', field: 'frequency' },
    { header: 'Context', field: 'text' },
    { header: 'Generated SQL', field: 'query', body: renderSQLBody },
    { header: 'Result', field: 'result', body: renderResultBody },
  ]);



  const handleTextChange = ({ target: { value } }: any) => {
    setText(value)
  }

  const handleSqlChange = ({ target: { value } }: any) => {
    setSql(value)
  }

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const handleCopySQL = () => {
    if (sql) {
      navigator.clipboard
        .writeText(sql)
        .then(() => {
          toast("SQL query copied to clipboard!")
        })
        .catch((err) => {
          console.error("Failed to copy SQL query: ", err);
          toast("Failed to copy SQL query. Please try again. ")
        });
    } else {
      toast("No SQL Query to copy.")

    }
  };




  return (
    <div className='history-container'>
      <div className='history-header'><h3>History</h3><HistoryIcon /></div>
      {!rowdata ?
        <>
          <span className='arrow-btn' onClick={() => navigate('/tools/amc')}><i className="bi bi-arrow-left-circle-fill"></i> Back to main menu </span>

          {
            isLoadingTable ? <LoaderSkeletonTable height='350px' /> :
              <div>
                <PrimeReactProvider>
                  <DataTable
                    value={data}
                    size='small'
                    columnResizeMode="expand"
                    resizableColumns
                    showGridlines
                    id='unique-table'
                    scrollable scrollHeight="450px"

                  >
                    {columns.map((col: any, index: any) => (

                      <Column
                        key={index}
                        field={col.field}
                        header={col.header}
                        body={col?.body}
                        style={{ width: '150px' }}
                        headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                        bodyStyle={{ color: '#000000', fontSize: '0.85rem', textTransform: 'capitalize' }}
                      // bodyStyle={column?.bodyStyle}
                      />

                    ))}

                  </DataTable>
                </PrimeReactProvider>
              </div>
          }

        </>
        :

        sql &&

        <>
          <div className='arrow-btn' onClick={() => setRowdata(null)}><i className="bi bi-arrow-left-circle-fill"></i> Back to History </div>
          <div className='query-box'>
            <div className='query-div'>{rowdata.text}</div>
            <div><button className="download-history-button" onClick={() => navigate('/tools/amc/report-scheduling')} >
              <div style={{ marginRight: '0.5rem' }}><CalendarIcon /> </div>
              Schedule
            </button>
            </div>
          </div>

          <div className="amc-sql">
            <div className="sql-header">
              <h5>SQL Query</h5>
              <div className="sql-actions">
                <button >Re-Execute</button>
                <button onClick={handleCopySQL}> <i className="bi bi-copy"></i></button>
                <button onClick={toggleExpand}>
                  {isExpanded ? <i className="bi bi-arrows-angle-contract"></i> : <i className="bi bi-arrows-angle-expand"></i>}
                </button>
              </div>
            </div>
            <div className={`sql-content ${isExpanded ? "expanded" : ""}`}>
              <textarea className="amc-sql-textarea" id="sql" name="sql" value={sql} rows={50} cols={100} onChange={handleSqlChange} disabled></textarea>
            </div>
            {/* <button  type="submit" onClick={handleSqlSubmit} className="execute-btn">Generate Report</button> */}
          </div>

        </>

      }
      {
        isLoading && <div style={{ position: "absolute", left: "50% ", top: "50%" }} >< Loader /></div>
      }



    </div>
  )
}

export default HistoryAMC

