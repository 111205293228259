import axios from 'axios';
import { PrimeReactProvider } from 'primereact/api';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { BASE_URL_NEW } from '../../../../appConstant';
import { useAppSelector } from '../../../../redux/hook';
import { formatedMetric, formatSingleDateFn, interpolateColor } from '../../../../utils/commonFunction';
import LoaderSkeletonTable from '../../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import { saveAs } from 'file-saver';

const ComparisonMetricwise = ({ intervalRange, selectedMetric2, setSelectedMetric2 }: any) => {
  const client = useAppSelector(state => state.client);
  const { current_brand: { client_id } } = client;
  const [rowData, setRowData] = useState<any[]>([]);
  const time_stamps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
  const [isLoading, setIsLoading] = useState(false);

  // console.log('clicked Kpi',clickedKPI)
  // console.log('setclicked Kpi',setClickedKPI)


  useEffect(() => {
    if (intervalRange) {
      const { former_start_date, former_end_date, later_start_date, later_end_date } = intervalRange;
      setIsLoading(true);
      axios.get(`${BASE_URL_NEW}/marketing-stream/${client_id}/comparativeGraph?firstStartDate=${former_start_date}&firstEndDate=${former_end_date}&secondStartDate=${later_start_date}&secondEndDate=${later_end_date}`)
        .then((res) => {
          let temp: any = [];
          let keysArr = ['cost', 'impressions', 'clicks', 'attributedSales14d', 'attributedConversions14d', 'attributedUnitsOrdered14d', 'acos', 'roas', 'cpc', 'ctr'];
          // Separate data based on date range
          const formerData = res.data.slice(0, 24);  // Assuming first 24 are for former date range
          const laterData = res.data.slice(24);  // Assuming last 24 are for later date range

          keysArr.forEach((el, i) => {
            let range1: any = { metric: el, range: `${formatSingleDateFn(former_start_date)} - ${formatSingleDateFn(former_end_date)}`, id: i };
            let range2: any = { metric: el, range: `${formatSingleDateFn(later_start_date)} - ${formatSingleDateFn(later_end_date)}`, id: i + 100 };

            formerData.forEach((item: any) => {
              if (el === 'ctr') {
                range1[`${item.hour}_hour`] = Math.round(item[el] * 100) / 100;
              } else {
                range1[`${item.hour}_hour`] = Math.round(item[el]);
              }
            });

            laterData.forEach((item: any) => {
              if (el === 'ctr') {
                range2[`${item.hour}_hour`] = Math.round(item[el] * 100) / 100;
              } else {
                range2[`${item.hour}_hour`] = Math.round(item[el]);
              }
            });

            temp.push(range1);
            temp.push(range2);
          });

          setRowData(temp);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [client_id, intervalRange]);


  // console.log('row data metric',rowData)

  // function heatmap(value: any, el: any) {
  //   let a: any = Object.values(value);
  //   console.log('a',a)
  //   a.splice(0, 2);
  //   let max = Math.max(...a);

  //   let color = '';
  //   if (value[`${el}_hour`] === '-') {
  //     color = '#B1B1B1';
  //   }
  //   if (value[`${el}_hour`] === max) {
  //     color = '#EEEEEE';
  //   } else if (value[`${el}_hour`] < max && value[`${el}_hour`] >= max * 0.8) {
  //     color = '#DDDDDD';
  //   } else if (value[`${el}_hour`] < max * 0.8 && value[`${el}_hour`] >= max * 0.6) {
  //     color = '#D5D5D5';
  //   } else if (value[`${el}_hour`] < max * 0.6 && value[`${el}_hour`] >= max * 0.4) {
  //     color = '#C7C7C7';
  //   } else if (value[`${el}_hour`] < max * 0.4 && value[`${el}_hour`] >= max * 0.2) {
  //     color = '#BDBDBD';
  //   } else if (value[`${el}_hour`] < max * 0.2 && value[`${el}_hour`] >= 0) {
  //     color = '#B1B1B1';
  //   }

  //   return color;
  // }

  // function heatmap(value: any, el: any) {
  //   let a: any = Object.values(value);
  //   a.splice(0,3);
  //   const min = Math.min(...a.filter((x:number) => x !== 0));
  //   const max = Math.max(...a.filter((x:number) => x !== 0));
  //   const currVal = value[`${el}_hour`]
  //   if (min === max || currVal===0) return '#ffffff'  

  //   const normalizedFactor = ((currVal - min) / (max - min))
  //   return interpolateColor('#EEEEEE', 'B1B1B1', normalizedFactor);
  // }

  const hoursBody = (props: any, el: any, metricMinMax: { [key: string]: { min: number, max: number } }) => {
    return (
      <div style={{
        backgroundColor: heatmap(props, el, metricMinMax),
        width: '100%',
        height: '100%',
        textAlign: 'center',
        padding: '10px 0px',
        boxSizing: 'border-box',
      }}
      >{props[`${el}_hour`] !== undefined ? (props[`${el}_hour`])?.toLocaleString() : '-'}
      </div>
    );
  };

  const totalBody = (props: any) => {
    // console.log(props);
    let total = 0;
    let obj: any = {};

    for (let time of time_stamps) {
      if (props[`${time}_hour`]) {
        total = total + props[`${time}_hour`];
      }
      obj[`total_${props.metric}`] = total;

    }

    if (props?.metric === 'acos' || props?.metric === 'ctr' || props?.metric === 'cpc' || props?.metric === 'roas') {
      total = total / 24
    } else {
      total = Math.round(total)
    }
    return (
      <div>
        {total?.toLocaleString()}
      </div>
    )
  };


  const performanceBody = (props: any, options: any) => {
    const { metric, range, rowIndex } = props;
    // console.log(metric,range);
    // console.log(props);
    // return (
    //   options.rowIndex % 2 === 0 ? (
    //     <td className="center-cell">
    //       {formatedMetric(metric)}
    //     </td>
    //   ) : null
    // )

    return <div>{formatedMetric(metric)}</div>
    // return (
    //   <td className="center-cell">
    //     {metric}
    //   </td>
    // );


    // const rowSpan = rowData[rowIndex - 1] && rowData[rowIndex - 1].metric === props.metric ? undefined : 2;
    // return rowSpan ? <td rowSpan={rowSpan}>{props.metric}</td> : null;
  };



  const handleRowSelect = (e: any) => {
    // console.log('selected', e);
    // if (e?.field === 'metric') {
    //   setSelectedMetric2(e.value)
    // }
    setSelectedMetric2(e.value)
  };


  const exportToCSV = () => {
    // Step 1: Create the header row
    const headers = ['Metrics/Hours', 'Date Range', ...time_stamps.map(ts => `${ts}`), 'Grand Total'];
    let csvContent = headers.join(',') + '\n';
  
    // Step 2: Append each row's data
    rowData.forEach((row: any) => {
      let total = 0;

      for (let time of time_stamps) {
        if (row[`${time}_hour`]) {
          total += row[`${time}_hour`];
        }
      }

      if (row?.metric === 'acos' || row?.metric === 'ctr' || row?.metric === 'cpc' || row?.metric === 'roas') {
        total = total / 24;
      } else {
        total = Math.round(total);
      }

      const formattedTotal = total.toLocaleString();


      const rowValues = [
        formatedMetric(row['metric']),                 // Metric column
        row['range'],                   // Range column
        ...time_stamps.map(ts => row[`${ts}_hour`]), // Time-stamp columns
        `"${formattedTotal}"` || 0               // Grand Total column
      ];
  
      // Join row data as a CSV line
      csvContent += rowValues.join(',') + '\n';
    });
  
    
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'metric_wise_performance.csv');
  };

  function calculateMetricMinMax(data: any[]) {
    const metricMinMax: { [key: string]: { min: number, max: number } } = {};

    data.forEach(row => {
      const metric = row.metric;
      if (!metricMinMax[metric]) {
        metricMinMax[metric] = { min: Number.MAX_VALUE, max: Number.MIN_VALUE };
      }

      Object.keys(row).forEach(key => {
        if (key.includes('_hour')) {
          const value = row[key];
          if (typeof value === 'number' && value !== 0) {
            metricMinMax[metric].min = Math.min(metricMinMax[metric].min, value);
            metricMinMax[metric].max = Math.max(metricMinMax[metric].max, value);
          }
        }
      });
    });

    return metricMinMax;
  }

  function heatmap(value: any, el: any, metricMinMax: { [key: string]: { min: number, max: number } }) {
    const metric = value.metric;
    const currVal = value[`${el}_hour`];

    const { min, max } = metricMinMax[metric];

    if (min === max || currVal === 0) return '#ffffff';

    const normalizedFactor = ((currVal - min) / (max - min));
    return interpolateColor('#EEEEEE', '#B1B1B1', normalizedFactor);
  }

  const metricMinMax = calculateMetricMinMax(rowData);

  // const rowClass = (data: any, options: any) => {
  //   console.log(data, options);
  //   // return options?.props?.value?.find((el:any) => el.category !== data.category) ? 'group-hover' : '';
  //   return options?.props?.value?.includes(data.metric) ? 'group-hover' : '';
  // };


  const isTableDataEmpty = rowData.every((row) => {
    // Check if all relevant data fields are empty or undefined in the row
    const dataFields = Object.keys(row).filter(key => key !== 'id' && key !== 'metric' && key !== 'range');
    return dataFields.every(field => !row[field]);  // Check if all data fields are falsy
  });

  // const customSortFunction = (e: any) => {
  //   const { field, order } = e;

  //   e.data.sort((data1:any, data2:any) => {
  //     let value1 = data1.total; 
  //     let value2 = data2.total;
  //     let result = null;

  //     if (value1 == null && value2 != null)
  //       result = -1;
  //     else if (value1 != null && value2 == null)
  //       result = 1;
  //     else if (value1 == null && value2 == null)
  //       result = 0;
  //     else if (typeof value1 === 'string' && typeof value2 === 'string')
  //       result = value1.localeCompare(value2);
  //     else
  //       result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

  //     return (order * result);
  //   });
  // };

  return (
    <div className='table_card_new'>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
      <div>
          <button className='table_btn' ><i className='bi bi-download' onClick={exportToCSV}></i></button>
        </div>
        <span className='table_kpi_name'>Metric Wise Performance</span>
      </div>
      <PrimeReactProvider>
        {isLoading ? (<LoaderSkeletonTable height='350px' />
        ) : isTableDataEmpty ? (
          <div
            style={{ padding: '5rem 0', textAlign: 'center', color: '#999', fontSize: '1.2rem' }}
          >
            No Data Available
          </div>
        ) : (
          <DataTable
            value={rowData}
            size='small'
            columnResizeMode="expand"
            resizableColumns
            scrollable scrollHeight="350px"
            // virtualScrollerOptions={{ itemSize: 20 }}
            selectionMode="single"
            selection={selectedMetric2}
            // onSelectionChange={(e:any) =>{return setSelectedMetric2(e.value)}}
            onSelectionChange={handleRowSelect}
            rowGroupMode="rowspan"
            groupRowsBy="metric"
            showGridlines
            metaKeySelection={false}
          // dataKey={'metric'}
          // rowClassName={rowClass} className="custom-row-hover-effect"

          >
            <Column
              key={'id'}
              field='metric'
              header='Metrics/Hours'
              style={{ width: '80px' }}
              headerStyle={{
                color: 'rgba(24, 24, 24, 1)',
                textAlign: 'center',
                fontSize: '0.75rem',
                fontWeight: 'bold',
                backgroundColor: 'rgba(245, 245, 247, 1)',
              }}
              bodyStyle={{
                color: 'rgba(24, 24, 24, 1)',
                fontSize: '0.7rem',
                fontWeight: 500,
                textTransform: 'capitalize',
              }}
              body={performanceBody}
            />
            <Column
              field='range'
              //   header='Week'
              style={{ width: '120px' }}
              headerStyle={{
                color: 'rgba(24, 24, 24, 1)',
                textAlign: 'center',
                fontSize: '0.75rem',
                fontWeight: 'bold',
                backgroundColor: 'rgba(245, 245, 247, 1)',
              }}
              bodyStyle={{
                color: 'rgba(24, 24, 24, 1)',
                fontSize: '0.7rem',
                fontWeight: 500,
                textTransform: 'capitalize',
              }}

            />
            {time_stamps.map((el, i) => {
              return (
                <Column
                  key={i}
                  header={el}
                  field={`${el}_hour`}
                  style={{ width: '42px' }}
                  headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)', }}
                  bodyStyle={{ color: 'rgba(24, 24, 24, 1)', fontSize: '0.7rem', fontWeight: 500, textTransform: 'capitalize', }}
                  bodyClassName="no-padding"
                  body={(props) => hoursBody(props, el, metricMinMax)}
                />
              );
            })}
            <Column
              field="total"
              header='Grand Total'
              // sortable
              // sortFunction={customSortFunction}
              style={{ width: '80px' }}
              headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)', }}
              bodyStyle={{ color: 'rgba(24, 24, 24, 1)', fontSize: '0.7rem', fontWeight: 500, textTransform: 'capitalize', }}
              body={totalBody}
            />
          </DataTable>
        )}
      </PrimeReactProvider>
    </div>
  );
};

export default ComparisonMetricwise;