import React, { useState } from 'react'
import HistoryIcon from '../../../../../assets/svgs/HistoryIcon'
import LoaderSkeletonTable from '../../../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import { PrimeReactProvider } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { colors } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AudienceHistory = () => {

    const [rowdata, setRowdata] = useState<any>(null);
    const [isLoadingTable, setIsLoadingTable] = useState(false);
    const navigate=useNavigate();


    const MockData = [
         {
          status:'completed',
          name:'AMC uppercase test ',
          name_id:'4243435413614803',
          description:'-',
          advertiser:'uppercase',
          advertiser_type:'Sponsored Ads',
          size:'8000',
          created_on:'Jan 14,2025 | 06:13 PM GMT+5:30',
          actions:'-'
         },
         {
            status:'completed',
            name:'AMC uppercase test',
            name_id:'4243435413614803',
            description:'-',
            advertiser:'uppercase',
            advertiser_type:'Sponsored Ads',
            size:'8000',
            created_on:'Jan 14,2025 | 06:13 PM GMT+5:30',
            actions:'-'
          },
          {
            status:'completed',
            name:'AMC uppercase test',
            name_id:'4243435413614803',
            description:'-',
            advertiser:'VIPuppercase',
            advertiser_type:'Sponsored Ads',
            size:'8000',
            created_on:'Jan 14,2025 | 06:13 PM GMT+5:30',
            actions:'-'
          },
          {
            status:'completed',
            name:'CMA uppercase test',
            name_id:'4243435413614803',
            description:'-',
            advertiser:'uppercase',
            advertiser_type:'Sponsored Ads',
            size:'8000',
            created_on:'Jan 14,2025 | 06:13 PM GMT+5:30',
            actions:'-'
          },


      ];


    const renderNameBody = (rowData: any, { rowIndex }: any) => {
        return (
          <div style={{display:'flex', flexDirection:'column'}}>
            <div style={{color:'#0065AF', fontWeight:'500'}}>{rowData.name}</div>
            <div style={{color:'#757575'}}>{rowData.name_id}</div>
          </div>
        );
      };

      const renderAdvertiserBody = (rowData: any, { rowIndex }: any) => {
        return (
          <div style={{display:'flex', flexDirection:'column'}}>
            <div style={{fontWeight:'500'}}>{rowData.advertiser}</div>
            <div style={{color:'#757575'}}>{rowData.advertiser_type}</div>
          </div>
        );
      };
      
      const renderDateBody = (rowData: any, { rowIndex }: any) => {
        console.log('rowdata',rowData.created_on)

        const dateparts=rowData.created_on.split('|');
        const dateparts1=dateparts[0];
        const dateparts2=dateparts[1];
        
        return (
          <div style={{display:'flex', flexDirection:'column'}}>
            <div style={{fontWeight:'500'}}>{dateparts1}</div>
            <div style={{color:'#757575'}}>{dateparts2}</div>
          </div>
        );
      };
      
    
    
     const [columns, setColumns] = useState<any[]>([
        { header: 'Status', field: 'status' },
        { header: 'Name', field: 'name', body:renderNameBody },
        { header: 'Description', field: 'description' },
        { header: 'Advertiser', field: 'advertiser', body:renderAdvertiserBody },
        { header: 'Size', field: 'size' },
        { header: 'Created on', field: 'created_on',body:renderDateBody },
        { header: 'Action', field: 'actions' },

      ]);

      const [searchQuery, setSearchQuery] = useState('');
      const [filteredData, setFilteredData] = useState(MockData);
    
      const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
    
        const filtered = MockData.filter(
          (item) =>
            item.name.toLowerCase().includes(query) ||
            item.description.toLowerCase().includes(query) ||
            item.advertiser.toLowerCase().includes(query)
        );
    
        setFilteredData(filtered);
      };


  return (
    <div className='audience-history-container'>
        <div className='audience-history-header'><h3>History</h3><HistoryIcon/></div>


        <div className='create-block'>
        <div className='create-audience' onClick={()=>navigate('/tools/amc-audience')}>Create Audience <i className="bi bi-plus"></i></div>
        <div className='search-box'>
          <input
            placeholder='Search'
            value={searchQuery}
            onChange={handleSearch}/>
            <i className='bi bi-search'></i>
        </div>
        </div>

        {/* table */}


        {
        isLoadingTable ? <LoaderSkeletonTable height='350px' /> :
          <div>
            <PrimeReactProvider>
              <DataTable
                value={filteredData}
                size='small'
                columnResizeMode="expand"
                resizableColumns
                showGridlines
                id='unique-table'
                scrollable scrollHeight="350px"
                paginator rows={6}
                rowsPerPageOptions={[6, 8, 12, 16, 18]}

              >
                {columns.map((col: any, index: any) => (

                  <Column
                    key={index}
                    field={col.field}
                    header={col.header}
                    body={col?.body}
                    style={{ width: '150px' }}
                    headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                    bodyStyle={{ color: '#000000', fontSize: '0.85rem', textTransform: 'capitalize' }}
                  // bodyStyle={column?.bodyStyle}
                  />

                ))}

              </DataTable>
            </PrimeReactProvider>
          </div>
      }

    </div>
  )
}

export default AudienceHistory
