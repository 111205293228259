import { PrimeReactProvider } from 'primereact/api';
import React, { useEffect, useRef, useState, ChangeEvent } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import LoaderSkeletonTable from '../../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import { toast } from 'react-toastify';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import { BASE_URL_NEW } from '../../../../appConstant';
import { useAppSelector } from '../../../../redux/hook';
import axios from 'axios';
import ProductStatus from '../../pages/ProductStatus';


const ProductStatusSKUTable :React.FC = () => {
    const [MockData, setMockData] = useState([
        {
        sku_code: 'TW010BEB013',
        product_name: 'Unibic: Double Choco Chip Cookies',
        category: "Cookies",
        pack_size: '100 Grams',
        mrp: 199,
        blinkit: 'Listed:13 Not Listed:12',
        zepto: 'Listed:13 Not Listed:12',
        instamart: 'Listed:13 Not Listed:12',
       },
       {
        sku_code: 'TW010BEB015',
        product_name: 'Unibic: Choco Nut Cookies',
        category: "Cookies",
        pack_size: '30 Grams',
        mrp: 79,
        blinkit: 'Listed:13 Not Listed:12',
        zepto: 'Listed:13 Not Listed:12',
        instamart: 'Listed:13 Not Listed:12',
       },
       {
        sku_code: 'TW025BEB033',
        product_name: 'Unibic: Choco Pista Badam Cookies',
        category: "Cookies",
        pack_size: '50 Grams',
        mrp: 129,
        blinkit: 'Listed:13 Not Listed:12',
        zepto: 'Listed:13 Not Listed:12',
        instamart: 'Listed:13 Not Listed:12',
       },
       {
        sku_code: 'TW025BEB033',
        product_name: 'Unibic: Choco Pista Badam Cookies',
        category: "Cookies",
        pack_size: '50 Grams',
        mrp: 199,
        blinkit: 'Listed:13 Not Listed:12',
        zepto: 'Listed:13 Not Listed:12',
        instamart: 'Listed:13 Not Listed:12',
        },
    ])
    const [MockDataCity, setMockDataCity] = useState([
        {
        city: 'Delhi',
        sku_code: 'TW010BEB013',
        product_name: 'Unibic: Double Choco Chip Cookies',
        blinkit_active: 12,
        blinkit_inactive: 7,
        zepto_active: 16,
        zepto_inactive: 6,
        instamart_active: 11,
        instamart_inactive: 5,
       },
       {
        city: 'Pune',
        sku_code: 'TW010BEB013',
        product_name: 'Unibic: Choco Nut Cookies',
        blinkit_active: 17,
        blinkit_inactive: 4,
        zepto_active: 15,
        zepto_inactive: 4,
        instamart_active: 14,
        instamart_inactive: 3,
       },
       {
        city: 'Mumbai',
        sku_code: 'TW010BEB013',
        product_name: 'Unibic: Choco Pista Badam Cookies',
        blinkit_active: 13,
        blinkit_inactive: 5,
        zepto_active: 9,
        zepto_inactive: 11,
        instamart_active: 6,
        instamart_inactive: 3
       },
       {
        city: 'Noida',
        sku_code: 'TW010BEB015',
        product_name: 'Unibic: Choco Pista Badam Cookies',
        blinkit_active: 18,
        blinkit_inactive: 9,
        zepto_active: 9,
        zepto_inactive: 6,
        instamart_active: 15,
        instamart_inactive: 4
        },
    ])
    const [MockDataPinCode, setMockDataPincode] = useState([
        {
        pin_code: '110059',
        product_name: 'Unibic: Double Choco Chip Cookies',
        blinkit_active: 12,
        blinkit_inactive: 7,
        zepto_active: 16,
        zepto_inactive: 6,
        instamart_active: 11,
        instamart_inactive: 5,
       },
       {
        pin_code: '110059',
        product_name: 'Unibic: Choco Nut Cookies',
        blinkit_active: 17,
        blinkit_inactive: 4,
        zepto_active: 15,
        zepto_inactive: 4,
        instamart_active: 14,
        instamart_inactive: 3,
       },
       {
        pin_code: '110059',
        product_name: 'Unibic: Choco Pista Badam Cookies',
        blinkit_active: 13,
        blinkit_inactive: 5,
        zepto_active: 9,
        zepto_inactive: 11,
        instamart_active: 6,
        instamart_inactive: 3
       },
       {
        pin_code: '110059',
        product_name: 'Unibic: Choco Pista Badam Cookies',
        blinkit_active: 18,
        blinkit_inactive: 9,
        zepto_active: 9,
        zepto_inactive: 6,
        instamart_active: 15,
        instamart_inactive: 4
        },
    ])


    const [rowSelect, setRowSelect] = useState(null)
    const [expandedRows, setExpandedRows] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [totalRow, setTotalRow] = useState<any[]>([]);
    const [kpiSelectToggle, setKpiSelectToggle] = useState<boolean>(false);
    const [kpiSelectToggle1, setKpiSelectToggle1] = useState<boolean>(false);

    const popupRef = useRef<HTMLDivElement>(null);

    const [selectedProductName, setSelectedProductName] = useState('');
    const [selectedSkuCode, setSelectedSkuCode] = useState('');
    const [selectedCity, setSelectedCity] = useState('');


    const [productStatus, setProductStatus] = useState<
      {
        platform: string,
        asins: number,
        platform_code: string,
        pname: string,
        main_cat: string,
        sub_cat: string,
        mrp: number,
        sp: number,
        listed_locations: number,
        unlisted_locations: number,
        active_locations: number,
        inactive_locations: number,
        listed_percentage: number,
        unlisted_percentage: number,
        active_percentage: number,
        inactive_percentage: number
      }[]
    >([])

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${BASE_URL_NEW}/product-status/?client_name=taali`)
          .then((res: any) => {
            console.log(res.data);
            res.data.forEach((tile: {
              platform: string,
              asins: number,
              platform_code: string,
              pname: string,
              main_cat: string,
              sub_cat: string,
              mrp: number,
              sp: number,
              listed_locations: number,
              unlisted_locations: number,
              active_locations: number,
              inactive_locations: number,
              listed_percentage: number,
              unlisted_percentage: number,
              active_percentage: number,
              inactive_percentage: number
            }) => {
              const totalListed = tile.listed_locations + tile.unlisted_locations
              tile.listed_percentage = (tile.listed_locations / totalListed) * 100;
              tile.unlisted_percentage = (tile.unlisted_locations / totalListed) * 100;
    
              const totalActive = tile.active_locations + tile.inactive_locations;
              tile.active_percentage = (tile.active_locations / totalActive) * 100;
              tile.inactive_percentage = (tile.inactive_locations / totalActive) * 100;
            });
            setProductStatus(res.data)
          }).catch((error: any) => {
            console.log(error);
          }).finally(() => {
            setIsLoading(false);
          })
      }, [])

    
    const cityOptions = ['Delhi', 'Pune', 'Mumbai', 'Nagpur', 'Noida'];


    console.log('selectedskucode out',selectedSkuCode)
    console.log('selectedcity out',selectedCity)


    // const bodyStyle = { color: 'rgba(24, 24, 24, 1)', fontSize: '0.85rem' }
    const bodyStyle = { color: '#000000', fontSize: '0.85rem' }

    const [columns, setColumns] = useState<any[]>([
        { header: 'SKU Code', field: 'sku_code', width:'120px', checked: true },
        { header: 'Product Name', field: 'pname', width:'180px', checked: true },
        { header: 'Category', field: 'main_cat', width:'90px',  checked: true },
        { header: 'Pack Size', field: 'pack_size', width:'90px', checked: true },
        { header: 'MRP', field: 'mrp', width:'70px',  checked: true },
        { header: 'Blinkit', field: 'blinkit', width:'160px',  checked: true },
        { header: 'Zepto', field: 'zepto', width:'160px', checked: true },
        { header: 'Instamart', field: 'instamart', width:'160px', checked: true },
    ]);

    const [columnsCity, setColumnsCity] = useState<any[]>([
        // { header: selectedCity ? 'Pincode':'City', field:  selectedCity ? 'pin_code':'city', width:'120px', checked: true },
        { header:'City', field:'city', width:'120px', checked: true },
        { header: 'Product Name', field: 'product_name', width:'220px', checked: true },
        { header: 'Active', field: 'blinkit_active', width:'120px',  checked: true },
        { header: 'Inactive', field: 'blinkit_inactive', width:'120px',  checked: true },
        { header: 'Active', field: 'zepto_active', width:'120px', checked: true },
        { header: 'Inactive', field: 'zepto_inactive', width:'120px', checked: true },
        { header: 'Active', field: 'instamart_active', width:'120px', checked: true },
        { header: 'Inactive', field: 'instamart_inactive', width:'120px', checked: true },

    ]);

    const [columnsPincode, setColumnsPincode] = useState<any[]>([
        { header: 'Pincode', field: 'pin_code', width:'120px', checked: true },
        { header: 'Product Name', field: 'product_name', width:'220px', checked: true },
        { header: 'Active', field: 'blinkit_active', width:'120px',  checked: true },
        { header: 'Inactive', field: 'blinkit_inactive', width:'120px',  checked: true },
        { header: 'Active', field: 'zepto_active', width:'120px', checked: true },
        { header: 'Inactive', field: 'zepto_inactive', width:'120px', checked: true },
        { header: 'Active', field: 'instamart_active', width:'120px', checked: true },
        { header: 'Inactive', field: 'instamart_inactive', width:'120px', checked: true },

    ]);


    const handleRowSelect = (e:any) => {
        console.log('selected rowSelect',e.value)
        if(selectedSkuCode===''){
           setSelectedSkuCode(e.value?.sku_code || null);
           setSelectedProductName(e.value?.product_name);
           console.log('selectedSkuCode',selectedSkuCode)
        }
        else if(selectedSkuCode && selectedCity===''){
           setSelectedCity(e.value?.city || null);
           console.log('selectedcity',selectedCity)
        }
    };

    const resetView = () => {
        setSelectedSkuCode(''); // Reset to SKU-wise view
    };


    const filteredCityData = MockData.filter((data) => data.sku_code === selectedSkuCode);



    // console.log('rowselect',rowSelect)

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setKpiSelectToggle(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, []);





    // const getColumnClassName = (field: string) => {
    //     // const isPercentageColumn = field.includes('percentage'); 
    //     return selectedColumns.includes(field) ? 'selected-column custom-header-class ' : 'custom-header-class '
    // }


    // const headerTemplate = (column: any) => {
    //     return (
    //         <div >
    //             <div className="p-column-title"  onClick={() => selectHeader(performance_type, column)}>
    //                 {column.header}
    //             </div >
    //         </div>
    //     )
    // }
    
    

    const handleColumnSelect = () => {
        setKpiSelectToggle(prev => !prev);
    }
    const handleColumnSelect1 = () => {
        setKpiSelectToggle1(prev => !prev);
    }

    // const exportToCSV = () => {
    //     let csvContent = '';
    //     const header = [...firstColumn, ...columns].map(col => col.field).join(',');
    //     csvContent += header + '\n';

    //     performanceData.forEach((row: any) => {
    //         const rowData = [...firstColumn, ...columns].map(col => row[col.field]).join(',');
    //         csvContent += rowData + '\n';
    //     });

    //     const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    //     saveAs(blob, 'table.csv');
    // };
    const getBackgroundColorHeader = (header:string) => {
        const colorMap:Record<string, string> = {
          Blinkit: '#F8F0C9',
          Instamart: '#F7D8BF',
          Zepto: '#DDBBF0',
        };
        return colorMap[header] || 'rgba(245, 245, 247, 1)'; // Default color
      };

      const headerGroup = (
        selectedSkuCode ?
        <ColumnGroup>
            <Row>
                <Column header=""  colSpan={2} style={{ width: '300px' }} />
                <Column header="Blinkit" colSpan={2} headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: getBackgroundColorHeader('Blinkit'), }} />
                <Column header="Zepto" colSpan={2} headerStyle={{ color: '#000000', textAlign: 'center', justifyContent:'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: getBackgroundColorHeader('Zepto'), }}/>
                <Column header="Instamart" colSpan={2} headerStyle={{ color: '#000000', textAlign: 'center', justifyContent:'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: getBackgroundColorHeader('Instamart'), }}/>
            </Row>
            <Row>
               { (selectedCity?columnsPincode:columnsCity).map((col)=>(
                  <Column header={col.header} 
                  headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: getBackgroundColorHeader(col.header), }}
                  style={{ width: col.width }}
                  />
               ))}
            </Row>
        </ColumnGroup>
        :
        <ColumnGroup>
            <Row>
               { columns.map((col)=>(
                  <Column header={col.header} 
                  headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: getBackgroundColorHeader(col.header), }}
                  style={{ width: col.width }}
                  />
               ))}
            </Row>
        </ColumnGroup>
        
    );
      

    return (
        <div style={{ marginTop: '1rem' }} className=' table_card_new'>
            <div style={{ position: 'relative' }}>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                    <button className='table_btn1' style={{marginRight:'0.5rem'}} >
                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.1765 5.64706H9.41176V0H3.76471V5.64706H0L6.58824 12.2353L13.1765 5.64706ZM0 14.1176V16H13.1765V14.1176H0Z" fill="black" />
                        </svg>
                    </button>
                <span style={{color:'#06486A'}}>Product Status</span>
                <div style={{margin:'0rem 0.5rem',color:'#06486A'}}>/</div>

            {!selectedSkuCode ?
                <span className='table_kpi_name'> SKU</span>  : 
                <>
                <span style={{color:'#06486A'}}><span style={{cursor:'pointer'}} onClick={()=>{setSelectedSkuCode(''); setSelectedCity('');}}>{selectedProductName}</span> <i className="bi bi-filter" style={{color:'#C1C1C1' ,cursor:'pointer'}} onClick={handleColumnSelect}>
                {kpiSelectToggle && (
            <div
                className="column_select-box"
                style={{
                    position: 'absolute',
                    marginTop: '0.5rem',
                    backgroundColor: '#fff',
                    fontStyle:'normal',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    padding: '0.5rem',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    zIndex: 999,
                }}
            >
                {MockData.map((el) => (
                    <div
                        key={el.product_name}
                        className="column_select-boxname"
                        style={{ padding: '0.3rem 0.5rem', cursor: 'pointer', textTransform: 'capitalize', fontSize: '0.85rem', color: '#000', borderBottom: '1px solid #ccc',}}
                        onClick={() =>{ setSelectedSkuCode(el.sku_code);  setSelectedProductName(el.product_name); setSelectedCity('');}} // Handle selection
                    >
                        {el.product_name}
                    </div>
                ))}
            </div>
        )}
                    </i></span>
                <div style={{margin:'0rem 0.5rem',color:'#06486A'}}>/</div>
                {!selectedCity?
                <span className='table_kpi_name'> City</span> :
                <>
                <span style={{color:'#06486A'}}><span style={{cursor:'pointer'}} onClick={()=>setSelectedCity('')}>{selectedCity} </span>
                    <i className="bi bi-filter" style={{color:'#C1C1C1', cursor: 'pointer'}} onClick={handleColumnSelect1}>
                    {kpiSelectToggle1 && (
            <div
                className="column_select-box"
                style={{
                    position: 'absolute',
                    marginTop: '0.5rem',
                    backgroundColor: '#fff',
                    fontStyle:'normal',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    padding: '0.5rem',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    zIndex: 999,
                }}
                >
                {cityOptions.map((el) => (
                    <div
                        key={el}
                        className="column_select-boxname"
                        style={{
                            padding: '0.3rem 0.5rem',
                            cursor: 'pointer',
                            textTransform: 'capitalize',
                            borderBottom: '1px solid #ccc',
                            fontSize: '0.85rem',
                            color: '#000',
                        }}
                        onClick={() => setSelectedCity(el) } // Handle selection
                    >
                        {el}
                    </div>
                ))}
            </div>
        )}
                    </i></span>
                <div style={{margin:'0rem 0.5rem',color:'#06486A'}}>/</div>
                <span className='table_kpi_name'>Pincode</span>
                </>
                }
                </>

            }

            </div>

            <PrimeReactProvider>
                {
                    isLoading ?
                        <LoaderSkeletonTable height='350px' /> :

                        <DataTable
                            value={selectedSkuCode? (selectedCity ? MockDataPinCode: MockDataCity):productStatus}
                            size='small'
                            columnResizeMode="expand"
                            resizableColumns
                            selectionMode="single"
                            selection={rowSelect}
                            onSelectionChange={handleRowSelect}
                            headerColumnGroup={headerGroup}
                            // expandedRows={MockData}
                            // onRowExpand={onRowExpand}
                            // onRowCollapse={onRowCollapse} 
                            // rowExpansionTemplate={rowExpansionTemplate}
                            showGridlines
                            id='unique-table'
                            scrollable scrollHeight="350px" 

                        >
                    { (selectedSkuCode ? (selectedCity ? columnsPincode : columnsCity ):columns).filter((column) => column.checked).map((col, index) => (  
                        <Column
                            key={index}
                            field={col.field}
                            // header={col.header}
                            // expander={allowExpansion}
                            // header={renderHeader(col.header, col.field)}
                            // style={{ width: '150px' }}
                            style={{ width: col.width }}
                           
                            bodyStyle={{ color: '#000000', fontSize: '0.85rem',textTransform: 'capitalize'}}
                            // bodyStyle={column?.bodyStyle}
                            body={col.body}
                            headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: getBackgroundColorHeader(col.header), }}
                            />
                    ))}
                        </DataTable>

                }

            </PrimeReactProvider>

        </div>
        </div>
    )
}

export default ProductStatusSKUTable