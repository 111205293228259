import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isOpen:false
};

const notificationSlice = createSlice({
    name:'notification',
    initialState:initialState,
    reducers:{
        notificationToggle(state,action){
            state.isOpen = !state.isOpen;
        }
    }
})

export const { notificationToggle } = notificationSlice.actions;

export default notificationSlice.reducer;