import { ReactNode } from "react"
import { Navigate, Outlet, RouteProps } from "react-router-dom"

interface CompProps {
    isAuthenticated: boolean,
}

const ProtectedRoute = (props: CompProps) => {
    const { isAuthenticated, } = props;
    if (!isAuthenticated) {
        return <Navigate to={'/login'} />
    }
    return (
        <Outlet />
    )
}

export default ProtectedRoute