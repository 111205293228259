
const getUserColor = (userRole: string): string => {
    if (userRole === 'AGENCY_ADMIN') {
        return '#2FE3CD'
    } else if (userRole === 'BRAND_MANAGER') {
        return '#8DBD05'
    } else if (userRole === 'BRAND_MEMBER') {
        return '#00A1AE'
    } else if (userRole === 'BRAND_VIEWER') {
        return '#5E36CC'
    } else {
        return '#FF007F'
    }
}

const formatedMetric = (str: string) => {
    if (str === 'impressions') {
        return 'Impressions'
    } else if (str === 'clicks') {
        return 'Clicks'
    } else if (str === 'ctr') {
        return 'CTR'
    } else if (str === 'cpc') {
        return 'CPC'
    } else if (str === 'orders' || str === "attributedUnitsOrdered14d") {
        return 'Units'
    } else if (str === 'conversions' || str === 'attributedConversions14d') {
        return 'Orders'
    } else if (str === 'acos') {
        return 'ACOS'
    } else if (str === 'cost') {
        return 'Spend'
    } else if (str === 'sales' || str === 'attributedSales14d') {
        return 'Sales'
    } else if (str === 'roas') {
        return 'ROAS'
    } else if (str === 'tos_is') {
        return 'TOS_IS'
    } else if (str === 'cvr') {
        return 'CVR'
    }
}

const formatedMetricWithUnits = (str: string) => {
    if (str === 'impressions') {
        return 'Impressions'
    } else if (str === 'clicks') {
        return 'Clicks'
    } else if (str === 'ctr') {
        return 'CTR(in %)'
    } else if (str === 'cpc') {
        return 'CPC(in ₹)'
    } else if (str === 'orders' || str === "attributedUnitsOrdered14d") {
        return 'Units'
    } else if (str === 'conversions' || str === 'attributedConversions14d') {
        return 'Orders'
    } else if (str === 'acos') {
        return 'ACOS(in %)'
    } else if (str === 'cost') {
        return 'Spend(in ₹)'
    } else if (str === 'sales' || str === 'attributedSales14d') {
        return 'Sales(in ₹)'
    } else if (str === 'roas') {
        return 'ROAS'
    } else if (str === 'tos_is') {
        return 'TOS_IS'
    } else if (str === 'cvr') {
        return 'CVR(in %)'
    }
}

const formatedMetricAsHeader = (str: string) => {
    if (str === 'impressions') {
        return 'IMPRESSIONS'
    } else if (str === 'clicks') {
        return 'CLICKS'
    } else if (str === 'ctr') {
        return 'CTR'
    } else if (str === 'cpc') {
        return 'CPC'
    } else if (str === 'orders' || str === "attributedUnitsOrdered14d") {
        return 'ORDERS'
    } else if (str === 'conversions' || str === 'attributedConversions14d') {
        return 'CONVERSIONS'
    } else if (str === 'acos') {
        return 'ACOS'
    } else if (str === 'cost') {
        return 'SPEND'
    } else if (str === 'sales' || str === 'attributedSales14d') {
        return 'SALES'
    } else if (str === 'roas') {
        return 'ROAS'
    } else if (str === 'tos_is') {
        return 'TOS_IS'
    } else if (str === 'cvr') {
        return 'CVR'
    }
}

// category,placement,ad_asin,campaign_type,type,keyword_type,keyword,range: Performace Type
const performanceName = (str: string) => {
    if (str === "category") {
        return 'CATEGORY'
    } else if (str === 'campaign_type') {
        return 'AD-TYPE'
    } else if (str === 'range') {
        return 'RANGE'
    } else if (str === 'ad_asin') {
        return 'ASIN'
    } else if (str === 'ad_asin') {
        return 'ASIN'
    } else if (str === 'placement') {
        return 'PLACEMENT'
    } else if (str === 'placement') {
        return 'PLACEMENT'
    } else if (str === 'type') {
        return 'TARGETING'
    } else if (str === 'keyword') {
        return 'TARGETING-WISE'
    } else if (str === 'campaign_name') {
        return 'CAMPAIGN NAME'
    }
}

const formatedMetricSymbol = (str: string, value: any) => {
    if (str === 'impressions') {
        return value;
    } else if (str === 'clicks') {
        return value
    } else if (str === 'ctr') {
        return value + '%';
    } else if (str === 'cpc') {
        return '₹' + value;
    } else if (str === 'orders' || str === "attributedConversions14d") {
        return value;
    } else if (str === 'conversions' || str === 'attributedConversions14d') {
        return Math.round(value) + '%';
    } else if (str === 'acos') {
        return Math.round(value) + '%';
    } else if (str === 'cost') {
        return '₹' + value;
    } else if (str === 'sales' || str === 'attributedSales14d') {
        return '₹' + value;
    } else if (str === 'roas') {
        return Math.round(value) + '%';
    } else if (str === 'tos_is') {
        return Math.round(value) + '%';
    } else if (str === 'units' || str === "attributedUnitsOrdered14d") {
        return value;
    } else if (str === 'cvr') {
        return Math.round(value * 100) / 100 + '%';
    }
}

const formatSingleDateFn = (date: any) => {
    const dateNew = new Date(date);
    const month = dateNew.toLocaleString('default', { month: 'short' });
    return `${dateNew.getDate()} ${month}`;
}
const formatSingleDateFnWithYear = (date: any) => {
    const dateNew = new Date(date);
    const month = dateNew.toLocaleString('default', { month: 'short' });
    return `${dateNew.getDate()} ${month}, ${dateNew.getFullYear()}`;
}

const formatDate = (date: any) => {
    let year = date?.getFullYear();
    let month = String(date?.getMonth() + 1).padStart(2, '0'); // Add 1 because months are 0-indexed
    let day = String(date?.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`
}

const getFlowColor = (flow: number) => {
    let color;
    if (flow > 0) {
        color = "#4CAF50";
    } else if (flow < 0) {
        color = "#F44336";
    } else if (flow === 0) {
        color = "#f5bf42"
    }
    return color;
}
const getFlowBackColor = (flow: number) => {
    let color;
    if (flow > 0) {
        color = "#06D00133";
    } else if (flow < 0) {
        color = "#C8003633";
    } else if (flow === 0) {
        color = "#f5bf4233"
    }
    return color;
}

const getFlowColorReverse = (flow: number) => {
    let color;
    if (flow > 0) {
        color = "#F44336";
    } else if (flow < 0) {
        color = "#4CAF50";
    } else if (flow === 0) {
        color = "#f5bf42"
    }
    return color;
}
const getFlowBackColorReverse = (flow: number) => {
    let color;
    if (flow > 0) {
        color = "#C8003633";
    } else if (flow < 0) {
        color = "#06D00133";
    } else if (flow === 0) {
        color = "#f5bf4233"
    }
    return color;
}

const getFlowColorForMetric = (flow: string, metric: string) => {
    if (["cost", "acos", "cpc"].includes(metric)) {
        if (flow === "positive") {
            return "#F44336";
        } else if (flow === "negative") {
            return "#4CAF50";
        } else if (flow === "zero") {
            return "#f5bf42"
        }
    } else {
        if (flow === "positive") {
            return "#4CAF50";
        } else if (flow === "negative") {
            return "#F44336";
        } else if (flow === "zero") {
            return "#f5bf42"
        }
    }
}

const getFlowColorForMetricBack = (flow: string, metric: string) => {
    if (["cost", "acos", "cpc"].includes(metric)) {
        if (flow === "positive") {
            return "#C8003633";
        } else if (flow === "negative") {
            return "#06D00133";
        } else if (flow === "zero") {
            return "#f5bf4233"
        }
    } else {
        if (flow === "positive") {
            return "#06D00133";
        } else if (flow === "negative") {
            return "#C8003633";
        } else if (flow === "zero") {
            return "#f5bf4233"
        }
    }
}

const getMontheInText = (monthInNumber: number) => {
    const monthObj: any = {
        "1": "Jan",
        "2": "Feb",
        "3": "Mar",
        "4": "Apr",
        "5": "May",
        "6": "Jun",
        "7": "Jul",
        "8": "Aug",
        "9": "Sep",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec",
    }
    const month = `${monthInNumber}`;
    return monthObj[month];
}

const getWeekdaysInText = (daysNum: any) => {
    const daysObj: any = {
        '0': "Sun",
        '1': "Mon",
        '2': "Tue",
        '3': "Wed",
        '4': "Thu",
        '5': "Fri",
        '6': "Sat",
    }
    const day = daysNum;
    return daysObj[day];
}

const formatDaterange = (str1: string, str2: string) => {
    let dateOne = new Date(str1);
    let dateTwo = new Date(str2);
    let startMonth = dateOne.toLocaleString('en-US', { month: 'short' });
    let endMonth = dateTwo.toLocaleString('en-US', { month: 'short' });

    if (dateOne.getDate() === dateTwo.getDate() && startMonth === endMonth) {
        return `${dateOne.getDate()} ${startMonth} (${getWeekdaysInText(dateOne.getDay())})`
    }

    return `${dateOne.getDate()} ${startMonth} (${getWeekdaysInText(dateOne.getDay())}) - ${dateTwo.getDate()} ${endMonth} (${getWeekdaysInText(dateTwo.getDay())})`;
}

const getTminus3date = () => {
    let today = new Date();
    today.setDate(today.getDate() - 3);
    return `${(today.toISOString()).split("T")[0]}T00:00:00.000+00:00`
}

const getLast30Days = () => {
    const last30Days = new Date();
    last30Days.setDate(last30Days.getDate() - 30)
    return `${(last30Days.toISOString()).split("T")[0]}T00:00:00.000+00:00`
}
const getLast31Days = () => {
    const last31Days = new Date();
    last31Days.setDate(last31Days.getDate() - 31)
    return `${(last31Days.toISOString()).split("T")[0]}T00:00:00.000+00:00`
}
const getLast7Days = () => {
    const last7Days = new Date();
    last7Days.setDate(last7Days.getDate() - 7)
    return `${(last7Days.toISOString()).split("T")[0]}T00:00:00.000+00:00`
}

function getColorComparativeMS(kpi: string) {
    if (kpi === 'attributedUnitsOrdered14d' || kpi === 'ctr' || kpi === 'attributedSales14d') {
        return '#00b2ff'
    } else if (kpi === 'clicks' || kpi === 'cpc' || kpi === 'roas') {
        return '#ffc700'
    } else if (kpi === 'impressions' || kpi === 'attributedConversions14d' || kpi === 'tos_is') {
        return '#ee3465'
    } else if (kpi === 'acos' || kpi === 'cost') {
        return '#6100ff'
    }
}

function getColorComparativeMSLight(kpi: string) {
    if (kpi === 'attributedUnitsOrdered14d' || kpi === 'ctr' || kpi === 'attributedSales14d') {
        return '#bfebff'
    } else if (kpi === 'clicks' || kpi === 'cpc' || kpi === 'roas') {
        return '#fff1bf'
    } else if (kpi === 'impressions' || kpi === 'attributedConversions14d' || kpi === 'tos_is') {
        return '#faccd8'
    } else if (kpi === 'acos' || kpi === 'cost') {
        return '#d7bfff'
    }
}

function hexToRgb(hex: string) {
    hex = hex.replace(/^#/, '');
    if (hex.length === 3) {
        hex = hex.split('').map(function (hex) {
            return hex + hex;
        }).join('');
    }
    const bigint = parseInt(hex, 16);
    return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
}

function interpolateColor(color1: string, color2: string, factor: number) {
    if (factor === undefined) {
        factor = 0.5;
    }
    const c1 = hexToRgb(color1);
    const c2 = hexToRgb(color2);
    const result = c1.map((value, index) =>
        Math.round(value + factor * (c2[index] - value))
    );
    return "#" + ((1 << 24) + (result[0] << 16) + (result[1] << 8) + result[2]).toString(16).slice(1);
}

const isValidDate = (date: any) => {
    return date instanceof Date && !isNaN(date.getTime());
};



export {
    getUserColor, formatedMetric, formatedMetricSymbol, formatSingleDateFn, formatSingleDateFnWithYear,
    getFlowColor, getFlowColorReverse, performanceName, getMontheInText, getFlowBackColor, getFlowBackColorReverse,
    formatedMetricAsHeader, getFlowColorForMetric, getFlowColorForMetricBack, getWeekdaysInText, formatDaterange,
    getTminus3date, getLast30Days, getLast31Days, getLast7Days, getColorComparativeMS, getColorComparativeMSLight,
    interpolateColor, formatDate, isValidDate,formatedMetricWithUnits
}