import { configureStore } from '@reduxjs/toolkit';
import userSlice from './slices/userSlice';
import clientSlice from './slices/clientSlice';
import authSlice from './slices/authSlice';
import notificationSlice from './slices/notificationSlice';
import { marketingApi } from '../components/reports/api/reportApi';
import { dashboardReducer } from './dashboard/dashboard.slice';

export const store = configureStore({
    reducer: {
        user: userSlice,
        client: clientSlice,
        auth: authSlice,
        notification:notificationSlice,
        dashboard: dashboardReducer,
        [marketingApi.reducerPath]:marketingApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(marketingApi.middleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch