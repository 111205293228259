import React, { useState } from 'react';

interface TabComponentProps {
    tabs: { label: string; content: JSX.Element }[];
    defaultTab?: string;
}

const TabComponent: React.FC<TabComponentProps> = ({ tabs, defaultTab = tabs[0].label }) => {
    const [selectedTab, setSelectedTab] = useState(defaultTab);

    const handleTabClick = (tabLabel: string) => {
        setSelectedTab(tabLabel);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%',padding:'4px' }}>
            {/* Tab Headers */}
            <div style={{ display: 'flex' }}>
                {tabs.map((tab) => (
                    <div
                        key={tab.label}
                        onClick={() => handleTabClick(tab.label)}
                        style={{
                            padding: '6px 14px',
                            cursor: 'pointer',
                            fontSize:'15px',
                            backgroundColor: selectedTab === tab.label ? 'rgba(205, 218, 225, 1)' : '#fff',
                            fontWeight: selectedTab === tab.label ? '500' : 'normal',
                            color: selectedTab === tab.label ? 'rgba(6, 72, 106, 1)':'rgba(91, 104, 113, 1)',
                            borderRadius:'30px'
                        }}
                    >
                        {tab.label}
                    </div>
                ))}
                <button className='table_btn' ><i className='bi bi-download'></i></button>
            </div>

            {/* Tab Content */}
            {/* <div style={{ flex: 1, padding: '20px' }}>
                {tabs.find((tab) => tab.label === selectedTab)?.content}
            </div> */}
        </div>
    );
};

export default TabComponent;
