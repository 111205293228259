import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './authStyle.scss';
import { toast } from 'react-toastify';
import Loader from '../../../utils/commonComponents/Loader/Loader';
import EnlyticalLogo from "../../../assets/EnlyticalLogo3.png";
import { BASE_URL_NEW } from '../../../appConstant';


const ForgetPassword = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [state, setState] = useState({
        email: "",
    });

    // useEffect(() => {
    //     const loginEmail = localStorage.getItem("loginEmail");
    //     if (loginEmail) {
    //         setState({
    //             email: loginEmail
    //         })
    //     }
    // }, []);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value);
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const onClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const { email } = state;
        if (email === "") {
            toast.error('email is required')
            return;
        } else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
            toast.error('Invalid email')
            return;
        }
        setLoader(true);
        try {
            const res = await axios.post(`${BASE_URL_NEW}/auth/forgot-password`, {
                email: state.email
            });
            // console.log(res);
            setLoader(false);
            toast.success(res.data.message)
            localStorage.setItem('email', state.email);
            navigate(`/verifyOtp`);
        } catch (error: any) {
            setLoader(false);
            console.log(error);
            toast.error(error?.response.data.error || 'no user exist')
        }
    }

    const goBackClick = (() => {
        navigate('/login');
    })
    return (
        <div className='login'>
            <div className="login-left">

            </div>
            <div className="login-right">
                <div className="login-loginContainer">
                    <div style={{ display: "flex", justifyContent: 'center' }}>
                        <img src={EnlyticalLogo} alt="logo" height={"80px"} />
                    </div>
                    <div className="login-loginContainerHeader">
                        <h5 style={{ color: '#384D6C', fontWeight: '700' }} className="mb-2">Forget Password</h5>
                    </div>
                    <hr />
                    <form className='form'>
                        <div className="mb-2"  >
                            <label className="mb-1 login-label">Enter email</label>
                            <input
                                placeholder="name@example.com"
                                className='form-control login-input'
                                type="email"
                                onChange={onChange}
                                name={"email"}
                                value={state.email}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <button
                                style={{ backgroundColor: "var(--secondary-color)", color: "#fff", fontSize: '17px' }}
                                className="btn btn-m col-lg-5 " onClick={goBackClick}>&larr; Go Back
                            </button>
                            <button
                                style={{ backgroundColor: "var(--active-color)", color: "#fff", fontSize: '17px', }}
                                className="btn btn-m col-lg-5" onClick={onClick}>Confirm Email
                            </button>
                        </div>
                    </form>
                </div>
                {
                    loader &&
                    <div style={{ position: 'absolute', top: '50%', right: '25%' }}>
                        <Loader />
                    </div>
                }
            </div>
        </div >
    )
}

export default ForgetPassword