import { useEffect, useRef, useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import axios from 'axios';
import { BASE_URL_NEW, USER_ROLES } from '../../../appConstant';
import { useAppSelector } from '../../../redux/hook';
import CustomSelect from '../../../utils/commonComponents/customSelect/CustomSelect';
import { toast } from 'react-toastify';
import Loader2 from '../../../utils/commonComponents/Loader/Loader2';

const Invite = () => {
    const toggleRef = useRef();
    const user = useAppSelector((state) => state.user);
    const appParams = useAppSelector((state) => state.client);
    const { current_agency, current_brand: { access_role } } = appParams;
    const [btnState, setBtnState] = useState('Invite');
    const [toggleBtnState, setToggleBtnState] = useState(false);
    const option = ['Invite', 'Request Access'];
    // const option = ['Invite'];
    const [isLoading, setIsLoading] = useState(false)
    const [potentialInvites, setPotentialInvites] = useState([])
    const [selectedBrand, setSelectedBrand] = useState("")
    const [selectedAccess, setSelectedAccess] = useState("")
    const [selectedEmail, setSelectedEmail] = useState("")


    useEffect(() => {
        const handleClickedOutside = (e) => {
            if (toggleRef.current && !toggleRef.current.contains(e.target)) {
                setToggleBtnState(false);
            }
        }
        document.addEventListener('mousedown', handleClickedOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickedOutside)
        }
    }, [])

    useEffect(() => {
        setIsLoading(true)
        if (btnState === "Invite") {
            axios.get(`${BASE_URL_NEW}/user/invites/${user?._id}`)
                .then((res) => {
                    console.log(res.data)
                    setPotentialInvites(res.data)
                }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    setIsLoading(false)
                })
        } else if (btnState === 'Request Access') {
            axios.get(`${BASE_URL_NEW}/user/requests/${user?._id}`)
                .then((res) => {
                    // console.log(res.data)
                    setPotentialInvites(res.data)
                }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    setIsLoading(false)
                })
        }

    }, [btnState, user?._id])

    const handleEmailChange = ({ target: { name, value } }) => {
        setSelectedEmail(value);
    };

    const handleSelectChange = ({ target: { name, value } }) => {
        setSelectedBrand(value);
    };


    const handleChangeAccess = ({ target: { name, value } }) => {
        setSelectedAccess(value);
    };

    const handleToggle = () => {
        setToggleBtnState(!toggleBtnState);
    }

    const handleBtnState = (el) => {
        setBtnState(el);
        setToggleBtnState(!toggleBtnState);
    }

    const handleSubmit = () => {
        if (!(selectedBrand && selectedAccess)) {
            toast.error('Select Both email, brand, role')
        }
        if (btnState === 'Invite') {
            // console.log(selectedBrand, selectedAccess);

            const invitation = {
                email: selectedEmail,
                access_type: 'BRAND',
                access_role: selectedAccess,
                invited_by: user._id,
                brand: selectedBrand,
                // agency: potentialInvites.filter((invite) => invite.client_id == selectedBrand)[0].agency
                agency: current_agency._id
            }

            setIsLoading(true)
            axios.post(`${BASE_URL_NEW}/access/invite`, invitation)
                .then((res) => {
                    console.log(res);
                    toast.success('Invitation sent successfully')
                }).catch((error) => {
                    console.log(error);
                    toast.error(error.response.data.error || 'Somthing went wrong')
                }).finally(() => {
                    setIsLoading(false)
                })

        } else {
            const request = {
                email: selectedEmail,
                access_type: 'BRAND',
                access_role: selectedAccess,
                brand: selectedBrand,
                // agency: potentialInvites.filter((invite) => invite.client_id == selectedBrand)[0].agency
                agency: current_agency._id
            }

            setIsLoading(true)
            axios.post(`${BASE_URL_NEW}/access/request`, request)
                .then((res) => {
                    console.log(res);
                    toast.success('Request access sent successfully')
                }).catch((error) => {
                    console.log(error);
                    toast.error(error.response.data.error || 'Somthing went wrong')
                }).finally(() => {
                    setIsLoading(false)
                })

        }
        setSelectedAccess("");
        setSelectedBrand('');
        setSelectedEmail('')
    }


    return (
        <div>
            {
                <div className='inviteBox'>
                    {
                        btnState === 'Invite' &&
                        <input className='inviteBox-email' type="text" placeholder='Enter Email' onChange={handleEmailChange} value={selectedEmail} />
                    }
                    <CustomSelect className={btnState === 'Invite' ? 'inviteBox-select1' : 'inviteBox-select3'} value={selectedBrand} onchange={handleSelectChange}>
                        <option value="" disabled>Select Brand/Role</option>
                        {potentialInvites.map((invite) => {
                            return <option value={invite.client_id}>{invite.client_name}</option>
                        })}
                    </CustomSelect>
                    <CustomSelect className='inviteBox-select2' value={selectedAccess} onchange={handleChangeAccess}>
                        <option value="" disabled>Select Role</option>
                        {
                            potentialInvites.filter((invite) => invite.client_id === selectedBrand)[0]?.access_roles.map((access) => {
                                return <option value={access}>{USER_ROLES[access]}</option>
                            })
                        }
                    </CustomSelect>
                    {access_role === 'AGENCY_ADMIN' &&
                        <button className={access_role === 'AGENCY_ADMIN' ? 'inviteBox-btn' : 'inviteBox-btn2'} onClick={handleSubmit}>{isLoading ? <span><Loader2 /></span> : "Invite"}</button>
                    }
                    {(access_role === 'BRAND_MEMBER' || access_role === 'BRAND_VIEWER') &&
                        <button className={(access_role === 'BRAND_MEMBER' || access_role === 'BRAND_VIEWER') ? 'inviteBox-btn' : 'inviteBox-btn2'} onClick={handleSubmit}>{isLoading ? <span><Loader2 /></span> : "Request Access"}</button>
                    }
                    {
                        access_role === 'BRAND_MANAGER' &&
                        <>
                            <button className='inviteBox-btn2' onClick={handleSubmit}>{isLoading ? <span><Loader2 /></span> : btnState}</button>
                            <div ref={toggleRef} className='inviteBox-btnSelect'>
                                <div onClick={handleToggle} className='inviteBox-btnOption'><ArrowDropDownIcon /></div>
                                {
                                    toggleBtnState &&
                                    <div className='inviteBox-btnOptionBox'>
                                        {option.map(el => {
                                            return <div className='inviteBox-btnOptionEl' key={el} onClick={() => handleBtnState(el)}>{el}</div>
                                        })}
                                    </div>
                                }
                            </div>
                        </>
                    }

                </div>

            }

        </div>
    )
}

export default Invite