import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import HistoryIcon from '../../../../assets/svgs/HistoryIcon';
import { toast } from 'react-toastify';
import { Calendar } from 'primereact/calendar';

const ReportSchedulingAMC = () => {

  const navigate = useNavigate();
  const [timeChange,setTimeChange]=useState('')
  const [dates, setDates] = useState<Date[]>([]);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);


 
  const handleRequestTimeChange = (type: string) => {
    if(type==='now'){
      setShowCalendar(false);
    }
    if (type === "future" || type === "recurring") {
      // <Calendar/>
      setShowCalendar(true);
    }
  };
  


  return (
    <div className='report-schedulingAMC-Container'>
      <div className='scheduling-header'><h3>Report Scheduling</h3><i className="bi bi-clipboard2-data" style={{display:'flex',alignItems:'center', fontSize:'1.1rem',marginBottom:'10px'}}></i></div>
      <div className='arrow-btn' onClick={() => navigate('/tools/amc/history')}><i className="bi bi-arrow-left-circle-fill"></i> Back to History</div>


      <div className='report-container'>
        <div className='box'> <div className='box1'>Report Name </div> <div className='box-contain'><input placeholder='Report Name'/></div></div>
        <div className='box'> <div className='box1'>Recipients</div>  <div className='box-contain recipients-input'><input placeholder='Enter Email' /><button className="add-btn">Add <i className="bi bi-plus"></i></button></div></div>
        <div className='box'> <div className='box1'>Request Time </div>
          <div className='box-contain1'>
            <div><input type='radio' name="requestTime" id="now"      defaultChecked  onChange={() => handleRequestTimeChange("now")} /><label htmlFor="now">Now</label></div>
            <div><input type='radio' name="requestTime" id="future"     onChange={() => handleRequestTimeChange("future")}/><label htmlFor="future">Future</label></div>
            <div><input type='radio' name="requestTime" id="recurring"  onChange={() => handleRequestTimeChange("recurring")}/><label htmlFor="recurring">Recurring</label></div>
          </div>
          { showCalendar &&
          <Calendar  value={dates} numberOfMonths={1}
                selectionMode="range" readOnlyInput hideOnRangeSelection showButtonBar showMinMaxRange showIcon />
          }
        </div>


      </div>

      <button className="common-btn">Finish </button>


    </div>
  )
}

export default ReportSchedulingAMC
