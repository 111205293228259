import { useState } from 'react'
import BrandPage from '../pages/BrandPage';
import AgencyPage from '../pages/AgencyPage';
import Invite from './Invite';
import '../pages/userstyle.scss';

const ManagementDetails = () => {
  const [tabs, setTabs] = useState('agency');
  const [search, setSearch] = useState('')
  const [brandList, setBrandList] = useState([]);
  const [UsersData, setUsersData] = useState([]);
  const [brandWiseUserData, setBrandWiseUserData] = useState([]);

  const [searchClick,setSearchClick]=useState(false);


  // console.log(UsersData, brandWiseUserData);

  const filterUserData = UsersData?.filter((el) => (el.first_name)?.toLowerCase().includes(search.toLowerCase())
    || (el.last_name)?.toLowerCase().includes(search.toLowerCase())
  );
  const filterBrandList = brandList?.filter((el) => (el.client_name)?.toLowerCase().includes(search.toLowerCase()));

  // const filterBrandWiseUser = brandWiseUserData?.filter((el) => (el.user.first_name)?.toLowerCase().includes(search.toLowerCase()) ||
  //   (el.user.last_name)?.toLowerCase().includes(search.toLowerCase())
  // );

  const handleTabs = (tab) => {
    setTabs(tab);
  }


  return (
    <div className='detailsPage'>
      <div className='detailsPage-header'>

        <div className='detailsPage-btn_box'>
          <button className={tabs === 'agency' ? 'detailsPage-box_button detailsPage-box_button_active' : 'detailsPage-box_button'}
            onClick={() => handleTabs('agency')}>Users</button>
          <button className={tabs === 'brands' ? 'detailsPage-box_button detailsPage-box_button_active' : 'detailsPage-box_button'}
            onClick={() => handleTabs('brands')}>Brands</button>
        </div>

        <div className='detailsPage-header-right'>
        {searchClick?

        <div className='detailsPage-inputBox'>
        <input value={search} onChange={(e) => setSearch(e.target.value)} className='detailsPage-input' type="text" placeholder={`${tabs === 'agency' ? 'Search User' : 'Search Brand'}`} />
        <i className="bi bi-search px-1"></i>
        </div>

        :
        <div className='wrapper-box'>
        <div className='search-input-box' onClick={()=>setSearchClick(true)}><i className="bi bi-search px-1 justify-center"></i></div>
        <div className='invite-box'>
          <Invite />
        </div>
        </div>   
      }
        <div className='request-btn' onClick={()=>setSearchClick(false)}> Request Access / Invite</div>
        </div>
        </div>


      <div >
        {tabs === 'agency' && <AgencyPage
          brandList={brandList}
          UsersData={UsersData}
          setUsersData={setUsersData}
          filterUserData={filterUserData}
        />}
        {tabs === 'brands' && <BrandPage
          brandList={brandList}
          setBrandList={setBrandList}
          // filterBrandWiseUser={filterBrandWiseUser}
          setBrandWiseUserData={setBrandWiseUserData}
          brandWiseUserData={brandWiseUserData}
          filterBrandList={filterBrandList}
        />}
      </div>
    </div>
  )
}

export default ManagementDetails