import React from 'react'

const MarketingStreamIcon = (props) => {
    return (
        <>
            {
                props.tab === 'marketingStream' ?
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.888889 2V0H15.1111V2H0.888889ZM0.888889 16V10H0V8L0.888889 3H15.1111L16 8V10H15.1111V16H13.3333V10H9.77778V16H0.888889ZM2.66667 14H8V10H2.66667V14ZM1.82222 8H14.1778L13.6444 5H2.35556L1.82222 8Z" fill="#06486A" />
                    </svg> : <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.888889 2V0H15.1111V2H0.888889ZM0.888889 16V10H0V8L0.888889 3H15.1111L16 8V10H15.1111V16H13.3333V10H9.77778V16H0.888889ZM2.66667 14H8V10H2.66667V14ZM1.82222 8H14.1778L13.6444 5H2.35556L1.82222 8Z" fill="#5B6871" />
                    </svg>
            }
        </>
    )
}

export default MarketingStreamIcon