import React from 'react'
import './Left.scss';

const Left = (props) => {
    
    const{isSidebarVisible, setIsSidebarVisible}=props
    let closeTimeout;

    const handleMouseLeave = () => {
      if (window.innerWidth <= 930) {
       closeTimeout = setTimeout(() => {
       setIsSidebarVisible(false);
         }, 200); // 200ms delay
     }
    };

    const handleMouseEnter = () => {
      clearTimeout(closeTimeout); // Prevent closing if the cursor re-enters
    };


    // const handleMouseLeave = (event) => {
    //     if (window.innerWidth <= 930) { // Ensure it only applies in responsive mode
    //       setIsSidebarVisible(false);
    //     }
    //   };


    return (
        // <div className={`backdrop dashboardsLeft  ${isSidebarVisible ? "visible" : ""}`}>
        // <div className="dashboardsLeft">
        <div className={(`backdrop dashboardsLeft ${isSidebarVisible ? "visible" : ""}`)}
             onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}
        >
            {props.children}
        </div>
    )
}

export default Left