import { useState } from 'react'
import KpiTile from '../components/KpiTile'
import { TotalSalesColFilter, TotalSalesFilter } from '../types/type'
import SalesTable from '../components/totalSales/SalesTable'
import SalesMetricTable from '../components/totalSales/SalesMetricTable'



const TotalSales = () => {

  const [selected4KpiArr, setSelected4KpiArr] = useState<any[]>([])
  const [data, setData] = useState<any[]>([])
  const [state, setState] = useState<string>('seller_central')
  const [isLoading, setIsLoading] = useState(false)
  const defaultKPI = ['conversions', 'acos', 'cost', 'sales']

  const [fs, setFs] = useState<TotalSalesFilter>({
    sub_brand_arr: [],
    category_arr: [],
    range_arr: [],
    asin_arr: [],
  })

  // console.log(fs);


  const [fs_col, setFs_col] = useState<TotalSalesColFilter>({
    sub_brand_arr_col: [],
    category_arr_col: [],
    range_arr_col: [],
    asin_arr_col: [],
  })

  // console.log(fs_col);


  return (
    <main className='totalSalesMain'>
      <section>
        <div>
          <KpiTile
            selected4KpiArr={selected4KpiArr}
            setSelected4KpiArr={setSelected4KpiArr}
            dataArr={data}
            isLoading={isLoading}
          />

        </div>
      </section>
      <section style={{ display: 'flex', justifyContent: 'center' }}>
        <div className='totalSalesTabs'>
          <span className={state === 'seller_central' ? 'totalSalesTabs-central_active' : 'totalSalesTabs-central'} onClick={() => setState('seller_central')}>Seller Central</span>
          <span className={state === 'vendor_central' ? 'totalSalesTabs-central_active' : 'totalSalesTabs-central'} onClick={() => setState('vendor_central')}>Vendor Central</span>
        </div>
      </section>
      <section style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        {/*sub brand table*/}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          <SalesTable
            tableName='Sub-Brand Wise Performance'
            firstColumn={[{ header: 'Sub-Brand', field: 'sub_brand', checked: true }]}
            selectedName={'sub_brand_arr'}
            setFs={setFs}
            selectedColumns={fs_col.sub_brand_arr_col}
            selectedColumnsName={'sub_brand_arr_col'}
            setFs_col={setFs_col}
          />
          {fs_col.sub_brand_arr_col.length > 0 &&
            <SalesMetricTable
              selectedColumns={fs_col.sub_brand_arr_col}
            />
          }
        </div>

        {/*category table*/}
        {/* <div>
          <SalesTable
            tableName='Category Wise Performance'
            firstColumn={[{ header: 'Category', field: 'category', checked: true }]}
          />
        </div> */}

        {/*range table*/}
        {/* <div>
          <SalesTable
            tableName='Range Wise Performance'
            firstColumn={[{ header: 'Range', field: 'range', checked: true }]}
          />
        </div> */}

        {/*product wise table*/}
        {/* <div>
          <SalesTable
            tableName='Product Wise Performance'
            firstColumn={[{ header: 'ASIN', field: 'ad_asin', checked: true }]}
          />
        </div> */}

      </section>
    </main>
  )
}

export default TotalSales