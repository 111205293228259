import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import { formatSingleDateFnWithYear, getLast31Days, getLast7Days, getTminus3date } from '../../../utils/commonFunction';
import { useAppSelector } from '../../../redux/hook';
import ReportsErrorPage from '../../../utils/commonComponents/errorFallback/ReportsErrorPage';
import { AG_AR, BASE_URL_NEW, BASE_URL } from '../../../appConstant';
import Loader from '../../../utils/commonComponents/Loader/Loader';
import Tippy from '@tippyjs/react';
import InfoIcon from '../../../assets/svgs/InfoIcon';

const KeywordTypeReport: React.FC = () => {
  const gridRef = useRef<any | null>(null);

  const client = useAppSelector(state => state.client);
  const { current_brand: { client_profile_id, main_category, client_id, client_name } } = client;
  const cat: any = main_category?.split('|');

  const popupRef = useRef<any | null>(null);
  const [toggle, setToggle] = useState(false);
  const [keywordCategories, setKeywordCategories] = useState([])
  const [category, setCategory] = useState<string>('');

  const [rowData, setRowData] = useState([]);
  const [dateType, setDateType] = useState('Daily');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [state, setState] = useState({
    brand: [],
    generic: [],
    competition: []
  })
  const [keywordType, setKeywordType] = useState<string>('generic');

  const [dateTypeArr, setDateTypeArr] = useState([
    { name: 'Daily', status: true },
    { name: 'Weekly', status: false },
    { name: 'Monthly', status: false },
  ])

  useEffect(() => {
    setKeywordCategories(cat);
    setCategory(cat[0])
  }, [client_profile_id])

  const dateFn = (dateType: string) => {
    if (dateType === 'Daily') {
      return getTminus3date()
    } else if (dateType === 'Weekly') {
      return getLast7Days()
    } else if (dateType === 'Monthly') {
      return getLast31Days()
    }
  }

  useEffect(() => {
    setIsLoading(true);
    if (!category) {
      setRowData([]);
      setIsLoading(false);
      return;
    }
    axios.post(`${BASE_URL}keywordTypeReport`, {
      main_category: category,
      view: dateType,
      time_stamp: dateFn(dateType),
      keyword_type: keywordType,
      client_profile_id: client_profile_id
    }).then((res) => {
      const data = res.data.data.keywordTypeReportData;
      // console.log(res.data);
      if (res.status === 200 && data.length === 0) {
        setRowData(data);
        throw Error(`Today, No Keyword type data for ${category} in ${keywordType}.`)
      }
      const generic = data?.filter((el: any) => el?.keyword_type === 'generic')
      const brand = data?.filter((el: any) => el?.keyword_type === 'brand')
      const competition = data?.filter((el: any) => el?.keyword_type === 'competition');
      setState((prevState) => ({
        ...prevState,
        brand: brand,
        generic: generic,
        competition: competition
      }))
      setError(false);
      setRowData(data);
    }).catch((err) => {
      console.log(err);
      setError(err.message)
    }).finally(() => {
      setIsLoading(false);
    })
  }, [category, dateType, keywordType])

  const DateComponentWithYear = (props: any) => {
    const { time_stamp } = props.data;
    const date = time_stamp.split('T')[0];
    return (
      <div>{formatSingleDateFnWithYear(date)}</div>
    )
  }

  const defaultColDef = useMemo(() => ({ sortable: true, resizable: true, flex: 1 }), []);
  const columnDefs: any[] = useMemo(() => ([
    { headerName: 'Date', field: 'time_stamp', cellRenderer: DateComponentWithYear },
    // { headerName: 'Main Category', field: 'main_category' },
    { headerName: 'Search Term', field: 'search_term' },
    { headerName: 'Brand', field: 'brand' },
    { headerName: 'Keyword Type', field: 'keyword_type' },
    { headerName: 'Total Search Volume', field: 'total_search_volume' },

  ]), [dateType])

  const handleCatChange = (el: any) => {
    setCategory(el);
  }

  const handleDWMChange = (e: any) => {
    setDateType(e);
  }

  const handleTabs = (keyword: string) => {
    setKeywordType(keyword);
  }
  const onBtnExport = useCallback(() => {
    // console.log(gridRef.current.api);
    const params = {
      fileName: `KeywordTypeReport-${category}-${keywordType}-${client_name}.csv`,
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, [client_id, category, keywordType])

  const tippyContent = () => {
    return (
      <div>
        <h6>Instructions</h6>
        <div>
          <ul style={{ color: '#b8b6b6', fontSize: "12px", margin: 0 }}>
            <li>You can edit the <strong>Brand column</strong> value by double click to active cell and after putting the value press enter to save it.</li>
            <li>Please Submit your saved value by clicking the <strong>Submit</strong> button down below.</li>
            <li>Mention the <strong>Brand Name</strong> according to the actual name, be specific for the brand's name.</li>
            <li>If the search term is Generic, do not use <strong>"generic", "genric"</strong> etc.</li>
          </ul>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className='keyword_cat_container-content' >
        <div style={{ display: 'flex' }}>
          {/* <div style={{ fontSize: '14px', marginRight: '10px', paddingTop: '3px' }}>Category: </div>
          <select value={category} onChange={(e) => setCategory(e.target.value)} name="" id="" className='form-select form-select-sm keyword_cat_container-select'>
            {keywordCategories?.map((category, index) => {
              return (
                <option key={index} value={category}>{category}</option>
              )
            })}
          </select> */}
          <div ref={popupRef} onClick={() => setToggle((prev) => !prev)} className='custom-select-container3'>
            <span className='custom-select3'>{category}</span>
            <span className='arrow3'><i className='bi bi-chevron-down'></i></span>
            {toggle &&
              <div className='box_3'>
                {keywordCategories?.map((option, i) => {
                  return (
                    <div key={i} onClick={() => handleCatChange(option)}>
                      <span>{option}</span>
                    </div>
                  )
                })}
              </div>
            }
          </div>

          <Tippy content={tippyContent()} placement='right-end' theme='custom-theme' arrow={false}>
            <span><InfoIcon /></span>
          </Tippy>
        </div>
        <div className='dateType'>
          {
            dateTypeArr?.map((el) => {
              return (
                <span className={dateType === el.name ? 'dateType-buttonActive' : 'dateType-button'} key={el.name} onClick={() => handleDWMChange(el.name)}>{el.name}</span>
              )
            })
          }
        </div>
      </div>
      <div className='table_card_new'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{cursor:'pointer'}}>
            <span className={keywordType === 'generic' ? 'dateType-buttonActive' : 'dateType-button'}
              onClick={() => handleTabs('generic')}>Generic</span>
            <span className={keywordType === 'brand' ? 'dateType-buttonActive' : 'dateType-button'}
              onClick={() => handleTabs('brand')}>Brand</span>
            <span className={keywordType === 'competition' ? 'dateType-buttonActive' : 'dateType-button'}
              onClick={() => handleTabs('competition')}>Competition</span>
          </div>
          <button
            style={{ border: 'none', backgroundColor: '#ffffff', padding: '0px 20px' }}
            type='button' onClick={onBtnExport}><i className="bi bi-download"></i>
          </button>
        </div>
        <div className="ag-theme-alpine" style={{ height: '80vh', width: '100%' }}>
          {error ? <div style={{ height: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              rowHeight={AG_AR.rH}
              headerHeight={40}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              suppressNoRowsOverlay={true}
              suppressDragLeaveHidesColumns={true}
              suppressMenuHide={true}
            >
            </AgGridReact>
          }

          {
            isLoading && <div style={{ position: "absolute", left: "50% ", top: "50%" }} >< Loader /></div>
          }
        </div>
      </div>
    </div >
  )
}

export default KeywordTypeReport;