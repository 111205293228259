import { useEffect, useState } from "react"
import axios from 'axios';
import { BASE_URL_NEW, ENLYTICAL_PROCESSING_BASE_URL } from '../../../appConstant'
import "./amc.scss"
import Loader1 from "../components/amc/Loader";
import { Calendar } from "primereact/calendar";
import { toast } from "react-toastify";
import HistoryIcon from "../../../assets/svgs/HistoryIcon";
import AMCanalysisIcon from "../../../assets/svgs/AMCanalysisIcon";
import HelloHandIcon from "../../../assets/svgs/HelloHandIcon";
import CalendarIcon from "../../../assets/svgs/CalendarIcon";
import ReportTable from "../components/amc/ReportTable";
import HistoryTableAMC from "../components/amc/ReportTable";
import * as XLSX from "xlsx";
import { useAppSelector } from "../../../redux/hook";
import { saveAs } from 'file-saver'
import { colors } from "@mui/material";
import Loader from "../../../utils/commonComponents/Loader/Loader";
import HistoryAMC from "../components/amc/HistoryAMC";
import { useNavigate } from "react-router-dom";
// import Loader from "../../../assets/svgs/LoaderAnimationfill";



const AMC: React.FC = () => {
    const user = useAppSelector(state => state.user)
    const brand = useAppSelector(state => state.client)
    const { current_brand } = brand;
    const token = localStorage.getItem('token');

    const [text, setText] = useState('')
    const [sql, setSql] = useState<any>({})
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const [showContent, setShowContent] = useState(false);

    const [showWelcome, setShowWelcome] = useState(true);

    const navigate = useNavigate();

    const [isLoading1, setIsLoading1] = useState(false)
    const [report, setReport] = useState<{ status: string, workflowExecutionId: string, workflowId: string, bucketName?: string, objectKey?: string, statusReason?: string }>({
        // status: 'PENDING',
        // workflowExecutionId: 'ff98f756-1c70-454f-9af0-37a4475a8553',
        // workflowId: 'transient_e92490d2-acfe-4d55-ae04-8f37beb0a955',
        status: '',
        workflowExecutionId: '',
        workflowId: '',
        bucketName: '',
        objectKey: '',
        statusReason: ''
    })

    // console.log('sqldata', sql);
    // console.log('reportdata', report);


    const [dates, setDates] = useState<Date[]>([]);
    const [intervalRange, setIntervalRange] = useState<any>({})

    const [reportData, setReportData] = useState<any[]>([]);
    // const [uploadedFile, setUploadedFile] = useState<string | null>(null);
    const [isLoadingTable, setIsLoadingTable] = useState(false);
    const [columns, setColumns] = useState([]); // Column definitions

    // const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [amcStatus,setAmcStatus] = useState('SQLPENDING');
    // console.log(amcStatus);
    

    const handleIntervalChange = (interval_range?: any) => {
        if (!interval_range) {
            if (dates && dates[0] && dates[1]) {
                interval_range = [dates[0], dates[1]]
            } else {
                var today = new Date();

                const yesterday = new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate() - 7
                )
                interval_range = [yesterday, today]
            }
        }

        interval_range = interval_range.map((date: Date) => {
            if (!date) return
            return new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                6,
                15,
                0
            )
        })
        if (interval_range[0] && interval_range[1]) {
            setIntervalRange({
                "start_date": interval_range[0].toISOString().split("T")[0],
                "end_date": interval_range[1].toISOString().split("T")[0]
            })
        }
        setDates(interval_range)
    }

    useEffect(() => {
        handleIntervalChange()
    }, [])


    const handleTextChange = ({ target: { value } }: any) => {
        setText(value)
    }

    const handleSqlChange = ({ target: { value } }: any) => {
        setSql((prevState: any) => ({ ...prevState, sql: value }))
    }

    const handleSqlSubmit = () => {
        axios.post(`${BASE_URL_NEW}/amc/generateReport`,
            {
                id: sql.id,
                sql: sql.sql,
                timeWindowStart: intervalRange.start_date,
                timeWindowEnd: intervalRange.end_date
            },
            {
                headers: {
                    token
                }
            }
        ).then((res: any) => {
            res = res.data
            // console.log(res);
            setReport({
                status: res.status,
                workflowExecutionId: res.workflowExecutionId,
                workflowId: res.workflowId
            })
            setAmcStatus(res.amcStatus)
            
            // setSql((prevState: any) => ({ ...prevState, amcStatus: res.amcStatus }))
            // console.log('sqldatagenerate',sql);
        }).catch((err) => {
            console.log(err);
            setError(err.message)
        }).finally(() => {
            setIsLoading(false);
        })
    }


    const handleReportCheckSubmit = () => {        
        setIsLoading(true)
        axios.post(`${BASE_URL_NEW}/amc/checkReport`,
            { workflowExecutionId: report.workflowExecutionId, id: sql.id },
            {
                headers: {
                    token
                }
            }
        ).then((res: any) => {
            res = res.data
            // console.log(res);
            if (res?.status === 'SUCCEEDED') {
                const currReport = {
                    status: res.status,
                    workflowExecutionId: res.workflowExecutionId,
                    workflowId: res.workflowId,
                    bucketName: res.bucketName,
                    objectKey: res.objectKey,
                    statusReason: res.statusReason
                }

                setReport(currReport);
                setAmcStatus(res.amcStatus)
                // setSql((prevState: any) => ({ ...prevState, amcStatus: res.amcStatus }))
                // console.log('sqldataCheck',amcStatus);
            }

        }).catch((err) => {
            console.log(err);
            setError(err.message)
        }).finally(() => {
            setIsLoading(false);
        })
    }


    // useEffect(() => {
    //     const fetchAndProcessFile = async () => {
    //         if (!uploadedFile) return;

    //         setIsLoadingTable(true);
    //         try {
    //             const response = await axios.get(uploadedFile, {
    //                 responseType: "blob",

    //             });

    //             const fileBlob = response.data;
    //             const reader = new FileReader();

    //             reader.onload = (e: any) => {
    //                 const data = new Uint8Array(e?.target?.result);
    //                 const workbook = XLSX.read(data, { type: 'array' });
    //                 const sheetName = workbook.SheetNames[0];
    //                 const sheet = workbook.Sheets[sheetName];

    //                 const rawData = XLSX.utils.sheet_to_json(sheet, { header: 1 }) as Array<any>;
    //                 const headers = rawData[0];

    //                 const dataJson = XLSX.utils.sheet_to_json<Record<string, any>>(sheet);
    //                 setReportData(dataJson);
    //                 setIsLoading(false);
    //                 const extractedColumns = headers.map((key: string) => ({
    //                     header: key,
    //                     field: key.toString(),
    //                 }));
    //                 setColumns(extractedColumns);

    //             };

    //             reader.readAsArrayBuffer(fileBlob);

    //         } catch (error) {
    //             console.error("Error reading the file:", error);
    //         } finally {
    //             setIsLoadingTable(false);
    //         }
    //     };

    //     fetchAndProcessFile();
    // }, [uploadedFile]);



    // const handleGeneratePresignedUrlSubmit = () => {
    //     setIsLoading(true)
    //     axios.post(`${ENLYTICAL_PROCESSING_BASE_URL}/amc/generate-presigned-url`,
    //         { bucket_name: report.bucketName, object_key: report.objectKey },
    //         {
    //             headers: {
    //                 token
    //             }
    //         }
    //     ).then((res: any) => {
    //         // setUploadedFile(res.data.data.presigned_url);
    //     }).catch((err) => {
    //         console.log(err);
    //         setError(err.message)
    //     }).finally(() => {
    //         setIsLoading(false);
    //     })
    // }


    const handleGeneratePresignedUrlSubmit = () => {
        setIsLoading(true)
        axios.post(`${BASE_URL_NEW}/amc/generatePresignedUrl`,
            { bucketName: report.bucketName, objectKey: report.objectKey },
            {
                headers: {
                    token
                }
            }
        ).then((res: any) => {
            setAmcStatus(res.amcStatus)
            function downloadFile(url: string) {
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = '';
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
            }

            downloadFile(res.data.data.presigned_url);
            
            // setSql((prevState: any) => ({ ...prevState, amcStatus: res.amcStatus }))
            // console.log('sqldataPre',amcStatus);
        }).catch((err) => {
            console.log(err);
            setError(err.message)
        }).finally(() => {
            setIsLoading(false);
            setIsLoading1(false);
        })
    }

    const handleTextSubmit = () => {
        setIsLoading1(true)
        setShowWelcome(false)
        setSql('')
        setReport({
            status: '',
            workflowExecutionId: '',
            workflowId: '',
            bucketName: '',
            objectKey: '',
            statusReason: ''
        })
        axios.post(`${BASE_URL_NEW}/amc/textToSql`,
            {
                text,
                brand_id: current_brand.client_id
            },
            {
                headers: {
                    token
                }
            }
        ).then((res) => {
            console.log(res.data);
            // const data = res.data.data.sql;
            setSql(res.data);
            setAmcStatus(res.data.amcStatus)
        }).catch((err) => {
            console.log(err);
            setError(err.message)
        }).finally(() => {
            // setIsLoading1(false);
        })

        // if (sql) {
        //     handleSqlSubmit();
        // }
        // if (report?.workflowId) {
        //     handleReportCheckSubmit();
        // }

        // if (report?.status.toLowerCase() === 'succeeded') {
        //     handleGeneratePresignedUrlSubmit();
        // }
    }

    useEffect(() => {
        if (sql.sql?.length > 1) {
            handleSqlSubmit()
        }
    }, [sql])


    useEffect(() => {
        if (report?.status?.toLowerCase() === 'succeeded') {
            handleGeneratePresignedUrlSubmit()
        }
    }, [report])



    useEffect(() => {
        if (
            report.workflowExecutionId &&
            report.status.toLowerCase() !== "succeeded" &&
            report.status.toLowerCase() !== "rejected"
            //  && report.status.toLowerCase() !== "running"
        ) {
            const interval = setInterval(() => {
                console.log("Checking report status...");
                // toast("Checking report status...")
                handleReportCheckSubmit();
            }, 5000);

            return () => clearInterval(interval);
        }
    }, [report.workflowExecutionId, report.status, handleReportCheckSubmit]);

    const toggleExpand = () => setIsExpanded(!isExpanded);

    const handleCopySQL = () => {
        if (sql) {
            navigator.clipboard
                .writeText(sql)
                .then(() => {
                    toast("SQL query copied to clipboard!")
                })
                .catch((err) => {
                    console.error("Failed to copy SQL query: ", err);
                    toast("Failed to copy SQL query. Please try again. ")
                });
        } else {
            toast("No SQL Query to copy.")

        }
    };

    // const exportToCSV = () => {
    //     let csvContent = '';
    //     const header = columns.map((col: any) => col?.field).join(',');
    //     csvContent += header + '\n';

    //     reportData.forEach((row: any) => {
    //         const rowData = columns.map((col: any) => row[col.field]).join(',');
    //         csvContent += rowData + '\n';
    //     });

    //     const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    //     saveAs(blob, 'table.csv');
    // };

    return (
        <>
            <div className="amc">


                <div className="amc-report-title">AMC Report<AMCanalysisIcon /></div>

                {showWelcome &&
                    <div className={`amc-welcome-section ${!showWelcome ? "amc-welcome-hidden" : ""
                        }`}>
                        <div className="circular-icon">
                            <i className="bi bi-person-circle"></i>
                        </div>
                        <h3 style={{textTransform:'capitalize'}}>Welcome, {user?.first_name} ! <HelloHandIcon /></h3>
                        <p>Here to help with ideas, information, and more! Describe the data you need below.</p>
                    </div>
                }


                <div className={`content ${!showWelcome ? "content-active" : ""}`} >
                    {/* Text input area */}
                    <div className="amc-text-area">
                        <div className="input-wrapper">
                            <div className="input-row">
                                <div className="input-icon" onClick={() => navigate('history')}>
                                    <HistoryIcon />
                                </div>
                                <textarea
                                    // type="text"
                                    className="amc-input"
                                    id="text"
                                    name="text"
                                    placeholder="Write your AMC requirements here..."
                                    value={text}
                                    onChange={handleTextChange}
                                    onInput={(e) => {
                                        e.currentTarget.style.height = "auto"; // Reset the height
                                        e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`; // Set to match content
                                    }}
                                />
                                <div className="buttons amc-text-to-sql">
                                    {
                                        <div className="amc-calendar">
                                            {/* <Calendar value={dates} onChange={(e: any) => handleIntervalChange(e.value)} numberOfMonths={2}
                                        selectionMode="range" readOnlyInput hideOnRangeSelection /> */}
                                            <Calendar value={dates} onChange={(e: any) => handleIntervalChange(e.value)} numberOfMonths={2}
                                                selectionMode="range" readOnlyInput hideOnRangeSelection />
                                        </div>
                                    }
                                    <button
                                        className="execute-btn"
                                        onClick={handleTextSubmit}
                                        disabled={!text.trim()}
                                    >
                                        Execute <i className="bi bi-arrow-down-short"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>



                {/* Calendar section */}


                {sql.sql &&
                    <div className="amc-sql">
                        <div className="sql-header">
                            <h5>SQL Query</h5>
                            <div className="sql-actions">
                                <button onClick={handleCopySQL}> <i className="bi bi-copy"></i></button>
                                <button onClick={toggleExpand}>
                                    {isExpanded ? <i className="bi bi-arrows-angle-contract"></i> : <i className="bi bi-arrows-angle-expand"></i>}
                                </button>
                            </div>
                        </div>
                        <div className={`sql-content ${isExpanded ? "expanded" : ""}`}>
                            <textarea className="amc-sql-textarea" id="sql" name="sql" value={sql.sql} rows={50} cols={100} onChange={handleSqlChange}></textarea>
                        </div>
                        {/* <button  type="submit" onClick={handleSqlSubmit} className="execute-btn">Generate Report</button> */}
                    </div>
                }

                {/* {report.status &&
                <>
                    <div className="amc-report">
                        <h6 className="amc-report-status">{report.status}</h6>
                        <div>
                            {report?.status?.toLowerCase() !== 'succeeded' && <button className="amc-report-check" type="submit" onClick={handleReportCheckSubmit}>Check Status</button>}
                            {report?.status?.toLowerCase() === 'succeeded' && <button className="amc-report-check" type="submit" onClick={handleGeneratePresignedUrlSubmit}>Download</button>}
                        </div>
                    </div>
                    {report?.status?.toLowerCase() === 'rejected' && <h6>{report.statusReason}</h6>}
                </>
            } */}

                {report.status &&
                    <>
                        <ReportTable
                            handleGeneratePresignedUrlSubmit={handleGeneratePresignedUrlSubmit}
                            reportStatus={report.status}
                            reportErrorReason={report.statusReason}
                            reportData={reportData}
                            columns={columns}
                            // exportToCSV={exportToCSV}
                            isLoadingTable={isLoadingTable}
                        />
                    </>
                }
                {
                    isLoading && <div style={{ position: "absolute", left: "50% ", top: "50%" }} >< Loader /></div>
                }

                {/* loader in test */}
                {isLoading1 &&
                    <div style={{ position: "absolute", left: "25% ", top: "65%" }} >< Loader1 isLoading={isLoading1} currentStatus={amcStatus} /></div>
                }

            </div>

        </>
    )

}

export default AMC