import { useState } from 'react';
import UserProfile from '../pages/UserProfile';
import AccountSettings from '../pages/AccountSettings';

const MyAccount = () => {
  const [tabs, setTabs] = useState('userProfile')

  const handleTabs = (tab) => {
    setTabs(tab);
  }

  return (
    <div style={{ height: '100%' }}>
      <div className='tabs_box'>
        <button className={tabs === 'userProfile' ? 'tabs_box-button_first tabs_box-active' : 'tabs_box-button_first'}
          onClick={() => handleTabs('userProfile')}>User Profile</button>
        <button className={tabs === 'accountSettings' ? 'tabs_box-button_last tabs_box-active' : 'tabs_box-button_last'}
          onClick={() => handleTabs('accountSettings')}>Account Settings</button>
      </div>
      <div>
        {tabs === 'userProfile' && <UserProfile />}
        {tabs === 'accountSettings' && <AccountSettings />}
      </div>
    </div>
  )
}

export default MyAccount