import React, { useState, useEffect } from "react";
// import "./Loader.css"; // Add styles here

const Loader = () => {
  const [showSecondSVG, setShowSecondSVG] = useState(false);

  useEffect(() => {
    // Change to second SVG after the loader completes one round
    const timer = setTimeout(() => {
      setShowSecondSVG(true);
    }, 2000); 

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="loader-wrapper">
      {!showSecondSVG ? (
        <svg
            className="loader-svg"
            width="90"
            height="90"
            viewBox="0 0 90 90"
            xmlns="http://www.w3.org/2000/svg"
        >
            {/* <!-- Background Circle --> */}
            <circle
                cx="45"
                cy="45"
                r="40"
                fill="none"
                stroke="#06486A"
                stroke-width="10"
            />
            {/* <!-- Animated Progress Circle --> */}
            <circle
                className="progress-circle"
                cx="45"
                cy="45"
                r="40"
                fill="none"
                stroke="#40BF80"
                stroke-width="10"
            />
        </svg>
      ) : (
        
        <svg
          className="svg1"
          width="90"
          height="90"
          viewBox="0 0 90 90"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M90 45C90 69.8528 69.8528 90 45 90C20.1472 90 0 69.8528 0 45C0 20.1472 20.1472 0 45 0C69.8528 0 90 20.1472 90 45ZM6.75 45C6.75 66.1249 23.8751 83.25 45 83.25C66.1249 83.25 83.25 66.1249 83.25 45C83.25 23.8751 66.1249 6.75 45 6.75C23.8751 6.75 6.75 23.8751 6.75 45Z"
            fill="#40BF80"
          />
          <circle cx="45" cy="45" r="40" fill="#40BF80" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.5 14C52.6394 14.1468 60.3958 17.4774 66.0999 23.2751C71.804 29.0727 75 36.874 75 45C75 53.126 71.804 60.9273 66.0999 66.7249C60.3958 72.5226 52.6394 75.8532 44.5 76C36.3606 75.8532 28.6042 72.5226 22.9001 66.7249C17.1959 60.9273 14 53.126 14 45C14 36.874 17.1959 29.0727 22.9001 23.2751C28.6042 17.4774 36.3606 14.1468 44.5 14ZM38.0505 55.0701L30.4449 47.4716C29.8223 46.848 29.4728 46.0035 29.4728 45.1232C29.4728 44.2428 29.8223 43.3983 30.4449 42.7747C31.0706 42.1539 31.917 41.8055 32.7992 41.8055C33.6815 41.8055 34.5279 42.1539 35.1536 42.7747L40.5113 48.1295L53.8464 34.8185C54.4711 34.1971 55.3171 33.8481 56.1991 33.8481C57.081 33.8481 57.927 34.1971 58.5517 34.8185C59.1735 35.4425 59.5225 36.2868 59.5225 37.1669C59.5225 38.047 59.1735 38.8913 58.5517 39.5153L42.8606 55.1781C42.542 55.4945 42.1628 55.7433 41.7455 55.9097C41.3283 56.0762 40.8817 56.1568 40.4325 56.1467C39.9833 56.1366 39.5407 56.036 39.1314 55.8511C38.7221 55.6661 38.3544 55.4005 38.0505 55.0701Z"
            fill="white"
          />
        </svg>
      )}
    </div>
  );
};

export default Loader;
