import React from 'react'

const HelloHandIcon = () => {
  return (
    
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2021_6481)">
    <path d="M29.2961 21.3125V10.5988C29.2961 9.19505 28.1581 8.05699 26.7543 8.05699C25.3504 8.05699 24.2124 9.195 24.2124 10.5988V13.2288L17.3253 6.34167C16.4648 5.48116 15.0697 5.48116 14.2092 6.34167C13.3487 7.20217 13.3487 8.59722 14.2092 9.45773L12.6362 7.88473C11.7757 7.02422 10.3806 7.02422 9.52015 7.88473C8.65965 8.74523 8.65965 10.1403 9.52015 11.0008L10.4309 11.9115C9.57037 11.0511 8.17525 11.0511 7.31481 11.9115C6.4543 12.772 6.4543 14.1671 7.31481 15.0276L9.56222 17.275C8.70172 16.4145 7.30661 16.4145 6.44616 17.275C5.58566 18.1355 5.58566 19.5306 6.44616 20.391L13.3838 27.3287C16.9452 30.8901 22.7195 30.8901 26.2809 27.3287L27.1747 26.4349C28.5891 25.0205 29.2963 23.1667 29.2963 21.3128C29.2961 21.3128 29.2961 21.3127 29.2961 21.3125Z" fill="#F4EA67"/>
    <path d="M9.88589 17.5985L9.56228 17.2749L8.76113 16.7636C7.98096 16.4789 7.07217 16.649 6.44616 17.2749C5.58566 18.1354 5.58566 19.5306 6.44616 20.391L10.7303 24.6752C9.33628 22.2613 9.05574 19.2666 9.88589 17.5985ZM7.31487 15.0275L8.52148 16.2341C8.30521 15.5644 7.77676 12.874 8.70799 11.2728C8.20027 11.3108 7.70317 11.5232 7.31493 11.9115C6.45442 12.7719 6.45442 14.167 7.31487 15.0275ZM18.4797 14.1674C18.422 14.1674 18.3648 14.1561 18.3115 14.134C18.2581 14.1119 18.2097 14.0795 18.1689 14.0386L13.8986 9.76834C13.8167 9.6858 13.771 9.57422 13.7712 9.458C13.7715 9.34178 13.8178 9.2304 13.8999 9.14823C13.9821 9.06606 14.0935 9.01978 14.2097 9.01953C14.3259 9.01928 14.4375 9.06508 14.5201 9.1469L18.7904 13.4172C18.8519 13.4787 18.8937 13.557 18.9107 13.6422C18.9276 13.7274 18.9189 13.8158 18.8857 13.8961C18.8524 13.9764 18.7961 14.045 18.7238 14.0933C18.6516 14.1416 18.5666 14.1674 18.4797 14.1674ZM15.4137 17.3336C15.356 17.3337 15.2988 17.3224 15.2455 17.3003C15.1922 17.2782 15.1437 17.2458 15.1029 17.2049L10.1201 12.2221C10.0383 12.1396 9.99255 12.028 9.99281 11.9118C9.99307 11.7956 10.0394 11.6842 10.1215 11.602C10.2037 11.5198 10.3151 11.4736 10.4313 11.4733C10.5475 11.4731 10.6591 11.5189 10.7417 11.6007L15.7244 16.5834C15.7859 16.6449 15.8278 16.7232 15.8447 16.8084C15.8617 16.8937 15.853 16.982 15.8198 17.0623C15.7865 17.1427 15.7302 17.2113 15.6579 17.2596C15.5856 17.3079 15.5006 17.3337 15.4137 17.3336Z" fill="#FFCB30"/>
    <path d="M12.8502 21.0015C12.7925 21.0016 12.7353 20.9902 12.682 20.9682C12.6287 20.9461 12.5802 20.9137 12.5395 20.8728L9.25234 17.5857C9.17053 17.5032 9.12474 17.3916 9.125 17.2754C9.12526 17.1592 9.17155 17.0478 9.25373 16.9656C9.33591 16.8834 9.4473 16.8372 9.56351 16.8369C9.67973 16.8367 9.79131 16.8825 9.87384 16.9643L13.161 20.2513C13.2224 20.3128 13.2643 20.3911 13.2812 20.4763C13.2982 20.5616 13.2895 20.6499 13.2562 20.7302C13.2229 20.8105 13.1666 20.8791 13.0944 20.9274C13.0221 20.9757 12.9371 21.0015 12.8502 21.0015Z" fill="#FFCB30"/>
    <path d="M21.1827 6.47825C20.8822 5.23132 19.8762 4.25942 18.6199 4.00237C18.5631 3.99111 18.509 3.96872 18.4609 3.93649C18.4127 3.90425 18.3714 3.86282 18.3393 3.81456C18.3073 3.76631 18.2851 3.71219 18.274 3.65531C18.2629 3.59844 18.2632 3.53994 18.2748 3.48317C18.2864 3.42641 18.3091 3.3725 18.3417 3.32455C18.3742 3.27661 18.4159 3.23557 18.4644 3.2038C18.5128 3.17203 18.5671 3.15015 18.624 3.13943C18.681 3.12871 18.7395 3.12936 18.7962 3.14134C20.3849 3.46647 21.6571 4.69542 22.0372 6.27235C22.0645 6.38565 22.0457 6.50514 21.9849 6.60456C21.9241 6.70399 21.8263 6.77519 21.713 6.80251C21.4781 6.85917 21.2399 6.7152 21.1827 6.47825ZM24.365 6.1813C23.7201 3.50632 21.5621 1.42149 18.8671 0.870007C18.7529 0.846639 18.6527 0.778873 18.5885 0.681615C18.5243 0.584357 18.5013 0.465574 18.5247 0.351394C18.5362 0.294838 18.5588 0.241111 18.5911 0.193287C18.6235 0.145462 18.6649 0.104477 18.7131 0.0726746C18.7612 0.0408726 18.8152 0.0188775 18.8719 0.00794697C18.9285 -0.00298358 18.9868 -0.00263521 19.0434 0.00897218C22.0708 0.628484 24.495 2.97042 25.2194 5.97534C25.2333 6.03159 25.236 6.09005 25.2273 6.14734C25.2186 6.20464 25.1986 6.25964 25.1685 6.30919C25.1385 6.35873 25.0989 6.40184 25.0521 6.43603C25.0053 6.47022 24.9522 6.49481 24.8958 6.50839C24.8395 6.52197 24.781 6.52427 24.7238 6.51516C24.6666 6.50605 24.6117 6.48571 24.5623 6.45531C24.513 6.42491 24.4702 6.38505 24.4363 6.33802C24.4024 6.29099 24.3782 6.23773 24.365 6.1813Z" fill="#4E5660"/>
    <path d="M6.97537 25.591C5.39849 25.2109 4.16949 23.9387 3.84441 22.3499C3.83284 22.2934 3.83253 22.2351 3.84348 22.1785C3.85443 22.1218 3.87643 22.0678 3.90824 22.0197C3.94004 21.9715 3.98102 21.9301 4.02883 21.8978C4.07664 21.8655 4.13035 21.8429 4.18689 21.8313C4.42437 21.7825 4.65681 21.936 4.7055 22.1737C4.96255 23.43 5.93439 24.4359 7.18138 24.7366C7.23766 24.7499 7.29076 24.7743 7.33761 24.8082C7.38447 24.8421 7.42417 24.8849 7.45444 24.9342C7.4847 24.9835 7.50494 25.0383 7.51399 25.0954C7.52303 25.1526 7.52071 25.2109 7.50715 25.2672C7.49359 25.3234 7.46906 25.3764 7.43497 25.4231C7.40088 25.4699 7.3579 25.5094 7.3085 25.5395C7.25909 25.5696 7.20423 25.5896 7.14706 25.5985C7.0899 25.6073 7.03155 25.6047 6.97537 25.591ZM6.67847 28.7732C3.67355 28.0488 1.33156 25.6246 0.712045 22.5971C0.688685 22.4829 0.711637 22.3641 0.775853 22.2669C0.840069 22.1696 0.940289 22.1018 1.05447 22.0785C1.11101 22.0669 1.16929 22.0665 1.22596 22.0774C1.28264 22.0884 1.33661 22.1104 1.38478 22.1422C1.43295 22.174 1.47438 22.215 1.5067 22.2628C1.53902 22.3106 1.5616 22.3643 1.57314 22.4209C2.12462 25.1158 4.20945 27.2739 6.88449 27.9187C6.94077 27.9321 6.99386 27.9564 7.04072 27.9903C7.08758 28.0243 7.12728 28.0671 7.15754 28.1164C7.18781 28.1657 7.20805 28.2205 7.21709 28.2776C7.22614 28.3347 7.22381 28.3931 7.21025 28.4493C7.19669 28.5056 7.17217 28.5586 7.13808 28.6053C7.10399 28.652 7.06101 28.6916 7.0116 28.7217C6.9622 28.7517 6.90734 28.7718 6.85017 28.7806C6.793 28.7895 6.73465 28.7869 6.67847 28.7732Z" fill="#4E5660"/>
    <path d="M22.0092 22.5402C21.8393 22.5402 21.6776 22.4412 21.6057 22.2754C20.6772 20.1332 20.8488 17.6672 22.0646 15.679C22.0947 15.6297 22.1343 15.5869 22.1809 15.5529C22.2276 15.519 22.2805 15.4945 22.3366 15.481C22.3927 15.4675 22.4509 15.4651 22.5079 15.4741C22.5649 15.4831 22.6196 15.5032 22.6689 15.5333C22.7683 15.5941 22.8395 15.6919 22.8668 15.8052C22.8941 15.9185 22.8753 16.038 22.8145 16.1375C21.7477 17.8819 21.5973 20.0458 22.4122 21.9259C22.4412 21.9927 22.4531 22.0658 22.4468 22.1384C22.4406 22.2109 22.4164 22.2809 22.3764 22.3418C22.3365 22.4027 22.282 22.4527 22.2179 22.4874C22.1538 22.522 22.0821 22.5402 22.0092 22.5402Z" fill="#FFCB30"/>
    </g>
    <defs>
    <clipPath id="clip0_2021_6481">
    <rect width="30" height="30" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
  )
}

export default HelloHandIcon
