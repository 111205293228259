import React from 'react'

const BlinkitQcomIcon = () => {
  return (
    <svg width="128" height="36" viewBox="0 0 128 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.0945 8.62314C18.259 8.62314 20.1915 9.19959 21.8922 10.353C23.6083 11.4895 24.9534 13.1035 25.9275 15.1956C26.8705 17.2053 27.3421 19.569 27.3421 22.2867C27.3421 24.9225 26.8705 27.278 25.9275 29.3537C24.9844 31.4289 23.6547 33.0516 21.9386 34.221C20.207 35.4071 18.259 36 16.0945 36C14.5175 36 13.0333 35.6541 11.6419 34.9624C10.2504 34.2706 9.05994 33.2986 8.07044 32.0468V35.3821H0V0H8.07044V12.5517C9.05994 11.2999 10.2504 10.3361 11.6419 9.66076C13.0333 8.96901 14.5175 8.62314 16.0945 8.62314ZM13.6826 28.8592C14.8267 28.8592 15.8471 28.5794 16.7439 28.0194C17.6406 27.4593 18.344 26.6769 18.8542 25.6721C19.3644 24.6837 19.6195 23.5554 19.6195 22.2867C19.6195 21.0513 19.3644 19.9312 18.8542 18.9264C18.344 17.9216 17.6406 17.1392 16.7439 16.5791C15.8471 16.0191 14.8267 15.7393 13.6826 15.7393C12.6004 15.7393 11.6341 16.0191 10.7838 16.5791C9.93345 17.1228 9.26865 17.8888 8.78936 18.8772C8.31006 19.882 8.07044 21.0185 8.07044 22.2867C8.07044 23.5554 8.31006 24.6919 8.78936 25.6967C9.26865 26.6851 9.93345 27.4593 10.7838 28.0194C11.6341 28.5794 12.6004 28.8592 13.6826 28.8592Z" fill="black"/>
            <path d="M28.4473 35.3821V0H36.5179V35.3821H28.4473Z" fill="black"/>
            <path d="M38.8047 35.382V9.21594H46.8288V35.382H38.8047Z" fill="black"/>
            <path d="M64.2608 8.62292C65.9769 8.62292 67.5228 9.05128 68.899 9.90799C70.2748 10.7478 71.3573 11.934 72.1456 13.4656C72.9032 15.0141 73.2819 16.7685 73.2819 18.7289V35.3819H65.5827V20.656C65.5827 19.7004 65.4049 18.8524 65.0492 18.111C64.7093 17.3532 64.2224 16.7685 63.5883 16.3565C62.9697 15.945 62.2434 15.7391 61.4084 15.7391C60.6196 15.7391 59.8933 15.945 59.2285 16.3565C58.5637 16.7521 58.0455 17.2957 57.6744 17.9875C57.288 18.6464 57.0947 19.4206 57.0947 20.3102L57.0486 35.3819H49.0244V9.21579H57.0486V12.2056C57.8061 11.0855 58.8185 10.2124 60.0865 9.58672C61.3541 8.94419 62.7457 8.62292 64.2608 8.62292Z" fill="black"/>
            <path d="M91.0142 20.6563L100.128 35.3821H91.0142L85.6804 26.1169L83.2222 29.1559V35.3821H75.1519V0H83.2222V19.5444L90.968 9.216H100.082L91.0142 20.6563Z" fill="black"/>
            <path d="M38.8008 0.00268555H46.851V6.71053H38.8008V0.00268555Z" fill="black"/>
            <path d="M101.409 35.3038V9.1377H109.433V35.3038H101.409Z" fill="#0C831F"/>
            <path d="M126.4 27.9159L128 33.4262C127.273 34.1671 126.353 34.7687 125.24 35.2298C124.142 35.691 123.068 35.9216 122.017 35.9216C120.502 35.9216 119.148 35.5675 117.958 34.8589C116.768 34.1343 115.84 33.1295 115.175 31.8445C114.51 30.5927 114.178 29.1513 114.178 27.5208V15.9821H110.955V9.13757H114.178V0.00268555H121.877V9.13757H126.956V15.9821H121.877V25.9395C121.877 26.7794 122.086 27.4629 122.504 27.9902C122.921 28.5174 123.454 28.7808 124.104 28.7808C124.568 28.7808 125 28.7065 125.403 28.5584C125.805 28.4103 126.137 28.1962 126.4 27.9159Z" fill="#0C831F"/>
            <path d="M101.346 0.00268555H109.396V6.71053H101.346V0.00268555Z" fill="#0C831F"/>
          </svg>
  )
}

export default BlinkitQcomIcon
