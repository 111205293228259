import { useEffect, useState } from "react";
import axios from "axios";
import { useAppSelector } from "../../../../redux/hook";
import { BASE_URL_NEW } from "../../../../appConstant";
import LineGraph from "../LineGraph";
import { Data, KPI } from "../../types/type";
import { formatedMetric } from "../../../../utils/commonFunction";
import LoaderSkeletonGraph from "../../../../utils/commonComponents/Loader/LoaderSkeletonGraph";

interface Props {
  selected4KpiArr: KPI[],
  intervalType: string,
  intervalRange: any
}

const MarketingGraph: React.FC<Props> = (props) => {
  const { selected4KpiArr, intervalType, intervalRange } = props;
  const client = useAppSelector(state => state.client);
  const { current_brand: { client_id } } = client
  const [isLoading, setIsLoading] = useState(false)
  const [state, setState] = useState<any>({
    hour: [],
    impressions: [],
    clicks: [],
    ctr: [],
    cpc: [],
    attributedUnitsOrdered14d: [],
    attributedConversions14d: [],
    acos: [],
    cost: [],
    attributedSales14d: [],
    roas: [],
    cvr:[],
    units:[]
  })


  useEffect(() => {
    if (client_id && intervalType && Object.keys(intervalRange).length > 0) {
      const { start_date, end_date } = intervalRange;
      console.log(start_date,end_date);
      
      setIsLoading(true);
      axios.get(`${BASE_URL_NEW}/marketing-stream/${client_id}/average?startDate=${start_date}&endDate=${end_date}`)
        .then((response) => {
          const graph_data_array: any[] = response.data;
          // console.log(graph_data_array);
          
          const labelsArray: string[] = [];
          const salesArray: number[] = [];
          const costArray: number[] = [];
          const acosArray: number[] = [];
          const ordersArray: number[] = [];
          const clicksArray: number[] = [];
          const cpcArray: number[] = [];
          const impressionsArray: number[] = [];
          const ctrArray: number[] = [];
          const conversionsArray: number[] = [];
          const roasArray: number[] = [];
          const cvrArray: number[] = [];
          const unitsArray: number[] = [];
          if (graph_data_array.length > 0) {
            graph_data_array.forEach(e => {
              const { hour, impressions, clicks, ctr, cpc, attributedUnitsOrdered14d, attributedConversions14d, acos, cost, attributedSales14d, roas, tos_is,cvr } = e;
              labelsArray.push(hour)
              impressionsArray.push(impressions);
              clicksArray.push(clicks);
              ctrArray.push(ctr);
              cpcArray.push(cpc);
              ordersArray.push(attributedConversions14d);
              conversionsArray.push(attributedConversions14d);
              acosArray.push(acos);
              costArray.push(cost);
              salesArray.push(attributedSales14d);
              roasArray.push(roas);
              cvrArray.push(cvr);
              unitsArray.push(attributedUnitsOrdered14d);
            })
          }
          setState((prevState: any) => ({
            ...prevState,
            hour: Array.from({ length: 24 }, (_, i) => i+1),
            impressions: impressionsArray,
            clicks: clicksArray,
            ctr: ctrArray,
            cpc: cpcArray,
            attributedUnitsOrdered14d: ordersArray,
            attributedConversions14d: conversionsArray,
            acos: acosArray,
            cost: costArray,
            attributedSales14d: salesArray,
            roas: roasArray,
            cvr: cvrArray,
            units:unitsArray
          }))
          setIsLoading(false);
        }).catch(function (error) {
          console.log(error);
          setIsLoading(false);
        });
    }
  }, [client_id, intervalRange, intervalType])

  const graphData = {
    labels: state.hour,
    datasets: [
      {
        label: formatedMetric(selected4KpiArr[0]?.metric),
        data: state[selected4KpiArr[0]?.metric],
        borderColor: 'rgba(0, 178, 255, 1)',
        backgroundColor: 'rgba(0, 178, 255, 1)',
        borderWidth: 2,
        // axesColor:
        yAxisID: 'y'
      },
      {
        label: formatedMetric(selected4KpiArr[1]?.metric),
        data: state[selected4KpiArr[1]?.metric],
        borderColor: 'rgba(255, 199, 0, 1)',
        backgroundColor: 'rgba(255, 199, 0, 1)',
        borderWidth: 2,
        yAxisID: 'y1'
      },
      {
        label: formatedMetric(selected4KpiArr[2]?.metric),
        data: state[selected4KpiArr[2]?.metric],
        borderColor: 'rgba(238, 52, 101, 1)',
        backgroundColor: 'rgba(238, 52, 101, 1)',
        borderWidth: 2,
        yAxisID: 'y2'
      },
      {
        label: formatedMetric(selected4KpiArr[3]?.metric),
        data: state[selected4KpiArr[3]?.metric],
        borderColor: 'rgba(97, 0, 255, 1)',
        backgroundColor: 'rgba(97, 0, 255, 1)',
        borderWidth: 2,
        yAxisID: 'y3'
      },
    ],
  };


  const isGraphDataEmpty = !graphData.datasets.some(dataset => dataset.data && dataset.data.length > 0);

  return (


    isLoading ? (
      <LoaderSkeletonGraph />
    ) : (
      <div
        style={{
          height: '22rem',
          backgroundColor: 'rgba(255, 255, 255, 1)',
          borderRadius: '0.5rem',
          padding: '0rem 0.5rem 0.5rem 0.5rem',
        }}
      >
        {isGraphDataEmpty ? (
          <div
            style={{ padding: '8rem 0', textAlign: 'center', color: '#999', fontSize: '1.2rem' }}>
            No Data Available
          </div>
        ) : (
          <LineGraph graphData={graphData} dataFor="marketing" />
        )}
      </div>
    )


  )
}

export default MarketingGraph