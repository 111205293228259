import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

export default function LoaderSkeletonLine() {
  return (
        <Skeleton
          variant="rectangular"
          width='100%'
          height='2rem'
        />
  );
}