import axios from 'axios';
import { PrimeReactProvider } from 'primereact/api'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useEffect, useState } from 'react';
import { BASE_URL_NEW } from '../../../../appConstant';
import { useAppSelector } from '../../../../redux/hook';
import Loader from '../../../../utils/commonComponents/Loader/Loader';
import LoaderSkeletonTable from '../../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import { interpolateColor } from '../../../../utils/commonFunction';
import { saveAs } from 'file-saver'

const AsinTable = ({ clickedKPI, selectedDate, selectedCategory }: any) => {
    const client = useAppSelector(state => state.client);
    const { current_brand: { client_id } } = client
    const [rowData, setRowData] = useState<any[]>([]);
    const time_stamps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
    const [isLoading, setIsLoading] = useState(false)
    const [dates, setDates] = useState<any[]>([])
    // console.log(clickedKPI);
    const [totalRow, setTotalRow] = useState<any[]>([]);

    const isRowDataEmpty = rowData.length === 0 || rowData.every(item => Object.keys(item).length === 0);

    useEffect(() => {
        let queryParam = selectedCategory && selectedCategory?.map((el: any) => {
            let encodedQuery = `category=${encodeURIComponent(el.category)}&`
            return encodedQuery;
        });

        let query = queryParam?.join('');

        // if (selectedCategory?.length > 0) {
        setIsLoading(true);
        axios.get(`${BASE_URL_NEW}/marketing-stream/${client_id}/asin?${query}date=${selectedDate?.date}`)
            .then((res: any) => {
                // console.log(res.data);
                const asinSet: any = {};
                res.data.forEach((item: any) => {
                    // console.log(item);
                    const performance: any = item['asin']
                    if (!asinSet[performance]) {
                        asinSet[performance] = [];
                    }

                    asinSet[performance].push(item);
                });
                let temp: any = [];
                Object.keys(asinSet).forEach((asin) => {
                    const dailyData: any = {
                        asin: asin
                    }
                    let total = 0;
                    asinSet[asin].forEach((item: any) => {
                        dailyData[item.hour+1 + '_' + clickedKPI?.metric] = Math.round(item[clickedKPI?.metric])
                        if (item['attributedSales14d']) {
                            total += Math.round(item['attributedSales14d']);
                        }
                        dailyData['total'] = total;
                    })
                    temp.push(dailyData)
                })
                temp.sort((a: any, b: any) => b.total - a.total);
                let totalObj: any = { asin: 'Total' }
                for (let hour of time_stamps) {
                    let total = 0;
                    for (let el of temp) {
                        if (el[`${hour}_${clickedKPI?.metric}`]) {
                            total += el[`${hour}_${clickedKPI?.metric}`]
                        }
                    }
                    totalObj[`${hour}_${clickedKPI?.metric}`] = total;
                }
                // console.log(totalObj);
                setTotalRow([totalObj]);
                // console.log(temp);
                setDates(dates)
                setRowData(temp);

            }).catch((error: any) => {
                console.log(error);

            }).finally(() => {
                setIsLoading(false);
            })
        // }
    }, [client_id, selectedDate, clickedKPI, selectedCategory])

    function heatmap(value: any, el: any) {
        let a: any = Object.values(value);
        a.splice(0, 1);
        const min = Math.min(...a.filter((x: number) => x !== 0));
        const max = Math.max(...a.filter((x: number) => x !== 0));
        const currVal = value[`${el}_${clickedKPI?.metric}`]
        if (min === max || currVal === 0) return '#ffffff'

        const normalizedFactor = ((currVal - min) / (max - min))
        return interpolateColor('#B3FFFF', '#1ABFFF', normalizedFactor);
    }

    const hoursBody = (props: any, el: any) => {

        return (
            <div style={{
                backgroundColor: heatmap(props, el),
                width: '100%',
                height: '100%',
                textAlign: 'center',
                padding: '10px 0px',
                boxSizing: 'border-box'
            }}>
                {props[`${el}_${clickedKPI?.metric}`] !== undefined ? (props[`${el}_${clickedKPI?.metric}`])?.toLocaleString() : '-'}
            </div>
        )
    }

    const totalBody = (props: any) => {
        // console.log(props);
        let total = 0;
        for (let time of time_stamps) {
            if (props[`${time}_${clickedKPI?.metric}`]) {
                total = total + props[`${time}_${clickedKPI?.metric}`];
            }
        }
        return (
            <div>
                {total?.toLocaleString()}
            </div>
        )
    }

    const performanceBody = (props: any) => {
        const { asin } = props;
        return (
            <div>
                {asin}
            </div>
        )
    }

    const exportToCSV = () => {
        let csvContent = '';
        const header = ['Asin/hour', ...time_stamps].map(col => col).join(',');
        csvContent += header + '\n';

        totalRow.forEach((row: any) => {
            csvContent+=row['asin'] 
            csvContent+=','
            
            const rowData = time_stamps.map(col => {
                return row[`${col}_${clickedKPI?.metric}`]
            }).join(',');
            csvContent += rowData + '\n';
        });

    
        rowData.forEach((row: any) => {
            csvContent+=row['asin'] 
            csvContent+=','
            
            const rowData = time_stamps.map(col => {
                return row[`${col}_${clickedKPI?.metric}`]
            }).join(',');
            csvContent += rowData + '\n';
        });
    
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'asin_wise_performance.csv');
      };

    return (
        <div style={{ marginTop: '1rem' }} className='table_card_new'>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    <button className='table_btn' onClick={exportToCSV}><i className='bi bi-download'></i></button>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '1rem' }}>
                    <span className='table_kpi_name'>Asin Wise Performance</span>
                </div>
            </div>
            <PrimeReactProvider>

                {isLoading ? (<LoaderSkeletonTable height='350px' />
                ) : isRowDataEmpty ? (
                    <div style={{ padding: '5rem 0', textAlign: 'center', color: '#999', fontSize: '1.2rem' }}>
                        No Data Available
                    </div>
                ) : (
                    <DataTable
                        value={rowData}
                        size='small'
                        columnResizeMode="expand"
                        resizableColumns
                        scrollable scrollHeight="350px"
                        // virtualScrollerOptions={{ itemSize: 20 }}
                        frozenValue={totalRow}
                    >
                        <Column field='asin' header='ASIN/Hour'
                            style={{ width: '100px' }}
                            headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                            bodyStyle={{ color: 'rgba(24, 24, 24, 1)', fontSize: '0.7rem', fontWeight: 500, textTransform: 'capitalize' }}
                            body={performanceBody}
                        />
                        {
                            time_stamps.map((el, i) => {
                                return (
                                    <Column
                                        key={i}
                                        header={el}
                                        field={`${el}_${clickedKPI?.metric}`}
                                        style={{ width: '42px' }}
                                        headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                                        bodyStyle={{ color: 'rgba(24, 24, 24, 1)', fontSize: '0.7rem', fontWeight: 500, textTransform: 'capitalize' }}
                                        bodyClassName="no-padding"
                                        body={(props) => hoursBody(props, el)}

                                    />
                                )
                            })
                        }
                        <Column field="total" header='Total'
                            style={{ width: '60px' }}
                            sortable
                            headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                            bodyStyle={{ color: 'rgba(24, 24, 24, 1)', fontSize: '0.7rem', fontWeight: 500, textTransform: 'capitalize' }}
                            body={totalBody}
                        />
                    </DataTable>
                )}

            </PrimeReactProvider>
        </div>
    )
}

export default AsinTable;

