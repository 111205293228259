import React, { useState } from 'react'
import ProductKPIQcom from '../components/ProductStatusQcom/ProductKPIQcom'
import ProductStatusSKUTable from '../components/ProductStatusQcom/ProductStatusSKUTable'
import ProductStatusPincodeWiseTable from '../components/ProductStatusQcom/ProductStatusPincodeWiseTable'
import ProductStatusCityWiseTable from '../components/ProductStatusQcom/ProductStatusCityWiseTable'

const ProductStatusQcom = () => {

    const [clientID, setClientID] = useState<string>('');
    const [category, setCategory] = useState<string>('');
    const [range, setRange] = useState<string>('');
    const [kpidata, setKpidata] = useState<any[]>([])
    const [isToggleOn, setIsToggleOn] = useState(true);
    const [isToggleOn2, setIsToggleOn2] = useState(true);
    const [isToggleOn3, setIsToggleOn3] = useState(true);

    // console.log(clientID);
    

    return (
        <main style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <section>
                <ProductKPIQcom/>
            </section>
            <section>
                <ProductStatusSKUTable/>                
            </section>
        </main>
    )
}

export default ProductStatusQcom