export enum AccessTypeEnum{
    AGENCY="AGENCY",
    BRAND="BRAND"
}

export enum AccessRoleEnum{
    BRAND_MEMBER='BRAND_MEMBER',
    BRAND_MANAGER='BRAND_MANAGER',
    BRAND_VIEWER='BRAND_VIEWER',
    AGENCY_ADMIN='AGENCY_ADMIN',
    ENLYTICAL_ADMIN='ENLYTICAL_ADMIN'
}


export enum ModuleEnum{
    MASTER='MASTER',
    REPORTS='REPORTS',
    MARKETING_STREAM='MarketingStream',
    AUTOMATION= 'AUTOMATION', 
    BUDGET='BUDGET', 
    TOOLS='TOOLS', 
    BENCHMARKS='BENCHMARKS', 
    CAMPAIGN_MANAGER='CAMPAIGN_MANAGER'
}

export const ActionStates = Object.freeze({
    UNINITIALISED:   "UNINITIALISED",
    PENDING:  "PENDING",
    REJECTED: "REJECTED",
    FULFILLED: "FULFILLED"
  });


export const UserDomainEnum=Object.freeze({
  ENLYTICAL_ADMIN:   "enlytical_admin",
  USER:  "user"
});