import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { PrimeReactProvider } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { PerformanceData } from '../types/type';
import { Skeleton } from 'primereact/skeleton';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import axios from 'axios';
import { BASE_URL_NEW } from '../../../appConstant';
import { useAppSelector } from '../../../redux/hook';
import Loader from '../../../utils/commonComponents/Loader/Loader';
import { formatSingleDateFn, formatedMetricAsHeader, getFlowBackColor, getFlowColor, getFlowColorForMetric, getFlowColorForMetricBack, getMontheInText, performanceName } from '../../../utils/commonFunction';
import '../pages/reportsStyle.scss';
import '../../masters/pages/masterStyle.scss';
import { saveAs } from 'file-saver';
import LoaderSkeletonTable from '../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import "./Table.scss"

interface CompProps {
    selectedColumns: string[];
    intervalType: string
    performance_type: string
    intervalRange: any
}


const MetricTable: React.FC<CompProps> = (props) => {
    const dt = useRef<any | null>(null);
    const { selectedColumns, intervalType, performance_type, intervalRange } = props;

    const client = useAppSelector(state => state.client);
    const { current_brand: { client_id } } = client;
    const [isLoading, setIsLoading] = useState(false);

    const [metricData, setMetricData] = useState<any[]>([]);
    const [totalRow, setTotalRow] = useState<any[]>([]);

    const [dates, SetDates] = useState<any[]>([])


    useEffect(() => {

        let body: any = {
            interval_type: intervalType,
            performance_type: performance_type,
            view: 'INDIVIDUAL',
            isSpot: true,
            ...intervalRange,
            metrics: selectedColumns,
        }
        setIsLoading(true)
        axios.post(`${BASE_URL_NEW}/report/performance/${client_id}`, body)
            .then((res) => {
                const result: any = {};
                const dateSet = new Set();
                res.data.forEach((item: any) => {
                    // console.log(item);

                    const performance = item[performance_type]
                    let date: any;
                    if (intervalType === 'DAILY') {
                        // date = new Date(item.time_stamp).toISOString().split('T')[0];
                        date = formatSingleDateFn(item.time_stamp)
                    } else if (intervalType === 'WEEKLY') {
                        date = `${getMontheInText(item.month)}-Week-${item.week}`
                    } else if (intervalType === 'MONTHLY') {
                        date = `${getMontheInText(item.month)}-${item.year}`
                    }
                    dateSet.add(date);
                    if (!result[performance]) {
                        result[performance] = { performance };
                        // console.log(result[performance]);
                    }

                    selectedColumns.forEach((metric) => {
                        result[performance][date + "_" + metric] = item[metric]
                        result[performance][date + "_" + `${metric}_percentage_change`] = item[`${metric}_percentage_change`]
                        result[performance][date + "_" + `${metric}_flow`] = item[`${metric}_flow`]
                    })
                });

                const groupedData: any[] = Object.values(result)
                const dates: any[] = Array.from(dateSet);
                // console.log(groupedData, dates, result);
                const data = groupedData;
                const totalData = data.splice(0, 1);
                setTotalRow(totalData);

                SetDates(dates)
                setMetricData(data);
            }).catch((error) => {
                console.log(error);

            }).finally(() => {
                setIsLoading(false)
            })
    }, [client_id, intervalRange, intervalType, selectedColumns, performance_type])


    const metricBody = (props: any, date: any, metric: any) => {

        // acos, cpc,spend
        const arrowDir = () => {
            if (['cpc', 'acos', 'cost']?.includes(metric)) {
                if (props[`${date}_${metric}_percentage_change`] < 0) {
                    return <i className="bi bi-arrow-up-short"></i>;
                } else if (props[`${date}_${metric}_percentage_change`] > 0) {
                    return <i className="bi bi-arrow-down-short"></i>;
                } else if (props[`${date}_${metric}_percentage_change`] === 0) {
                    return;
                }
            } else {
                if (props[`${date}_${metric}_percentage_change`] > 0) {
                    return <i className="bi bi-arrow-up-short"></i>;
                } else if (props[`${date}_${metric}_percentage_change`] < 0) {
                    return <i className="bi bi-arrow-down-short"></i>;
                } else if (props[`${date}_${metric}_percentage_change`] === 0) {
                    return;
                }
            }

        }

        if (metric === 'sales' || metric === 'cost' || metric === 'cpc') {
            // return (
            //     <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            //        <span style={{ color: '#5B6871' }}>₹{props[`${date}_${metric}`]?.toLocaleString("en-IN")}</span>
            //         {/* <span style={{ color: '#5B6871' }}>₹{props[`${date}_${metric}`]?.toLocaleString("en-IN")}</span>  */}

            //         {
            //         !isNaN(props[`${date}_${metric}_percentage_change`]) &&
            //             <span style={{
            //                 color: getFlowColorForMetric(props[`${date}_${metric}_flow`], metric), fontSize: "0.6rem", backgroundColor: getFlowColorForMetricBack(props[`${date}_${metric}_flow`], metric),
            //                 padding: '0rem 0.5rem', borderRadius: '0.6rem', alignContent: "center"
            //             }}
            //             >{Math.floor(props[`${date}_${metric}_percentage_change`])}%{arrowDir()}</span>

                        
            //         }
            //     </div>
            // )
            return(
                <div  style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }} >
                     <span style={{ color: '#000000'}}>₹{props[`${date}_${metric}`]?.toLocaleString("en-IN")}</span>
                     {
                !isNaN(props[`${date}_${metric}_percentage_change`]) && (
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.3rem',
                            color: getFlowColorForMetric(props[`${date}_${metric}_flow`], metric),
                            fontSize: "0.75rem",
                            fontWeight: 'bold',
                            // backgroundColor: getFlowColorForMetricBack(props[`${date}_${metric}_flow`], metric),
                            backgroundColor: "white",
                            border: `1px solid ${getFlowColorForMetric(props[`${date}_${metric}_flow`], metric)}`,
                            borderRadius: '0.6rem',
                            marginRight: '1px',
                            padding: '0rem 0rem', // Adjusted for uniformity
                            minWidth: '2rem',
                        }}
                    >
                        <div className="arrow-wrapper" style={{ backgroundColor: getFlowColorForMetricBack(props[`${date}_${metric}_flow`], metric) }}>{arrowDir()}</div>
                        <div className="percentage-wrap">{Math.floor(props[`${date}_${metric}_percentage_change`])}%</div>
                    </span>

                )
               }

                </div>    

            )



        } else if (metric === 'ctr' || metric === 'acos' || metric === 'conversions' || metric === 'tos_is') {
            // return (
            //     <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            //         <span style={{ color: '#5B6871' }}>{props[`${date}_${metric}`]?.toLocaleString("en-IN")}%</span>
            //         {!isNaN(props[`${date}_${metric}_percentage_change`]) &&
            //             <span style={{
            //                 color: getFlowColorForMetric(props[`${date}_${metric}_flow`], metric), fontSize: "0.6rem", backgroundColor: getFlowColorForMetricBack(props[`${date}_${metric}_flow`], metric),
            //                 padding: '0rem 0.5rem', borderRadius: '0.6rem', alignContent: "center"
            //             }}
            //             >{Math.floor(props[`${date}_${metric}_percentage_change`])}%{arrowDir()}</span>
            //         }
            //     </div>
            // )
            return(
                <div  style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }} >
                     <span style={{ color: '#000000'}}>{props[`${date}_${metric}`]?.toLocaleString("en-IN")}%</span>
                     {
                !isNaN(props[`${date}_${metric}_percentage_change`]) && (
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.3rem',
                            color: getFlowColorForMetric(props[`${date}_${metric}_flow`], metric),
                            fontSize: "0.75rem",
                            fontWeight: 'bold',
                            backgroundColor: "white",
                            border: `1px solid ${getFlowColorForMetric(props[`${date}_${metric}_flow`], metric)}`,
                            borderRadius: '0.6rem',
                            marginRight: '1px',
                            padding: '0rem 0rem', // Adjusted for uniformity
                            minWidth: '2rem',
                        }}
                    >
                        <div className="arrow-wrapper" style={{ backgroundColor: getFlowColorForMetricBack(props[`${date}_${metric}_flow`], metric) }}>{arrowDir()}</div>
                        <div className="percentage-wrap">{Math.floor(props[`${date}_${metric}_percentage_change`])}%</div>
                    </span>

                )
               }

                </div>    

            )


        } else {
            // return (
            //     <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            //         <span style={{ color: '#5B6871' }}>{props[`${date}_${metric}`]?.toLocaleString("en-IN")}</span>
            //         {!isNaN(props[`${date}_${metric}_percentage_change`]) &&
            //             <span style={{
            //                 color: getFlowColorForMetric(props[`${date}_${metric}_flow`], metric), fontSize: "0.6rem", backgroundColor: getFlowColorForMetricBack(props[`${date}_${metric}_flow`], metric),
            //                 padding: '0rem 0.5rem', borderRadius: '0.6rem', alignContent: "center"
            //             }}
            //             >{Math.floor(props[`${date}_${metric}_percentage_change`])}%{arrowDir()}</span>
            //         }
            //     </div>
            // )

            return(
                <div  style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }} >
                     <span style={{ color: '#000000'}}>{props[`${date}_${metric}`]?.toLocaleString("en-IN")}</span>
                     {
                !isNaN(props[`${date}_${metric}_percentage_change`]) && (
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.3rem',
                            color: getFlowColorForMetric(props[`${date}_${metric}_flow`], metric),
                            fontSize: "0.75rem",
                            fontWeight: 'bold',
                            backgroundColor: "white",
                            border: `1px solid ${getFlowColorForMetric(props[`${date}_${metric}_flow`], metric)}`,
                            borderRadius: '0.6rem',
                            marginRight: '1px',
                            padding: '0rem 0rem', // Adjusted for uniformity
                            minWidth: '2rem',
                        }}
                    >
                        <div className="arrow-wrapper" style={{ backgroundColor: getFlowColorForMetricBack(props[`${date}_${metric}_flow`], metric) }}>{arrowDir()}</div>
                        <div className="percentage-wrap">{Math.floor(props[`${date}_${metric}_percentage_change`])}%</div>
                    </span>

                )
               }

                </div>    

            )
        }
    }


    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="" colSpan={1} style={{ width: '120px' }} />
                {dates.map((date, i) => {
                    return <Column header={date} colSpan={selectedColumns.length}
                        key={date}
                        style={{ width: `${selectedColumns.length * 140}px` }}
                        headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                    />
                })}
            </Row>
            <Row>
                <Column header={performanceName(performance_type)}
                    headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                />
                {dates.map((date, dateIndex) => {
                    return selectedColumns?.map((metric, index) => {
                        return <Column header={formatedMetricAsHeader(metric)}
                            key={dateIndex + index}
                            // style={{ width: '120px' }}
                            headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)', }}
                        />
                    })
                })}
            </Row>
        </ColumnGroup>
    );

    const exportToCSV = () => {
        let csvContent = '';
        // const header = dates.map(col => col).join(',');
        // csvContent += header + '\n';

        // selectedColumns.forEach((metric: any) => {
        //     const rowData = dates.map(col => col + '_' + metric).join(',');
        //     csvContent += rowData + '\n';
        // });

        // selectedColumns.forEach(metric => {
        //     metricData.forEach((row: any) => {
        //         const rowData = dates.map(col => row[col + '_' + metric]).join(',');
        //         csvContent += rowData + '\n';
        //     });
        // })

        // const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        // saveAs(blob, 'table.csv');
        dt.current.exportCSV({ selectionOnly: false, filename: `${performance_type} wise metric performance.csv` });
    };

    return (
        <div style={{ marginTop: '1rem' }} className='table_card_new'>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                <span className='table_kpi_name'>Metric Wise Performance</span>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginRight: '1rem' }}>
                    <button onClick={exportToCSV} className='table_btn1'>
                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.1765 5.64706H9.41176V0H3.76471V5.64706H0L6.58824 12.2353L13.1765 5.64706ZM0 14.1176V16H13.1765V14.1176H0Z" fill="black" />
                        </svg>
                    </button>
                </div>

            </div>

            <PrimeReactProvider>
                {isLoading ? <LoaderSkeletonTable height='400px' /> :
                    <DataTable
                        id='unique-table'
                        ref={dt}
                        value={metricData}
                        size='small'
                        columnResizeMode="expand" resizableColumns
                        headerColumnGroup={headerGroup}
                        scrollable scrollHeight="400px" virtualScrollerOptions={{ itemSize: 20 }}
                        frozenValue={totalRow}
                    >
                        <Column field="performance" header={performanceName(performance_type)}
                            // style={{ width: '120px' }}
                            bodyStyle={{ color: 'rgba(24, 24, 24, 1)', fontSize: '0.85rem', fontWeight: 500, textTransform: 'capitalize' }}
                        />
                        {dates.map((date, dateIndex) => {
                            return selectedColumns?.map((metric, index) => {
                                return <Column
                                    field={`${date}_` + metric}
                                    key={dateIndex + index}
                                    // style={{ width: '120px' }}
                                    bodyStyle={{ color: 'rgba(24, 24, 24, 1)', fontSize: '0.85rem', width: '120px' }}
                                    body={(e) => metricBody(e, date, metric)}
                                />
                            })
                        })}
                    </DataTable>
                }
            </PrimeReactProvider>
        </div>
    )
}

export default MetricTable