import { getFlowBackColor, getFlowBackColorReverse, getFlowColor, getFlowColorReverse } from "../../../utils/commonFunction";
import { PerformanceData } from "../types/type";
import "./Table.scss"

// const color = '#545454';
const color = '#000000';


const salesCellComponent = (props: PerformanceData) => {
    const { sales,sales_flow, sales_percentage_change} = props
    //  console.log(props)
    const arrowDirSales = function () {
        if (sales_percentage_change < 0) {
            return <i className="bi bi-arrow-down-short arrow-icon"></i>;
        }
        else if (sales_percentage_change > 0) {
            return <i className="bi bi-arrow-up-short arrow-icon"></i>;
        }
        else {
            return;
        }
    }
    const borderColor = getFlowColor(sales_percentage_change);
    const bgcoloricon = getFlowBackColor(sales_percentage_change);
    const bgColor = "white";
    const textColor = getFlowColor(sales_percentage_change);
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <span style={{ color: color }}>₹{sales?.toLocaleString("en-IN")}</span>
            {
                // !isNaN(sales_percentage_change) &&
                // <span style={{
                //     color: getFlowColor(sales_flow), fontSize: "0.6rem", backgroundColor: getFlowBackColor(sales_flow),
                //     padding: '0rem 0.5rem', borderRadius: '0.6rem', alignContent: "center"
                // }}
                // >{Math.floor(sales_percentage_change)}%{arrowDirSales()}</span>

                !isNaN(sales_percentage_change) && (
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.3rem',
                            color: textColor,
                            fontSize: "0.75rem",
                            fontWeight: 'bold',
                            backgroundColor: bgColor,
                            border: `1px solid ${borderColor}`,
                            borderRadius: '0.6rem',
                            marginRight: '1px',
                            padding: '0rem 0rem', // Adjusted for uniformity
                            minWidth: '2rem',
                        }}
                    >
                        <div className="arrow-wrapper" style={{ backgroundColor: bgcoloricon }}>{arrowDirSales()}</div>
                        <div className="percentage-wrap">{Math.floor(sales_percentage_change)}%</div>
                    </span>

                )
            }
        </div>
    )
}

const acosCellComponent = (props: PerformanceData) => {
    const { acos, acos_flow, acos_percentage_change } = props
    const arrowDirACOS = function () {
        if (acos_percentage_change < 0) {
            return <i className="bi bi-arrow-down-short arrow-icon"></i>;
        }
        else if (acos_percentage_change > 0) {
            return <i className="bi bi-arrow-up-short arrow-icon"></i>;
        }
        else {
            return;
        }
    }

    const borderColor = getFlowColorReverse(acos_percentage_change);
    const bgcoloricon = getFlowBackColorReverse(acos_percentage_change);
    const bgColor = "white";
    const textColor = getFlowColorReverse(acos_percentage_change);
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <span style={{ color: color }}>{acos?.toLocaleString("en-IN")}%</span>
            {
                // !isNaN(acos_percentage_change) &&
                // <span style={{
                //     color: getFlowColorReverse(acos_flow), fontSize: "0.6rem", backgroundColor: getFlowBackColorReverse(acos_flow),
                //     padding: '0rem 0.5rem', borderRadius: '0.6rem', alignContent: "center"
                // }} >{Math.floor(acos_percentage_change)}%{arrowDirACOS()}</span>
                !isNaN(acos_percentage_change) && (
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.3rem',
                            color: textColor,
                            fontSize: "0.75rem",
                            fontWeight: 'bold',
                            backgroundColor: bgColor,
                            border: `1px solid ${borderColor}`,
                            borderRadius: '0.6rem',
                            marginRight: '1px',
                            padding: '0rem 0rem', // Adjusted for uniformity
                            minWidth: '2rem',
                        }}
                    >
                        <div className="arrow-wrapper" style={{ backgroundColor: bgcoloricon }}>{arrowDirACOS()}</div>
                        <div className="percentage-wrap">{Math.floor(acos_percentage_change)}%</div>
                    </span>

                )
            }
        </div>
    )
}
const costCellComponent = (props: PerformanceData) => {
    const { cost, cost_flow, cost_percentage_change } = props
    const arrowDirCost = function () {
        if (cost_percentage_change < 0) {
            return <i className="bi bi-arrow-up-short arrow-icon"></i>;
        }
        else if (cost_percentage_change > 0) {
            return <i className="bi bi-arrow-down-short arrow-icon"></i>;
        }
        else {
            return;
        }
    }

    const borderColor = getFlowColor(cost_percentage_change);
    const bgcoloricon = getFlowBackColor(cost_percentage_change);
    const bgColor = "white";
    const textColor = getFlowColor(cost_percentage_change);
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <span style={{ color: color }}>₹{cost?.toLocaleString("en-IN")}</span>
            {
                // !isNaN(cost_percentage_change) &&
                // <span style={{
                //     color: getFlowColor(cost_flow), fontSize: "0.6rem", backgroundColor: getFlowBackColor(cost_flow),
                //     padding: '0rem 0.5rem', borderRadius: '0.6rem', alignContent: "center"
                // }} >{Math.floor(cost_percentage_change)}%{arrowDirCost()}</span>
                !isNaN(cost_percentage_change) && (
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.3rem',
                            color: textColor,
                            fontSize: "0.75rem",
                            fontWeight: 'bold',
                            backgroundColor: bgColor,
                            border: `1px solid ${borderColor}`,
                            borderRadius: '0.6rem',
                            marginRight: '1px',
                            padding: '0rem 0rem', // Adjusted for uniformity
                            minWidth: '2rem',
                        }}
                    >
                        <div className="arrow-wrapper" style={{ backgroundColor: bgcoloricon }}>{arrowDirCost()}</div>
                        <div className="percentage-wrap">{Math.floor(cost_percentage_change)}%</div>
                    </span>

                )
            }
        </div>
    )
}
const impressionsCellComponent = (props: PerformanceData) => {
    const { impressions, impressions_flow, impressions_percentage_change } = props;
    const arrowDirImpressions = function () {
        if (impressions_percentage_change < 0) {
            return <i className="bi bi-arrow-down-short arrow-icon"></i>;
        }
        else if (impressions_percentage_change > 0) {
            return <i className="bi bi-arrow-up-short arrow-icon"></i>;
        }
        else {
            return;
        }
    }

    const borderColor = getFlowColor(impressions_percentage_change);
    const bgcoloricon = getFlowBackColor(impressions_percentage_change);
    const bgColor = "white";
    const textColor = getFlowColor(impressions_percentage_change);
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <span style={{ color: color }}>{impressions?.toLocaleString("en-IN")}</span>
            {
                // !isNaN(impressions_percentage_change) &&
                // <span style={{
                //     color: getFlowColor(impressions_flow), fontSize: "0.6rem", backgroundColor: getFlowBackColor(impressions_flow),
                //     padding: '0rem 0.5rem', borderRadius: '0.6rem', alignContent: "center"
                // }}
                // >
                //     {Math.floor(impressions_percentage_change)}%{arrowDirImpressions()}
                // </span>

                !isNaN(impressions_percentage_change) && (
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.3rem',
                            color: textColor,
                            fontSize: "0.75rem",
                            fontWeight: 'bold',
                            backgroundColor: bgColor,
                            border: `1px solid ${borderColor}`,
                            borderRadius: '0.6rem',
                            marginRight: '1px',
                            padding: '0rem 0rem', // Adjusted for uniformity
                            minWidth: '2rem',
                        }}
                    >
                        <div className="arrow-wrapper" style={{ backgroundColor: bgcoloricon }}>{arrowDirImpressions()}</div>
                        <div className="percentage-wrap">{Math.floor(impressions_percentage_change)}%</div>
                    </span>

                )
            }
        </div>
    )
}
// const cpcCellComponent = (props: PerformanceData) => {
//     const { cpc, cpc_flow, cpc_percentage_change } = props
//     //  console.log(props)
//     const arrowDirCPC = function () {
//         if (cpc_percentage_change < 0) {
//             return <i className="bi bi-arrow-down-short"></i>;
//         }
//         else if (cpc_percentage_change > 0) {
//             return <i className="bi bi-arrow-up-short"></i>;
//         }
//         else {
//             return;
//         }
//     }
//     return (
//         <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
//             <span style={{ color: color }}>₹{cpc?.toLocaleString("en-IN")}</span>
//             {
//                 !isNaN(cpc_percentage_change) &&
//                 <span style={{
//                     color: getFlowColorReverse(cpc_flow), fontSize: "0.6rem", backgroundColor: getFlowBackColorReverse(cpc_flow),
//                     padding: '0rem 0.5rem', borderRadius: '0.6rem', alignContent: "center"
//                 }} >{Math.floor(cpc_percentage_change)}%{arrowDirCPC()}</span>
//             }
//         </div>
//     )
// }

const cpcCellComponent = (props: PerformanceData) => {
    const { cpc, cpc_flow, cpc_percentage_change } = props;

    const arrowDirCPC = () => {
        if (cpc_percentage_change < 0) {
            return <i className="bi bi-arrow-down-short arrow-icon"></i>;
        } else if (cpc_percentage_change > 0) {
            return <i className="bi bi-arrow-up-short arrow-icon"></i>;
        } else {
            return null;
        }
    };

    const borderColor = getFlowColorReverse(cpc_percentage_change);
    const bgcoloricon = getFlowBackColorReverse(cpc_percentage_change);
    const bgColor = "white";
    const textColor = getFlowColorReverse(cpc_percentage_change);

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <span style={{ color: color }}>₹{cpc?.toLocaleString("en-IN")}</span>
            {
                !isNaN(cpc_percentage_change) && (
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.3rem',
                            color: textColor,
                            fontSize: "0.75rem",
                            fontWeight: 'bold',
                            backgroundColor: bgColor,
                            border: `1px solid ${borderColor}`,
                            borderRadius: '0.6rem',
                            marginRight: '1px',
                            padding: '0rem 0rem', // Adjusted for uniformity
                            minWidth: '2rem',
                        }}
                    >
                        <div className="arrow-wrapper" style={{ backgroundColor: bgcoloricon }}>{arrowDirCPC()}</div>
                        <div className="percentage-wrap">{Math.floor(cpc_percentage_change)}%</div>
                    </span>
                )
            }
        </div>
    );
};


const ctrCellComponent = (props: PerformanceData) => {
    const { ctr, ctr_flow, ctr_percentage_change } = props
    //  console.log(props)
    const arrowDirCTR = function () {
        if (ctr_percentage_change < 0) {
            return <i className="bi bi-arrow-down-short arrow-icon"></i>;
        }
        else if (ctr_percentage_change > 0) {
            return <i className="bi bi-arrow-up-short arrow-icon"></i>;
        }
        else {
            return;
        }
    }

    const borderColor = getFlowColor(ctr_percentage_change);
    const bgcoloricon = getFlowBackColor(ctr_percentage_change);
    const bgColor = "white";
    const textColor = getFlowColor(ctr_percentage_change);

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <span style={{ color: color }}>{ctr?.toLocaleString("en-IN")}%</span>
            {
                // !isNaN(ctr_percentage_change) &&
                // <span style={{
                //     color: getFlowColor(ctr_flow), fontSize: "0.6rem", backgroundColor: getFlowBackColor(ctr_flow),
                //     padding: '0rem 0.5rem', borderRadius: '0.6rem', alignContent: "center"
                // }} >{Math.floor(ctr_percentage_change)}%{arrowDirCTR()}</span>
                !isNaN(ctr_percentage_change) && (
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.3rem',
                            color: textColor,
                            fontSize: "0.75rem",
                            fontWeight: 'bold',
                            backgroundColor: bgColor,
                            border: `1px solid ${borderColor}`,
                            borderRadius: '0.6rem',
                            marginRight: '1px',
                            padding: '0rem 0rem', // Adjusted for uniformity
                            minWidth: '2rem',
                        }}
                    >
                        <div className="arrow-wrapper" style={{ backgroundColor: bgcoloricon }}>{arrowDirCTR()}</div>
                        <div className="percentage-wrap">{Math.floor(ctr_percentage_change)}%</div>
                    </span>
                )
            }
        </div>
    )
}

const clicksCellComponent = (props: PerformanceData) => {
    const { clicks, clicks_flow, clicks_percentage_change } = props
    const arrowDirClicks = function () {
        if (clicks_percentage_change < 0) {
            return <i className="bi bi-arrow-down-short arrow-icon"></i>;
        }
        else if (clicks_percentage_change > 0) {
            return <i className="bi bi-arrow-up-short arrow-icon"></i>;
        }
        else {
            return;
        }
    }

    const borderColor = getFlowColor(clicks_percentage_change);
    const bgcoloricon = getFlowBackColor(clicks_percentage_change);
    const bgColor = "white";
    const textColor = getFlowColor(clicks_percentage_change);

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <span style={{ color: color }}>{clicks?.toLocaleString("en-IN")}</span>
            {
                // !isNaN(clicks_percentage_change) &&
                // <span style={{
                //     color: getFlowColor(clicks_flow), fontSize: "0.6rem", backgroundColor: getFlowBackColor(clicks_flow),
                //     padding: '0rem 0.5rem', borderRadius: '0.6rem', alignContent: "center"
                // }} >{Math.floor(clicks_percentage_change)}%{arrowDirClicks()}</span>
                !isNaN(clicks_percentage_change) && (
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.3rem',
                            color: textColor,
                            fontSize: "0.75rem",
                            fontWeight: 'bold',
                            backgroundColor: bgColor,
                            border: `1px solid ${borderColor}`,
                            borderRadius: '0.6rem',
                            marginRight: '1px',
                            padding: '0rem 0rem', // Adjusted for uniformity
                            minWidth: '2rem',
                        }}
                    >
                        <div className="arrow-wrapper" style={{ backgroundColor: bgcoloricon }}>{arrowDirClicks()}</div>
                        <div className="percentage-wrap">{Math.floor(clicks_percentage_change)}%</div>
                    </span>
                )
            }
        </div>
    )
}

const orderComponent = (props: PerformanceData) => {
    const { orders, orders_flow, orders_percentage_change } = props
    //  console.log(props)
    const arrowDirOrders = function () {
        if (orders_percentage_change < 0) {
            return <i className="bi bi-arrow-down-short arrow-icon"></i>;
        }
        else if (orders_percentage_change > 0) {
            return <i className="bi bi-arrow-up-short arrow-icon"></i>;
        }
        else {
            return;
        }
    }

    const borderColor = getFlowColor(orders_percentage_change);
    const bgcoloricon = getFlowBackColor(orders_percentage_change);
    const bgColor = "white";
    const textColor = getFlowColor(orders_percentage_change);

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <span style={{ color: color }}>{orders?.toLocaleString("en-IN")}</span>
            {
                /* {!isNaN(orders_percentage_change) &&
                <span style={{
                    color: getFlowColor(orders_flow), fontSize: "0.6rem", backgroundColor: getFlowBackColor(orders_flow),
                    padding: '0rem 0.5rem', borderRadius: '0.6rem', alignContent: "center"
                }} >{Math.floor(orders_percentage_change)}%{arrowDirOrders()}</span> */
                !isNaN(orders_percentage_change) && (
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.3rem',
                            color: textColor,
                            fontSize: "0.75rem",
                            fontWeight: 'bold',
                            backgroundColor: bgColor,
                            border: `1px solid ${borderColor}`,
                            borderRadius: '0.6rem',
                            marginRight: '1px',
                            padding: '0rem 0rem', // Adjusted for uniformity
                            minWidth: '2rem',
                        }}
                    >
                        <div className="arrow-wrapper" style={{ backgroundColor: bgcoloricon }}>{arrowDirOrders()}</div>
                        <div className="percentage-wrap">{Math.floor(orders_percentage_change)}%</div>
                    </span>
                )
            }


        </div>
    )
}
const unitsCellComponent = (props: PerformanceData) => {
    const { units, units_flow, units_percentage_change } = props
    //  console.log(props)
    const arrowDirOrders = function () {
        if (units_percentage_change < 0) {
            return <i className="bi bi-arrow-down-short arrow-icon"></i>;
        }
        else if (units_percentage_change > 0) {
            return <i className="bi bi-arrow-up-short arrow-icon"></i>;
        }
        else {
            return;
        }
    }

    const borderColor = getFlowColor(units_percentage_change);
    const bgcoloricon = getFlowBackColor(units_percentage_change);
    const bgColor = "white";
    const textColor = getFlowColor(units_percentage_change);

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <span style={{ color: color }}>{units?.toLocaleString("en-IN")}</span>
            {
                // !isNaN(units_percentage_change) &&
                //     <span style={{
                //         color: getFlowColor(units_flow), fontSize: "0.6rem", backgroundColor: getFlowBackColor(units_flow),
                //         padding: '0rem 0.5rem', borderRadius: '0.6rem', alignContent: "center"
                //     }} >{Math.floor(units_percentage_change)}%{arrowDirOrders()}</span>
                !isNaN(units_percentage_change) && (
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.3rem',
                            color: textColor,
                            fontSize: "0.75rem",
                            fontWeight: 'bold',
                            backgroundColor: bgColor,
                            border: `1px solid ${borderColor}`,
                            borderRadius: '0.6rem',
                            marginRight: '1px',
                            padding: '0rem 0rem', // Adjusted for uniformity
                            minWidth: '2rem',
                        }}
                    >
                        <div className="arrow-wrapper" style={{ backgroundColor: bgcoloricon }}>{arrowDirOrders()}</div>
                        <div className="percentage-wrap">{Math.floor(units_percentage_change)}%</div>
                    </span>
                )
            }
        </div>
    )
}
const conversionComponent = (props: PerformanceData) => {
    const { cvr, cvr_flow, cvr_percentage_change } = props
    // console.log(props)
    const arrowDirConversion = function () {
        if (cvr_percentage_change < 0) {
            return <i className="bi bi-arrow-down-short arrow-icon"></i>;
        }
        else if (cvr_percentage_change > 0) {
            return <i className="bi bi-arrow-up-short arrow-icon"></i>;
        }
        else {
            return;
        }
    }

    const borderColor = getFlowColor(cvr_percentage_change);
    const bgcoloricon = getFlowBackColor(cvr_percentage_change);
    const bgColor = "white";
    const textColor = getFlowColor(cvr_percentage_change);

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <span style={{ color: color }}>{cvr?.toLocaleString("en-IN")}%</span>
            {
                // !isNaN(cvr_percentage_change) &&
                // <span style={{
                //     color: getFlowColor(cvr_flow), fontSize: "0.6rem", backgroundColor: getFlowBackColor(cvr_flow),
                //     padding: '0rem 0.5rem', borderRadius: '0.6rem', alignContent: "center"
                // }} >{Math.floor(cvr_percentage_change)}%{arrowDirConversion()}</span>
                !isNaN(cvr_percentage_change) && (
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.3rem',
                            color: textColor,
                            fontSize: "0.75rem",
                            fontWeight: 'bold',
                            backgroundColor: bgColor,
                            border: `1px solid ${borderColor}`,
                            borderRadius: '0.6rem',
                            marginRight: '1px',
                            padding: '0rem 0rem', // Adjusted for uniformity
                            minWidth: '2rem',
                        }}
                    >
                        <div className="arrow-wrapper" style={{ backgroundColor: bgcoloricon }}>{arrowDirConversion()}</div>
                        <div className="percentage-wrap">{Math.floor(cvr_percentage_change)}%</div>
                    </span>
                )

            }
        </div>
    )
}
const roasCellComponent = (props: PerformanceData) => {
    const { roas, roas_flow, roas_percentage_change } = props
    // console.log(props)
    const arrowDirROAS = function () {
        if (roas_percentage_change < 0) {
            return <i className="bi bi-arrow-down-short arrow-icon"></i>;
        }
        else if (roas_percentage_change > 0) {
            return <i className="bi bi-arrow-up-short arrow-icon"></i>;
        }
        else {
            return;
        }
    }

    const borderColor = getFlowColor(roas_percentage_change);
    const bgcoloricon = getFlowBackColor(roas_percentage_change);
    const bgColor = "white";
    const textColor = getFlowColor(roas_percentage_change);

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <span style={{ color: color }}>{roas?.toLocaleString("en-IN")}</span>
            {
                // !isNaN(roas_percentage_change) &&
                //     <span style={{
                //         color: getFlowColor(roas_flow), fontSize: "0.6rem", backgroundColor: getFlowBackColor(roas_flow),
                //         padding: '0rem 0.5rem', borderRadius: '0.6rem', alignContent: "center"
                //     }} >{Math.floor(roas_percentage_change)}%{arrowDirROAS()}</span>
                !isNaN(roas_percentage_change) && (
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.3rem',
                            color: textColor,
                            fontSize: "0.75rem",
                            fontWeight: 'bold',
                            backgroundColor: bgColor,
                            border: `1px solid ${borderColor}`,
                            borderRadius: '0.6rem',
                            marginRight: '1px',
                            padding: '0rem 0rem', // Adjusted for uniformity
                            minWidth: '2rem',
                        }}
                    >
                        <div className="arrow-wrapper" style={{ backgroundColor: bgcoloricon }}>{arrowDirROAS()}</div>
                        <div className="percentage-wrap">{Math.floor(roas_percentage_change)}%</div>
                    </span>
                )
            }
        </div>
    )
}
const tosCellComponent = (props: PerformanceData) => {
    const { tos_is, tos_is_flow, tos_is_percentage_change } = props
    const arrowDirTOS = function () {
        if (tos_is_percentage_change < 0) {
            return <i className="bi bi-arrow-down-short arrow-icon"></i>;
        }
        else if (tos_is_percentage_change > 0) {
            return <i className="bi bi-arrow-up-short arrow-icon"></i>;
        }
        else {
            return;
        }
    }

    const borderColor = getFlowColor(tos_is_percentage_change);
    const bgcoloricon = getFlowBackColor(tos_is_percentage_change);
    const bgColor = "white";
    const textColor = getFlowColor(tos_is_percentage_change);

    return (
        <div className="cellStyleRoas">
            <span style={{ color: color, justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>{tos_is?.toLocaleString("en-IN")}%</span>
            {
                // !isNaN(tos_is_percentage_change) &&
                //     <span style={{
                //         color: getFlowColor(tos_is_flow), fontSize: "0.6rem", backgroundColor: getFlowBackColor(tos_is_flow),
                //         padding: '0rem 0.5rem', borderRadius: '0.6rem', alignContent: "center"
                //     }} >{Math.floor(tos_is_percentage_change)}%{arrowDirTOS()}</span>
                !isNaN(tos_is_percentage_change) && (
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.3rem',
                            color: textColor,
                            fontSize: "0.75rem",
                            fontWeight: 'bold',
                            backgroundColor: bgColor,
                            border: `1px solid ${borderColor}`,
                            borderRadius: '0.6rem',
                            marginRight: '1px',
                            padding: '0rem 0rem', // Adjusted for uniformity
                            minWidth: '2rem',
                        }}
                    >
                        <div className="arrow-wrapper" style={{ backgroundColor: bgcoloricon }}>{arrowDirTOS()}</div>
                        <div className="percentage-wrap">{Math.floor(tos_is_percentage_change)}%</div>
                    </span>
                )
            }
        </div>
    )
}


// for comparison



export {
    salesCellComponent, costCellComponent, conversionComponent, acosCellComponent, ctrCellComponent, cpcCellComponent, tosCellComponent,
    roasCellComponent, orderComponent, clicksCellComponent, impressionsCellComponent, unitsCellComponent
}