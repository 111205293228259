import React, { useState  } from 'react'
import CompetitionMasterTable from '../components/competitiveMaster/CompetitionMasterTable';
import CompetitionMasterCityTable from '../components/competitiveMaster/CompetitionMasterCityTable';
import BlinkitIcon from '../../../assets/svgs/BlinkitIcon';
import InstamartIcon from '../../../assets/svgs/InstamartIcon';
import ZeptoIcon from '../../../assets/svgs/ZeptoIcon';
import CompetitionMasterCityTable2 from '../components/competitiveMaster/CompetitionMasterCityTable2';



const CompetitiveMaster = () => {
    const platforms = [ "Blinkit", "Zepto", "Swiggy Instamart" ];

    const [currentPlatform, setCurrentPlatform] = useState<string>(platforms[0]);
    const [rowSelect, setRowSelect] = useState<any>(null);


      const handleNext = () => {
        const currentIndex = platforms.indexOf(currentPlatform);
        const nextIndex = (currentIndex + 1) % platforms.length;
        setCurrentPlatform(platforms[nextIndex]);
      };
    
      const handlePrevious = () => {
        const currentIndex = platforms.indexOf(currentPlatform);
        const previousIndex =
          currentIndex === 0 ? platforms.length - 1 : currentIndex - 1;
        setCurrentPlatform(platforms[previousIndex]);
      };

    return (
        <div>
            <div style={{display:'flex',justifyContent:'flex-end'}}>
             <div className='platform-picker'>
                    <button className='table_btn1 table_btn_mod' onClick={handlePrevious} >
                     <i className="bi bi-arrow-left"></i>
                    </button>

                    <div className="platform-display">
                       {currentPlatform === "Blinkit" && <BlinkitIcon />}
                       {currentPlatform === "Zepto" && <ZeptoIcon />}
                       {currentPlatform === "Swiggy Instamart" && <InstamartIcon />}
                    </div>
                
                    <button className='table_btn1 table_btn_mod' onClick={handleNext}>
                     <i className="bi bi-arrow-right"></i>
                    </button>
             </div>
            </div>

            
            <CompetitionMasterTable currentPlatform={currentPlatform} rowSelect={rowSelect} setRowSelect={setRowSelect}/>
            {rowSelect && <CompetitionMasterCityTable currentPlatform={currentPlatform} topRowSelect={rowSelect} />}
            {/* <CompetitionMasterCityTable2 currentPlatform={currentPlatform} topRowSelect={rowSelect} /> */}
        </div>
    )
}

export default CompetitiveMaster

