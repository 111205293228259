import { AgGridReact } from 'ag-grid-react';
import '../pages/userstyle.scss';
import { useEffect, useMemo, useState } from 'react';
import { AG_AR, BASE_URL_NEW } from '../../../appConstant';
import axios from 'axios';


const BrandModules = ({ brand, nameComp, setIsLoading }) => {
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        // setIsLoading(true)
        axios.get(`${BASE_URL_NEW}/user/brand/${brand?._id}`)
            .then((res) => {
                setRowData(res.data)
            })
            .catch(error => {
                console.log(error);
            }).finally(() => {
                // setIsLoading(false)
            })
    }, [brand])

    const handleModules = async (e, accessId) => {
        if (rowData && rowData.length > 0) {
            for (let i = 0; i < rowData.length; i++) {
                const row = rowData[i];
                if (row._id === accessId) {
                    if (row.modules.includes(e.target.name)) {
                        row.modules = row.modules.filter((module) => module != e.target.name)
                    } else {
                        row.modules.push(e.target.name)
                    }
                    setIsLoading(true)
                    await axios.patch(`${BASE_URL_NEW}/access/${accessId}`, {
                        modules: row.modules
                    })
                    setIsLoading(false)
                }
            }
            setRowData([...rowData]);
        } else {
            console.log("rowData does not exists", rowData);
        }
    }

    const accessComp = (props, field) => {
        const { modules, _id } = props.data;
        return (
            <div>
                <input type="checkbox" name={field} checked={modules.includes(field)} onChange={(e) => handleModules(e, _id)} />
            </div>
        )
    }


    const columnDefs = [
        { headerName: "NAME", field: "name", cellRenderer: nameComp, width: 200 },
        { headerName: "REPORTS", field: 'REPORTS', cellRenderer: (props) => accessComp(props, 'REPORTS'), cellClass: "center-text", width: 150 },
        { headerName: "AUTOMATION", field: 'AUTOMATION', cellRenderer: (props) => accessComp(props, 'AUTOMATION'), cellClass: 'center-text', width: 150, },
        { headerName: "BUDGET", field: 'BUDGET', cellRenderer: (props) => accessComp(props, 'BUDGET'), cellClass: 'center-text', width: 150, },
        { headerName: "MASTER", field: 'MASTER', cellRenderer: (props) => accessComp(props, 'MASTER'), cellClass: 'center-text', width: 150, },
        { headerName: "TOOLS", field: 'TOOLS', cellRenderer: (props) => accessComp(props, 'TOOLS'), cellClass: 'center-text', width: 150, },
        { headerName: "BENCHMARKS", field: 'BENCHMARKS', cellRenderer: (props) => accessComp(props, 'BENCHMARKS'), cellClass: 'center-text', width: 150, },
        { headerName: "CAMPAIGN MANAGER", field: 'CAMPAIGN_MANAGER', cellRenderer: (props) => accessComp(props, 'CAMPAIGN_MANAGER'), cellClass: 'center-text', width: 150, },
    ]

    const defaultColDef = useMemo(() => ({
        sortable: true, resizable: true, minWidth: 100
    }), []);

    return (
        <div className="ag-theme-alpine agency-table" style={{ height: '60vh', width: '100%', boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px " }}>
            <AgGridReact
                rowData={rowData}
                rowHeight={AG_AR.rH}
                headerHeight={AG_AR.hH}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                suppressNoRowsOverlay={true}
                suppressDragLeaveHidesColumns={true}
                suppressMenuHide={true}
                suppressCellFocus={true}
            >
            </AgGridReact>
        </div>
    )
}

export default BrandModules