import React, { useState } from 'react'
import { PrimeReactProvider } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "../components/Table.scss"

const PerformanceByPlacement = () => {
    // {isLoading ? <LoaderSkeletonTable height='350px' /> :}

    type TableDataItem = {
        campaignType: string  ;
        campaignName: string ;
        placement: string ;
        keywordType: string ;
        salesContribution: number ;
        sales: number ;
        spend: number ;
        acos: number;
        impressions: number;
        clicks: number;
        ctr: number;
        order: number;
        conversions: number;
        cpc: number;
        salesNTB: number;
      };

      type ColumnField = keyof TableDataItem;
      type ColumnType = {
        field: ColumnField;
        header: string;
        width: string;
        paddingLeft:boolean;
      };

      
    

    const [columns,setColumns] = useState<ColumnType[]>([
        { field: 'campaignType', header: 'Campaign Type', width: '130px',paddingLeft:true },
        { field: 'campaignName', header: 'Campaign Name', width: '130px', paddingLeft:true },
        { field: 'placement', header: 'Placement', width: '150px', paddingLeft:true },
        { field: 'keywordType', header: 'Keyword Type', width: '150px', paddingLeft:true },
        { field: 'salesContribution', header: 'Sales Contribution', width: '120px', paddingLeft:true },
        { field: 'sales', header: 'Sales', width: '80px', paddingLeft:false },
        { field: 'spend', header: 'Spend', width: '80px', paddingLeft:false },
        { field: 'acos', header: 'ACOS', width: '80px', paddingLeft:false },
        { field: 'impressions', header: 'Impressions', width: '120px', paddingLeft:false },
        { field: 'clicks', header: 'Clicks', width: '80px', paddingLeft:false },
        { field: 'ctr', header: 'CTR', width: '80px', paddingLeft:false },
        { field: 'order', header: 'Order', width: '80px', paddingLeft:false },
        { field: 'conversions', header: 'Conversions', width: '120px', paddingLeft:false },
        { field: 'cpc', header: 'CPC', width: '80px', paddingLeft:false },
        { field: 'salesNTB', header: 'Sales NTB', width: '120px', paddingLeft:true }
    ]);

    // Mock data
    const mockData: TableDataItem[] = [
        {
            campaignType: "SP",
            campaignName: "campaignA",
            placement: "Top of Search",
            keywordType: "Auto",
            salesContribution: 25,
            sales: 500,
            spend: 100,
            acos: 20,
            impressions: 5000,
            clicks: 300,
            ctr: 6,
            order: 20,
            conversions: 10,
            cpc: 0.5,
            salesNTB: 150
        },
        {
            campaignType: "SP",
            campaignName: "campaignB",
            placement: "Rest of Search",
            keywordType: "Branded",
            salesContribution: 15,
            sales: 400,
            spend: 80,
            acos: 25,
            impressions: 10000,
            clicks: 200,
            ctr: 5,
            order: 15,
            conversions: 7.5,
            cpc: 0.4,
            salesNTB: 120
        },
        {
            campaignType: "SP",
            campaignName: "campaignC",
            placement: "Top of Search",
            keywordType: "Generic",
            salesContribution: 35,
            sales: 800,
            spend: 200,
            acos: 25,
            impressions: 500,
            clicks: 600,
            ctr: 6,
            order: 40,
            conversions: 15,
            cpc: 0.33,
            salesNTB: 250
        },
        {
            campaignType: "SP",
            campaignName: "campaignD",
            placement: "Top of Search",
            keywordType: "Auto",
            salesContribution: 25,
            sales: 500,
            spend: 100,
            acos: 20,
            impressions: 5000,
            clicks: 300,
            ctr: 6,
            order: 20,
            conversions: 10,
            cpc: 0.5,
            salesNTB: 150
        },
        {
            campaignType: "SP",
            campaignName: "campaignE",
            placement: "Top of Search",
            keywordType: "Auto",
            salesContribution: 45,
            sales: 1000,
            spend: 150,
            acos: 10,
            impressions: 3000,
            clicks: 700,
            ctr: 3,
            order: 25,
            conversions: 15,
            cpc: 0.9,
            salesNTB: 250
        },
    ];

    const [tableData, setTableData] = useState<TableDataItem[]>(mockData);


    const getMaxValue = <K extends keyof TableDataItem>(tableData: TableDataItem[],field: K):number => {
        return (Math.max(...tableData.map((item) => (item[field] as number) || 0)))*1.2;
    };

    const barCellTemplate = (value: number,field:string, maxValue: number) => {
        const backgroundColors:any = {
                    sales: '#c8e6c9',
                    spend: '#ffcdd2',
                    acos: '#d1c4e9',
                    impressions: '#bbdefb',
                    clicks: '#ffecb3',
                    ctr: '#b2dfdb',
                    order: '#ffe0b2',
                    conversions: '#c5cae9',
                    cpc: '#d7ccc8'
                };  
        const percentage = (value / maxValue) * 100;

        return (
            <div style={{ position: 'relative', height: '20px', borderRadius: '4px',paddingLeft:'0px' }}
           
            >
                <div
                    style={{
                        width: `${percentage}%`,
                        backgroundColor: `${backgroundColors[field]}`,
                        height: '100%',
                        paddingLeft:'0px'
                        // borderRadius: '4px',
                    }}
                ></div>
                <span
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        fontSize: '0.75rem',
                        // fontWeight: 'bold',
                        color: '#000',
                        paddingLeft:'0.5rem'
                    }}
                >
                    {value}
                </span>
            </div>
        );
    };

    const renderCell = (field: keyof TableDataItem, value: number | string) => {
        const maxValue = getMaxValue(tableData,field);
        return typeof value === 'number' ? barCellTemplate(value,field, maxValue) : value;
    };


    // const renderHeader = (header: string, field: string) => (
    //     <div
    //         style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}
    //         onClick={() => handleSort(field)}
    //     >
    //         {header}
    //     </div>
    // );
    

  return (
    <div>
     <h2> Performance Placement Report </h2>
     <div style={{ marginTop: '1rem' }} className=' table_card_new'>
     {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem',color:'white' }}>
     <span className='table_kpi_name'>Performance By Placement Report</span>
     </div> */}
      <PrimeReactProvider>
                <DataTable
                    value={tableData}
                    size='small'
                    columnResizeMode="expand"
                    resizableColumns
                    scrollable scrollHeight="350px"
                    // virtualScrollerOptions={{ itemSize: 20 }}
                    selectionMode="single"
                    // selection={}
                    // onSelectionChange={}
                    showGridlines
                    // className="custom-datatable"
                    className="unique-table2"
                    id='unique-table'
                    sortMode="single" // Optional: or use "multiple" for multi-column sorting
                    sortIcon="bi bi bi-chevron-expand stroke"
                    // className="custom-datatable"
                    // metaKeySelection={false}
                >
                
            {
            //   columns.map((el, i) => {
            //       return (
            //         <Column
            //           key={i}
            //           header={el.header}
            //           field={el.field}
            //           style={{ width: '42px' }}
            //           headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
            //           bodyStyle={{ color: 'rgba(24, 24, 24, 1)', fontSize: '0.75rem', fontWeight: 500, textTransform: 'capitalize' }}
  
            //         />
            //       )
            //   })
            }
             {columns.map((col, index) => (
                        <Column
                            key={index}
                            field={col.field}
                            header={col.header}
                            // header={renderHeader(col.header, col.field)}
                            style={{ width: col.width }}
                            headerStyle={{ color: '#fff', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: '#449c97' }}
                            bodyStyle={{ color: '#000000', fontSize: '0.85rem',textTransform: 'capitalize' , paddingLeft: col.paddingLeft ? '0.5rem' : '0rem', paddingTop:'0.3rem',paddingBottom:'0.3rem', paddingRight:'.5rem'}}
                            // bodyStyle={column?.bodyStyle}
                            sortable={['campaignType', 'campaignName', 'placement', 'keywordType'].includes(col.field)} // Enable sorting only for specified columns
                            body={(rowData:any) => renderCell(col.field, rowData[col.field])} // Use renderCell to apply bar template

                        />
                    ))}

          </DataTable>
        
      </PrimeReactProvider>
      </div>
    </div>
  )
}


export default PerformanceByPlacement
