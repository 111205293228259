import React, { useEffect, useRef, useState } from 'react'
import KpiTile from '../components/KpiTile';
import { KPI } from '../types/type';
import axios from 'axios';
import { BASE_URL_NEW } from '../../../appConstant';
import { useAppSelector } from '../../../redux/hook';
import DailyPageGraph from '../components/DailyPageGraph';
import MarketingGraph from '../components/marketingStream/MarketingGraph';
import DatewiseTable from '../components/marketingStream/DatewiseTable';
import MetricwiseTable from '../components/marketingStream/MetricwiseTable';
import CategoryTable from '../components/marketingStream/CategoryTable';
import AsinTable from '../components/marketingStream/AsinTable';
import LoaderSkeletonGraph from '../../../utils/commonComponents/Loader/LoaderSkeletonGraph';
import DateRange from '../components/DateRange';
import { ModuleEnum } from '../../../utils/types.enum';
import ComparisonGraph from '../components/marketingStream/ComparisonGraph';
import SingleCard from '../components/marketingStream/SingleCard';
import ComparisonMetricwise from '../components/marketingStream/ComparisonMetricwise';
import ComparisonCategory from '../components/marketingStream/ComparisonCategory';
import ComparisonAsin from '../components/marketingStream/ComparisonAsin';
import { getColorComparativeMS } from '../../../utils/commonFunction';

const MarketingStreamPage: React.FC = () => {
    const client = useAppSelector(state => state.client);
    const { current_brand: { client_id } } = client;
    const [selected4KpiArr, setSelected4KpiArr] = useState<KPI[]>([])
    const [kpi, setKpi] = useState<KPI>()

    const [data, setData] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const defaultKPI = ['impressions', 'clicks', 'attributedUnitsOrdered14d', 'acos'];  // attributedSales14d attributedConversions14d attributedUnitsOrdered14d

    const [selectedMetric, setSelectedMetric] = useState<string>('attributedUnitsOrdered14d');
    const [selectedMetric2, setSelectedMetric2] = useState<string>('');  //removed default attributedUnitsOrdered14d  //removed default attributedUnitsOrdered14d

    const [selectedDate, setSelectedDate] = useState<any | null>(null)
    const [clickedKPI, setClickedKPI] = useState<any | null>(null)
    const [selectedCategory, setSelectedCategory] = useState<any | null>(null)

    const previousSelectedDate = useRef();

    const [intervalRange, setIntervalRange] = useState({})
    const [graphIntervalRange, setGraphIntervalRange] = useState({})
    const [intervalType, setIntervalType] = useState<string>('DAILY')



    const DatewiseRef = useRef<HTMLDivElement>(null);
    const MetricRef = useRef<HTMLDivElement>(null);
    const CategoryRef = useRef<HTMLDivElement>(null);
    const AsinRef = useRef<HTMLDivElement>(null);

    const ComparisonCategoryRef = useRef<HTMLDivElement>(null);
    const CompariosnAsinRef = useRef<HTMLDivElement>(null);

    // console.log(selectedCategory);
    useEffect(() => {
        if (!selectedDate) {
            setClickedKPI(null);
            setSelectedCategory(null);
        }
        if (selectedDate) {
            MetricRef.current?.scrollIntoView({ behavior: 'smooth' });
            setClickedKPI(null);
            setSelectedCategory(null)
        }
    }, [selectedDate])

    useEffect(() => {
        if (!clickedKPI) {
            setSelectedCategory(null);
        }
        if (selectedDate && clickedKPI) {
            CategoryRef.current?.scrollIntoView({ behavior: 'smooth' });
            setSelectedCategory(selectedCategory)
        }
    }, [clickedKPI])

    useEffect(() => {
        if (selectedDate && clickedKPI && selectedCategory?.length > 0) {
            AsinRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
        if (selectedMetric && selectedCategory?.length > 0) {
            CompariosnAsinRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [selectedCategory?.length])

    // useEffect(() => {
    //     if (selectedMetric2 ) {
    //         ComparisonCategoryRef.current?.scrollIntoView({ behavior: 'smooth' });
    //     }
    // }, [selectedMetric2])

    useEffect(() => {
        const today = new Date().toISOString().split('T')[0];
        setIsLoading(true);
        axios.get(`${BASE_URL_NEW}/marketing-stream/${client_id}/aggregate?date=${today}&aggregateLevel=TOTAL`)
            .then((res): any => {
                const data = res.data;
                let dataObj = data[0]
                let newData: any[] = [];
                for (let el in dataObj) {
                    newData.push({
                        metric: el,
                        value: dataObj[el]
                    })
                }
                // console.log(newData);
                setData(newData);
                const selectedData = newData.filter((el: any) => defaultKPI.includes(el?.metric))
                setSelected4KpiArr(selectedData);
                const selectSingleData = newData.find((el: any) => el?.metric === 'attributedUnitsOrdered14d')
                setKpi(selectSingleData);
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setIsLoading(false)
            })
    }, [client_id]);

    return (
        <main className='main'>
            <section className='main-header'>
                <div>
                    {
                        intervalType === "DAILY" ?
                            <KpiTile
                                selected4KpiArr={selected4KpiArr}
                                setSelected4KpiArr={setSelected4KpiArr}
                                dataArr={data}
                                isLoading={isLoading}
                            /> :
                            <SingleCard
                                dataArr={data}
                                kpi={kpi}
                                setKpi={setKpi}
                            />
                    }
                </div>
                <div>
                    <DateRange
                        intervalType={intervalType}
                        setIntervalType={setIntervalType}
                        intervalRange={intervalRange}
                        setIntervalRange={setIntervalRange}
                        graphIntervalRange={graphIntervalRange}
                        setGraphIntervalRange={setGraphIntervalRange}
                        report_type={ModuleEnum.MARKETING_STREAM}
                    />
                </div>
            </section>
            <section>
                {isLoading ? <LoaderSkeletonGraph /> :
                    intervalType === 'DAILY' ?
                        <MarketingGraph
                            intervalType={intervalType}
                            selected4KpiArr={selected4KpiArr}
                            intervalRange={graphIntervalRange}
                        /> : <ComparisonGraph
                            intervalRange={intervalRange}
                            kpi={kpi}
                        />
                }
            </section>
            <section style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>

                {intervalType === 'DAILY' ? <div>

                    <div ref={DatewiseRef}>
                        <DatewiseTable
                            dataArr={data}
                            selectedMetric={selectedMetric}
                            setSelectedMetric={setSelectedMetric}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            intervalType={intervalType}
                            intervalRange={graphIntervalRange}
                        />
                    </div>


                    {selectedDate && (
                        <div ref={MetricRef}>
                            <MetricwiseTable
                                selectedDate={selectedDate}
                                clickedKPI={clickedKPI}
                                setClickedKPI={setClickedKPI}
                            />
                        </div>
                    )}


                    {selectedDate && clickedKPI && (
                        <div ref={CategoryRef}>
                            <CategoryTable
                                clickedKPI={clickedKPI}
                                selectedDate={selectedDate}
                                selectedCategory={selectedCategory}
                                setSelectedCategory={setSelectedCategory}
                            />
                        </div>
                    )}

                    {selectedDate && clickedKPI && selectedCategory?.length > 0 && (
                        <div ref={AsinRef}>
                            <AsinTable
                                clickedKPI={clickedKPI}
                                selectedDate={selectedDate}
                                selectedCategory={selectedCategory}
                            />
                        </div>
                    )}

                </div>
                    :
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }} >

                        <div><ComparisonMetricwise
                            intervalRange={intervalRange}
                            selectedMetric2={selectedMetric2}
                            setSelectedMetric2={setSelectedMetric2}
                        /></div>
                        {selectedMetric2 &&
                            <div ref={ComparisonCategoryRef}><ComparisonCategory
                                intervalRange={intervalRange}
                                selectedMetric2={selectedMetric2}
                                selectedCategory={selectedCategory}
                                setSelectedCategory={setSelectedCategory}

                            /></div>
                        }
                        {selectedMetric2 && selectedCategory?.length > 0 &&
                            <div ref={CompariosnAsinRef}><ComparisonAsin
                                intervalRange={intervalRange}
                                selectedMetric2={selectedMetric2}
                                selectedCategory={selectedCategory}
                            /></div>
                        }
                    </div>
                }
            </section>
        </main>
    )
}

export default MarketingStreamPage;