import { PrimeReactProvider } from 'primereact/api';
import React, { useRef, useState } from 'react'
import LoaderSkeletonTable from '../../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

interface Props {
  selectedColumns: string[] | never[],
}

const SalesMetricTable: React.FC<Props> = (props) => {
  const { selectedColumns } = props;
  const dt = useRef<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <div className='table_card_new'>
      <div className='table_kpi_name'>Metric Wise Performance</div>
      <PrimeReactProvider>
        {
          isLoading ?
            <LoaderSkeletonTable height='350px' /> :

            <DataTable
              value={[]}
              ref={dt}
              size='small'
              columnResizeMode="expand"
              resizableColumns
              // frozenValue={totalRow}
              // sortField={sortField} sortOrder={sortOrder}
              sortIcon="bi bi-chevron-expand stroke"
              // onSort={onSort}

              // rowClassName={rowClassName}
              scrollable scrollHeight="350px" virtualScrollerOptions={{ itemSize: 20 }}
            // virtualScrollerOptions={{ lazy: true, delay: 200, showLoader: true, itemSize: 20, loadingTemplate }}

            >
              {[]?.map((el, i) => {
                return (
                  <Column
                    sortable
                    key={i}
                    // header={headerTemplate(column)}
                    // field={el.field}
                    // headerClassName={getColumnClassName(column.field)}
                    // bodyClassName={getColumnClassName(column.field)}
                    style={{ width: '150px' }}
                    headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                  // bodyStyle={column?.bodyStyle}
                  // body={column.body}
                  />
                )
              })}
            </DataTable>

        }

      </PrimeReactProvider>
    </div>
  )
}

export default SalesMetricTable;