import { useRef } from 'react';
import './CustomSelect2.css';

const CustomSelect2 = ({ children, value, onchange, id }) => {
    const selectRef = useRef(null);

    const handleClick = () => {
        // console.log('sdfj',selectRef.current);
        selectRef.current.click()
        // if (selectRef.current) {
        //     const event = new Event('change', { bubbles: true });
        //     selectRef.current.dispatchEvent(event);
        // }
        // onchange();
    }

    // const handleArrowClick = () => {
    //     selectRef.current.focus();
    //     selectRef.current.click();
    // };

    return (
        <div onClick={handleClick} className={`custom-select-container2`}>
            <select 
                ref={selectRef}
                className={`custom-select2`}
                value={value}
                onChange={onchange}
                id={id}
            >
                {children}
            </select>
            <span className='arrow'><i className='bi bi-chevron-down'></i></span>
        </div >
    );
};

export default CustomSelect2;