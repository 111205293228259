import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL_NEW } from "../../appConstant";

export const fetchDashbaord:any = createAsyncThunk("dashboard/get", async (id, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL_NEW}/powerBi/getEmbedInfo/${id}`,
      {
        headers: {
          token: localStorage.getItem("enlytical_api_token"),
        },
      }
    );
    return data;
  } catch (error:any) {
    console.log(error.response.data);
    if (error.response.data?.status) {
      return rejectWithValue(error.response.data)
    }
    throw error;
  }
});