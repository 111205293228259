import React from 'react'

const InfoIcon = () => {
    return (
        <div>
            <svg width="35" height="35" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="21" cy="21" r="21" fill="white" />
                <path d="M21 16C20.45 16 19.9792 15.8042 19.5875 15.4125C19.1958 15.0208 19 14.55 19 14C19 13.45 19.1958 12.9792 19.5875 12.5875C19.9792 12.1958 20.45 12 21 12C21.55 12 22.0208 12.1958 22.4125 12.5875C22.8042 12.9792 23 13.45 23 14C23 14.55 22.8042 15.0208 22.4125 15.4125C22.0208 15.8042 21.55 16 21 16ZM19.5 30V18H22.5V30H19.5Z" fill="#B0BABF" />
            </svg>
        </div>
    )
}

export default InfoIcon