import { ChangeEvent, useEffect, useState } from 'react'
import { useAppSelector } from '../../../redux/hook'
import axios from 'axios';
import { BASE_URL_NEW } from '../../../appConstant';
import LoaderSkeletonGraph from '../../../utils/commonComponents/Loader/LoaderSkeletonGraph';
import { toast } from 'react-toastify';

interface Report {
    report: string;
    field: string;
    status: boolean;
}

const Subscription = () => {
    const user = useAppSelector(state => state.user);
    const client = useAppSelector(state => state.client);
    const { brand_array, current_brand } = client;
    
    const [reports, setReports] = useState<Report[]>([
        { report: 'Target vs Achievement', field: 'target_vs_achievement', status: false },
        { report: 'Sub Brand Wise', field: 'sub_brand_wise', status: false },
        { report: 'Category Wise', field: 'category_wise', status: false },
        { report: 'Range Wise', field: 'range_wise', status: false },
        { report: 'Ad-Type Wise', field: 'adType_wise', status: false },
        { report: 'Targeting Wise', field: 'targeting_wise', status: false },
        { report: 'Placement Wise', field: 'placement_wise', status: false },
        { report: 'Keyword Wise', field: 'keyword_wise', status: false },
    ])

    const [reportType, setReportType] = useState<string[]>([]);
    const [brand, setBrand] = useState(current_brand?.client_profile_id);
    const [brandName, setBrandName] = useState(current_brand?.client_name);
    const [brandtype,setBrandtype] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [phoneNo, setPhoneNo] = useState<any>((user?.phone)?.toString());
    const [isPrimaryClient, setIsPrimaryClient] = useState<boolean>(false);
    const [primaryClientId, setPrimaryClientId] = useState<string | null>(null);

    useEffect(() => {
        setReportType([]);
    }, [isLoading])


    // Check if a primary client already exists for the given phone number
    const checkPrimaryClient = async () => {
        try {
            if (phoneNo?.length === 10) {
                const response = await axios.get(`${BASE_URL_NEW}/user/subscription/getall`, {
                    params: {
                        phone_no: phoneNo,
                        brand_type: 'primary' // Check if there's a primary client
                    }
                });
                if (response.data) {
                    setIsPrimaryClient(true); // If a primary client exists, set to true
                    setPrimaryClientId(response.data.client_profile_id); 
                    setBrandtype(response.data.client_profile_id === brand ? 'primary' : 'secondary');
        
                } else {
                    setIsPrimaryClient(false);
                    setPrimaryClientId(null);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        checkPrimaryClient();
    }, [phoneNo,brand]);


    const handleReports = (e: ChangeEvent<HTMLInputElement>, el: Report) => {
        if (e.target.value === el.field) {
            el.status = !el.status;
        }
        // console.log(reports);
        const filteredReports = reports.filter((el) => el.status === true)?.map((el) => el.field)
        setReportType(filteredReports);
    }


    const sendReports = async () => {
        try {
            if (phoneNo?.length !== 10) {
                toast.error('Please enter a valid Mobile No.')
                return;
            }
            if (!reportType.length) {
                toast.error('Please select a report')
                return;
            }
            setIsLoading(true);

            const checkUserExists = await axios.get(`${BASE_URL_NEW}/user/subscription/getall`, {
                params: {
                    client_profile_id: brand,
                    phone_no: phoneNo
                }
            });


            if (checkUserExists.data) {
                await axios.patch(`${BASE_URL_NEW}/user/subscription/`, {
                    report_type: reportType,
                    phone_no: phoneNo,
                    user_id: user?._id,
                    client_profile_id: brand,
                    client_name: brandName,
                    brand_type: brandtype
                }, {
                    params: { client_profile_id: brand, phone_no: phoneNo }
                });
                toast.success('Subscription updated successfully');
            }
            else{
                await axios.post(`${BASE_URL_NEW}/user/subscription`, {
                    report_type: reportType,
                    phone_no: phoneNo,
                    user_id: user?._id,
                    client_profile_id: brand,
                    client_name: brandName,
                    brand_type:brandtype
                })
                toast.success('Reports Sent');

            }

            setIsLoading(false);
            setReports((prev) => prev.map((el: Report) => ({
                ...el,
                status: false
            })))

            // Trigger the useEffect by using func
            await checkPrimaryClient();

        } catch (error) {
            console.log(error);
        }
    }

    if (isLoading) {
        return (
            <LoaderSkeletonGraph />
        )
    }
    return (
        <div>
            <h3>WhatsApp Subscription</h3>
            <div className='subscription_page'>
                <h5>Profile Info</h5>
                <form style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
                    <div className='userProfileFormBox'>
                        <div className='col-sm-6'>
                            <label className='' htmlFor="">First Name</label>
                            <input value={user?.first_name} readOnly name='first_name' className='form-control form-contol-sm' type="text" />
                        </div>
                        <div className='col-sm-6'>
                            <label className='' htmlFor="">Last Name</label>
                            <input value={user?.last_name} readOnly name='last_name' className='form-control form-contol-sm' type="text" />
                        </div>
                    </div>
                    <div className='userProfileFormBox'>
                        <div className='col-sm-6'>
                            <label className='' htmlFor="">Mobile No.</label>
                            <input value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)}
                                name='phone' className='form-control form-contol-sm' type="number"
                                minLength={10} maxLength={10} />
                        </div>
                        <div className='col-sm-6'>
                            <label className='' htmlFor="">Clients</label>
                            <select className='form-select' value={brand} onChange={(e) =>{
                                const selectedBrand:any = brand_array.find((el) => el.client_profile_id === e.target.value);
                                setBrand(e.target.value)
                                setBrandName(selectedBrand.client_name)}}                            
                                name="" id="" key={primaryClientId}>
                                {
                                    brand_array?.map((el) => {
                                        const isPrimary = el.client_profile_id === primaryClientId;
                                        return (
                                            <option key={el.client_profile_id} value={el.client_profile_id} style={isPrimary ? { backgroundColor: '#d4edda', fontWeight: 'bold' } : {}}>
                                                {el.client_name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='userProfileFormBox'>
                    <div className='col-sm-6'>
                            <label className='' htmlFor="">Brand Type</label>
                            <select className='form-select' value={brandtype} onChange={(e) => setBrandtype(e.target.value)} name="" id="" disabled={isPrimaryClient}>
                                <option value="primary" disabled={isPrimaryClient}>Primary</option>
                                <option value="secondary">Secondary</option>
                            </select>
                        </div>
                    </div>
                </form>
                <h5>Performance Reports</h5>
                < div style={{ display: "grid", gridTemplateColumns: 'repeat(4, 1fr)', rowGap: '1rem' }}>
                    {reports.map((el, colIndex) => (
                        <div key={colIndex} style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                            <input type="checkbox" name={el.field} value={el.field} onChange={(e) => handleReports(e, el)} />
                            <span style={{ fontWeight: '500' }}>{el.report}</span>
                        </div>
                    ))}
                </div>
                <div>
                    <button
                        style={{ backgroundColor: 'rgb(6, 72, 106)', color: '#fff', border: 'none', borderRadius: '0.5rem', padding: '0.25rem 0.5rem' }}
                        onClick={sendReports}>
                        Send Reports
                    </button>
                </div>

            </div>
        </div >
    )
}

export default Subscription