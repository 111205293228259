import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

export default function LoaderSkeletonGraph({ height = '20rem', width = '100%' }) {
  return (
      <Skeleton
        variant="rectangular"
        width={width}
        height={height}
      />
  );
}


// <Box
//       sx={{
//         bgcolor:'rgba(24, 24, 24, 1)',
//         p: 8,
//         width: '100%',
//         display: 'flex',
//         justifyContent: 'center',
//       }}
//     ></Box>



