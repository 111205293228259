import React from 'react'

const ProductStatusIcon = (props) => {
    return (
        <>
            {
                props.tab === 'productStatus' ?
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.3333 12.2V10.3333H10.6667V12.4667L12.1 13.9L12.5667 13.4333L11.3333 12.2ZM7 2.33333L3.05 4.61667L7 6.9L10.95 4.61667L7 2.33333ZM1 10.3167V5.01667C1 4.77222 1.05833 4.55 1.175 4.35C1.29167 4.15 1.45556 3.98889 1.66667 3.86667L6.33333 1.18333C6.44444 1.12778 6.55278 1.08333 6.65833 1.05C6.76389 1.01667 6.87778 1 7 1C7.12222 1 7.23889 1.01667 7.35 1.05C7.46111 1.08333 7.56667 1.12778 7.66667 1.18333L12.3333 3.86667C12.5444 3.98889 12.7083 4.15 12.825 4.35C12.9417 4.55 13 4.77222 13 5.01667V7.66667H11.6667V5.73333L6.98333 8.43333L2.33333 5.73333V10.3L6.33333 12.6167V14.15L1.66667 11.4667C1.45556 11.3444 1.29167 11.1833 1.175 10.9833C1.05833 10.7833 1 10.5611 1 10.3167ZM11 15.6667C10.0778 15.6667 9.29167 15.3417 8.64167 14.6917C7.99167 14.0417 7.66667 13.2556 7.66667 12.3333C7.66667 11.4111 7.99167 10.625 8.64167 9.975C9.29167 9.325 10.0778 9 11 9C11.9222 9 12.7083 9.325 13.3583 9.975C14.0083 10.625 14.3333 11.4111 14.3333 12.3333C14.3333 13.2556 14.0083 14.0417 13.3583 14.6917C12.7083 15.3417 11.9222 15.6667 11 15.6667Z" fill="#06486A" />
                    </svg> : <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.3333 12.2V10.3333H10.6667V12.4667L12.1 13.9L12.5667 13.4333L11.3333 12.2ZM7 2.33333L3.05 4.61667L7 6.9L10.95 4.61667L7 2.33333ZM1 10.3167V5.01667C1 4.77222 1.05833 4.55 1.175 4.35C1.29167 4.15 1.45556 3.98889 1.66667 3.86667L6.33333 1.18333C6.44444 1.12778 6.55278 1.08333 6.65833 1.05C6.76389 1.01667 6.87778 1 7 1C7.12222 1 7.23889 1.01667 7.35 1.05C7.46111 1.08333 7.56667 1.12778 7.66667 1.18333L12.3333 3.86667C12.5444 3.98889 12.7083 4.15 12.825 4.35C12.9417 4.55 13 4.77222 13 5.01667V7.66667H11.6667V5.73333L6.98333 8.43333L2.33333 5.73333V10.3L6.33333 12.6167V14.15L1.66667 11.4667C1.45556 11.3444 1.29167 11.1833 1.175 10.9833C1.05833 10.7833 1 10.5611 1 10.3167ZM11 15.6667C10.0778 15.6667 9.29167 15.3417 8.64167 14.6917C7.99167 14.0417 7.66667 13.2556 7.66667 12.3333C7.66667 11.4111 7.99167 10.625 8.64167 9.975C9.29167 9.325 10.0778 9 11 9C11.9222 9 12.7083 9.325 13.3583 9.975C14.0083 10.625 14.3333 11.4111 14.3333 12.3333C14.3333 13.2556 14.0083 14.0417 13.3583 14.6917C12.7083 15.3417 11.9222 15.6667 11 15.6667Z" fill="#5B6871" />
                    </svg>
            }
        </>
    )
}

export default ProductStatusIcon