import { useEffect, useState } from 'react'
import { Avatar } from '@mui/material'
import './userstyle.scss';
import { BASE_URL_NEW, USER_ROLES } from '../../.././appConstant';
import axios from 'axios';
import { getUserColor } from '../../../utils/commonFunction';
import { useAppSelector } from '../../../redux/hook';
import Loader from '../../../utils/commonComponents/Loader/Loader';
import { toast } from 'react-toastify';

const UserProfile = () => {
  const user = useAppSelector((state) => state.user);

  const [edit, setEdit] = useState(false)
  const [userDetails, setUserDetails] = useState({})
  const [userbrandDetails, setUserBrandDetails] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  const fetchInitialData = () => {
    setIsLoading(true)
    axios.get(`${BASE_URL_NEW}/user/userDetails/${user._id}`)
      .then((res) => {
        const user = res.data
        if (!user.last_active_on) user.status = "NA"
        else if (Date.now() - user.last_active_on < 60000 * 2) user.status = 'ACTIVE'
        else if (Date.now() - user.last_active_on < 60000 * 60) user.status = Math.round((Date.now() - user.last_active_on) / 60000) + " min"
        else if (Date.now() - user.last_active_on < 60000 * 60 * 24) user.status = Math.round((Date.now() - user.last_active_on) / (60000 * 60)) + " hr"
        else user.status = Math.round((Date.now() - user.last_active_on) / (60000 * 60 * 24)) + " days"

        setUserDetails(res.data);
        setUserBrandDetails(res.data.brands)
      }).catch((error) => {
        console.log(error);
        toast.error(error.message || 'Unable to update data')
      }).finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    fetchInitialData()
  }, [user])

  const handleChange = ({ target: { name, value } }) => {
    setEdit(true)
    setUserDetails((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })

  }


  const saveProfile = () => {
    setIsLoading(true);
    axios.patch(`${BASE_URL_NEW}/user/${user._id}`, {
      first_name: userDetails.first_name,
      last_name: userDetails.last_name,
      phone: Number(userDetails.phone)
    }, {
      headers: {
        // token
      }
    }).then((res) => {
      fetchInitialData()
    }).catch((err) => {
      console.log(err);
      toast.error(err.message || 'Unable to update data')
    }).finally(() => {
      setEdit(false)
      setIsLoading(false);
    })

  }

  if (isLoading) {
    return (
      <div>
        {
          isLoading && <div style={{ position: "absolute", left: "calc(55%) ", top: "calc(50% - 15px)" }} >< Loader /></div>
        }
      </div>
    )
  }
  return (
    <div className='userProfile'>
      <div>
        <h6 style={{ color: '#384D6C', fontWeight: 'bold' }}>User Profile</h6>
        <div className='userProfileFirstBox'>
          <Avatar sx={{
            height: '5rem', width: '5rem',
            backgroundColor: `${getUserColor(userDetails?.access_roles?.length && userDetails?.access_roles[0])}`
          }}>
            <span style={{ fontSize: '1.5rem', color: '#000000' }}>{userDetails?.first_name?.split(" ")[0][0]?.toUpperCase() + userDetails?.last_name?.split(" ")[0][0]?.toUpperCase()}</span>
          </Avatar>
          <div>
            <div>
              <span style={{ color: '#384D6C', fontSize: '1rem', fontWeight: 'bold', textTransform: 'capitalize', marginRight: '0.5rem' }}>
                {userDetails?.first_name + " " + userDetails?.last_name}</span>
              {userDetails?.status === 'ACTIVE' ? <span className='userStatus'>online</span> : <span>{userDetails?.status} ago</span>}
            </div>
            <div style={{ color: '#384D6C', fontSize: '0.9rem' }}>{userDetails?.agency}</div>
            <div style={{ display: 'flex', color: '#6B7280', fontSize: '0.75rem' }}>
              {userDetails?.access_roles?.map(el => USER_ROLES[el])?.join(' | ')}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <form>
          <div className='userProfileFormBox'>
            <div className='userProfileInput'>
              <label className='userProfilelabel' htmlFor="">First Name</label>
              <input value={userDetails?.first_name} name='first_name' onChange={handleChange} className='userProfileInputBox' type="text" />
            </div>
            <div className='userProfileInput'>
              <label className='userProfilelabel' htmlFor="">Last Name</label>
              <input value={userDetails?.last_name} name='last_name' onChange={handleChange} className='userProfileInputBox' type="text" />
            </div>
          </div>
          <hr />
          <div className='userProfileFormBox'>
            <div className='userProfileInput'>
              <label className='userProfilelabel' htmlFor="">Email</label>
              <input className='userProfileInputBox form-control' type="text" value={userDetails?.email} readOnly ></input>
            </div>
            <div className='userProfileInput'>
              <label className='userProfilelabel' htmlFor="">Mobile No.</label>
              <input value={userDetails?.phone || ""} name='phone' onChange={handleChange} className='userProfileInputBox' type="number" />
            </div>
          </div>
        </form>
        <hr />
        <h6 style={{ color: '#384D6C', fontWeight: 'bold' }}>Brands</h6>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: "1rem", maxHeight: '30vh', padding: '0.5rem 1rem' }}>
            {
              userDetails?.brands?.map((brand) => {
                return (
                  <div
                    className='brandPage-brand'
                  >
                    <div className='brandPage-brandInGrid'>{brand?.logo ? <img src={brand?.logo} alt="" height={30} /> : brand.client_name}</div>
                    <div style={{ fontSize: '0.85rem' }}>{USER_ROLES[brand?.access_role]}</div>
                  </div>
                )
              })
            }
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button className='submit-btn' onClick={saveProfile} disabled={!edit}>Save</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserProfile