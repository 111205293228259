import React from 'react'

const AMCAnalyticsIcon = (props) => {
    return (
        <>
        {
          props.tab==="amc-reporting" ?
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
<path d="M16.7288 2.84766H4.52543C3.68339 2.84865 3.00099 3.53105 3 4.37308V14.1358C2.99992 14.8316 3.47067 15.4392 4.14437 15.613L4.29692 15.0224C4.10033 14.9717 3.92618 14.8571 3.80186 14.6966C3.67753 14.5361 3.6101 14.3388 3.61017 14.1358V4.37308C3.61017 3.86759 4.01994 3.45783 4.52543 3.45783H16.7288C17.2343 3.45783 17.6441 3.86759 17.6441 4.37308V4.67817H18.2543V4.37308C18.2533 3.53105 17.5709 2.84865 16.7288 2.84766Z" fill="#06486A"/>
<path d="M4.21875 4.06641H4.82892V4.67658H4.21875V4.06641ZM5.43909 4.06641H6.04926V4.67658H5.43909V4.06641ZM6.65943 4.06641H7.2696V4.67658H6.65943V4.06641ZM20.909 19.7151L18.0153 16.8214C18.1673 16.5501 18.2889 16.2628 18.3778 15.9647H18.8628V15.3546H18.5089C18.6238 14.6161 18.5352 13.8602 18.2527 13.1683V10.1681H17.6425V12.1109C17.4613 11.8794 17.2569 11.6672 17.0323 11.4775V8.94777H16.4222V11.051C16.2268 10.9378 16.0228 10.8404 15.812 10.7597V7.72743H15.2018V10.5745C15.0008 10.5311 14.7969 10.5024 14.5916 10.4888V8.3376H13.9815V10.4888C13.7763 10.5024 13.5723 10.5311 13.3713 10.5745V9.55794H12.7611V10.7597C12.5503 10.8404 12.3463 10.9378 12.151 11.051V9.25285H11.5408V11.4775C11.3163 11.6672 11.1118 11.8794 10.9306 12.1109V9.86302H10.3205V13.1683C10.0379 13.8602 9.94927 14.6161 10.0642 15.3546H6.04926V15.9647H10.1954C10.8645 18.2217 13.2365 19.5088 15.4934 18.8397C15.9382 18.7078 16.3584 18.5042 16.7376 18.237L19.5624 21.0618C19.6196 21.119 19.6972 21.1511 19.7781 21.1511C19.859 21.1511 19.9366 21.119 19.9938 21.0618L20.909 20.1465C20.9662 20.0893 20.9984 20.0117 20.9984 19.9308C20.9984 19.8499 20.9662 19.7724 20.909 19.7151ZM14.2866 18.4054C12.2655 18.4032 10.6277 16.7654 10.6255 14.7444C10.6255 12.7225 12.2646 11.0834 14.2866 11.0834C16.3085 11.0834 17.9476 12.7225 17.9476 14.7444C17.9476 16.7663 16.3085 18.4054 14.2866 18.4054ZM19.7781 20.4147L17.2117 17.8483C17.378 17.6912 17.5315 17.5212 17.6709 17.3397L20.262 19.9308L19.7781 20.4147Z" fill="#06486A"/>
<path d="M14.2891 11.6953C12.6042 11.6953 11.2383 13.0612 11.2383 14.7462C11.2383 16.4311 12.6042 17.797 14.2891 17.797C15.9741 17.797 17.34 16.4311 17.34 14.7462C17.3382 13.062 15.9733 11.6971 14.2891 11.6953ZM14.2891 17.1868C13.8612 17.1863 13.441 17.0733 13.0706 16.8591C12.7001 16.6449 12.3925 16.3371 12.1786 15.9665H16.3997C16.1858 16.3371 15.8781 16.6449 15.5077 16.8591C15.1373 17.0733 14.717 17.1863 14.2891 17.1868ZM11.9287 15.3563C11.6832 14.4274 12.011 13.4412 12.7637 12.844V14.7462H13.3739V12.4852C13.5695 12.406 13.7746 12.3527 13.984 12.3265V14.7462H14.5942V12.3265C14.8036 12.3527 15.0088 12.406 15.2044 12.4852V14.7462H15.8146V12.844C16.5673 13.4412 16.8951 14.4274 16.6496 15.3563H11.9287Z" fill="#06486A"/>
<path d="M18.5609 5.28906H6.35746C5.51542 5.29005 4.83302 5.97245 4.83203 6.81449V16.5772C4.83302 17.4192 5.51542 18.1016 6.35746 18.1026H10.3236V17.4925H6.35746C5.85197 17.4925 5.4422 17.0827 5.4422 16.5772V6.81449C5.4422 6.309 5.85197 5.89923 6.35746 5.89923H18.5609C19.0664 5.89923 19.4761 6.309 19.4761 6.81449V16.5772C19.4761 16.8001 19.3947 17.0153 19.2473 17.1825L19.7049 17.5861C19.9508 17.3076 20.0865 16.9488 20.0863 16.5772V6.81449C20.0853 5.97245 19.4029 5.29005 18.5609 5.28906Z" fill="#06486A"/>
<path d="M6.05078 6.50781H6.66095V7.11798H6.05078V6.50781ZM7.27112 6.50781H7.88129V7.11798H7.27112V6.50781ZM8.49146 6.50781H9.10163V7.11798H8.49146V6.50781ZM6.66095 12.9146H7.27112V14.7451H6.66095V12.9146ZM7.88129 11.0841H8.49146V14.7451H7.88129V11.0841ZM9.10163 11.9993H9.7118V14.7451H9.10163V11.9993Z" fill="#06486A"/>
</svg> : 
<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
<path d="M16.7288 2.84766H4.52543C3.68339 2.84865 3.00099 3.53105 3 4.37308V14.1358C2.99992 14.8316 3.47067 15.4392 4.14437 15.613L4.29692 15.0224C4.10033 14.9717 3.92618 14.8571 3.80186 14.6966C3.67753 14.5361 3.6101 14.3388 3.61017 14.1358V4.37308C3.61017 3.86759 4.01994 3.45783 4.52543 3.45783H16.7288C17.2343 3.45783 17.6441 3.86759 17.6441 4.37308V4.67817H18.2543V4.37308C18.2533 3.53105 17.5709 2.84865 16.7288 2.84766Z" fill="#5B6871"/>
<path d="M4.21875 4.06641H4.82892V4.67658H4.21875V4.06641ZM5.43909 4.06641H6.04926V4.67658H5.43909V4.06641ZM6.65943 4.06641H7.2696V4.67658H6.65943V4.06641ZM20.909 19.7151L18.0153 16.8214C18.1673 16.5501 18.2889 16.2628 18.3778 15.9647H18.8628V15.3546H18.5089C18.6238 14.6161 18.5352 13.8602 18.2527 13.1683V10.1681H17.6425V12.1109C17.4613 11.8794 17.2569 11.6672 17.0323 11.4775V8.94777H16.4222V11.051C16.2268 10.9378 16.0228 10.8404 15.812 10.7597V7.72743H15.2018V10.5745C15.0008 10.5311 14.7969 10.5024 14.5916 10.4888V8.3376H13.9815V10.4888C13.7763 10.5024 13.5723 10.5311 13.3713 10.5745V9.55794H12.7611V10.7597C12.5503 10.8404 12.3463 10.9378 12.151 11.051V9.25285H11.5408V11.4775C11.3163 11.6672 11.1118 11.8794 10.9306 12.1109V9.86302H10.3205V13.1683C10.0379 13.8602 9.94927 14.6161 10.0642 15.3546H6.04926V15.9647H10.1954C10.8645 18.2217 13.2365 19.5088 15.4934 18.8397C15.9382 18.7078 16.3584 18.5042 16.7376 18.237L19.5624 21.0618C19.6196 21.119 19.6972 21.1511 19.7781 21.1511C19.859 21.1511 19.9366 21.119 19.9938 21.0618L20.909 20.1465C20.9662 20.0893 20.9984 20.0117 20.9984 19.9308C20.9984 19.8499 20.9662 19.7724 20.909 19.7151ZM14.2866 18.4054C12.2655 18.4032 10.6277 16.7654 10.6255 14.7444C10.6255 12.7225 12.2646 11.0834 14.2866 11.0834C16.3085 11.0834 17.9476 12.7225 17.9476 14.7444C17.9476 16.7663 16.3085 18.4054 14.2866 18.4054ZM19.7781 20.4147L17.2117 17.8483C17.378 17.6912 17.5315 17.5212 17.6709 17.3397L20.262 19.9308L19.7781 20.4147Z" fill="#5B6871"/>
<path d="M14.2891 11.6953C12.6042 11.6953 11.2383 13.0612 11.2383 14.7462C11.2383 16.4311 12.6042 17.797 14.2891 17.797C15.9741 17.797 17.34 16.4311 17.34 14.7462C17.3382 13.062 15.9733 11.6971 14.2891 11.6953ZM14.2891 17.1868C13.8612 17.1863 13.441 17.0733 13.0706 16.8591C12.7001 16.6449 12.3925 16.3371 12.1786 15.9665H16.3997C16.1858 16.3371 15.8781 16.6449 15.5077 16.8591C15.1373 17.0733 14.717 17.1863 14.2891 17.1868ZM11.9287 15.3563C11.6832 14.4274 12.011 13.4412 12.7637 12.844V14.7462H13.3739V12.4852C13.5695 12.406 13.7746 12.3527 13.984 12.3265V14.7462H14.5942V12.3265C14.8036 12.3527 15.0088 12.406 15.2044 12.4852V14.7462H15.8146V12.844C16.5673 13.4412 16.8951 14.4274 16.6496 15.3563H11.9287Z" fill="#5B6871"/>
<path d="M18.5609 5.28906H6.35746C5.51542 5.29005 4.83302 5.97245 4.83203 6.81449V16.5772C4.83302 17.4192 5.51542 18.1016 6.35746 18.1026H10.3236V17.4925H6.35746C5.85197 17.4925 5.4422 17.0827 5.4422 16.5772V6.81449C5.4422 6.309 5.85197 5.89923 6.35746 5.89923H18.5609C19.0664 5.89923 19.4761 6.309 19.4761 6.81449V16.5772C19.4761 16.8001 19.3947 17.0153 19.2473 17.1825L19.7049 17.5861C19.9508 17.3076 20.0865 16.9488 20.0863 16.5772V6.81449C20.0853 5.97245 19.4029 5.29005 18.5609 5.28906Z" fill="#5B6871"/>
<path d="M6.05078 6.50781H6.66095V7.11798H6.05078V6.50781ZM7.27112 6.50781H7.88129V7.11798H7.27112V6.50781ZM8.49146 6.50781H9.10163V7.11798H8.49146V6.50781ZM6.66095 12.9146H7.27112V14.7451H6.66095V12.9146ZM7.88129 11.0841H8.49146V14.7451H7.88129V11.0841ZM9.10163 11.9993H9.7118V14.7451H9.10163V11.9993Z" fill="#5B6871"/>
</svg>
        }
        </>
      )
}

export default AMCAnalyticsIcon
