import { useState } from 'react';
import CustomSelect from '../../../utils/commonComponents/customSelect/CustomSelect';
import ModalComponent from '../../../utils/commonComponents/Modal/ModalComponent';
import image from '../../../assets/img.png'

const OnboardBrand = () => {

    const [memberCount, setMemberCount] = useState(1);
    const [open, setopen] = useState(false);

    const [brand, setBrand] = useState('');
    const [brandLogo, setBrandLogo] = useState(image);
    const [userList, setUserList] = useState([{
        id: 0,
        userName: '',
        role: ''
    }])

    const [userArr, setUserArr] = useState([]);

    const handleAddMember = () => {
        setMemberCount((prev) => (prev + 1));
        let obj = { id: memberCount, userName: '', role: '' };
        userList.push(obj);
    }


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setBrandLogo(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleUserName = (e) => {
        setUserList(prevValues =>
            prevValues.map(el =>
                el.id === Number(e.target.id) ? { ...el, userName: e.target.value } : el
            )
        );
    }
    const handleRole = (e) => {
        setUserList(prevValues =>
            prevValues.map(input =>
                input.id === Number(e.target.id) ? { ...input, role: e.target.value } : input
            )
        );
    }

    const handleMember = (el) => {
        // console.log(el);
        if (el?.userName && el?.role) {
            userArr.push(el);
        }
    }

    // console.log(userArr);

    return (
        <div className='onboard'>
            <h6 className='onboard-heading'>Onboard Brand</h6>
            <section className='onboard-section'>
                <div>
                    <form action="" className='onboard-addBrand'>
                        <div className='onboard-input'>
                            <label htmlFor="">Brand Name</label>
                            <input value={brand} onChange={(e) => setBrand(e.target.value)} className='input-field' type="text" />
                        </div>
                        <div className='userProfileInput'>
                            <label htmlFor="">Brand Logo</label>
                            <div className="onboard-uploadfile">
                                <div className="onboard-imageContainer">
                                    <img src={brandLogo} alt="upload" width={72} height={72} />
                                </div>
                                <div>
                                    <label className="onboard-imageupload" htmlFor="fileInput"><i class="bi bi-paperclip"></i>&nbsp;Attach image</label>
                                    <input onChange={handleImageChange} type="file" id="fileInput" accept='.png, .jpeg' style={{ display: 'none' }} />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='onboard-addMember'>
                    <div>Add Members</div>
                    <div>
                        {
                            userList?.map((el) => {
                                return (
                                    <div key={el?.id} className='onboard-memberFormUnit'>
                                        <span>Member {el?.id + 1}</span>
                                        <form action="" className='onboard-memberForm'>
                                            <div>
                                                <input id={el?.id} value={el?.userName} onChange={handleUserName} className='input-field' type="text" placeholder='Name' />
                                            </div>
                                            <CustomSelect id={el?.id} value={el?.role} onchange={handleRole} className='onboard-select'>
                                                <option value="" disabled>Select Role</option>
                                                <option value="brand_manager" >brand manager</option>
                                                <option value="brand_member" >brand memeber</option>
                                                <option value="brand_viewer" >brand viewer</option>
                                            </CustomSelect>
                                        </form>
                                        <div>
                                            <button id={el?.id} onClick={() => handleMember(el)} style={{ fontSize: '0.75rem' }} className='submit-btn'>Add</button>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <button className='onboard-addMemberBtn' onClick={handleAddMember}>Add New Member +</button>
                    </div>
                </div>
            </section>
            <section className='onboard-btnContainer'>
                <button type='button' className='submit-btn' onClick={() => setopen(true)}>Confirm</button>
                <button type='reset' className='cancel-btn'>Cancel</button>
            </section>
            <ModalComponent
                open={open}
                modalClose={() => setopen(false)}
            >
                <div>
                    <h6 className='onboard-heading'>Onboard Brand</h6>
                    <img src={brandLogo} alt="Logo" />
                    <div>brand name</div>
                    <p>4 member</p>
                    <button className='submit-btn'>Add brand</button>
                </div>
            </ModalComponent>
        </div>
    )
}

export default OnboardBrand;