import { createSlice } from "@reduxjs/toolkit";
import { fetchDashbaord } from "./dashboard.thunk";
// import { signOut } from "../user/user.slice";
import { ActionStates } from "../../utils/types.enum";

const INITIAL_STATE = {
  dashboard: undefined,
  status: {
    action: ActionStates.UNINITIALISED,
    error: undefined,
    message: undefined,
  },
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: INITIAL_STATE,
  reducers: {
    resetDashboard:(state, action)=>{
      return INITIAL_STATE
    }
  },
  extraReducers: (builder) => {
    // When our request is pending:
    builder.addCase(fetchDashbaord.pending, (state:any, action:any) => {
      state.status.action = ActionStates.PENDING;
      state.status.message = undefined;
      state.dashboard = undefined;
      state.status.error = undefined;
    });
    // When our request is fulfilled:
    builder.addCase(fetchDashbaord.fulfilled, (state:any, action:any) => {
      state.status.action = ActionStates.FULFILLED;
      state.status.error = null;
      state.dashboard = action.payload;
      state.status.message = `Dashboard Successfully Loaded`;
    });
    // When our request is rejected:
    builder.addCase(fetchDashbaord.rejected, (state:any, action:any) => {
      state.status.action = ActionStates.REJECTED;
      state.status.error = action.error;
      console.log(action?.payload);
      state.status.message = "Failed Dashboard Fetching: " + (action.payload?.data?.detailMessage?.error.message || action.error.message);
      state.dashboard = null;
    });
  },
});

export const dashboardReducer = dashboardSlice.reducer;
export const {resetDashboard} = dashboardSlice.actions;