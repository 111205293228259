import React, { useState } from 'react'
import "./audience.scss"
import AudienceIcon from '../../../../../assets/svgs/AudienceIcon'
import { useNavigate } from 'react-router-dom'

const AMCaudience = () => {

    const navigate = useNavigate();

    const data=[
        {
            query:'Audience that added to list or registry ',
            description:'Build an audience of shoppers that have added products to a wishlist or registry.'
        },
        {
            query:'Audiences exposed to Amazon DSP campaigns',
            description:'Build an audience exposed to Amazon DSP campaigns or creative'
        },
        {
            query:'Audience exposed to one Amazon DSP campaign but not another',
            description:'Build an audience of shoppers exposed to one Amazon DSP campaign, but not another.'
        },
        {
            query:'Audience that added to cart but did not purchase',
            description:'Build an audience that have added one or more ASINs to cart but have not yet purchased.'
        },
        {
            query:'Audience of high-value, new-to-brand customers',
            description:'Build an audience that are new-to-brand (NTB) and high-value based on total spend.'
        },
        {
            query:'Audience frequently exposed to your campaigns',
            description:'Build a frequently exposed audience for negative targeting in Amazon DSP.'
        },
        {
            query:'Introduction to AMC lookalike audiences',
            description:'Create lookalike audiences to expand campaign reach.'
        },
        {
            query:'Audiences based on high value customer segments',
            description:'Build an audience of customers that ranked in the top percentile of total purchases.'
        },
        {
            query:'Audience with multiple detail page views',
            description:'Build an audience with a high number of detail page views but who have not yet purchased.'
        },
        {
            query:'Audience that clicked sponsored ads but did not purchase',
            description:'Build an audience of shoppers that clicked a sponsored ad but have not purchased since.'
        },
        {
            query:'Audience based on sponsored ads keywords',
            description:'Build an audience of shoppers who have searched using keywords, but have not purchased.'
        },
    ]


  return (
    <div className='audience-container'>
        <div className='audience-header'><h3>AMC Audience</h3><AudienceIcon/></div>

        <div className='choose-query'>Choose the query</div>

        <div className='box-container'>
            {data.map((dt:any)=>(
            <div className='boxes'>
                <div className='box1 ' onClick={()=>{
                    navigate('/tools/amc-audience/query',{ state: { selectedQuery: dt.query, selectedDescription: dt.description } })}}>
                        {dt.query} 
                </div>
                <div className='box2'>{dt.description}</div>
            </div>
            ))}
        </div>

        <div className='end-bottom'>Want to check your past queries ? <span className='click-here-box' onClick={()=>navigate('/tools/amc-audience/history')}>Click Here</span></div>
       
    </div>
  )
}

export default AMCaudience
