import React, { useState, useMemo, useRef, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react';
import ReportsErrorPage from '../../../utils/commonComponents/errorFallback/ReportsErrorPage';
import Loader from '../../../utils/commonComponents/Loader/Loader';
import { AG_AR, BASE_URL_NEW } from '../../../appConstant';
import '../../../appLayout/layoutComponents/Content.scss';
import DrawerComponent from '../components/DrawerComponent';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Avatar } from '@mui/material';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import axios from 'axios';
import { getUserColor } from '../../../utils/commonFunction';
import ModifyRole from '../components/ModifyRole';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../../redux/hook';

const SettingsComponent = ({ data, setModalOpen, setModifyRole, fetchData }) => {
  const loggeduser = useAppSelector((state) => state.user);
  const { current_agency, current_brand:{access_role} } = useAppSelector((state) => state.client)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModifyRole = () => {
    if (access_role === 'AGENCY_ADMIN') {
      setModifyRole(data);
      setModalOpen(true);
    } else {
      toast.error("You do not have access to perform this action")
    }
    handleClose()
  }

  const handleSetAdmin = () => {
    if (access_role === 'AGENCY_ADMIN') {
      axios.post(`${BASE_URL_NEW}/access/admin`, {
        user: data?._id,
        agency: current_agency?._id,
        invited_by: loggeduser?._id
      }).then((res) => {
        fetchData();
        toast.success("User is now Set as Admin successfully in this agency")
      }).catch((error) => {
        console.log(error);
        toast.error("Something went Wrong")
      })
    } else {
      toast.error("You do not have access to perform this action")
    }
    handleClose();
  }

  const handleDelete = () => {
    if (access_role === 'AGENCY_ADMIN') {
      axios.patch(`${BASE_URL_NEW}/access/?user=${data._id}&agency=${current_agency._id}`, {
        status: "INACTIVE"
      }).then((res) => {
        // console.log(res);
        fetchData();
        toast.success("User deleted successfully in this agency")
      }).catch(error => {
        console.log(error);
        toast.error("Something went Wrong")
      }).finally(() => {

      })
    } else {
      toast.error("You do not have access to perform this action")
    }
    handleClose();
  }

  return (
    <div>
      <i onClick={handleClick} className="bi bi-three-dots-vertical"></i>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}

      >
        <MenuItem sx={{ fontSize: '0.75rem' }} onClick={handleSetAdmin}><PersonOutlineIcon sx={{ fontSize: '0.85rem', color: '#384D6C' }} />&nbsp; Set as Admin</MenuItem>
        <MenuItem sx={{ fontSize: '0.75rem' }} onClick={handleModifyRole}><EditOutlinedIcon sx={{ fontSize: '0.85rem', color: '#384D6C' }} />&nbsp; Modify Brand Roles</MenuItem>
        <MenuItem sx={{ fontSize: '0.75rem' }} onClick={handleDelete}><DeleteOutlineOutlinedIcon sx={{ fontSize: '0.85rem', color: 'red' }} />&nbsp; Delete User</MenuItem>
      </Menu>

    </div>
  )
}

const AgencyPage = (props) => {
  const { brandList, UsersData, setUsersData, filterUserData } = props;
  const appParams = useAppSelector((state) => state.client);
  const { current_agency } = appParams;
  const user = useAppSelector((state) => state.user);
  // console.log(user);
  const gridRef = useRef();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [modifyRole, setModifyRole] = useState({});


  const fetchData = () => {
    setIsLoading(true)
    axios.get(`${BASE_URL_NEW}/user/agency/${current_agency?._id}`)
      .then((res) => {
        res.data.forEach((user) => {
          if (!user.last_active_on) user.status = "NA"
          else if (Date.now() - user.last_active_on < 60000 * 2) user.status = 'ACTIVE'
          else if (Date.now() - user.last_active_on < 60000 * 60) user.status = Math.round((Date.now() - user.last_active_on) / 60000) + " min"
          else if (Date.now() - user.last_active_on < 60000 * 60 * 24) user.status = Math.round((Date.now() - user.last_active_on) / (60000 * 60)) + " hr"
          else user.status = Math.round((Date.now() - user.last_active_on) / (60000 * 60 * 24)) + " days"
        })
        setUsersData(res.data)
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        setIsLoading(false)
      })
  }


  useEffect(() => {
    fetchData()
    const intervalId = setInterval(fetchData, 60000 * 2);
    return () => clearInterval(intervalId);
  }, [current_agency?._id])

  const handleclose = () => {
    if (gridRef.current.api) {
      gridRef.current.api.getSelectedNodes().forEach(node => {
        node.setSelected(false)
      });
    }
    setOpen(false)
  }

  const handleOpenDrawer = (data) => {
    setOpen(true);
    setSelectedRowId(data)
  }

  const nameComp = (props) => {
    const { data } = props;
    const { status, access_role } = props.data;
    return (
      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }} onClick={() => handleOpenDrawer(data)}>
        <span style={{ position: 'relative' }}>
          <Avatar sx={{ width: '1.25rem', height: '1.25rem', backgroundColor: `${getUserColor(access_role[0])}` }}>
            <span style={{ fontSize: '0.65rem', color: '#ffffff' }}>{data?.first_name?.split(" ")[0][0]?.toUpperCase() + data?.last_name?.split(" ")[0][0]?.toUpperCase()}</span>
          </Avatar>
          {status === 'ACTIVE' &&
            <i style={{ position: 'absolute', top: '-12px', right: '-4px', zIndex: 999, color: '#2FE346', WebkitTextStroke: '0.25rem', }} className="bi bi-dot"></i>
          }
        </span>
        <span style={{ textTransform: 'capitalize' }}>{data?.first_name + ' ' + data?.last_name}</span>
      </div>
    )
  }

  const statusComp = (props) => {
    const { status } = props.data;
    return (
      <div>
        {
          status === 'ACTIVE' ?
            <span style={{ backgroundColor: '#2fe34742', padding: '2px 4px', border: '2px solid #2FE346', borderRadius: '1rem' }}>
              <i style={{ color: '#2FE346', WebkitTextStroke: '0.25rem' }} class="bi bi-dot"></i>&nbsp;Active
            </span> :
            <span>{status} ago</span>
        }
      </div>
    )
  }

  const brandComp = (props) => {
    const { brands, access_role } = props.data;
    // console.log(props.data);
    const sortedBrands = brands.sort((a, b) => a.client_name.localeCompare(b.client_name));
    const first3element = sortedBrands.slice(0, 3);
    const otherElements = sortedBrands.length - 3;


    const tippyContent = () => {
      return (
        <div>
          <div style={{ textTransform: 'capitalize' }}>Name : {props.data.first_name}&nbsp;{props.data.last_name}</div>
          <div>Brands: <br /> <span style={{ fontWeight: 500 }}>{sortedBrands.map((el) => el.client_name).join(', ')}</span></div>
        </div>
      )
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }}>
        <div style={{ display: 'flex' }}>
          {
            access_role[0] === 'AGENCY_ADMIN' ? <div>All Brands</div> :
              <div style={{ display: 'flex' }}>
                {
                  sortedBrands.length > 3 ? <div style={{ display: 'flex' }}>
                    {
                      first3element.map((brand) => {
                        return (
                          <div key={brand?._id}>
                            <span>{brand?.logo ? <img src={brand?.logo} alt="" width={40} height={20} /> : brand.client_name}&nbsp;</span>
                          </div>
                        )
                      })
                    }
                    <span>{`+${otherElements} more`}</span>
                  </div> : <div style={{ display: 'flex' }}>
                    {
                      sortedBrands.map((brand) => {
                        return (
                          <div key={brand?._id}>
                            <span>{brand?.logo ? <img src={brand?.logo} alt="" width={40} height={20} /> : brand.client_name}&nbsp;</span>
                          </div>
                        )
                      })
                    }
                  </div>
                }

              </div>
          }
        </div>

        <div>
          <Tippy content={tippyContent()}
            placement='bottom' theme='custom-theme'
          >
            <i style={{ color: '#384D6C' }} className="bi bi-info-circle-fill"></i>
          </Tippy>
        </div>
      </div>
    )
  }

  const roleComp = (props) => {
    const { data: { access_role } } = props;
    if (access_role[0] === 'AGENCY_ADMIN') {
      return 'Admin'
    } else if (access_role[0] === 'BRAND_MANAGER') {
      return 'Manager'
    } else if (access_role[0] === 'BRAND_MEMBER') {
      return 'Member'
    } else if (access_role[0] === 'BRAND_VIEWER') {
      return 'Viewer'
    } else if (access_role[0] === "EXTERNAL_BRAND_VIEWER") {
      return 'Guest'
    }

    return (
      <div style={{ borderLeft: `4px solid ${getUserColor(access_role[0])}`, paddingLeft: '0.5rem' }}>
        {access_role[0]}
      </div>
    )
  }

  const columnDefs = useMemo(() => [
    { headerName: "NAME", field: "name", cellRenderer: nameComp, width: 200 },
    { headerName: "EMAIL", field: 'email', cellClass: "email-cell", width: 280 },
    { headerName: "STATUS", field: 'status', cellRenderer: statusComp, width: 180, cellClass: 'center-text' },
    { headerName: "ROLE", field: 'access_role', cellRenderer: roleComp, width: 180, cellClass: 'center-text' },
    { headerName: "BRANDS", field: '', cellRenderer: brandComp, width: 250, cellClass: 'brand-cell' },
    { headerName: "SETTINGS", field: '', cellRenderer: ({ data }) => SettingsComponent({ data, setModalOpen, setModifyRole, fetchData }), width: 150, cellClass: 'center-text' },
  ], [])

  const defaultColDef = useMemo(() => ({
    sortable: true, resizable: true, minWidth: 100
  }), []);

  return (
    <div>
      <div className="ag-theme-alpine agency-table" style={{ height: '75vh', width: '100%', boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px " }}>
        {error ? <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
          <AgGridReact
            ref={gridRef}
            rowData={filterUserData}
            rowHeight={AG_AR.rH}
            headerHeight={AG_AR.hH}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            suppressNoRowsOverlay={true}
            suppressDragLeaveHidesColumns={true}
            suppressMenuHide={true}
            suppressCellFocus={true}
          >
          </AgGridReact>
        }
        {
          isLoading && <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} >< Loader /></div>
        }
        <DrawerComponent
          open={open}
          handleclose={handleclose}
          selectedRowId={selectedRowId}
        />
        <ModifyRole
          modifyRole={modifyRole}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          fetchData={fetchData}
        />
      </div>
    </div>
  )
}

export default AgencyPage