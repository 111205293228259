import { useEffect, useState } from "react";
import { Data, KPI, State, StateKeys } from "../types/type";
import axios from "axios";
import { BASE_URL_NEW } from "../../../appConstant";
import { useAppSelector } from "../../../redux/hook";
import LineGraph from "./LineGraph";
import { formatSingleDateFn, formatedMetric, getMontheInText } from "../../../utils/commonFunction";

interface Props {
  intervalType: string,
  intervalRange: any,
  selected4KpiArr: KPI[]
}

const DailyPageGraph: React.FC<Props> = (props) => {
  const { intervalType, selected4KpiArr, intervalRange } = props;
  const client = useAppSelector(state => state.client);
  const { current_brand: { client_id } } = client
  const [isLoading, setIsLoading] = useState(false)
  const [state, setState] = useState<State>({
    time_stamp: [],
    impressions: [],
    clicks: [],
    ctr: [],
    cpc: [],
    orders: [],
    conversions: [],
    acos: [],
    cost: [],
    sales: [],
    roas: [],
    tos_is: [],
    cvr:[],
    units:[]
  })

  useEffect(() => {
    if (client_id && intervalType && Object.keys(intervalRange).length > 0) {
      let body: any = {
        interval_type: intervalType,
        ...intervalRange
      }

      setIsLoading(true);
      axios.post(`${BASE_URL_NEW}/report/graph/${client_id}`, body)
        .then((response) => {
          const graph_data_array: Data[] = response.data;
          const labelsArray: string[] = [];
          const salesArray: number[] = [];
          const costArray: number[] = [];
          const acosArray: number[] = [];
          const ordersArray: number[] = [];
          const clicksArray: number[] = [];
          const cpcArray: number[] = [];
          const impressionsArray: number[] = [];
          const ctrArray: number[] = [];
          const conversionsArray: number[] = [];
          const roasArray: number[] = [];
          const tos_isArray: number[] = [];
          const cvrArray: number[] = [];
          const unitsArray: number[] = [];
          if (graph_data_array.length > 0) {
            graph_data_array.forEach(e => {
              const { time_stamp, month, week, year, impressions, clicks, ctr, cpc, orders, conversions, acos, cost, sales, roas, tos_is,cvr,units } = e;
              
              let date: any;
              if (intervalType === 'DAILY') {
                // date = new Date(item.time_stamp).toISOString().split('T')[0];
                date = formatSingleDateFn(time_stamp)
              } else if (intervalType === 'WEEKLY') {
                date = `${getMontheInText(month)}-Week-${week}`
              } else if (intervalType === 'MONTHLY') {
                date = `${getMontheInText(month)}-${year}`
              }
              
              labelsArray.push(date)
              impressionsArray.push(impressions);
              clicksArray.push(clicks);
              ctrArray.push(ctr);
              cpcArray.push(cpc);
              ordersArray.push(orders);
              conversionsArray.push(conversions);
              acosArray.push(acos);
              costArray.push(cost);
              salesArray.push(sales);
              roasArray.push(roas);
              tos_isArray.push(tos_is);
              cvrArray.push(cvr);
              unitsArray.push(units);
            })
          }
          setState(prevState => ({
            ...prevState,
            time_stamp: labelsArray,
            impressions: impressionsArray,
            clicks: clicksArray,
            ctr: ctrArray,
            cpc: cpcArray,
            orders: ordersArray,
            conversions: conversionsArray,
            acos: acosArray,
            cost: costArray,
            sales: salesArray,
            roas: roasArray,
            tos_is: tos_isArray,
            cvr: cvrArray,
            units: unitsArray,
          }))
          setIsLoading(false);
        }).catch(function (error) {
          console.log(error);
          setIsLoading(false);
        });
    }
  }, [client_id, intervalType, intervalRange])

  const graphData = {
    labels: state.time_stamp,
    datasets: [
      {
        label: formatedMetric(selected4KpiArr[0]?.metric),
        data: state[selected4KpiArr[0]?.metric as StateKeys],
        borderColor: 'rgba(0, 178, 255, 1)',
        backgroundColor: 'rgba(0, 178, 255, 1)',
        borderWidth: 2,
        // axesColor:
        yAxisID: 'y'
      },
      {
        label: formatedMetric(selected4KpiArr[1]?.metric),
        data: state[selected4KpiArr[1]?.metric as StateKeys],
        borderColor: 'rgba(255, 199, 0, 1)',
        backgroundColor: 'rgba(255, 199, 0, 1)',
        borderWidth: 2,
        yAxisID: 'y1'
      },
      {
        label: formatedMetric(selected4KpiArr[2]?.metric),
        data: state[selected4KpiArr[2]?.metric as StateKeys],
        borderColor: 'rgba(238, 52, 101, 1)',
        backgroundColor: 'rgba(238, 52, 101, 1)',
        borderWidth: 2,
        yAxisID: 'y2'
      },
      {
        label: formatedMetric(selected4KpiArr[3]?.metric),
        data: state[selected4KpiArr[3]?.metric as StateKeys],
        borderColor: 'rgba(97, 0, 255, 1)',
        backgroundColor: 'rgba(97, 0, 255, 1)',
        borderWidth: 2,
        yAxisID: 'y3'
      },
    ],
  };



  return (
    <div style={{ height: '20rem', backgroundColor: 'rgba(255, 255, 255, 1)', borderRadius: '0.5rem', padding: '0rem 0.5rem 0.5rem 0.5rem' }}>
      <LineGraph
        graphData={graphData}
        dataFor = {'others'}
      />
    </div >
  )
}

export default DailyPageGraph;