import { PrimeReactProvider } from 'primereact/api'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useState } from 'react'
import '../../pages/amc.scss';
import LoaderSkeletonTable from '../../../../utils/commonComponents/Loader/LoaderSkeletonTable';

const ReportTable = (props: any) => {

    const { reportStatus, reportErrorReason, reportData, columns, exportToCSV, isLoadingTable, handleGeneratePresignedUrlSubmit } = props


    return (
        <div className='reportTableContainer'>
            <div className='headers'>
                <div className='headersBtn'>
                    <button className='button'><i className="bi bi-building-fill-gear"> </i>{reportStatus}</button>
                    {reportStatus?.toLowerCase() === 'succeeded' && <button onClick={handleGeneratePresignedUrlSubmit} className='button'><i className="bi bi-download"></i> Download</button>}
                </div>
                {reportStatus?.toLowerCase() === 'rejected' && <h6>{reportErrorReason}</h6>}
                {
                    isLoadingTable ? <LoaderSkeletonTable height='350px' /> :
                        <PrimeReactProvider>
                            <DataTable
                                value={reportData}
                                size='small'
                                columnResizeMode="expand"
                                resizableColumns
                                showGridlines
                                id='unique-table'
                                scrollable scrollHeight="350px"

                            >
                                {columns.map((col: any, index: any) => (
                                    <Column
                                        key={index}
                                        field={col.field}
                                        header={col.header}
                                        style={{ width: '150px' }}
                                        headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                                        bodyStyle={{ color: '#000000', fontSize: '0.85rem', textTransform: 'capitalize' }}
                                    // bodyStyle={column?.bodyStyle}

                                    />
                                ))}
                            </DataTable>
                        </PrimeReactProvider>
                }
            </div>
        </div>
    )
}

export default ReportTable