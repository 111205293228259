import { PrimeReactProvider } from 'primereact/api';
import React, { useEffect, useRef, useState, ChangeEvent } from 'react'
import LoaderSkeletonTable from '../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';




const ProductAssortment :React.FC = () => {

    const [MockData, setMockData] = useState([
        {
        platform: 'Amazon Web',
        client_profile_id: 1498269816203807,
        client: 'Unibic Cookies',
        ASIN: 'B0B73RVWZG',
        Product_Name: 'Unibic Qubz Wafer Biscuits 150 Gram, All Natural, Hazelnut Flavour(/100 g)',
        Competitor_Listings: 2,
        Top_Competitor: 'Kyomi',
        Competitor_Availability: 'Low',
        Comp_Average_Rating: 4.3,
        Comp_Average_Weightage: '115g - 150g',
        Average_Comp_Reviews: 41,
        Comp_Price_Range: '₹99 - ₹159',
        features_in_comp: NaN,
        our_customer_complaints: NaN,
        potential_product_solution: NaN
    },
       {
        platform: 'Amazon Web',
        client_profile_id: 1498269816203807,
        client: 'Unibic Cookies',
        ASIN: 'B0BBSNTFQT',
        Product_Name: 'Unibic Cubes Wafer Value Pack | Chocolate Flavoured | Pack of 3(/100 g)',
        Competitor_Listings: 10,
        Top_Competitor: 'Equal',
        Competitor_Availability: 'High',
        Comp_Average_Rating: 4.2,
        Comp_Average_Weightage: '180g - 405g',
        Average_Comp_Reviews: 3928,
        Comp_Price_Range: '₹90 - ₹489',
        features_in_comp: NaN,
        our_customer_complaints: NaN,
        potential_product_solution: NaN
    },
       {
        platform: 'Amazon Web',
        client_profile_id: 1498269816203807,
        client: 'Unibic Cookies',
        ASIN: 'B00RZDVU9K',
        Product_Name: 'Unibic Cashew Cookies, 150g',
        Competitor_Listings: 10,
        Top_Competitor: 'Britannia',
        Competitor_Availability: 'High',
        Comp_Average_Rating: 4.2,
        Comp_Average_Weightage: '100g - 200g',
        Average_Comp_Reviews: 715,
        Comp_Price_Range: '₹10 - ₹499',
        features_in_comp: 'Broken cookies in delivery, mixed reviews on value for money, some mention of cookie texture issues',
        our_customer_complaints: 'Customers dissatisfied with receiving broken cookies in delivery',
        potential_product_solution: 'Improve packaging to prevent cookies from breaking during delivery'
    },
       {
        platform: 'Amazon Web',
        client_profile_id: 1498269816203807,
        client: 'Unibic Cookies',
        ASIN: 'B08BMCW4TV',
        Product_Name: 'Unibic Oatmeal Digestive 150g Buy 1 Get 1 [Pack of 2](/100 g)',
        Competitor_Listings: 2,
        Top_Competitor: 'Tiffany',
        Competitor_Availability: 'Low',
        Comp_Average_Rating: 3.8,
        Comp_Average_Weightage: '300g - 360g',
        Average_Comp_Reviews: 1007,
        Comp_Price_Range: '₹270 - ₹480',
        features_in_comp: 'Sugar-free, Oatmeal base, Natural ingredients, Fiber-rich',
        our_customer_complaints: 'Mixed opinions on quality and value for money',
        potential_product_solution: 'Improvement in quality consistency and better pricing strategy'
    },
       {
        platform: 'Amazon Web',
        client_profile_id: 1498269816203807,
        client: 'Unibic Cookies',
        ASIN: 'B08ML4Q82Z',
        Product_Name: 'Unibic Choco Nut 500 gm Pouch(/100 g)',
        Competitor_Listings: 10,
        Top_Competitor: 'Karachi',
        Competitor_Availability: 'High',
        Comp_Average_Rating: 4.2,
        Comp_Average_Weightage: '300g - 500g',
        Average_Comp_Reviews: 283,
        Comp_Price_Range: '₹120 - ₹597',
        features_in_comp: NaN,
        our_customer_complaints: NaN,
        potential_product_solution: NaN
    },
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B09MLDMJB4',
    //     'Product Name': 'UNIBIC : Sugar Free Cashew, 75g (Pack of 3)(/100 g)',
    //     'Competitor Listings': 10,
    //     'Top Competitor': 'Britannia',
    //     'Competitor Availability': 'High',
    //     'Comp Average Rating': 4.2,
    //     'Comp Average Weightage': '180g - 300g',
    //     'Average Comp Reviews': 833,
    //     'Comp Price Range': '₹50 - ₹349',
    //     'features_in_comp': 'taste_POSITIVE, Biscuit quality_MIXED, Taste_POSITIVE, Biscuit quality_NEGATIVE, Quality_POSITIVE, Taste_MIXED, Quality_NEGATIVE, Taste_POSITIVE',
    //     'our_customer_complaints': 'Wastage of money, Missing item, all broken in smaller pieces, Taste not so good, Not good we ordered 3 packet but one of them is totally empty, poor quality, most of the biscuits were broken',
    //     'potential_product_solution': 'Improve product quality control to avoid wastage and missing items, Enhance packaging to prevent biscuits from breaking during transit, Focus on improving taste to meet customer expectations'
    // },
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B0BWJPW5GC',
    //     'Product Name': 'UNIBIC Butter Cookies Tiffin Pack | Ideal pack to carry in School and Workplace| Butter Cookies | Pk of 12, 900g.(/100 g)',
    //     'Competitor Listings': 1,
    //     'Top Competitor': 'ALL SOW GREAT',
    //     'Competitor Availability': 'Low',
    //     'Comp Average Rating': nan,
    //     'Comp Average Weightage': '800g - 800g',
    //     'Average Comp Reviews': 'No Competition',
    //     'Comp Price Range': '₹355 - ₹355',
    //     'features_in_comp': nan,
    //     'our_customer_complaints': nan,
    //     'potential_product_solution': nan},
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B0C1NMHZ8G',
    //     'Product Name': 'UNIBIC Danish Coconut Cookies, 900g | Pack of 03(/100 g)',
    //     'Competitor Listings': 10,
    //     'Top Competitor': 'Artinci',
    //     'Competitor Availability': 'High',
    //     'Comp Average Rating': 3.3,
    //     'Comp Average Weightage': '185g - 400g',
    //     'Average Comp Reviews': 374,
    //     'Comp Price Range': '₹120 - ₹599',
    //     'features_in_comp': nan,
    //     'our_customer_complaints': nan,
    //     'potential_product_solution': nan},
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B0CK4RH76H',
    //     'Product Name': 'Unibic Danish Coconut Cookies 37.5g PACK OF 2.Unique(/100 g)',
    //     'Competitor Listings': 2,
    //     'Top Competitor': 'minimal',
    //     'Competitor Availability': 'Low',
    //     'Comp Average Rating': 4.3,
    //     'Comp Average Weightage': '100g - 100g',
    //     'Average Comp Reviews': 118,
    //     'Comp Price Range': '₹199 - ₹449',
    //     'features_in_comp': 'high quality, great taste, versatile usage, mixed opinions on value for money',
    //     'our_customer_complaints': 'No specific complaints mentioned',
    //     'potential_product_solution': 'To improve competitiveness, focus on enhancing value for money aspect while maintaining high quality and taste standards.'},
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B0CNTCGN84',
    //     'Product Name': 'Unibic Cake - Choco Fudge 140gm(/100 g)',
    //     'Competitor Listings': 7,
    //     'Top Competitor': 'Cadbury',
    //     'Competitor Availability': 'Medium',
    //     'Comp Average Rating': 3.8,
    //     'Comp Average Weightage': '100g - 150g',
    //     'Average Comp Reviews': 3407,
    //     'Comp Price Range': '₹60 - ₹540',
    //     'features_in_comp': nan,
    //     'our_customer_complaints': nan,
    //     'potential_product_solution': nan},
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B0D3R2PKT2',
    //     'Product Name': 'Unibic Wafer Qubz Belgium Chocolate 300gm(/100 g)',
    //     'Competitor Listings': 10,
    //     'Top Competitor': 'Dukes',
    //     'Competitor Availability': 'High',
    //     'Comp Average Rating': 4.1,
    //     'Comp Average Weightage': '180g - 400g',
    //     'Average Comp Reviews': 3596,
    //     'Comp Price Range': '₹90 - ₹409',
    //     'features_in_comp': nan,
    //     'our_customer_complaints': nan,
    //     'potential_product_solution': nan},
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B0BCVQYVBS',
    //     'Product Name': 'Unibic Choco Kiss Cookies 75g (pack of 4) Unique(/100 g)',
    //     'Competitor Listings': 10,
    //     'Top Competitor': 'Oreo',
    //     'Competitor Availability': 'High',
    //     'Comp Average Rating': 4.3,
    //     'Comp Average Weightage': '200g - 400g',
    //     'Average Comp Reviews': 6478,
    //     'Comp Price Range': '₹50 - ₹289',
    //     'features_in_comp': nan,
    //     'our_customer_complaints': nan,
    //     'potential_product_solution': nan},
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B0BCW7FNZ2',
    //     'Product Name': 'Unibic Sugar Free Cashew Cookies 75g (Pack of 3) Unique(/100 g)',
    //     'Competitor Listings': 10,
    //     'Top Competitor': 'Britannia',
    //     'Competitor Availability': 'High',
    //     'Comp Average Rating': 4.2,
    //     'Comp Average Weightage': '180g - 300g',
    //     'Average Comp Reviews': 833,
    //     'Comp Price Range': '₹50 - ₹349',
    //     'features_in_comp': nan,
    //     'our_customer_complaints': nan,
    //     'potential_product_solution': nan},
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B0CJ5G7442',
    //     'Product Name': 'Unibic Marie Thinz Atta 495 gm(/100 g)',
    //     'Competitor Listings': 10,
    //     'Top Competitor': 'LittleVeda',
    //     'Competitor Availability': 'High',
    //     'Comp Average Rating': 4.1,
    //     'Comp Average Weightage': '300g - 600g',
    //     'Average Comp Reviews': 114,
    //     'Comp Price Range': '₹180 - ₹568',
    //     'features_in_comp': nan,
    //     'our_customer_complaints': nan,
    //     'potential_product_solution': nan},
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B0D3HJYCHF',
    //     'Product Name': 'UNIBIC Pop Crisp I Rice and Beans Pack of 3 (3 X 80G)(/100 g)',
    //     'Competitor Listings': 10,
    //     'Top Competitor': 'Ghasitaram Gifts',
    //     'Competitor Availability': 'High',
    //     'Comp Average Rating': 4.1,
    //     'Comp Average Weightage': '150g - 325g',
    //     'Average Comp Reviews': 855,
    //     'Comp Price Range': '₹30 - ₹599',
    //     'features_in_comp': nan,
    //     'our_customer_complaints': nan,
    //     'potential_product_solution': nan},
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B00PG34FL6',
    //     'Product Name': 'Unibic Cashew Cookies, 75g',
    //     'Competitor Listings': 10,
    //     'Top Competitor': 'Generic',
    //     'Competitor Availability': 'High',
    //     'Comp Average Rating': 4.1,
    //     'Comp Average Weightage': '50g - 105g',
    //     'Average Comp Reviews': 971,
    //     'Comp Price Range': '₹10 - ₹499',
    //     'features_in_comp': nan,
    //     'our_customer_complaints': nan,
    //     'potential_product_solution': nan},
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B00RZDVIGA',
    //     'Product Name': 'Unibic Cookies, Honey Oatmeal, 75 Gram | Oatmeal Cookies | Honey Oatmeal Cookies - Oat Meal',
    //     'Competitor Listings': 1,
    //     'Top Competitor': 'Sri Sri TATTVA shuddhta ka naam',
    //     'Competitor Availability': 'Low',
    //     'Comp Average Rating': 4.2,
    //     'Comp Average Weightage': '100g - 100g',
    //     'Average Comp Reviews': 986,
    //     'Comp Price Range': '₹580 - ₹580',
    //     'features_in_comp': nan,
    //     'our_customer_complaints': nan,
    //     'potential_product_solution': nan},
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B01MU773CB',
    //     'Product Name': 'Unibic Cookies|Assorted,75G (Pack Of 6)(/100 g)',
    //     'Competitor Listings': 10,
    //     'Top Competitor': 'CookieMan',
    //     'Competitor Availability': 'High',
    //     'Comp Average Rating': 3.9,
    //     'Comp Average Weightage': '300g - 600g',
    //     'Average Comp Reviews': 250,
    //     'Comp Price Range': '₹260 - ₹750',
    //     'features_in_comp': nan,
    //     'our_customer_complaints': nan,
    //     'potential_product_solution': nan},
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B07DLYK78Z',
    //     'Product Name': 'Unibic Foods Choco Chip Cookies, 500gram, Chocolate(/100 g)',
    //     'Competitor Listings': 10,
    //     'Top Competitor': 'Parle',
    //     'Competitor Availability': 'High',
    //     'Comp Average Rating': 4.1,
    //     'Comp Average Weightage': '350g - 600g',
    //     'Average Comp Reviews': 730,
    //     'Comp Price Range': '₹88 - ₹599',
    //     'features_in_comp': nan,
    //     'our_customer_complaints': nan,
    //     'potential_product_solution': nan},
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B08CRNQCK8',
    //     'Product Name': 'Unibic Cookies - Cashew, 75g(/100 g)',
    //     'Competitor Listings': 10,
    //     'Top Competitor': 'Generic',
    //     'Competitor Availability': 'High',
    //     'Comp Average Rating': 4.1,
    //     'Comp Average Weightage': '50g - 105g',
    //     'Average Comp Reviews': 971,
    //     'Comp Price Range': '₹10 - ₹499',
    //     'features_in_comp': nan,
    //     'our_customer_complaints': nan,
    //     'potential_product_solution': nan},
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B08FBFFFHM',
    //     'Product Name': 'Fresh Produce Unibic Fruit & Nut Cookies, 500 g(/100 g)',
    //     'Competitor Listings': 10,
    //     'Top Competitor': 'Karachi Bakery',
    //     'Competitor Availability': 'High',
    //     'Comp Average Rating': 3.8,
    //     'Comp Average Weightage': '300g - 600g',
    //     'Average Comp Reviews': 2064,
    //     'Comp Price Range': '₹100 - ₹465',
    //     'features_in_comp': nan,
    //     'our_customer_complaints': nan,
    //     'potential_product_solution': nan},
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B09Q9FBK3F',
    //     'Product Name': 'Unibic Yummy Strawberry Wafers - 75gm(/100 g)',
    //     'Competitor Listings': 2,
    //     'Top Competitor': 'Dukes',
    //     'Competitor Availability': 'Low',
    //     'Comp Average Rating': 4.2,
    //     'Comp Average Weightage': '55g - 60g',
    //     'Average Comp Reviews': 9360,
    //     'Comp Price Range': '₹25 - ₹25',
    //     'features_in_comp': 'premium quality, divine filling, mixed opinions on taste and value for money',
    //     'our_customer_complaints': 'taste like eating medicine, not happy',
    //     'potential_product_solution': 'Improve taste profile, enhance flavor to avoid medicinal taste, address customer satisfaction concerns'},
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B09VGBGBW5',
    //     'Product Name': 'Unibic Assorted Cookies, 450g (Pack of 6 x 75 g), mix and match, daily delicious choice, chocolate, fruit and nut, honey oatmeal & Snack bar Fruit & Nut 360g Pack of 12, 360g',
    //     'Competitor Listings': 4,
    //     'Top Competitor': 'Ghasitaram',
    //     'Competitor Availability': 'Low',
    //     'Comp Average Rating': nan,
    //     'Comp Average Weightage': '2700g - 2700g',
    //     'Average Comp Reviews': 'No Competition',
    //     'Comp Price Range': '₹719 - ₹2596',
    //     'features_in_comp': nan,
    //     'our_customer_complaints': nan,
    //     'potential_product_solution': nan},
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B09ZJJ5XH6',
    //     'Product Name': 'Unibic Oats Digestive, 1kg(/100 g)',
    //     'Competitor Listings': 1,
    //     'Top Competitor': 'Fit Pro Healthy Lives',
    //     'Competitor Availability': 'Low',
    //     'Comp Average Rating': 5.0,
    //     'Comp Average Weightage': '100g - 100g',
    //     'Average Comp Reviews': 2,
    //     'Comp Price Range': '₹233 - ₹233',
    //     'features_in_comp': nan,
    //     'our_customer_complaints': nan,
    //     'potential_product_solution': nan},
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B0BCGQFBZZ',
    //     'Product Name': 'Unibic Fruit & Nut Cookies 75g (Pack of 8) Unique(/100 g)',
    //     'Competitor Listings': 10,
    //     'Top Competitor': 'Karachi Bakery',
    //     'Competitor Availability': 'High',
    //     'Comp Average Rating': 3.6,
    //     'Comp Average Weightage': '360g - 800g',
    //     'Average Comp Reviews': 2305,
    //     'Comp Price Range': '₹100 - ₹480',
    //     'features_in_comp': nan,
    //     'our_customer_complaints': nan,
    //     'potential_product_solution': nan},
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B0BCQR4W8T',
    //     'Product Name': 'Unibic Pista Badam Cookies & Fruit Nut Cookies 75g (Pack of 2) Unique(/100 g)',
    //     'Competitor Listings': 6,
    //     'Top Competitor': 'Britannia',
    //     'Competitor Availability': 'Medium',
    //     'Comp Average Rating': 4.1,
    //     'Comp Average Weightage': '100g - 200g',
    //     'Average Comp Reviews': 309,
    //     'Comp Price Range': '₹55 - ₹310',
    //     'features_in_comp': nan,
    //     'our_customer_complaints': nan,
    //     'potential_product_solution': nan},
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B0BCWKF2PY',
    //     'Product Name': 'Unibic Sugar Free Butter Cookies 75g (Pack of 2) Unique(/100 g)',
    //     'Competitor Listings': 10,
    //     'Top Competitor': 'Sunfeast',
    //     'Competitor Availability': 'High',
    //     'Comp Average Rating': 4.2,
    //     'Comp Average Weightage': '100g - 200g',
    //     'Average Comp Reviews': 548,
    //     'Comp Price Range': '₹20 - ₹360',
    //     'features_in_comp': 'dairy-based butter, good taste, crunchy texture',
    //     'our_customer_complaints': 'mixed opinions on taste, some customers disagree on biscuit quality',
    //     'potential_product_solution': 'Improve taste consistency and focus on enhancing overall biscuit quality to meet customer expectations.'},
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B0BRN8F2KR',
    //     'Product Name': 'Unibic Sugar Free Butter + Sugar Free Cashew Cookies 75g (Pack of 2) Unique(/count)',
    //     'Competitor Listings': 10,
    //     'Top Competitor': 'Sunfeast',
    //     'Competitor Availability': 'High',
    //     'Comp Average Rating': 4.2,
    //     'Comp Average Weightage': '100g - 200g',
    //     'Average Comp Reviews': 1059,
    //     'Comp Price Range': '₹10 - ₹216',
    //     'features_in_comp': 'taste_POSITIVE, biscuit quality_MIXED, taste_MIXED, quality_POSITIVE, biscuit quality_NEGATIVE, value for money_MIXED, cookie quality_NEGATIVE',
    //     'our_customer_complaints': 'biscuits broken in delivery, mixed opinions on taste, value for money concerns',
    //     'potential_product_solution': 'Improve packaging to prevent breakage, focus on enhancing taste consistency, address value for money perception'},
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B0BSTY9L65',
    //     'Product Name': 'UNIBIC Choco Kiss, Chocolate Center Filled Cookies, Valentine Limited Edition, Gift Box Cookies (1425 g, Pack of 3)',
    //     'Competitor Listings': 10,
    //     'Top Competitor': 'Soulfull',
    //     'Competitor Availability': 'High',
    //     'Comp Average Rating': 4.1,
    //     'Comp Average Weightage': '900g - 1800g',
    //     'Average Comp Reviews': 1830,
    //     'Comp Price Range': '₹195 - ₹1249',
    //     'features_in_comp': 'Minimal chocolate filling, Mixed opinions on taste and crunchiness, Awful smell reported, Hard biscuits, Mixed opinions on quality',
    //     'our_customer_complaints': 'Expensive, Poor value for money, Hard biscuits, Poor quality, Minimal chocolate filling',
    //     'potential_product_solution': 'Improve chocolate filling quantity, Enhance taste and crunchiness, Address smell issue, Ensure biscuits are not too hard, Enhance overall product quality and value for money'},
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B0D3HRVYF3',
    //     'Product Name': 'Unibic Foods India Pvt LTD Assorted Cookies|Fruit & Nut, Choconut, Chocochip|Combo Gift Pack|Pack Of 3|900 Gm(/100 g)',
    //     'Competitor Listings': 10,
    //     'Top Competitor': 'Early Foods',
    //     'Competitor Availability': 'High',
    //     'Comp Average Rating': 4.0,
    //     'Comp Average Weightage': '185g - 400g',
    //     'Average Comp Reviews': 739,
    //     'Comp Price Range': '₹260 - ₹589',
    //     'features_in_comp': nan,
    //     'our_customer_complaints': nan,
    //     'potential_product_solution': nan},
    //    {'platform': 'Amazon Web',
    //     'client_profile_id': 1498269816203807,
    //     'client': 'Unibic Cookies',
    //     'ASIN': 'B0DCSVXFLL',
    //     'Product Name': 'Unibic Fruit and Nut Cookies 75g (Pack of 3) Humarabazar(/100 g)',
    //     'Competitor Listings': 10,
    //     'Top Competitor': 'KARACHI',
    //     'Competitor Availability': 'High',
    //     'Comp Average Rating': 4.3,
    //     'Comp Average Weightage': '140g - 300g',
    //     'Average Comp Reviews': 163,
    //     'Comp Price Range': '₹105 - ₹369',
    //     'features_in_comp': 'durability issues, high price, mixed quality opinions, hard texture',
    //     'our_customer_complaints': 'pricey, durability issues',
    //     'potential_product_solution': 'Improve durability and adjust pricing to address customer concerns'}
    ])


    const [rowSelect, setRowSelect] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(false);
    const [totalRow, setTotalRow] = useState<any[]>([]);
    const [kpiSelectToggle, setKpiSelectToggle] = useState<boolean>(false);
    const popupRef = useRef<HTMLDivElement>(null);

    // const bodyStyle = { color: 'rgba(24, 24, 24, 1)', fontSize: '0.85rem' }
    const bodyStyle = { color: '#000000', fontSize: '0.85rem' }

    const [columns, setColumns] = useState<any[]>([
        { header: 'Platform', field: 'platform',  checked: true },
        { header: 'Client Profile Id', field: 'client_profile_id', bodyStyle, checked: true },
        { header: 'Client', field: 'client',  checked: true },
        { header: 'ASIN', field: 'ASIN',  checked: true },
        { header: 'Product Name', field: 'Product_Name',  checked: true },
        { header: 'Competitor Listing', field: 'Competitor_Listings',   checked: true },
        { header: 'Top Competitor', field: 'Top_Competitor',  checked: true },
        { header: 'Competitor Availability', field: 'Competitor_Availability',   checked: false },
        { header: 'Comp Average Rating', field: 'Comp_Average_Rating',  checked: false },
        { header: 'Comp Average Weightage', field: 'Comp_Average_Weightage',  checked: false },
        { header: 'Average Comp Reviews', field: 'Average_Comp_Reviews',  checked: false },
        { header: 'Comp Price Range', field: 'Comp_Price_Range',  checked: false },
        { header: 'features_in_comp', field: 'features_in_comp',  checked: false },
        { header: 'our_customer_complaints', field: 'our_customer_complaints',  checked: false },
        { header: 'potential_product_solution', field: 'potential_product_solution',  checked: false },

    ]);




    // console.log('rowselect',rowSelect)

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setKpiSelectToggle(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, []);


    // const getColumnClassName = (field: string) => {
    //     // const isPercentageColumn = field.includes('percentage'); 
    //     return selectedColumns.includes(field) ? 'selected-column custom-header-class ' : 'custom-header-class '
    // }


    // const headerTemplate = (column: any) => {
    //     return (
    //         <div >
    //             <div className="p-column-title"  onClick={() => selectHeader(performance_type, column)}>
    //                 {column.header}
    //             </div >
    //         </div>
    //     )
    // }
    
    


    const handleKpiChange = (e: ChangeEvent<HTMLInputElement>) => {
        let newArr = columns.map((el) => {
            if (el.header === e.target.name) {
                console.log('el', el.header, e.target.name)
                el.checked = !el.checked;
            }
            return el

        })
        setColumns(newArr);
    }

    const handleColumnSelect = () => {
        setKpiSelectToggle(prev => !prev);
    }

    // const exportToCSV = () => {
    //     let csvContent = '';
    //     const header = [...firstColumn, ...columns].map(col => col.field).join(',');
    //     csvContent += header + '\n';

    //     performanceData.forEach((row: any) => {
    //         const rowData = [...firstColumn, ...columns].map(col => row[col.field]).join(',');
    //         csvContent += rowData + '\n';
    //     });

    //     const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    //     saveAs(blob, 'table.csv');
    // };

    return (
        <div style={{ marginTop: '1rem' }} className=' table_card_new'>
            <div style={{ position: 'relative' }}>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                <span className='table_kpi_name'>Assortment Product Table</span>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <div className='column_select' ref={popupRef} >
                        <div className='column_select-tag' onClick={handleColumnSelect}>Select Columns <i className="bi bi-chevron-down stroke"></i></div>
                        {kpiSelectToggle &&
                            <div className='column_select-box' style={{ marginTop: '2rem', zIndex:9 }}>
                                {
                                    columns.map((el) => {
                                        return (
                                            <div key={el.header} className="column_select-boxname" >
                                                <input onChange={handleKpiChange} type="checkbox" id={el.header} name={el.header} checked={el.checked}></input>&nbsp;&nbsp;&nbsp;
                                                <label className="" htmlFor={el.header}>{el.header}</label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                    <button className='table_btn1' >
                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.1765 5.64706H9.41176V0H3.76471V5.64706H0L6.58824 12.2353L13.1765 5.64706ZM0 14.1176V16H13.1765V14.1176H0Z" fill="black" />
                        </svg>
                    </button>
                </div>
            </div>

            <PrimeReactProvider>
                {
                    isLoading ?
                        <LoaderSkeletonTable height='350px' /> :

                        <DataTable
                            value={MockData}
                            size='small'
                            columnResizeMode="expand"
                            resizableColumns
                            selectionMode="multiple"
                            selection={rowSelect}
                            showGridlines
                            id='unique-table'
                            scrollable scrollHeight="350px" 

                        >
                    {columns.filter((column) => column.checked).map((col, index) => (  
                        <Column
                            key={index}
                            field={col.field}
                            header={col.header}
                            // header={renderHeader(col.header, col.field)}
                            style={{ width: '150px' }}
                            headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                            bodyStyle={{ color: '#000000', fontSize: '0.85rem',textTransform: 'capitalize'}}
                            // bodyStyle={column?.bodyStyle}
                            body={col.body}

                        />
                    ))}
                        </DataTable>

                }

            </PrimeReactProvider>
        </div>
        </div>
    )
//   return (
//     <div>
//       hello product assortment 
//     </div>
//   )
}

export default ProductAssortment
