import { PrimeReactProvider } from 'primereact/api';
import React, { useEffect, useRef, useState, ChangeEvent } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { WidthFull } from '@mui/icons-material';
import LoaderSkeletonTable from '../../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { toast } from 'react-toastify';
import { AnyAaaaRecord } from 'node:dns';
import CloudUploadIcon from '../../../../assets/svgs/CloudUploadIcon';
import LoaderAnimationfill from '../../../../assets/svgs/LoaderAnimationfill';
import { BASE_URL } from '../../../../appConstant';
import axios from 'axios';
import LoaderSkeletonLine from '../../../../utils/commonComponents/Loader/LoaderSkeletonLine';



const CompetitionMasterCityTable = ({ currentPlatform, topRowSelect }: { currentPlatform: any, topRowSelect: any }) => {




    const [rowSelect, setRowSelect] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSub, setIsLoadingSub] = useState(false);
    const [expandedRows, setExpandedRows] = useState<any>(null);

    const [isModalOpendownload, setIsModalOpendownload] = useState(false);
    const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [cityWiseCompetitionMaster, setCityWiseCompetitionMaster]=useState([])
    const [competitionMaster, setCompetitionMaster]=useState([])
    const [error, setError]=useState(undefined)


    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        const allowedExtensions = /(\.xls|\.xlsx|\.csv)$/i;
        if (file && allowedExtensions.exec(file.name)) {
            setUploadedFile(file);
            setIsModalOpen(false); // Close modal after upload
        } else {
            toast("Only Excel files (.xls, .xlsx, .csv) are allowed!");
        }
    };

    useEffect(()=>{
        const selectedPlatformCode = topRowSelect?.['platform_code']
        if (topRowSelect) {
            setIsLoading(true)
            axios.get(`${BASE_URL}/qcomm/competition-master?client_name=${'taali'}&platform_code=${selectedPlatformCode}`,
            ).then((res) => {
                res.data.forEach((master: any) => {
                    master.sp = `${master.max_sp}-${master.min_sp}`
                    master.mrp = `${master.max_mrp}-${master.min_mrp}`
                    master.expiry_days = `${master.max_expiry_days}-${master.min_expiry_days}`
                    master.client_name= `${master.client_name} - ${master.competitions}`
                })
                setCompetitionMaster(res.data)
            }).catch((err) => {
                console.log(err);
                setError(err.message)
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }, [topRowSelect])


    useEffect(() => {
        const selectedPlatformCode = rowSelect?.['platform_code']
        const selectedCity = rowSelect?.['city']
        if (selectedPlatformCode) {
            setIsLoadingSub(true)
            axios.get(`${BASE_URL}/qcomm/competition-master?client_name=${'taali'}&platform_code=${selectedPlatformCode}&city=${selectedCity}&master_type=competition`,
            ).then((res) => {
                res.data.forEach((master: any) => {
                    master.sp = `${master.max_sp}-${master.min_sp}`
                    master.mrp = `${master.max_mrp}-${master.min_mrp}`
                    master.expiry_days = `${master.max_expiry_days}-${master.min_expiry_days}`
                })
                setCityWiseCompetitionMaster(res.data)
            }).catch((err) => {
                console.log(err);
                setError(err.message)
            }).finally(() => {
                setIsLoadingSub(false);
            })
        }
    }, [rowSelect])



    const bodyStyle = { color: '#000000', fontSize: '0.85rem' }


    const [columns, setColumns] = useState<any[]>([
        { header: 'city', field: 'city', width: '130px' },
        { header: 'client name', field: 'client_name', width: '130px' },
        { header: 'pincodes', field: 'pincodes', width: '130px' },
        { header: 'Platform Code', field: 'platform_code', bodyStyle, width: '130px' },
        { header: 'Platform', field: 'platform', width: '130px' },
        { header: 'Name', field: 'pname', width: '130px' },
        { header: 'sp', field: 'sp', width: '150px' },
        { header: 'mrp', field: 'mrp', width: '150px' },
        { header: 'expiry_days', field: 'expiry_days', width: '150px' },
        { header: 'Main category', field: 'main_cat', width: '150px' },
        { header: 'Sub category', field: 'sub_cat', width: '130px' },
       
        { header: 'Pack Size', field: 'pack_size', width: '130px' },
        { header: 'Description', field: 'description', width: '130px' },
        { header: 'Ingredients', field: 'ingredients', width: '130px' },
    ]);

    const getBackgroundColorHeader = (header: string) => {
        const colorMap: Record<string, string> = {
            Blinkit: '#F6D75133',
            'Swiggy Instamart': '#FC801933',
            Zepto: '#950EDB33',
        };
        if (header !== 'City') {
            return colorMap[currentPlatform]
        }
        else return 'rgba(245, 245, 247, 1)'; // Default color
    };

    const getBackgroundColorHeader2 = (header: string) => {
        const colorMap: Record<string, string> = {
            Blinkit: '#F6D75133',
            'Swiggy Instamart': '#FC801933',
            Zepto: '#950EDB33',
        };

        return colorMap[currentPlatform]

    };


    const renderheader = (head: string) => {
        if (head === 'City') return '';
        else {
            return head;
        }
    }
    const renderHeader2 = (col: string) => {
        if (col === "city") return 'City';
        {
            if (topRowSelect !== null)
                return topRowSelect[col];
        }
    };

    const headerGroup = (
        <ColumnGroup>
            <Row>
                {columns.map((col) => (
                    <Column header={renderheader(col?.header)}
                        headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                        style={{ width: col.width }}
                    />
                ))}
            </Row>
            <Row>
                {columns.map((col) => (
                    //change it later
                    <Column
                        header={renderHeader2(col?.field)}
                        //   header={(col.field)}
                        headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', backgroundColor: getBackgroundColorHeader(col.header), }}
                        style={{ width: col.width }}
                    />
                ))}
            </Row>
        </ColumnGroup>
    )

    const rowExpansionTemplate = (data: any) => {
        return (
            //   <div style={{ padding: "1rem" }}>
            isLoadingSub?<LoaderSkeletonLine/> :
            <DataTable value={cityWiseCompetitionMaster}
                size='small'
                columnResizeMode="expand"
                resizableColumns
                className='unq-tble'
            // showGridlines
            >
                {columns.map((col) => (

                    <Column field={col.field}
                        // style={{ width: col.width }}
                        style={{width: col.field === 'pincodes' ? '128px' : col.width}}
                        bodyStyle={{ color: '#000000', fontSize: '0.85rem', textTransform: 'capitalize', backgroundColor: getBackgroundColorHeader2(col.header) }}
                    // headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: getBackgroundColorHeader(col.header), }}
                    // body={col.body}
                    ></Column>

                ))}
            </DataTable>
        );
    };

    const allowExpansion = (rowData: any) => {
        // return rowData.orders.length > 0;
        // return 1>0;
        return rowData.expansionData && rowData.expansionData.length > 0;
    };

    const onRowSelect = (e: any) => {
        setRowSelect(e.value);
        setExpandedRows([e.value]);
    };

    const onRowUnselect = () => {
        setRowSelect(null);
        setExpandedRows(null);
    };

    // const exportToCSV = () => {
    //     let csvContent = '';
    //     const header = [...firstColumn, ...columns].map(col => col.field).join(',');
    //     csvContent += header + '\n';

    //     performanceData.forEach((row: any) => {
    //         const rowData = [...firstColumn, ...columns].map(col => row[col.field]).join(',');
    //         csvContent += rowData + '\n';
    //     });

    //     const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    //     saveAs(blob, 'table.csv');
    // };

    return (
        <>{
            topRowSelect ?
                <div style={{ marginTop: '1rem' }} className=' table_card_new'>
                    <div style={{ position: 'relative' }}>

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                            <span className='table_kpi_name'>City Wise</span>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                <button className='table_btn1' onClick={() => setIsModalOpendownload(true)} >
                                    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.1765 5.64706H9.41176V0H3.76471V5.64706H0L6.58824 12.2353L13.1765 5.64706ZM0 14.1176V16H13.1765V14.1176H0Z" fill="black" />
                                    </svg>
                                </button>
                                {isModalOpendownload && <>

                                    <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000, }}>
                                        <div className="modal-card" style={{ backgroundColor: '#fff', padding: '2rem', borderRadius: '10px', textAlign: 'center', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', maxWidth: '400px', width: '90%', }}>
                                            <div className="upload-icon" style={{ marginTop: '1rem' }}>
                                                <LoaderAnimationfill />
                                            </div>
                                            <p style={{ textAlign: 'center', color: '#1E1E1E', fontSize: 'bold', marginBottom: '.5rem', marginTop: '1rem' }}>You can download the file after progress is complete</p>
                                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '.5rem' }}><button className="download-button" ><i className="bi bi-download"></i>&nbsp;&nbsp;Download Now</button></div>
                                            <div
                                                className="back-button"
                                                style={{ color: '#5595F6', cursor: 'pointer', }}
                                                onClick={() => setIsModalOpendownload(false)}
                                            >
                                                Back
                                            </div>
                                        </div>

                                    </div>
                                </>}
                                <button className='table_btn1' onClick={() => setIsModalOpen(true)}>
                                    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.1765 5.64706H9.41176V0H3.76471V5.64706H0L6.58824 12.2353L13.1765 5.64706ZM0 14.1176V16H13.1765V14.1176H0Z"
                                            fill="black"
                                            transform="scale(1, -1) translate(0, -16)" />
                                    </svg>
                                </button>
                                {isModalOpen && <>

                                    <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000, }}>
                                        <div className="modal-card" style={{ backgroundColor: '#fff', padding: '2rem', borderRadius: '10px', textAlign: 'center', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', maxWidth: '400px', width: '90%', }}>
                                            <div className="upload-icon" style={{ marginTop: '1rem' }}>
                                                <CloudUploadIcon />
                                            </div>
                                            <label
                                                htmlFor="file-upload"
                                                className="file-upload-label"
                                                style={{ border: '1.5px dashed #000000', borderRadius: '.5rem', padding: '.7rem .9rem', margin: '.7rem 0', textAlign: 'center', cursor: 'pointer', color: '#5B6871', fontSize: '.85rem' }}
                                            >
                                                Choose File
                                                <input
                                                    id="file-upload"
                                                    type="file"
                                                    accept=".xls,.xlsx,.csv"
                                                    className="file-upload-input"
                                                    onChange={handleFileUpload}
                                                    style={{ display: 'none' }}
                                                />
                                            </label>
                                            <p style={{ textAlign: 'center', color: '#1E1E1E', fontSize: 'bold', marginBottom: '.5rem', marginTop: '.5rem' }}>Upload your Excel file to import data</p>
                                            <div
                                                className="back-button"
                                                style={{ color: '#5595F6', cursor: 'pointer', }}
                                                onClick={() => setIsModalOpen(false)}
                                            >
                                                Back
                                            </div>
                                        </div>

                                    </div>
                                </>}
                                <button className='table_btn1' onClick={() => setIsModalOpenEdit(true)} >
                                    <i className="bi bi-pencil"></i>
                                </button>
                                {isModalOpenEdit && <>
                                    <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000, }}>
                                        <div className="modal-card" style={{ backgroundColor: '#fff', padding: '2rem', borderRadius: '10px', textAlign: 'center', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', maxWidth: '400px', width: '90%', }}>
                                            <div className="upload-icon" style={{ marginTop: '1rem' }}>
                                                <span className="loader"></span>
                                            </div>
                                            <p style={{ textAlign: 'center', color: '#1E1E1E', fontSize: 'bold', marginBottom: '.5rem', marginTop: '1rem' }}>Redirecting You to Excel Sheet</p>
                                            <div
                                                className="back-button"
                                                style={{ color: '#5595F6', cursor: 'pointer', }}
                                                onClick={() => setIsModalOpenEdit(false)}
                                            >
                                                Back
                                            </div>
                                        </div>

                                    </div>
                                </>}
                            </div>
                        </div>

                        <PrimeReactProvider>
                            {
                                isLoading ?
                                    <LoaderSkeletonTable height='350px' /> :

                                    <DataTable
                                        value={competitionMaster}
                                        size='small'
                                        columnResizeMode="expand"
                                        resizableColumns
                                        selectionMode="single"
                                        selection={rowSelect}
                                        onSelectionChange={(e) => {
                                            e.value ? onRowSelect(e) : onRowUnselect();
                                        }}
                                        // onSelectionChange={(e) => { setRowSelect(e.value) }}
                                        headerColumnGroup={headerGroup}
                                        showGridlines
                                        id='unique-table'
                                        className='unique-tble'
                                        scrollable scrollHeight="350px"

                                        expandedRows={expandedRows}
                                        onRowToggle={(e) => setExpandedRows(e.data)}
                                        // onRowExpand={onRowExpand}
                                        // onRowCollapse={onRowCollapse}
                                        rowExpansionTemplate={rowExpansionTemplate}

                                    >
                                        {columns.map((col, index) => (
                                            <Column
                                                key={index}
                                                field={col.field}
                                                // header={col.header}
                                                // header={renderheader(col.header)}
                                                // expander={allowExpansion}
                                                // expander={allowExpansion}
                                                style={{ width: col.width }}
                                                headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                                                bodyStyle={{ color: '#000000', fontSize: '0.85rem', textTransform: 'capitalize' }}
                                                // bodyStyle={column?.bodyStyle}
                                                body={col.body}

                                            />

                                        ))}
                                    </DataTable>
                            }

                        </PrimeReactProvider>
                    </div>
                </div>
                : null
        }</>
    )
}

export default CompetitionMasterCityTable

