import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    Title,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ChartData, ChartOptions } from 'chart.js';



interface SolidRingChartProps {
    data?: number[];
    labels?: string[];
    chartTitle?: string;
}

const SolidRingChart: React.FC<SolidRingChartProps> = ({
    data = [60, 40],
    labels = ['Value 1', 'Value 2'],
    // chartTitle = 'Comparison of Values',
}) => {

    ChartJS.register(
        ArcElement,
        Tooltip,
        Legend,
        Title,
        ChartDataLabels
    );

    const total = data.reduce((acc, value) => acc + value, 0);

    const chartData: ChartData<'doughnut'> = {
        labels,
        datasets: [
            {
                label: 'Comparison',
                data,
                backgroundColor: [
                    'rgba(56, 109, 136, 1)',
                    'rgba(64, 148, 177, 1)',
                ],
                borderColor: [
                    'rgba(56, 109, 136, 1.2)',
                    'rgba(64, 148, 177, 1.2)',
                ],
                borderWidth: 1,
            }
        ]
    };

    const options: ChartOptions<'doughnut'> = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    usePointStyle: true, // Use bullet points instead of rectangles
                    pointStyle: 'circle', // Set the style of the bullet points
                },
            },
            title: {
                display: true,
                // text: chartTitle,
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const value = context.raw as number;
                        const percentage = ((value / total) * 100).toFixed(2) + '%';
                        return context.label + ': ' + percentage;
                    }
                }
            },
            datalabels: {
                color: '#fff',
                formatter: (value, context) => {
                    return ((value / total) * 100).toFixed(2) + '%';
                },
                font: {
                    weight: 'normal',
                    size: 14,
                },
                anchor: 'end',
                align: 'start',
                offset: -5,
            }
        },
        cutout: '55%', // Makes the center of the chart a hole to create a ring effect
    };

    return <Doughnut data={chartData}  options={options} />;
};




export default SolidRingChart;

