import axios from 'axios';
import '../../pages/reportsStyle.scss';
import { PrimeReactProvider } from 'primereact/api';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { BASE_URL_NEW } from '../../../../appConstant';
import { useAppSelector } from '../../../../redux/hook';
import { formatedMetric, formatSingleDateFn, interpolateColor } from '../../../../utils/commonFunction';
import LoaderSkeletonTable from '../../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import { saveAs } from 'file-saver';

const ComparisonCategory = ({ intervalRange, selectedMetric2, setSelectedCategory, selectedCategory }: any) => {
  const client = useAppSelector(state => state.client);
  const { current_brand: { client_id } } = client;
  const [rowData, setRowData] = useState<any[]>([]);
  const time_stamps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
  const [isLoading, setIsLoading] = useState(false);
  

  useEffect(() => {
    if (intervalRange && selectedMetric2) {
      const { former_start_date, former_end_date, later_start_date, later_end_date } = intervalRange;
      setIsLoading(true);

      axios
        .get(`${BASE_URL_NEW}/marketing-stream/${client_id}/comparativeCategory?firstStartDate=${former_start_date}&firstEndDate=${former_end_date}&secondStartDate=${later_start_date}&secondEndDate=${later_end_date}`)
        .then((res) => {

          let formattedData: any = [];

          // Group data by category and week
          const groupedByCategoryAndWeek = res.data.reduce((acc: any, curr: any) => {
            const weekLabel = curr.date_range === `${former_start_date} - ${former_end_date}` ? 'Week 1' : 'Week 2';

            if (!acc[curr.category]) {
              acc[curr.category] = { 'Week 1': {}, 'Week 2': {} };
            }

            acc[curr.category][weekLabel][`${curr.hour}_hour`] = Math.round(curr[selectedMetric2?.metric]);
            acc[curr.category][weekLabel]['category'] = curr.category;
            acc[curr.category][weekLabel]['week'] = curr.date_range;

            return acc;
          }, {});

          // Format the data to have Week 1 followed by Week 2 for each category and remove empty objects
          for (const category in groupedByCategoryAndWeek) {
            if (Object.keys(groupedByCategoryAndWeek[category]['Week 1']).length > 2) {
              formattedData.push(groupedByCategoryAndWeek[category]['Week 1']);
            }
            if (Object.keys(groupedByCategoryAndWeek[category]['Week 2']).length > 2) {
              formattedData.push(groupedByCategoryAndWeek[category]['Week 2']);
            }
          }

          setRowData(formattedData);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [client_id, intervalRange, selectedMetric2]);


  // function heatmap(value: any, el: any) {
  //   let hours = time_stamps.map(time => value[`${time}_hour`] || 0);
  //   let max = Math.max(...hours);

  //   let color = '';
  //   let currentValue = value[`${el}_hour`];

  //   if (currentValue === undefined || currentValue === '-') {
  //     color = '#ffffff';
  //   } else if (currentValue === max) {
  //     color = '#E9EDFF';
  //   } else if (currentValue >= max * 0.8) {
  //     color = '#DFE8FF';
  //   } else if (currentValue >= max * 0.6) {
  //     color = '#BDA3FF';
  //   } else if (currentValue >= max * 0.4) {
  //     color = '#A066FF';
  //   } else if (currentValue >= max * 0.2) {
  //     color = '#BDA3FF';
  //   } else {
  //     color = '#CBC2FF';
  //   }

  //   return color;
  // }
  // function heatmap(value: any, el: any) {
  //   // console.log('heat values',value)
  //   let a: any = Object.values(value);
  //   a=a.filter((el:any)=>typeof(el)==='number')
  //   const min = Math.min(...a.filter((x:number) => x !== 0));
  //   const max = Math.max(...a.filter((x:number) => x !== 0));
  //   const currVal = value[`${el}_hour`]
  //   if (min === max || currVal===0) return '#ffffff'  

  //   const normalizedFactor = ((currVal - min) / (max - min))
  //   return interpolateColor('#E9EDFF', '#A066FF', normalizedFactor);
  // }

  const hoursBody = (props: any, el: any, categoryMinMax: { [key: string]: { min: number, max: number } }) => {
    return (
      <div
        style={{
          backgroundColor: heatmap(props, el, categoryMinMax),
          width: '100%',
          height: '100%',
          textAlign: 'center',
          padding: '10px 0px',
          boxSizing: 'border-box',
        }}
      >
        {props[`${el}_hour`] !== undefined ? (props[`${el}_hour`])?.toLocaleString() : '-'}
      </div>
    );
  };

  const totalBody = (props: any) => {
    let total = 0;
    for (let time of time_stamps) {
      if (props[`${time}_hour`]) {
        total += props[`${time}_hour`];
      }
    }
    return <div>{total?.toLocaleString()}</div>;
  };

  const performanceBody = (props: any) => {
    // console.log(props);
    
    return <div>{props.category}</div>;
  };


  const onRowSelect = (e: any) => {
    // console.log('category value', e.value);
    // if (e.value.length > 0) {
    //   e.value.forEach((el:any) => {
    //     if (el.field === 'category') {
    //       setSelectedCategory(e.value);
    //     }
    //   })
    // }
    setSelectedCategory(e.value);
  }

  function calculateCategoryMinMax(data: any[]) {
    const categoryMinMax: { [key: string]: { min: number, max: number } } = {};

    data.forEach(row => {
      const category = row.category;
      if (!categoryMinMax[category]) {
        categoryMinMax[category] = { min: Number.MAX_VALUE, max: Number.MIN_VALUE };
      }
      //change it here infinity

      Object.keys(row).forEach(key => {
        if (key.includes('_hour')) {
          const value = row[key];
          if (typeof value === 'number' && value !== 0) {
            categoryMinMax[category].min = Math.min(categoryMinMax[category].min, value);
            categoryMinMax[category].max = Math.max(categoryMinMax[category].max, value);
          }
        }
      });
    });

    return categoryMinMax;
  }



  function heatmap(value: any, el: any, categoryMinMax: { [key: string]: { min: number, max: number } }) {
    const category = value.category;
    const currVal = value[`${el}_hour`];

    const { min, max } = categoryMinMax[category];

    if (min === max || currVal === 0) return '#ffffff';

    const normalizedFactor = ((currVal - min) / (max - min));
    return interpolateColor('#E9EDFF', '#A066FF', normalizedFactor);
  }

  const categoryMinMax = calculateCategoryMinMax(rowData);

  const isTableDataEmpty = rowData.length === 0;

  const exportToCSV = () => {
    // Step 1: Create the header row
    const headers = ['Category/Hours', 'Date Range', ...time_stamps.map(ts => `${ts}`), 'Grand Total'];
    let csvContent = headers.join(',') + '\n';
  
    // Step 2: Append each row's data
    rowData.forEach((row: any) => {
      let total = 0;

      for (let time of time_stamps) {
        if (row[`${time}_hour`]) {
          total += row[`${time}_hour`];
        }
      }

      if (row?.metric === 'acos' || row?.metric === 'ctr' || row?.metric === 'cpc' || row?.metric === 'roas') {
        total = total / 24;
      } else {
        total = Math.round(total);
      }

      const formattedTotal = total.toLocaleString();

      const formatWeekRange = (week: string): string => {
        const [startDate, endDate] = week.split(' - ');
        return `${formatSingleDateFn(startDate)} - ${formatSingleDateFn(endDate)}`;
      };

      const rowValues = [
        row['category'],                 // category column
        formatWeekRange(row['week']),    // Range column
        ...time_stamps.map(ts => row[`${ts}_hour`]), // Time-stamp columns
        `"${formattedTotal}"` || 0               // Grand Total column
      ];
  
      // Join row data as a CSV line
      csvContent += rowValues.join(',') + '\n';
    });
  
    
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'category_wise_performance.csv');
  };





  return (
    <div className='table_card_new'>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
      <div>
        <button className='table_btn' ><i className='bi bi-download' onClick={exportToCSV}></i></button>
      </div>
        <span className='table_kpi_name'>Category Wise Performance</span>
      </div>
      <PrimeReactProvider>
        {isLoading ? (<LoaderSkeletonTable height='350px' /> 
          ) : isTableDataEmpty ? (
            <div
              style={{ padding: '5rem 0', textAlign: 'center', color: '#999', fontSize: '1.2rem' }}
            >
              No Data Available
            </div>
          ) : (
          <DataTable
            value={rowData}
            size='small'
            columnResizeMode="expand"
            resizableColumns
            scrollable
            scrollHeight="350px"
            // virtualScrollerOptions={{ itemSize: 20 }}
            selectionMode="multiple"
            selection={selectedCategory}
            // selection='category'
            onSelectionChange={onRowSelect}
            rowGroupMode="rowspan"
            groupRowsBy="category"
            showGridlines
            metaKeySelection={false}
            // dataKey='category'
          // dataKey="id" // Ensure each row has a unique key based on a unique identifier in your data
          >
            <Column
              field='category'
              header='Category/Hours'
              style={{ width: '120px' }}
              headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)', }}
              bodyStyle={{ color: 'rgba(24, 24, 24, 1)', fontSize: '0.7rem', fontWeight: 500, textTransform: 'capitalize', }}
              body={performanceBody}
            />
            <Column
              field='week'
              // header='Week'
              // bodyClassName="no-hover-column"
              style={{ width: '100px' }}
              headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)', }}
              bodyStyle={{ color: 'rgba(24, 24, 24, 1)', fontSize: '0.7rem', fontWeight: 500, textTransform: 'capitalize', }}
              body={(rowData) => {
                const [startDate, endDate] = rowData.week.split(' - ');
                const formattedWeek = `${formatSingleDateFn(startDate)} - ${formatSingleDateFn(endDate)}`;
                return formattedWeek;
              }}
            />
            {time_stamps.map((el, i) => {
              return (
                <Column
                  key={i}
                  header={el}
                  field={`${el}_hour`}
                  style={{ width: '50px' }}
                  headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)', }}
                  bodyStyle={{ color: 'rgba(24, 24, 24, 1)', fontSize: '0.7rem', fontWeight: 500, textTransform: 'capitalize', }}
                  bodyClassName="no-padding"
                  body={(props) => hoursBody(props, el, categoryMinMax)}
                />
              );
            })}
            <Column
              field=""
              header='Grand Total'
              style={{ width: '100px' }}
              headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)', }}
              bodyStyle={{ color: 'rgba(24, 24, 24, 1)', fontSize: '0.7rem', fontWeight: 500, textTransform: 'capitalize', }}
              body={totalBody}
            />
          </DataTable>
        )}
      </PrimeReactProvider>
    </div>
  );
};

export default ComparisonCategory;
