import { ChangeEvent, MouseEvent, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import './authStyle.scss';
import EnlyticalLogo from "../../../assets/EnlyticalLogo3.png";
import Loader from "../../../utils/commonComponents/Loader/Loader";
import { toast } from "react-toastify";


const Signup: React.FC = () => {
  let navigate = useNavigate();
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    contact_num: "",
    email: "",
    password: '',
    cnfmPassword: ''
  });

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    console.log(state);
    if (state.email === '') {
      toast.error('email is required');
      return
    }
    if (state.password === '') {
      toast.error('password is required');
      return
    }
    if (state.cnfmPassword === '') {
      toast.error('password confirmation is required');
      return
    }
    if (state.cnfmPassword !== state.password) {
      toast.error('password does not match');
      return
    }
    // axios
    //   .post(`${BASE_URL}user/adminSignUp`, state)
    //   .then(function (response) {
    //     //console.log(response.data.user);
    //     const { email } = response.data.user;
    //     navigate(`/user/setpassword/${email}`);
    //   })
    //   .catch(function (error) {
    //     //console.log(error);
    //   });

    e.preventDefault();
  };

  return (
    <div className="login">
      <div className="login-left">

      </div>
      <div className="login-right">
        <div className="login-loginContainer">
          <div style={{ display: "flex", justifyContent: 'center' }}>
            <img src={EnlyticalLogo} alt="logo" height={"80px"} />
          </div>
          <div className="login-loginContainerHeader">
            <h4 className="mb-1">Sign Up</h4>
            <h5 className="mb-2">to continue with <span style={{ color: "var(--active-color)" }}>Enlytical.com</span></h5>
          </div>
          <hr />
          <form>
            <div style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between' }}>
              <div className="mb-3">
                <label className="mb-1 login-label">First Name</label>
                <input
                  type="text"
                  name="first_name"
                  className="form-control login-input"
                  id="first_name"
                  placeholder="First Name"
                  onChange={handleInputChange}
                  value={state.first_name}
                />
              </div>
              <div className="mb-3">
                <label className="mb-1 login-label">Last Name</label>
                <input
                  type="text"
                  name="last_name"
                  className="form-control login-input"
                  id="last_name"
                  placeholder="Last Name"
                  onChange={handleInputChange}
                  value={state.last_name}
                />
              </div>
            </div>
            <div className="mb-3">
              <label className="mb-1 login-label">Mobile Number</label>
              <input
                type="text"
                name="contact_num"
                className="form-control login-input"
                id="contact_num"
                placeholder="Contact Number"
                onChange={handleInputChange}
                value={state.contact_num}
              />
            </div>
            <div className="mb-3">
              <label className="mb-1 login-label">Email</label>
              <input
                type="email"
                name="email"
                className="form-control login-input"
                id="email"
                placeholder="Email"
                onChange={handleInputChange}
                value={state.email}
              />
            </div>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <div style={{ position: 'relative' }} className="mb-3">
                <label className="mb-1 login-label">Set Password</label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={state.password}
                  placeholder="Password"
                  style={{ paddingRight: '30px' }}
                  onChange={handleInputChange}
                  className="form-control login-input"
                  id="password"
                />
                <span
                  onClick={togglePasswordVisibility}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '70%',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                    color: '#384D6C'
                  }}
                >
                  {showPassword ? <i className="bi bi-eye"></i> : <i className="bi bi-eye-slash"></i>}
                </span>
              </div>
              <div style={{ position: 'relative' }} className="mb-3">
                <label className="mb-1 login-label">Confirm Password</label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="cnfmPassword"
                  value={state.cnfmPassword}
                  placeholder="Password"
                  style={{ paddingRight: '30px' }}
                  onChange={handleInputChange}
                  className="form-control login-input"
                  id="cnfmPassword"
                />
                <span
                  onClick={togglePasswordVisibility}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '70%',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                    color: '#384D6C'
                  }}
                >
                  {showPassword ? <i className="bi bi-eye"></i> : <i className="bi bi-eye-slash"></i>}
                </span>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.5rem' }}>
              <div>
                <button
                  className="login-btn"
                  type="button"
                  onClick={handleSubmit}
                >
                  Create Account
                </button>
              </div>
              <div>
                <Link style={{ color: 'var(--active-color)', fontWeight: '600' }} to={"/login"}> login </Link>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
