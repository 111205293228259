import React from 'react'

const MonthlyBudgetIcon = (props) => {
  return (
    <>
      {
        props.tab === 'monthlyBudget' ?
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.44444 9.6C4.19259 9.6 3.98148 9.52333 3.81111 9.37C3.64074 9.21667 3.55556 9.02667 3.55556 8.8C3.55556 8.57333 3.64074 8.38333 3.81111 8.23C3.98148 8.07667 4.19259 8 4.44444 8C4.6963 8 4.90741 8.07667 5.07778 8.23C5.24815 8.38333 5.33333 8.57333 5.33333 8.8C5.33333 9.02667 5.24815 9.21667 5.07778 9.37C4.90741 9.52333 4.6963 9.6 4.44444 9.6ZM8 9.6C7.74815 9.6 7.53704 9.52333 7.36667 9.37C7.1963 9.21667 7.11111 9.02667 7.11111 8.8C7.11111 8.57333 7.1963 8.38333 7.36667 8.23C7.53704 8.07667 7.74815 8 8 8C8.25185 8 8.46296 8.07667 8.63333 8.23C8.8037 8.38333 8.88889 8.57333 8.88889 8.8C8.88889 9.02667 8.8037 9.21667 8.63333 9.37C8.46296 9.52333 8.25185 9.6 8 9.6ZM11.5556 9.6C11.3037 9.6 11.0926 9.52333 10.9222 9.37C10.7519 9.21667 10.6667 9.02667 10.6667 8.8C10.6667 8.57333 10.7519 8.38333 10.9222 8.23C11.0926 8.07667 11.3037 8 11.5556 8C11.8074 8 12.0185 8.07667 12.1889 8.23C12.3593 8.38333 12.4444 8.57333 12.4444 8.8C12.4444 9.02667 12.3593 9.21667 12.1889 9.37C12.0185 9.52333 11.8074 9.6 11.5556 9.6ZM1.77778 16C1.28889 16 0.87037 15.8433 0.522222 15.53C0.174074 15.2167 0 14.84 0 14.4V3.2C0 2.76 0.174074 2.38333 0.522222 2.07C0.87037 1.75667 1.28889 1.6 1.77778 1.6H2.66667V0H4.44444V1.6H11.5556V0H13.3333V1.6H14.2222C14.7111 1.6 15.1296 1.75667 15.4778 2.07C15.8259 2.38333 16 2.76 16 3.2V14.4C16 14.84 15.8259 15.2167 15.4778 15.53C15.1296 15.8433 14.7111 16 14.2222 16H1.77778ZM1.77778 14.4H14.2222V6.4H1.77778V14.4ZM1.77778 4.8H14.2222V3.2H1.77778V4.8Z" fill="#06486A" />
          </svg> : <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.44444 9.6C4.19259 9.6 3.98148 9.52333 3.81111 9.37C3.64074 9.21667 3.55556 9.02667 3.55556 8.8C3.55556 8.57333 3.64074 8.38333 3.81111 8.23C3.98148 8.07667 4.19259 8 4.44444 8C4.6963 8 4.90741 8.07667 5.07778 8.23C5.24815 8.38333 5.33333 8.57333 5.33333 8.8C5.33333 9.02667 5.24815 9.21667 5.07778 9.37C4.90741 9.52333 4.6963 9.6 4.44444 9.6ZM8 9.6C7.74815 9.6 7.53704 9.52333 7.36667 9.37C7.1963 9.21667 7.11111 9.02667 7.11111 8.8C7.11111 8.57333 7.1963 8.38333 7.36667 8.23C7.53704 8.07667 7.74815 8 8 8C8.25185 8 8.46296 8.07667 8.63333 8.23C8.8037 8.38333 8.88889 8.57333 8.88889 8.8C8.88889 9.02667 8.8037 9.21667 8.63333 9.37C8.46296 9.52333 8.25185 9.6 8 9.6ZM11.5556 9.6C11.3037 9.6 11.0926 9.52333 10.9222 9.37C10.7519 9.21667 10.6667 9.02667 10.6667 8.8C10.6667 8.57333 10.7519 8.38333 10.9222 8.23C11.0926 8.07667 11.3037 8 11.5556 8C11.8074 8 12.0185 8.07667 12.1889 8.23C12.3593 8.38333 12.4444 8.57333 12.4444 8.8C12.4444 9.02667 12.3593 9.21667 12.1889 9.37C12.0185 9.52333 11.8074 9.6 11.5556 9.6ZM1.77778 16C1.28889 16 0.87037 15.8433 0.522222 15.53C0.174074 15.2167 0 14.84 0 14.4V3.2C0 2.76 0.174074 2.38333 0.522222 2.07C0.87037 1.75667 1.28889 1.6 1.77778 1.6H2.66667V0H4.44444V1.6H11.5556V0H13.3333V1.6H14.2222C14.7111 1.6 15.1296 1.75667 15.4778 2.07C15.8259 2.38333 16 2.76 16 3.2V14.4C16 14.84 15.8259 15.2167 15.4778 15.53C15.1296 15.8433 14.7111 16 14.2222 16H1.77778ZM1.77778 14.4H14.2222V6.4H1.77778V14.4ZM1.77778 4.8H14.2222V3.2H1.77778V4.8Z" fill="#5B6871" />
          </svg>
      }
    </>
  )
}

export default MonthlyBudgetIcon