import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";


export default function LoaderSkeletonBoxes({ height = '5rem', width = '11rem' }) {
  return (
    <Box sx={{ color: 'rgba(24, 24, 24, 1)', width, height }}>
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
    </Box>
  );
}
