import './CustomSelect.css'; 

const CustomSelect = ({ children, className, value, onchange, id }) => {
    // const [selectedOption, setSelectedOption] = useState(''); // State to track the selected option

    // const handleOptionSelect = (e) => {
    //     setSelectedOption(e.target.value); // Update the selected option when an option is selected
    // };

    return (
        <div className={`custom-select-container ${className}`}>
            <select
                className={`custom-select ${className}`}
                value={value}
                onChange={onchange}
                id={id}
            >
                {children}
            </select>
            <span className='custom-arrow'></span>
        </div >
    );
};

export default CustomSelect;