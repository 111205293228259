import { PrimeReactProvider } from 'primereact/api'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../redux/hook';
import Loader from '../../../utils/commonComponents/Loader/Loader';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnEditorOptions } from 'primereact/column';
import Tippy from '@tippyjs/react';
import { saveAs } from 'file-saver'
import { BASE_URL_NEW } from '../../../appConstant';
import { toast } from 'react-toastify';
import axios from 'axios';
import { InputSwitch } from 'primereact/inputswitch';
import { ActionEnum } from '../types';
import { AccessRoleEnum, AccessTypeEnum, ModuleEnum } from '../../../utils/types.enum';
import LoaderSkeletonTable from '../../../utils/commonComponents/Loader/LoaderSkeletonTable';

const SellerMaster = () => {
    const client = useAppSelector(state => state.client);
    const { current_brand: { client_id, access_type, access_role, modules } } = client;
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [forceRerender, setForceRerender] = useState("")
    const [action, setAction] = useState<ActionEnum>(ActionEnum.NOCHANGES)

    const canApproveReject:boolean = (
        access_type==AccessTypeEnum.AGENCY ||
        (
            access_type==AccessTypeEnum.BRAND && access_role==AccessRoleEnum.BRAND_MANAGER && modules.find((module)=>module===ModuleEnum.MASTER)?true:false
        )
    )

    const canEdit:boolean = (
        (
            access_type==AccessTypeEnum.AGENCY || (access_role==AccessRoleEnum.BRAND_MANAGER && modules.find((module)=>module===ModuleEnum.MASTER))?true:false
        )
    )

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${BASE_URL_NEW}/master/${client_id}/seller-master`)
            .then((res) => {
                res.data.forEach((dt: any) => {
                    if (dt.seller_type === 'authorised') dt['authorised'] = true
                    else dt['authorised'] = false
                })
                setData(res.data);
                if (res.data && res.data.length>0 && res.data[0].status === 'DRAFT') { //and u have access for this client
                    setAction(ActionEnum.APPROVAL)
                } else {
                    setAction(ActionEnum.NOCHANGES)
                }
            }).catch((error) => {
                toast.error(error?.response?.data?.error || 'Unable to get sellers')
            }).finally(() => {
                setIsLoading(false);
            })
    }, [client_id, forceRerender])

    const handlecheckChange = (product: any) => {
        if(!(canEdit || canApproveReject)) return
        if (product.authorised) {
            product.authorised = false;
            product.seller_type = 'unauthorised'
        } else {
            product.authorised = true;
            product.seller_type = 'authorised'
        }
        setAction(ActionEnum.EDIT)
        setData((prev: any) => {
            return [...prev]
        })
    }

    const RadioTemplate = (product: any) => {
        return <input type="checkbox" id="scales" name="scales" checked={product.authorised} onChange={() => { handlecheckChange(product) }} />
    };

    const columns = [
        { header: 'Seller Name', field: 'seller_name' },
        { header: 'Authorised', field: 'authorised', body: RadioTemplate },
        { header: 'Portfolio', field: 'seller_portfolio' },
        { header: 'Ratings', field: 'seller_rating' }
    ]

    const exportToCSV = () => {
        let csvContent = '';
        const header = columns.map(col => col.field).join(',');
        csvContent += header + '\n';

        data.forEach((row: any) => {
            const rowData = columns.map(col => row[col.field]).join(',');
            csvContent += rowData + '\n';
        });

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'seller_master.csv');
    };

    const handlSubmitBudgets = (status:string) => {
        setIsLoading(true);
        const body = data.map((dt) => {
            const newDt = JSON.parse(JSON.stringify(dt))
            delete newDt.authorised
            delete newDt.id
            newDt.status = status
            return newDt
        })
        axios.patch(`${BASE_URL_NEW}/master/${client_id}/seller-master`,
            body
        )
            .then((res) => {
                toast.success("Seller Master successfully updated")
                setIsLoading(false)
                setForceRerender("render")
            }).catch((error) => {
                toast.error(error?.response?.data?.error || 'Unable to update product master')
                setIsLoading(false)
            })
    }



    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Tippy content='Download *.csv file'>
                    <button style={{ padding: '0.25rem 0.75rem' }} onClick={exportToCSV} className='downloadBtn'>Download</button>
                </Tippy>
            </div>

            <PrimeReactProvider>
                <div className='table_card_new'>
                {isLoading?<LoaderSkeletonTable length='8' height='58vh'/>: 
                    <DataTable
                        value={data}
                        size='small'
                        columnResizeMode="expand" resizableColumns
                        scrollable scrollHeight="60vh" virtualScrollerOptions={{ itemSize: 20 }}
                    >
                        {columns.map((col: any) => (
                            <Column
                                {...col}
                                bodyStyle={{ color: 'rgba(24, 24, 24, 1)', fontSize: '0.85rem', fontWeight: 500, textTransform: 'capitalize' }}
                                headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                            // editor={(options) => cellEditor(options)}
                            // onCellEditComplete={onCellEditComplete}
                            />

                        ))}
                    </DataTable>
                }
                </div>
            </PrimeReactProvider>

            <div style={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end', fontSize: '0.85rem' }}>
                <button type='button' className='cancel-btn' onClick={() => { setForceRerender('re') }}>Cancel</button>
                {action === ActionEnum.EDIT && <button type='button' disabled={!canEdit} className='submit-btn' onClick={()=>handlSubmitBudgets("DRAFT")}>Submit</button>}
                {action == ActionEnum.APPROVAL && <>
                    <button type='button' disabled={!canApproveReject} className='submit-btn' onClick={()=>handlSubmitBudgets('REJECTED')}>Reject</button>
                    <button type='button' disabled={!canApproveReject} className='submit-btn' onClick={()=>handlSubmitBudgets('APPROVED')}>Approve</button>
                </>}


            </div>
        </div>
    )
}

export default SellerMaster