import React from 'react'

const TotalSalesIcon = (props) => {
    return (
        <>
            {
                props.tab === 'totalSales' ?
                    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.3698 0.853418C12.4038 0.64215 12.2704 0.440405 12.0691 0.400755L10.0691 0.00681805C9.86555 -0.0332768 9.66959 0.107571 9.63142 0.32141C9.59326 0.535249 9.72733 0.741103 9.93089 0.781198L11.2045 1.03206L9.05259 2.64675C8.80349 2.83367 8.45656 2.76687 8.28675 2.4993C7.91011 1.90581 7.16041 1.71989 6.57088 2.07378L3.81395 3.72872L4.18605 4.41279L6.94298 2.75785C7.19026 2.60941 7.50473 2.68739 7.66271 2.93633C8.06754 3.57424 8.89465 3.73349 9.48852 3.28787L11.625 1.68475V3.54551C11.625 3.76307 11.7929 3.93944 12 3.93944C12.2071 3.93944 12.375 3.76307 12.375 3.54551V0.919258C12.375 0.896824 12.3732 0.874828 12.3698 0.853418Z" fill="#06486A" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 8.798C10 9.95835 9.10457 10.899 8 10.899C6.89543 10.899 6 9.95835 6 8.798C6 7.63765 6.89543 6.697 8 6.697C9.10457 6.697 10 7.63765 10 8.798ZM8 9.8485C8.55228 9.8485 9 9.37818 9 8.798C9 8.21783 8.55228 7.7475 8 7.7475C7.44772 7.7475 7 8.21783 7 8.798C7 9.37818 7.44772 9.8485 8 9.8485Z" fill="#06486A" />
                        <path d="M4 9.8485C4.55228 9.8485 5 9.37818 5 8.798C5 8.21783 4.55228 7.7475 4 7.7475C3.44772 7.7475 3 8.21783 3 8.798C3 9.37818 3.44772 9.8485 4 9.8485Z" fill="#06486A" />
                        <path d="M13 8.798C13 9.37818 12.5523 9.8485 12 9.8485C11.4477 9.8485 11 9.37818 11 8.798C11 8.21783 11.4477 7.7475 12 7.7475C12.5523 7.7475 13 8.21783 13 8.798Z" fill="#06486A" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 13H16V4.59601H0V13ZM14 5.6465H2C2 6.22668 1.55228 6.697 1 6.697V10.899C1.55228 10.899 2 11.3693 2 11.9495H14C14 11.3693 14.4477 10.899 15 10.899V6.697C14.4477 6.697 14 6.22668 14 5.6465Z" fill="#06486A" />
                    </svg> : <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.3698 0.853418C12.4038 0.64215 12.2704 0.440405 12.0691 0.400755L10.0691 0.00681805C9.86555 -0.0332768 9.66959 0.107571 9.63142 0.32141C9.59326 0.535249 9.72733 0.741103 9.93089 0.781198L11.2045 1.03206L9.05259 2.64675C8.80349 2.83367 8.45656 2.76687 8.28675 2.4993C7.91011 1.90581 7.16041 1.71989 6.57088 2.07378L3.81395 3.72872L4.18605 4.41279L6.94298 2.75785C7.19026 2.60941 7.50473 2.68739 7.66271 2.93633C8.06754 3.57424 8.89465 3.73349 9.48852 3.28787L11.625 1.68475V3.54551C11.625 3.76307 11.7929 3.93944 12 3.93944C12.2071 3.93944 12.375 3.76307 12.375 3.54551V0.919258C12.375 0.896824 12.3732 0.874828 12.3698 0.853418Z" fill="#5B6871" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 8.798C10 9.95835 9.10457 10.899 8 10.899C6.89543 10.899 6 9.95835 6 8.798C6 7.63765 6.89543 6.697 8 6.697C9.10457 6.697 10 7.63765 10 8.798ZM8 9.8485C8.55228 9.8485 9 9.37818 9 8.798C9 8.21783 8.55228 7.7475 8 7.7475C7.44772 7.7475 7 8.21783 7 8.798C7 9.37818 7.44772 9.8485 8 9.8485Z" fill="#5B6871" />
                        <path d="M4 9.8485C4.55228 9.8485 5 9.37818 5 8.798C5 8.21783 4.55228 7.7475 4 7.7475C3.44772 7.7475 3 8.21783 3 8.798C3 9.37818 3.44772 9.8485 4 9.8485Z" fill="#5B6871" />
                        <path d="M13 8.798C13 9.37818 12.5523 9.8485 12 9.8485C11.4477 9.8485 11 9.37818 11 8.798C11 8.21783 11.4477 7.7475 12 7.7475C12.5523 7.7475 13 8.21783 13 8.798Z" fill="#5B6871" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 13H16V4.59601H0V13ZM14 5.6465H2C2 6.22668 1.55228 6.697 1 6.697V10.899C1.55228 10.899 2 11.3693 2 11.9495H14C14 11.3693 14.4477 10.899 15 10.899V6.697C14.4477 6.697 14 6.22668 14 5.6465Z" fill="#5B6871" />
                    </svg>
            }
        </>
    )
}

export default TotalSalesIcon