import { AgGridReact } from 'ag-grid-react';
import { AG_AR } from '../../../appConstant';
import ReportsErrorPage from '../../../utils/commonComponents/errorFallback/ReportsErrorPage';
import { useMemo } from 'react';


const BrandAccess = ({ brandWiseUserData, columnDefs, error }) => {

    const defaultColDef = useMemo(() => ({
        sortable: true, resizable: true, minWidth: 100
    }), []);
    return (
        <div className="ag-theme-alpine agency-table" style={{ height: '60vh', width: '100%', boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px " }}>
            {error ? <div style={{ height: 350, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
                <AgGridReact
                    // ref={gridRef}
                    rowData={brandWiseUserData}
                    rowHeight={AG_AR.rH}
                    headerHeight={AG_AR.hH}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    suppressNoRowsOverlay={true}
                    suppressDragLeaveHidesColumns={true}
                    suppressMenuHide={true}
                    suppressCellFocus={true}
                >
                </AgGridReact>
            }
        </div>
    )
}

export default BrandAccess