import "./powerbi.scss";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
// import BallTriangleLoader from "../../components/loaders/ballTriangle";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// import Notification from "../../components/notifications/notification";
// import Header from "../../layouts/header/header";
import { useState } from "react";
import { ActionStates } from "../../../../utils/types.enum";
import { getCurrentDashboard } from "../../../../redux/dashboard/dashboard.selector";
import { fetchDashbaord } from "../../../../redux/dashboard/dashboard.thunk";

const Powerbi = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { dashboard, status: { action, message, error }, } = useSelector(getCurrentDashboard);
    const [rendered, setRendered] = useState(false);
    const [finalAction, setFinalAction] = useState(action);
    // const [error,setError] = useState<any>(null)

    useEffect(() => {
        if (action === ActionStates.FULFILLED) {
            if (!rendered) setFinalAction(ActionStates.PENDING)
            else setFinalAction(ActionStates.FULFILLED)
        }
        // else if(action === ActionStates.REJECTED){
        //     setError(`you don't have permission to access the dashboard`);
        // }
    }, [action, rendered,])

    useEffect(() => {
        if (id) dispatch(fetchDashbaord(id));
    }, [id]);

    // if (error) {
    //     return (
    //         <h1 className="powerbiError">{error}</h1>
    //     )
    // }

    return (
        <div style={{ width: '100%', height: '100%' }}>
            {/* <Header/> */}
            {/* <aside>
                <BallTriangleLoader action={action} /> 
                <Notification action={action} message={message} retry={retry} />
            </aside> */}
            {dashboard && (

                <PowerBIEmbed
                    embedConfig={{
                        type: "report", // Supported types: report, dashboard, tile, visual and qna
                        id: dashboard.embedUrl[0].reportId,
                        embedUrl: dashboard.embedUrl[0].embedUrl,
                        accessToken: dashboard.accessToken,
                        tokenType: models.TokenType.Embed,
                        settings: {
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: false,
                                },
                            },
                            background: models.BackgroundType.Transparent,
                        },
                    }}
                    eventHandlers={
                        new Map([
                            [
                                "loaded",
                                function () {
                                    console.log("Report loaded");
                                },
                            ],
                            [
                                "rendered",
                                function () {
                                    setRendered(true);
                                    console.log("Report Rendered");
                                },
                            ],
                            [
                                "error",
                                function (event: any) {
                                    console.log(event.detail);
                                },
                            ],
                        ])
                    }
                    cssClassName={"Embed-container"}
                    getEmbeddedComponent={(embeddedReport) => {
                        const win: any = window
                        win.report = embeddedReport;
                    }}
                />

            )}
        </div>
    );
};

export default Powerbi;