import React from 'react'

const KeywordCategorizationIcon = (props) => {
    return (
        <>
            {
                props.tab === 'keywordCategorization' ?
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 14.2141V8.38269H6.1025V14.2141H0ZM1.52563 12.7563H4.57688V9.84055H1.52563V12.7563ZM2.66984 6.56036L6.86532 0L11.0608 6.56036H2.66984ZM5.37783 5.10251H8.3528L6.86532 2.80638L5.37783 5.10251ZM14.9321 16L12.9106 14.0683C12.6436 14.2384 12.3544 14.366 12.0429 14.451C11.7314 14.5361 11.4041 14.5786 11.0608 14.5786C10.1073 14.5786 9.29678 14.2597 8.62932 13.6219C7.96186 12.9841 7.62813 12.2096 7.62813 11.2984C7.62813 10.3872 7.96186 9.61276 8.62932 8.97494C9.29678 8.33713 10.1073 8.01822 11.0608 8.01822C12.0143 8.01822 12.8248 8.33713 13.4923 8.97494C14.1597 9.61276 14.4934 10.3872 14.4934 11.2984C14.4934 11.6143 14.4489 11.921 14.36 12.2187C14.271 12.5163 14.1438 12.7927 13.9785 13.0478L16 14.9795L14.9321 16ZM11.0608 13.1207C11.5948 13.1207 12.0461 12.9446 12.4148 12.5923C12.7835 12.2399 12.9678 11.8087 12.9678 11.2984C12.9678 10.7882 12.7835 10.3569 12.4148 10.0046C12.0461 9.65224 11.5948 9.47608 11.0608 9.47608C10.5268 9.47608 10.0755 9.65224 9.70679 10.0046C9.3381 10.3569 9.15375 10.7882 9.15375 11.2984C9.15375 11.8087 9.3381 12.2399 9.70679 12.5923C10.0755 12.9446 10.5268 13.1207 11.0608 13.1207Z" fill="#06486A" />
                    </svg> : <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 14.2141V8.38269H6.1025V14.2141H0ZM1.52563 12.7563H4.57688V9.84055H1.52563V12.7563ZM2.66984 6.56036L6.86532 0L11.0608 6.56036H2.66984ZM5.37783 5.10251H8.3528L6.86532 2.80638L5.37783 5.10251ZM14.9321 16L12.9106 14.0683C12.6436 14.2384 12.3544 14.366 12.0429 14.451C11.7314 14.5361 11.4041 14.5786 11.0608 14.5786C10.1073 14.5786 9.29678 14.2597 8.62932 13.6219C7.96186 12.9841 7.62813 12.2096 7.62813 11.2984C7.62813 10.3872 7.96186 9.61276 8.62932 8.97494C9.29678 8.33713 10.1073 8.01822 11.0608 8.01822C12.0143 8.01822 12.8248 8.33713 13.4923 8.97494C14.1597 9.61276 14.4934 10.3872 14.4934 11.2984C14.4934 11.6143 14.4489 11.921 14.36 12.2187C14.271 12.5163 14.1438 12.7927 13.9785 13.0478L16 14.9795L14.9321 16ZM11.0608 13.1207C11.5948 13.1207 12.0461 12.9446 12.4148 12.5923C12.7835 12.2399 12.9678 11.8087 12.9678 11.2984C12.9678 10.7882 12.7835 10.3569 12.4148 10.0046C12.0461 9.65224 11.5948 9.47608 11.0608 9.47608C10.5268 9.47608 10.0755 9.65224 9.70679 10.0046C9.3381 10.3569 9.15375 10.7882 9.15375 11.2984C9.15375 11.8087 9.3381 12.2399 9.70679 12.5923C10.0755 12.9446 10.5268 13.1207 11.0608 13.1207Z" fill="#5B6871" />
                    </svg>
            }
        </>
    )
}

export default KeywordCategorizationIcon