import React, { useEffect, useState } from "react";
import BlinkitIcon from "../../../assets/svgs/BlinkitIcon";
import ZeptoIcon from "../../../assets/svgs/ZeptoIcon";
import InstamartIcon from "../../../assets/svgs/InstamartIcon";
import ProteinPuff from "../../../assets/ProteinPuffs.png"
import { Category } from "@mui/icons-material";
import axios from "axios";
import { BASE_URL_NEW } from "../../../appConstant";
import LoaderSkeletonBoxes from "../../../utils/commonComponents/Loader/LoaderSkeletonBoxes";
import LoaderSkeletonGraph from "../../../utils/commonComponents/Loader/LoaderSkeletonGraph";
import LoaderSkeletonTable from "../../../utils/commonComponents/Loader/LoaderSkeletonTable";
import { toast } from "react-toastify";
// import "./ProductHarmonization.scss";



interface Product {
  UID: string;
  platformName: string;
  name: string;
  Category: string;
  [key: string]: any; // To account for other possible properties
}

interface GroupedProduct {
  Blinkit: Product | null;
  Zepto: Product | null;
  Instamart: Product | null;
}


const ProductHarmonization = () => {
  const [editCard, setEditCard] = useState(false);
  const [isActive, setIsActive] = useState("");
  const [editCardData1, setEditCardData1] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);

  const [selectedHarmonizationId,setSelectedHarmonizationId]=useState(null);


  const [data, setData] = useState([])




  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${BASE_URL_NEW}/qcomm/product-harmonization?client_name=taali`)
      .then((res: any) => {
        const rawData = res.data;

        // Normalize data
        const formattedData = rawData.map((item: any) => ({
          harmonized_id: item.harmonized_id,
          internal_category: item.internal_category,
          blinkit: item["blinkit"] || {
            platform_code: "",
            short_name: "",
            pname: "",
            product_pic_url: "",
          },
          zepto: item["zepto"] || {
            platform_code: "",
            short_name: "",
            pname: "",
            product_pic_url: "",
          },
          instamart: item["swiggy instamart"] || {
            platform_code: "",
            short_name: "",
            pname: "",
            product_pic_url: "",
          },
        }));

        setData(formattedData);
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);



  const editCardData = [
    {
      id: 1,
      image: "/path/to/image1.jpg",
      platformName: "Instamart",
      name: "Protein Puffs Masala Mania",
      sku: "2334567778",
      UID: "UID1",
      Category: 'Protein Puffs'
    },
    {
      id: 2,
      image: "/path/to/image2.jpg",
      platformName: "Blinkit",
      name: "Protein Chips Salt Delight",
      sku: "657c3d",
      UID: "UID1",
      Category: 'Protein Puffs'
    },
    {
      id: 3,
      image: "/path/to/image2.jpg",
      platformName: "Zepto",
      name: "Protein Chips Salt Delight",
      sku: "554872762cd",
      UID: "UID1",
      Category: 'Protein Puffs'
    },
    {
      id: 4,
      image: "/path/to/image2.jpg",
      platformName: "Zepto",
      name: "Protein Chips Salt Delight",
      sku: "554872762cd",
      UID: "UID1",
      Category: 'Protein Puffs'
    },
    {
      id: 5,
      image: "/path/to/image1.jpg",
      platformName: "Instamart",
      name: "Protein Puffs Masala Mania",
      sku: "2334567778",
      UID: "UID1",
      Category: 'Protein Puffs'
    },

  ];



  console.log('edit card data',editCardData1)


  const [filteredData, setFilteredData] = useState(editCardData1);

  const [activeEditProduct, setActiveEditProduct] = React.useState<number | null>(null);



  const handleEditClick = (productId: any) => {
    // setEditCardData([product]);
    setActiveEditProduct((prev) => (prev === productId ? null : productId));
    // setEditCard(true);
  };

  const handleEditIconClick = (category:any,platform:any,harmonizationid:any) => {

    setSelectedHarmonizationId(harmonizationid);
    setIsLoadingEdit(true);
      axios
        .get(`${BASE_URL_NEW}/qcomm/product-harmonization/products?client_name=taali&platform=${platform}&category=${category}`)
        .then((res: any) => {
          const rawData = res.data;
          setEditCardData1(rawData)
          setFilteredData(rawData);
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoadingEdit(false);
        });

  };

  const handleEditCardClick= (data: any) => {
    console.log('target key',data);

    //  api call
    //  set Edit Data 
    setIsLoading(true);

      axios
        .patch(`${BASE_URL_NEW}/qcomm/product-harmonization/${data.platform_code}`,
          {
            harmonized_id: selectedHarmonizationId, // Body data   // where to get this from
          }
        )
        .then((res: any) => {
          toast('successfully updated')
        })
        .catch((error: any) => {
          console.error(error);
          toast('not updated')
        })
        .finally(() => {
          setIsLoading(false);
        });

        handleEditClick(false);

  };


  const handleChange = (e: any) => {
    setSearchQuery(e.target.value)
    const filtered = editCardData1.filter((product:any) =>
      product.platform_code.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
  };



  return (
    <>
      <div className={editCard ? "product-harmoniz" : ''}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
          <h3>Product Harmonization</h3>
          <div className="harmonization-block">
            <div className="summary-block">Product Summary</div>
            <div
              className={`match-block match-button ${isActive === 'match' ? 'active' : ''}`}
              onClick={() => setIsActive(isActive === 'match' ? '' : 'match')}>
              <div className="match">Match</div>
              <div>3</div>
            </div>
            <div className={`unmatch-block unmatch-button ${isActive === 'unmatch' ? 'active' : ''}`}
              onClick={() => setIsActive(isActive === 'unmatch' ? '' : 'unmatch')}>
              <div className="unmatch">Unmatch</div>
              <div>1</div>
            </div>
          </div>
        </div>





        {/* <div className="product-harmonization">
          <div className="product-cards">
            {products.map((product) => (

              <div className="product-card" key={product.id}>
                
                <div className="card-section image-section">
                  <img src={ProteinPuff} alt="Product" />
                </div>

                
                <div className="card-section details-section">
                  <div className="icons">
                    {product.platformName.toLowerCase() === "blinkit" && <BlinkitIcon />}
                    {product.platformName.toLowerCase() === "zepto" && <ZeptoIcon />}
                    {product.platformName.toLowerCase() === "instamart" && <InstamartIcon />}
                  </div>
                  <div className="product-info">
                    <div className="product-name">{product.name}</div>
                    <div className="product-sku">
                      SKU: {product.sku.length > 9 ? `${product.sku.slice(0, 3)}...` : product.sku}
                      <div className="edit-iconback">
                        <div
                          className="edit-icon"
                          onClick={() => handleEditClick(product)}
                        >
                          <i className="bi bi-pencil"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

               
              </div>
            ))}
          </div>

          
          {editCard && 

            <div className="edit-card">
             
              <div className="search-bar" style={{ marginBottom: '1rem' }}>
                <div>
                  <i className="bi bi-search search-icon" ></i>
                  <input
                    type="text"
                    placeholder="ENTER ASIN to add Product"
                    value={searchQuery}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>

              <button className="close-btn" onClick={() => setEditCard(false)}>
                ✖
              </button>
              {
                filteredData.map((data: any) => (
                  <>
                    <div className="product-card" key={data.id}>
                      <div className="card-section image-section">
                        <img src={ProteinPuff} alt="Product" />
                      </div>

                      
                      <div className="card-section details-section">
                        <div className="icons">
                          {data.platformName === "Blinkit" && <BlinkitIcon />}
                          {data.platformName === "Zepto" && <ZeptoIcon />}
                          {data.platformName === "Instamart" && <InstamartIcon />}
                        </div>
                        <div className="product-info">
                          <div className="product-name">{data.name}</div>
                          <div className="product-sku">
                            SKU: {data.sku}
                          </div>
                        </div>
                      </div>
                    </div>

                  </>
                ))
              }
            </div>

          }


        </div> */}


        {/* new one harmonization  */}
        <div className="product-harmonization1">
          <div className="product-cards">



            {data.map((it: any) => (<>


              {/* Fixed Column for Blinkit */}
              <div className="column">

                <div className="product-card" key={it.harmonized_id}>
                  <div className="card-section image-section">
                    <img src={it.blinkit.product_pic_url} alt={it.blinkit.short_name} />
                  </div>
                  <div className="card-section details-section">
                    {it.blinkit.pname ?
                      <div className="icons">
                        <BlinkitIcon />
                      </div> : ''
                    }
                    <div className="product-info">
                      <div className="product-name">{it.blinkit.short_name}</div>
                      {it.blinkit.pname ?
                        <div className="product-sku">
                          SKU: {it.blinkit.platform_code.length > 9
                            ? `${it.blinkit.platform_code.slice(0, 9)}...`
                            : it.blinkit.platform_code}
                          <div className="edit-iconback">
                            <div
                              className="edit-icon"
                              onClick={() => {handleEditClick(it.blinkit.platform_code)
                                            handleEditIconClick(it.internal_category,'blinkit',it.harmonized_id)}
                              }
                            >
                              <i className="bi bi-pencil"></i>
                            </div>
                          </div>
                        </div>
                        : ""}
                    </div>
                  </div>



                  {/* Editable Card */}
                  {activeEditProduct === it.blinkit.platform_code &&

                    <div className="edit-card">
                      {/* // search bar  */}
                      <div className="search-bar" style={{ marginBottom: '1rem' }}>
                        <div>
                          <i className="bi bi-search search-icon" ></i>
                          <input
                            type="text"
                            placeholder="ENTER ASIN to add Product"
                            value={searchQuery}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>

                      <button className="close-btn" onClick={() => handleEditClick(false)}>
                        ✖
                      </button>
                      { isLoadingEdit?<LoaderSkeletonTable length="4"/>:
                        filteredData.map((data: any) => (
                          <>
                            <div className="product-card select-Edit-Card" key={data.id}  onClick={()=>handleEditCardClick(data)}>
                              <div className="card-section image-section">
                                <img src={data.product_pic_url} alt={data.short_name} />
                              </div>

                              {/* Middle Section: Details */}
                              <div className="card-section details-section">
                                <div className="icons">
                                 <BlinkitIcon />
                                </div>
                                <div className="product-info">
                                  <div className="product-name">{data.short_name}</div>
                                  <div className="product-sku">
                                    SKU: {data.platform_code}
                                  </div>
                                </div>
                              </div>
                            </div>

                          </>
                        ))
                      }
                    </div>

                  }





                </div>

              </div>


              {/* Fixed Column for Zepto */}
              <div className="column">

                <div className="product-card" key={it.harmonized_id}>
                  <div className="card-section image-section">
                    <img src={it.zepto.product_pic_url} alt={it.zepto.short_name} />
                  </div>
                  <div className="card-section details-section">
                    {it.zepto.pname ?
                      <div className="icons">
                        <ZeptoIcon />
                      </div> : ''
                    }
                    <div className="product-info">
                      <div className="product-name">{it.zepto.short_name}</div>
                      {it.zepto.pname ?
                        <div className="product-sku">
                          SKU: {it.zepto.platform_code.length > 9
                            ? `${it.zepto.platform_code.slice(0, 9)}...`
                            : it.zepto.platform_code}
                          <div className="edit-iconback">
                            <div
                              className="edit-icon"
                              onClick={() => {handleEditClick(it.zepto.platform_code)
                                              handleEditIconClick(it.internal_category,'zepto',it.harmonized_id)}
                              }
                            >
                              <i className="bi bi-pencil"></i>
                            </div>
                          </div>
                        </div> : ''
                      }
                    </div>
                  </div>


                  {/* Editable Card */}
                  {activeEditProduct === it.zepto.platform_code &&

                    <div className="edit-card">
                      {/* // search bar  */}
                      <div className="search-bar" style={{ marginBottom: '1rem' }}>
                        <div>
                          <i className="bi bi-search search-icon" ></i>
                          <input
                            type="text"
                            placeholder="ENTER ASIN to add Product"
                            value={searchQuery}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>

                      <button className="close-btn" onClick={() => handleEditClick(false)}>
                        ✖
                      </button>

                      { isLoadingEdit?<LoaderSkeletonTable length="4"/>:
                        filteredData.map((data: any) => (
                          <>
                            <div className="product-card select-Edit-Card" key={data.id}  onClick={()=>handleEditCardClick(data)}>
                              <div className="card-section image-section">
                                <img src={data.product_pic_url} alt={data.short_name} />
                              </div>

                              {/* Middle Section: Details */}
                              <div className="card-section details-section">
                                <div className="icons">
                                  <ZeptoIcon />
                                </div>
                                <div className="product-info">
                                  <div className="product-name">{data.short_name}</div>
                                  <div className="product-sku">
                                    SKU: {data.platform_code}
                                  </div>
                                </div>
                              </div>
                            </div>

                          </>
                        ))
                      }
                    </div>

                  }



                </div>

              </div>



              {/* Fixed Column for Instamart */}
              <div className="column">
                
                    <div className="product-card" key={it.harmonized_id}>
                      <div className="card-section image-section">
                        <img src={it.instamart.product_pic_url} alt={it.instamart.short_name} />
                      </div>
                      <div className="card-section details-section">
                        {it.instamart.pname ?
                          <div className="icons">
                            <InstamartIcon />
                          </div> : ""
                        }
                        <div className="product-info">
                          <div className="product-name">{it.instamart.short_name}</div>
                          {it.instamart.pname?
                          <div className="product-sku">
                            SKU: {it.instamart.platform_code.length > 9
                          ? `${it.instamart.platform_code.slice(0, 9)}...`
                          : it.instamart.platform_code}
                            <div className="edit-iconback">
                              <div
                                className="edit-icon"
                                onClick={() => {handleEditClick(it.instamart.platform_code)
                                                handleEditIconClick(it.internal_category,'swiggy instamart',it.harmonized_id)}
                                }
                              >
                                <i className="bi bi-pencil"></i>
                              </div>
                            </div>
                          </div>:''}
                        </div>
                      </div>




                      {/* Editable Card */}
                      {activeEditProduct === it.instamart.platform_code &&

                        <div className="edit-card">
                          {/* // search bar  */}
                          <div className="search-bar" style={{ marginBottom: '1rem' }}>
                            <div>
                              <i className="bi bi-search search-icon" ></i>
                              <input
                                type="text"
                                placeholder="ENTER ASIN to add Product"
                                value={searchQuery}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>

                          <button className="close-btn" onClick={() => handleEditClick(false)}>
                            ✖
                          </button>

                          { isLoadingEdit?<LoaderSkeletonTable length="4"/>:
                            filteredData.map((data: any) => (
                              <>
                                <div className="product-card select-Edit-Card" key={data.id}  onClick={()=>handleEditCardClick(data)}>
                                  <div className="card-section image-section">
                                    <img src={data.product_pic_url} alt={data.short_name} />
                                  </div>

                                  {/* Middle Section: Details */}
                                  <div className="card-section details-section">
                                    <div className="icons">
                                      <InstamartIcon />
                                    </div>
                                    <div className="product-info">
                                      <div className="product-name">{data.short_name}</div>
                                      <div className="product-sku">
                                        SKU: {data.platform_code}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </>
                            ))
                          }
                        </div>

                      }



                    </div>
                
              </div>



            </>))}

          </div>

        </div>


      </div>

    </>
  );
};

export default ProductHarmonization;









// {data.map((it: any) => (
//   <div key={it.harmonized_id} className="row">
   
//     <div className="column">
//       {it.blinkit?.platform_code ? (
//         <div className="product-card">
//           <div className="card-section image-section">
//             <img src={it.blinkit.product_pic_url} alt={it.blinkit.pname} />
//           </div>
//           <div className="card-section details-section">
//             <div className="icons">
//               <BlinkitIcon />
//             </div>
//             <div className="product-info">
//               <div className="product-name">{it.blinkit.pname}</div>
//               <div className="product-sku">
//                 SKU: {it.blinkit.platform_code.length > 9
//                   ? `${it.blinkit.platform_code.slice(0, 9)}...`
//                   : it.blinkit.platform_code}
//               </div>
//             </div>
//           </div>
//         </div>
//       ) : (
//         <div className="empty-card">No Data</div>
//       )}
//     </div>

   
//     <div className="column">
//       {it.zepto?.platform_code ? (
//         <div className="product-card">
//           <div className="card-section image-section">
//             <img src={it.zepto.product_pic_url} alt={it.zepto.pname} />
//           </div>
//           <div className="card-section details-section">
//             <div className="icons">
//               <ZeptoIcon />
//             </div>
//             <div className="product-info">
//               <div className="product-name">{it.zepto.pname}</div>
//               <div className="product-sku">
//                 SKU: {it.zepto.platform_code.length > 9
//                   ? `${it.zepto.platform_code.slice(0, 9)}...`
//                   : it.zepto.platform_code}
//               </div>
//             </div>
//           </div>
//         </div>
//       ) : (
//         <div className="empty-card">No Data</div>
//       )}
//     </div>

   
//     <div className="column">
//       {it.instamart?.platform_code ? (
//         <div className="product-card">
//           <div className="card-section image-section">
//             <img src={it.instamart.product_pic_url} alt={it.instamart.pname} />
//           </div>
//           <div className="card-section details-section">
//             <div className="icons">
//               <InstamartIcon />
//             </div>
//             <div className="product-info">
//               <div className="product-name">{it.instamart.pname}</div>
//               <div className="product-sku">
//                 SKU: {it.instamart.platform_code.length > 9
//                   ? `${it.instamart.platform_code.slice(0, 9)}...`
//                   : it.instamart.platform_code}
//               </div>
//             </div>
//           </div>
//         </div>
//       ) : (
//         <div className="empty-card">No Data</div>
//       )}
//     </div>
//   </div>
// ))}