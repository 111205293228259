
export interface platformsState {
    id: string,
    name: string,
    checked: boolean
}

export const enum ActionEnum {
    NOCHANGES = 'NOCHANGES',
    EDIT = 'EDIT',
    APPROVAL = 'APPROVAL'
}