import { PrimeReactProvider } from 'primereact/api'
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import LoaderSkeletonTable from '../../../../utils/commonComponents/Loader/LoaderSkeletonTable'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../../pages/reportsStyle.scss';
import { TotalSalesColFilter, TotalSalesFilter } from '../../types/type';

interface Props {
    tableName: string,
    firstColumn: {
        header: string,
        field: string,
        checked: boolean
    }[],
    selectedName: string,
    setFs: Dispatch<SetStateAction<TotalSalesFilter>>,
    selectedColumns: string[] | never[],
    selectedColumnsName: string
    setFs_col: Dispatch<SetStateAction<TotalSalesColFilter>>
}

interface KPIselect{
    field:string,
    header:string,
    checked:boolean
}

const dataset = [
    {
        sub_brand: 'aristocrat',
        total_sales: 35879432,
        ad_sales: 457234,
        organic_sales: 58748,
        blended_acos: 34,
        total_orders: 4574,
        ad_orders: 3424,
        organic_orders: 1233,
        total_units: 23487,
        ad_units: 3424,
        organic_units: 1233,
        cancellation: 234,
        cancellation_value: 234,
        cancellation_percent: 30,
        return: 234,
        return_value: 124,
        return_percent: 20,
    },
    {
        sub_brand: 'skybags',
        total_sales: 35879432,
        ad_sales: 457234,
        organic_sales: 58748,
        blended_acos: 34,
        total_orders: 4574,
        ad_orders: 3424,
        organic_orders: 1233,
        total_units: 23487,
        ad_units: 3424,
        organic_units: 1233,
        cancellation: 234,
        cancellation_value: 234,
        cancellation_percent: 30,
        return: 234,
        return_value: 124,
        return_percent: 20,
    },
    {
        sub_brand: 'caprese',
        total_sales: 35879432,
        ad_sales: 457234,
        organic_sales: 58748,
        blended_acos: 34,
        total_orders: 4574,
        ad_orders: 3424,
        organic_orders: 1233,
        total_units: 23487,
        ad_units: 3424,
        organic_units: 1233,
        cancellation: 234,
        cancellation_value: 234,
        cancellation_percent: 30,
        return: 234,
        return_value: 124,
        return_percent: 20,
    },
]

const SalesTable: React.FC<Props> = (props) => {
    const { tableName, firstColumn, selectedName, setFs, selectedColumns, selectedColumnsName, setFs_col } = props;
    const dt = useRef<any | null>(null);
    const popupRef = useRef<any | null>(null);

    const [rowSelect, setRowSelect] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [kpiSelect, setKpiSelect] = useState<KPIselect[]>([
        { field: "total_sales", header: "Total Sales", checked: true },
        { field: "ad_sales", header: "Ad Sales", checked: true },
        { field: "organic_sales", header: "Organic Sales", checked: true },
        { field: "total_orders", header: "Total Orders", checked: true },
        { field: "total_units", header: "Total Units", checked: true },
        { field: "cancellation", header: "Cancellations", checked: true },
        { field: "return", header: "Returns", checked: true },
        { field: "blended_acos", header: "Blended ACoS", checked: false },
        { field: "ad_orders", header: "Ad Orders", checked: false },
        { field: "organic_orders", header: "Organic Orders", checked: false },
        { field: "ad_units", header: "Ad Units", checked: false },
        { field: "organic_units", header: "Organic Units", checked: false },
        { field: "cancellation_value", header: "Cancellation Value", checked: false },
        { field: "cancellation_percent", header: "Cancellation %", checked: false },
        { field: "return_value", header: "Return Value", checked: false },
        { field: "return_percent", header: "Return %", checked: false }
    ])

    const [kpiSelectToggle, setKpiSelectToggle] = useState<boolean>(false);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setKpiSelectToggle(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, []);

    const handleKpiChange = (e: ChangeEvent<HTMLInputElement>) => {
        let newArr = kpiSelect.map((el) => {
            if (el.field === e.target.name) {
                el.checked = !el.checked;
            }
            return el
        })
        setKpiSelect(newArr);
    }

    const handleColumnSelect = () => {
        setKpiSelectToggle(prev => !prev);
    }
    const exportToCSV = () => {
        dt.current.exportCSV();
    };

    const selectHeader = (performance_type: string, column: any) => {
        let arr = [...selectedColumns]
        if (!arr.includes(column.field)) {
            if (column.field !== performance_type) {
                arr.push(column.field)
            }
        } else {
            arr = arr.filter((el) => el !== column.field)
        }
        setFs_col(prevState => ({ ...prevState, [selectedColumnsName]: arr }));
    }

    const headerTemplate = (column: any) => {
        return (
            <div>
                <div onClick={() => selectHeader("performance_type", column)}>
                    {column?.header}
                </div >
            </div>
        )
    }

    // const getColumnClassName = (field: string) => {
    //     // console.log(field);        
    //     return selectedColumns?.includes(field) ? 'selected-column custom-header-class' : 'custom-header-class'
    // }

    const onRowSelect = (e: any) => {
        const arr = e.value.map((el: any) => {
            return el["performance_type"]
        })
        setFs(prevState => ({ ...prevState, [selectedName]: arr }));
        setRowSelect(e.value);
    }

    return (
        <div className='table_card_new'>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                <div className='column_select' ref={popupRef}>
                    <div className='column_select-tag' onClick={handleColumnSelect}>Select Columns <i style={{ fontSize: '12px' }} className="bi bi-chevron-down stroke"></i></div>
                    {kpiSelectToggle &&
                        <div className='column_select-box'>
                            {
                                kpiSelect.map((el) => {
                                    return (
                                        <div key={el.field} className="column_select-boxname" >
                                            <input onChange={handleKpiChange} type="checkbox" id={el.field} name={el.field} checked={el.checked}></input>&nbsp;&nbsp;&nbsp;
                                            <label className="" htmlFor={el.field}>{el.header}</label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                    <div>
                        <button className='table_btn' onClick={exportToCSV}><i className='bi bi-download'></i></button>
                    </div>
                </div>
                <div className='table_kpi_name'>{tableName}</div>
            </div>
            <PrimeReactProvider>
                {
                    isLoading ?
                        <LoaderSkeletonTable height='350px' /> :

                        <DataTable
                            value={dataset}
                            ref={dt}
                            size='small'
                            columnResizeMode="expand"
                            resizableColumns
                            selectionMode="multiple"
                            selection={rowSelect}
                            onSelectionChange={onRowSelect}
                            // frozenValue={totalRow}
                            // sortField={sortField} sortOrder={sortOrder}
                            sortIcon="bi bi-chevron-expand stroke"
                            // onSort={onSort}

                            // rowClassName={rowClassName}
                            scrollable scrollHeight="350px" virtualScrollerOptions={{ itemSize: 20 }}
                        // virtualScrollerOptions={{ lazy: true, delay: 200, showLoader: true, itemSize: 20, loadingTemplate }}

                        >
                            {[...firstColumn, ...kpiSelect]?.map((el, i) => {
                                if (el.checked) {
                                    return (
                                        <Column
                                            sortable
                                            key={i}
                                            header={headerTemplate(el)}
                                            // header={el?.header}
                                            field={el.field}
                                            // headerClassName={getColumnClassName(el?.field)}
                                            // bodyClassName={getColumnClassName(el.field)}
                                            style={{ width: '150px' }}
                                            headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                                        // bodyStyle={column?.bodyStyle}
                                        // body={column.body}
                                        />
                                    )
                                }
                            })}
                        </DataTable>

                }

            </PrimeReactProvider>
        </div>
    )
}

export default SalesTable