import React from 'react'

const Backgroundprofileupload = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M68.0808 26.2105V45.6L62.9192 41.1667C61.6588 40.1373 60.0815 39.575 58.4542 39.575C56.8268 39.575 55.2495 40.1373 53.9892 41.1667L40.8158 52.4717C39.5555 53.5011 37.9781 54.0634 36.3508 54.0634C34.7235 54.0634 33.1462 53.5011 31.8858 52.4717L30.8092 51.585C29.6471 50.6251 28.2074 50.0638 26.7023 49.984C25.1972 49.9042 23.7062 50.31 22.4492 51.1417L6.86915 61.5917L6.52081 61.845C5.28369 59.0116 4.67855 55.9427 4.74748 52.8517V26.315C4.74748 14.7883 11.6191 7.91667 23.1458 7.91667H49.787C49.3039 8.93511 49.0622 10.0513 49.0808 11.1783V20.4883C49.0009 21.3525 49.1122 22.2237 49.407 23.0399C49.7018 23.8561 50.1727 24.5974 50.7864 25.2111C51.4001 25.8248 52.1413 26.2957 52.9576 26.5905C53.7738 26.8853 54.645 26.9966 55.5092 26.9167H64.8192C65.9462 26.9353 67.0624 26.6936 68.0808 26.2105Z" fill="white"/>
               <path d="M68.0805 45.5998V52.8514C68.0805 64.3781 61.2088 71.2498 49.6822 71.2498H23.1455C15.0705 71.2498 9.24384 67.8615 6.52051 61.8448L6.86884 61.5914L22.4488 51.1414C23.7059 50.3098 25.1969 49.904 26.702 49.9838C28.2071 50.0636 29.6468 50.6248 30.8088 51.5848L31.8855 52.4714C33.1459 53.5008 34.7232 54.0631 36.3505 54.0631C37.9778 54.0631 39.5552 53.5008 40.8155 52.4714L53.9888 41.1664C55.2492 40.1371 56.8265 39.5748 58.4538 39.5748C60.0812 39.5748 61.6585 40.1371 62.9189 41.1664L68.0805 45.5998Z" fill="#06486A" fill-opacity="0.3"/>
               <path d="M34.4347 26.9164C34.4347 28.4039 33.9936 29.858 33.1672 31.0948C32.3408 32.3316 31.1662 33.2956 29.7919 33.8648C28.4177 34.434 26.9055 34.583 25.4466 34.2928C23.9877 34.0026 22.6476 33.2863 21.5958 32.2345C20.544 31.1827 19.8277 29.8426 19.5375 28.3837C19.2473 26.9248 19.3963 25.4126 19.9655 24.0383C20.5347 22.6641 21.4987 21.4895 22.7355 20.6631C23.9723 19.8367 25.4264 19.3956 26.9138 19.3956C28.908 19.3973 30.82 20.1902 32.23 21.6003C33.6401 23.0103 34.433 24.9223 34.4347 26.9164Z" fill="#06486A" fill-opacity="0.3"/>
               <path d="M64.8188 4.74978H55.5089C54.6447 4.66981 53.7735 4.78118 52.9573 5.07595C52.141 5.37073 51.3997 5.84169 50.7861 6.45535C50.1724 7.06901 49.7015 7.8103 49.4067 8.62655C49.1119 9.4428 49.0006 10.314 49.0805 11.1781V20.4881C49.0006 21.3523 49.1119 22.2234 49.4067 23.0397C49.7015 23.8559 50.1724 24.5972 50.7861 25.2109C51.3997 25.8245 52.141 26.2955 52.9573 26.5903C53.7735 26.885 54.6447 26.9964 55.5089 26.9164H64.8188C65.683 26.9964 66.5542 26.885 67.3704 26.5903C68.1867 26.2955 68.928 25.8245 69.5416 25.2109C70.1553 24.5972 70.6262 23.8559 70.921 23.0397C71.2158 22.2234 71.3271 21.3523 71.2472 20.4881V11.1781C71.3271 10.314 71.2158 9.4428 70.921 8.62655C70.6262 7.8103 70.1553 7.06901 69.5416 6.45535C68.928 5.84169 68.1867 5.37073 67.3704 5.07595C66.5542 4.78118 65.683 4.66981 64.8188 4.74978ZM67.7955 17.1948C67.4444 17.5333 66.984 17.7354 66.4972 17.7648H62.0322L62.0639 22.1664C62.0458 22.6808 61.8304 23.1685 61.4622 23.5281C61.116 23.8697 60.6502 24.0628 60.1639 24.0664C59.9143 24.0664 59.6673 24.0173 59.4367 23.9218C59.2062 23.8263 58.9968 23.6864 58.8203 23.5099C58.6439 23.3335 58.504 23.1241 58.4085 22.8935C58.313 22.663 58.2639 22.416 58.2639 22.1664V17.7331L53.8305 17.7648C53.3237 17.7565 52.8405 17.5493 52.4851 17.1879C52.1296 16.8266 51.9304 16.34 51.9305 15.8331C51.9305 15.3292 52.1307 14.8459 52.487 14.4896C52.8433 14.1333 53.3266 13.9331 53.8305 13.9331L58.2639 13.9648V9.53145C58.2639 9.02753 58.464 8.54426 58.8203 8.18794C59.1767 7.83162 59.6599 7.63145 60.1639 7.63145C60.6678 7.63145 61.151 7.83162 61.5074 8.18794C61.8637 8.54426 62.0639 9.02753 62.0639 9.53145L62.0322 13.9331H66.4972C67.0011 13.9331 67.4844 14.1333 67.8407 14.4896C68.197 14.8459 68.3972 15.3292 68.3972 15.8331C68.3646 16.344 68.1513 16.8267 67.7955 17.1948Z" fill="#06486A" fill-opacity="0.3"/>
            </svg>
  )
}

export default Backgroundprofileupload
