import { PrimeReactProvider } from "primereact/api"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import ProductKpiBox from "../productStatus/ProductKpiBox"
import { useRef } from "react"

const ReviewTable = () => {
    const dt = useRef<any | null>(null);
    let headerStyle: any = { color: '#545454', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold' }
    let bodyStyle: any = { color: '#545454', fontSize: '0.7rem', fontWeight: 500, textTransform: 'capitalize' }

    const exportToCSV = () => {
        dt.current.exportCSV();
    };
    return (
        <div className="table_card_new">
            <section style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                <div>
                    <button className='table_btn' onClick={exportToCSV}><i className='bi bi-download'></i></button>
                </div>
                <div >
                    <span className='table_kpi_name'>Reviews</span>
                </div>
            </section>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '0.5rem' }}>
                {/* <ProductKpiBox /> */}
            </div>
            <div>
                <PrimeReactProvider>
                    <DataTable
                        value={[]}
                        size='small'
                        columnResizeMode="expand"
                        resizableColumns
                        scrollable scrollHeight="350px"
                        virtualScrollerOptions={{ itemSize: 20 }}
                    // selectionMode="single"
                    // selection={selectedDate}
                    // onSelectionChange={(e) => setSelectedDate(e.value)}
                    >
                        <Column field='asin' header='ASIN'
                            headerStyle={headerStyle}
                            bodyStyle={bodyStyle}
                        />
                        <Column field='asin' header='Product Name'
                            headerStyle={headerStyle}
                            bodyStyle={bodyStyle}
                        />
                        <Column field='asin' header='Rating'
                            headerStyle={headerStyle}
                            bodyStyle={bodyStyle}
                        />
                        <Column field='asin' header='Recent3_ve'
                            headerStyle={headerStyle}
                            bodyStyle={bodyStyle}
                        />
                        <Column field='asin' header='Top3_ve'
                            headerStyle={headerStyle}
                            bodyStyle={bodyStyle}
                        />
                        <Column field='asin' header='Review'
                            headerStyle={headerStyle}
                            bodyStyle={bodyStyle}
                        />
                        <Column field='asin' header='L30 Days Sales'
                            headerStyle={headerStyle}
                            bodyStyle={bodyStyle}
                        />
                    </DataTable>
                </PrimeReactProvider>
            </div>
        </div>
    )
}

export default ReviewTable