import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

export default function LoaderSkeletonTable({ length='7', height = '18rem', width = '100%'}) {
  // Adjust the number of skeleton lines and height as needed
  const skeletonLines = Array.from({ length }, (_, index) => (
    <Skeleton key={index} height={40} animation="wave" />
  ));

  return (
    <Box sx={{ color: 'rgba(24, 24, 24, 1)', height, width }}>
      {skeletonLines}
    </Box>
  );
}

// import React from "react";
// import Box from "@mui/material/Box";
// import Skeleton from "@mui/material/Skeleton";

// export default function LoaderSkeletonTable({ height = '18rem', width = '100%' }) {
//   const skeletonLineHeight = 40; // Height of each skeleton line in pixels

//   // Function to convert height to pixels
//   const convertToPixels = (height) => {
//     if (typeof height === 'number') return height;
//     const value = parseFloat(height);
//     if (height.endsWith('rem')) return value * 16; // Assuming 1rem = 16px
//     if (height.endsWith('em')) return value * 16; // Assuming 1em = 16px
//     if (height.endsWith('px')) return value;
//     return value; // Fallback to the raw value if no unit or unsupported unit
//   };

//   const heightInPixels = convertToPixels(height);

//   // Calculate the number of skeleton lines
//   const numSkeletonLines = Math.ceil(heightInPixels / skeletonLineHeight);

//   // Create skeleton lines
//   const skeletonLines = Array.from({ length: numSkeletonLines }, (_, index) => (
//     <Skeleton key={index} height={skeletonLineHeight} animation="wave" />
//   ));

//   return (
//     <Box sx={{ color: 'rgba(24, 24, 24, 1)', height, width }}>
//       {skeletonLines}
//     </Box>
//   );
// }



