import React, { useEffect, useState } from 'react';
import './Loader.scss';

const Loader = ({ isLoading,currentStatus }:any) => {
  // const [currentStep, setCurrentStep] = useState(0);

  const statusMapping: { [key: string]: number } = {
    SQLPENDING: 1,
    SQLGENERATED: 2,
    REPORTPENDING: 3,
    REPORTSUCCEEDED: 4,
  };

  const currentStep = statusMapping[currentStatus] ;  

  const steps = [
    { name: 'Analyzing', status: 'success' },
    { name: 'Fetching', status: 'success' },
    { name: 'Validating', status: 'error' },
    { name: 'Success', status: 'pending' },
  ];

  // useEffect(() => {
  //   if (!isLoading) {
  //     const interval = setInterval(() => {
  //       setCurrentStep((prevStep) => (prevStep < steps.length ? prevStep + 1 : prevStep));
  //     }, 2000);

  //     return () => clearInterval(interval);
  //   } else {
  //     setCurrentStep(0); // Reset when not loading
  //   }
  // }, [isLoading]);


  // useEffect(() => {
  //   let interval: NodeJS.Timeout | null = null;

  //   if (!isLoading) {
  //     setCurrentStep(currentStep + 1);
  //     // const timer = setTimeout(() => {
  //     //   setShowContent(true); // Hide the loader after 2 seconds
  //     // }, 1000);
  //     // clearTimeout(timer);
  //   } else {
  //     // Progress through the steps only when `isLoading` is true
  //     setCurrentStep(0)
  //     interval = setInterval(() => {
  //       setCurrentStep((prevStep) => {
  //         // Do not move to the 'Success' step until all previous steps are completed
  //         if (prevStep < steps.length - 2) {
  //           return prevStep + 1;
  //         }
  //         clearInterval(interval!);
  //         return prevStep;
  //       });
  //     }, 2000);
  //   }

  //   return () => {
  //     if (interval) clearInterval(interval);
  //   };
  // }, [isLoading]);

  return (
    <div className="loader-container">
      {steps.map((step, index) => (
        <div key={index} className="step-container1">
          <div className={`circle1 ${currentStep > index ? 'completed' : currentStep === index ? 'active' : ''}`}>
            {currentStep > index ? <span className="tick"><i className="bi bi-check2"></i></span> : ''}
          </div>
          {index < steps.length - 1 && (<div className={`line1 ${currentStep > index ? 'line1-completed' : ''}`}
            // style={{
            //   animation: currentStep > index ? 'fillLine 3s forwards' : '',
            // }}
            ></div>
          )}
          <span className="step-name1">{step.name}</span>
        </div>
      ))}
    </div>
  );
};

export default Loader;
