import React, { useState, useRef, Dispatch, SetStateAction, ChangeEvent, } from 'react';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import '../pages/budgetStyle.scss';
import { ActionEnum } from '../../masters/types';

interface Props {
    data: any[],
    setData: Dispatch<SetStateAction<any[]>>,
    setAction: Dispatch<SetStateAction<any>>,
    // columnDefs: object[],
    // setColumnDefs: Dispatch<SetStateAction<any>>
    // platformColumns: any
}

const BudgetUpload: React.FC<Props> = (props) => {
    const fileInputRef = useRef<any>(null);
    const { data, setData, setAction } = props;
    const [file, setFile] = useState<any[] | null>([]);

    const resetUpload = (str: string) => {
        toast.error(str);
        setTimeout(() => {
            fileInputRef?.current?.form?.reset();
        }, 1000)
    }

    const successUpload = (headers: any, dataArr: any) => {
        const arrOfObjdata = dataArr.map((row: any) => {
            const dataObj: any = {};
            headers.forEach((header: any, index: any) => {
                dataObj[header] = row[index];
            });
            dataObj.target_sales = Number(((dataObj.target_spend / dataObj.target_acos) * 100).toFixed(0))
            return dataObj;
        });
        setFile(arrOfObjdata);
    }
    let fileName: any;
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        let extractedFile = e.target && e.target.files && e?.target?.files[0];
        // console.log(extractedFile?.name);
        fileName = extractedFile?.name;

        if (extractedFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const dataa = e.target && e.target.result;
                const workbook = XLSX.read(dataa, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];

                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                const dataArr = jsonData.slice(1);
                let headers: any = jsonData[0];

                const arrOfObjdata = dataArr.map((row: any) => {
                    const dataObj: any = {};
                    headers.forEach((header: any, index: any) => {
                        dataObj[header] = row[index];
                    });
                    return dataObj;
                });
                // setFile(arrOfObjdata)
                // console.log(headers, arrOfObjdata);
                const checkHeaders = ['category', 'target_spend', 'target_acos', 'target_sales', 'objective', 'exclude'];
                const checkAllElExist = checkHeaders.every(el => headers.includes(el));
                if (checkHeaders.length === 6 && checkAllElExist) {
                    successUpload(headers, dataArr);
                } else {
                    resetUpload("File doesn't contain the required headers and data")
                }
                const productMasterCat = data?.map((el: any) => el.category);
                // for (let el of arrOfObjdata) {
                //     if (!productMasterCat.includes(el.category)) {
                //         resetUpload("you can't add or delete any category");
                //     }
                // }
            }
            reader.readAsBinaryString(extractedFile);
        }
    }

    const updateUpload = () => {
        if (file) {
            setAction(ActionEnum.EDIT)
            let dataset = file.map((fileData) => {
                return {
                    category: fileData.category,
                    target_spend: fileData?.target_spend || 0,
                    target_sales: fileData?.target_sales || 0,
                    target_acos: fileData?.target_acos || 0,
                    objective: fileData?.objective || "ROI",
                    exclude: fileData?.exclude || "No"
                }
            })
            setData(dataset);
        }
        else {
            resetUpload("Something went wrong!! Unable to upload");
            fileInputRef.current.form.reset();
        }
    }

    // const handleUpload = () => {
    //     if (selectedDate.toLocaleDateString() === new Date(range[0]).toLocaleDateString()) {
    //         resetUpload('You can not upload previous month budget')
    //         return;
    //     }
    //     // else if (selectedDate.toLocaleDateString() === new Date(range[2]).toLocaleDateString()) {
    //     // if (selectedDate.getDate() >= 25) {
    //     //     updateUpload();
    //     // } else {
    //     //     resetUpload('You can upload the projection for next month after 25th.')
    //     //     return;
    //     // }
    //     // updateUpload();
    //     // } 
    //     updateUpload();

    // }
    return (
        <div className='upload_container'>
            <div className="file-input-container">
                <input ref={fileInputRef} type="file" id="fileInput" className="file-input" accept=".xlsx, .xls, .csv" onChange={handleFileChange} />
                <label htmlFor="fileInput" className="file-label">Choose File</label>
                <span id="fileName">{fileName ? fileName : 'No file choosen'}</span>
            </div>
            <Tippy content='Upload file'>
                <button type='button' className='uploadBtn'
                    onClick={updateUpload}
                >Upload
                </button>
            </Tippy>
        </div>
    )
}

export default BudgetUpload;