import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    // agency_array: [{ _id: '64c7b3276424529e5878855a', name: 'Enlytical', logo: 'https://i.ibb.co/yyf7C3Z/nuvoretail-188x55-px.png' },
    // { _id: '64c7b3276424529e5878855b', name: 'Enlytical2', logo: 'https://i.ibb.co/pv87B27/unibic.png' }
    // ],
    // brand_array: [
    //     { _id: '647db14248e85283c904e93e', client_profile_id: '2420385954020984', client_name: 'Aristocrat', main_category: 'Bags, Wallets and Luggage', country: 'INDIA', },
    //     { _id: '647db14248e85283c904e94c', client_profile_id: '4439285904250734', client_name: 'Bagrrys India Ltd', main_category: 'Grocery & Gourmet Foods', country: 'INDIA' },
    //     { hourly_bid_changes_edit_status: false, _id: '647db14248e85283c904e946', client_profile_id: '3604300076928928', client_name: 'Caprese', main_category: 'Bags, Wallets and Luggage|Shoes & Handbags', }

    // ],
    // current_agency: { name: "Enlytical", _id: "64c7b3276424529e5878855a", logo: 'https://i.ibb.co/yyf7C3Z/nuvoretail-188x55-px.png' },
    // current_brand: {
    //     automation_status: "no",
    //     client_name: "cocomama",
    //     client_profile_id: "4189802637819455",
    //     country: "INDIA",
    //     entity_id: "ENTITY3IJKSG2PQTHXF",
    //     hourly_bid_changes_edit_by_name: "system",
    //     hourly_bid_changes_edit_statu: false,
    //     hourly_paused_bid_changes_edit_status: false,
    //     main_category: "Grocery & Gourmet Foods",
    //     _id: "66169fe26599d2cd250c359c"
    // }
}

const clientSlice = createSlice({
    name: 'brands',
    initialState: initialState,
    reducers: {
        brandDetails: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        clearBranddetails: (state, action) => {
            return {

            }
        }
    }
})

export const { brandDetails, clearBranddetails } = clientSlice.actions

export default clientSlice.reducer