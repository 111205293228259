import { createSlice } from "@reduxjs/toolkit";

const initialState = JSON.parse(localStorage.getItem('user')!) || {}


const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        userDetails(state, action) {
            return {
                ...state,
                ...action.payload,
            }
        }
    }
});

export const { userDetails } = userSlice.actions;

export default userSlice.reducer;