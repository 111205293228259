import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL_NEW } from '../../../appConstant';


// `${BASE_URL_NEW}/marketing-stream/${client_id}/daily?startDate=${start_date}&endDate=${end_date}`)
export const marketingApi = createApi({
    reducerPath: 'marketingApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL_NEW }),
    endpoints: (builder) => ({
        getDatewise: builder.query({
            query: ({client_id, start_date, end_date }) => `/marketing-stream/${client_id}/daily?startDate=${start_date}&endDate=${end_date}`,
        }),
    }),
})

export const { useGetDatewiseQuery } = marketingApi;



