import { PrimeReactProvider } from 'primereact/api';
import React, { useEffect, useRef, useState, ChangeEvent } from 'react'
import LoaderSkeletonTable from '../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';


const MonitoringSystem: React.FC = () => {

    const [rowSelect, setRowSelect] = useState<any[]>([])
    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalRow, setTotalRow] = useState<any[]>([]);
    const [kpiSelectToggle, setKpiSelectToggle] = useState<boolean>(false);
    const popupRef = useRef<HTMLDivElement>(null);

    const bodyStyle = { color: '#000000', fontSize: '0.85rem' }

    // Include this custom body for the "Duration" column
    const durationBodyTemplate = (rowData: any) => getDuration(rowData);

    const [columns, setColumns] = useState<any[]>([
        { header: 'Platform', field: 'platform', checked: true },
        { header: 'Data Source', field: 'data_source', bodyStyle, checked: true },
        { header: 'ID', field: 'id', bodyStyle, checked: true },
        { header: 'Data Type', field: 'data_type', checked: true },
        { header: 'Resource Identifier', field: 'resource_identifier', checked: true },
        { header: 'Job Identifier', field: 'job_identifier', checked: true },
        { header: 'Client Name', field: 'client_name', checked: true },
        { header: 'Status', field: 'status', checked: true },
        { header: 'Duration', field: 'duration', body: durationBodyTemplate, checked: true },
        // { header: 'Trigger Start At', field: 'trigger_start_at',   checked: true },
        // { header: 'Trigger End At', field: 'trigger_end_at',   checked: true },
        // { header: 'Trigger Error', field: 'trigger_error',   checked: true },
        { header: 'Process Start At', field: 'process_start_at_ui', checked: true },
        { header: 'Process End At', field: 'process_end_at_ui', checked: true },
        { header: 'Process Error', field: 'process_error', checked: true },
        { header: 'Check Start At', field: 'checks_start_at_ui', checked: true },
        { header: 'Check End At', field: 'checks_end_at_ui', checked: true },
        { header: 'Check Error', field: 'checks_error', checked: true },

    ]);




    useEffect(() => {
        setIsLoading(true);
        axios.get('https://monitoring-microservice-dot-famous-archway-436113-a8.el.r.appspot.com/job')
            .then((res) => {
                res?.data?.forEach((row: any) => {
                    const timeConfig = {
                        timeZone: "Asia/Kolkata",
                        hour12: false // Ensures 24-hour format
                    }
                    if (row.process_start_at) row.process_start_at_ui = new Date(row.process_start_at).toLocaleString("en-IN", timeConfig)
                    if (row.process_end_at) row.process_end_at_ui = new Date(row.process_end_at).toLocaleString("en-IN", timeConfig)
                    if (row.checks_start_at) row.checks_start_at_ui = new Date(row.checks_start_at).toLocaleString("en-IN", timeConfig)
                    if (row.checks_end_at) row.checks_end_at_ui = new Date(row.checks_end_at).toLocaleString("en-IN", timeConfig)
                })
                const sortedData = res.data.sort((a: any, b: any) => {
                    if (a.platform < b.platform) return -1;
                    if (a.platform > b.platform) return 1;
                    return 0;
                });
                setData(sortedData);
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setIsLoading(false);
            })

    }, [])





    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setKpiSelectToggle(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, []);





    const handleKpiChange = (e: ChangeEvent<HTMLInputElement>) => {
        let newArr = columns.map((el) => {
            if (el.header === e.target.name) {
                // console.log('el', el.header, e.target.name)
                el.checked = !el.checked;
            }
            return el

        })
        setColumns(newArr);
    }

    const handleColumnSelect = () => {
        setKpiSelectToggle(prev => !prev);
    }

    // const exportToCSV = () => {
    //     let csvContent = '';
    //     const header = [...firstColumn, ...columns].map(col => col.field).join(',');
    //     csvContent += header + '\n';

    //     performanceData.forEach((row: any) => {
    //         const rowData = [...firstColumn, ...columns].map(col => row[col.field]).join(',');
    //         csvContent += rowData + '\n';
    //     });

    //     const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    //     saveAs(blob, 'table.csv');
    // };


    function getTimeDifference(startDate: any, endDate: any) {
        // Convert to timestamps
        const start = new Date(startDate).getTime();
        const end = new Date(endDate).getTime();

        // Calculate the difference in milliseconds
        const difference = end - start;

        // Convert to time units
        const days = Math.floor(difference / (1000 * 60 * 60 * 24)); // Total days
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); // Remaining hours
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)); // Remaining minutes
        const seconds = Math.floor((difference % (1000 * 60)) / 1000); // Remaining seconds

        return { days, hours, minutes, seconds };
    }


    const getDuration = (rowData: any) => {
        const { trigger_start_at, process_start_at, checks_end_at } = rowData;
        // Determine the start_time and end_time
        let start_time = trigger_start_at || process_start_at;
        const end_time = checks_end_at || Date.now()

        if (start_time === null) {
            return 'No Start time';
        }

        const difference = getTimeDifference(start_time, end_time);

        const ans = (difference?.days > 0 ? `${difference.days} days, ${difference.hours} : ${difference.minutes} : ${difference.seconds}` :
            `${difference.hours} : ${difference.minutes} : ${difference.seconds}`);
        return ans;

    };



    return (
        <div style={{ marginTop: '1rem' }} className=' table_card_new'>
            <div style={{ position: 'relative' }}>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                    <span className='table_kpi_name'>Monitoring System Table</span>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <div className='column_select' ref={popupRef} >
                            <div className='column_select-tag' onClick={handleColumnSelect}>Select Columns <i className="bi bi-chevron-down stroke"></i></div>
                            {kpiSelectToggle &&
                                <div className='column_select-box' style={{ marginTop: '2rem', zIndex: 9 }}>
                                    {
                                        columns.map((el) => {
                                            return (
                                                <div key={el.header} className="column_select-boxname" >
                                                    <input onChange={handleKpiChange} type="checkbox" id={el.header} name={el.header} checked={el.checked}></input>&nbsp;&nbsp;&nbsp;
                                                    <label className="" htmlFor={el.header}>{el.header}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                        <button className='table_btn1' >
                            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.1765 5.64706H9.41176V0H3.76471V5.64706H0L6.58824 12.2353L13.1765 5.64706ZM0 14.1176V16H13.1765V14.1176H0Z" fill="black" />
                            </svg>
                        </button>
                    </div>
                </div>

                <PrimeReactProvider>
                    {
                        isLoading ?
                            <LoaderSkeletonTable height='100vh' /> :

                            <DataTable
                                value={data}
                                size='small'
                                columnResizeMode="expand"
                                resizableColumns
                                selectionMode="multiple"
                                selection={rowSelect}
                                rowGroupMode="rowspan"
                                sortField='process_start_at'
                                // sortField=''

                                groupRowsBy="platform"
                                showGridlines
                                id='unique-table'
                                scrollable scrollHeight="100vh"

                            >
                                {columns.filter((column) => column.checked).map((col, index) => (
                                    <Column
                                        key={index}
                                        field={col.field}
                                        sortable
                                        header={col.header}
                                        style={{ width: '150px' }}
                                        headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                                        bodyStyle={{ color: '#000000', fontSize: '0.85rem', textTransform: 'capitalize' }}
                                        body={col.body}

                                    />
                                ))}
                            </DataTable>

                    }

                </PrimeReactProvider>
            </div>
        </div>
    )

}

export default MonitoringSystem
