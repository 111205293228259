import { useRef, useState } from "react"
import ReviewTable from "../components/retailReadiness/ReviewTable"
import { useAppSelector } from "../../../redux/hook";
import SolidRingChart from "../components/retailReadiness/Graph"
import TabComponent from "../components/retailReadiness/Tab";
import NavbarTabs from "../components/retailReadiness/Tab";
import StaticDataTable from "../components/retailReadiness/Table";




const data = [
  { asin: 'B0C94G1CX3', category: 'Suitcase-HL', productName: 'Tooper Set of 3', authorization: 'Authorized', seller: 'E.V.A.N', l30DaySales: 9876500 },
  { asin: 'B002', category: 'Books', productName: 'Product 2', authorization: 'Unauthorized', seller: 'casual Backpack', l30DaySales: 354800 },
  { asin: 'B0C94G1CX3', category: 'Suitcase-HL', productName: 'Tooper Set of 3', authorization: 'Authorized', seller: 'E.V.A.N', l30DaySales: 9876500 },
  { asin: 'B002', category: 'Books', productName: 'Product 2', authorization: 'Unauthorized', seller: 'casual Backpack', l30DaySales: 354800 },
  { asin: 'B0C94G1CX3', category: 'Suitcase-HL', productName: 'Tooper Set of 3', authorization: 'Authorized', seller: 'E.V.A.N', l30DaySales: 9876500 },
  { asin: 'B002', category: 'Books', productName: 'Product 2', authorization: 'Unauthorized', seller: 'casual Backpack', l30DaySales: 354800 },
  { asin: 'B0C94G1CX3', category: 'Suitcase-HL', productName: 'Tooper Set of 3', authorization: 'Authorized', seller: 'E.V.A.N', l30DaySales: 9876500 },
  { asin: 'B002', category: 'Books', productName: 'Product 2', authorization: 'Unauthorized', seller: 'casual Backpack', l30DaySales: 354800 },
  { asin: 'B0C94G1CX3', category: 'Suitcase-HL', productName: 'Tooper Set of 3', authorization: 'Authorized', seller: 'E.V.A.N', l30DaySales: 9876500 },
  { asin: 'B002', category: 'Books', productName: 'Product 2', authorization: 'Unauthorized', seller: 'casual Backpack', l30DaySales: 354800 },
  // Add more rows as needed
];

const RetailReadiness = () => {
  const client = useAppSelector(state => state.client);
  const { current_brand: { client_id } } = client;

  const popupRef1 = useRef<any | null>(null);
  const popupRef2 = useRef<any | null>(null);
  const [toggle1, setToggle1] = useState<boolean>(false);
  const [toggle2, setToggle2] = useState<boolean>(false);

  const categories = ['suitcase', 'bag', 'cream', 'sampoo', 'hairoil']

  const handleCatChange = (el: any) => {
    console.log();

  }


  const tabs = [
    {
        label: 'Unavailable',
        content: <div>Content for Unavailable</div>,
    },
    {
        label: 'Buy Box',
        content: <div>Content for Buy Box</div>,
    },
    {
        label: 'Delivery Time',
        content: <div>Content for Delivery Time</div>,
    },
    {
        label: 'Range Wise',
        content: <div>Content for Range Wise</div>,
    },
];




  return (
    <main style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <section style={{ display: 'flex', justifyContent: 'end', gap: '1rem' }}>
        <div ref={popupRef1} onClick={() => setToggle1((prev) => !prev)} className='custom-select-container3'>
          <span className='custom-select3'>Top Sale Product</span>
          <span className='arrow3'><i className='bi bi-chevron-down'></i></span>
          {toggle1 &&
            <div className='box_3'>
              {categories?.map((el, i) => {
                return (
                  <div key={i} onClick={() => handleCatChange(el)}>
                    <span>{el}</span>
                  </div>
                )
              })}
            </div>
          }
        </div>

        <div ref={popupRef2} onClick={() => setToggle2((prev) => !prev)} className='custom-select-container3'>
          <span className='custom-select3'>Category</span>
          <span className='arrow3'><i className='bi bi-chevron-down'></i></span>
          {toggle2 &&
            <div className='box_3'>
              {categories?.map((el, i) => {
                return (
                  <div key={i} onClick={() => handleCatChange(el)}>
                    <span>{el}</span>
                  </div>
                )
              })}
            </div>
          }
        </div>
      </section>
      <section>
        <ReviewTable />
      </section>
      <section style={{backgroundColor:'#fff'}}>
        <TabComponent tabs={tabs}/>
       <section style={{ display: 'flex', gap: '0.5rem', backgroundColor:'#fff' }}>
                <div style={{ width: '30%' }}>
                    <SolidRingChart
                data={[77, 27]}
                labels={['Authorized Seller', 'Unauthorized Seller']}
                chartTitle='Custom Comparison of Values'
            />
                </div>
                <div style={{ width: '70%'  }}>
                    <StaticDataTable isLoading={false} data={data} />
                </div>
            </section>

      </section>
    </main>
  )
}

export default RetailReadiness