import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { KPI } from '../types/type'
import { formatedMetric, formatedMetricSymbol } from '../../../utils/commonFunction'

interface Props {
  bg: string,
  selectedKpi: KPI | any
  selected4KpiArr: KPI[] | any[]
  setSelected4KpiArr: Dispatch<SetStateAction<KPI[]>> | Dispatch<SetStateAction<any[]>>
  dataArr: KPI[] | any[]
}

const Card: React.FC<Props> = (props) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const { bg, selectedKpi, selected4KpiArr, setSelected4KpiArr, dataArr } = props;

  const [toggle, setToggle] = useState<boolean>(false);
  const [kpi, setKpi] = useState<KPI>(selectedKpi)
  // console.log(kpi);


  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setToggle(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, []);

  const handleKPI = (el: KPI) => {
    setKpi(el);
    setToggle(prev => !prev)
    setSelected4KpiArr(selected4KpiArr.map((ele, i) => ele?.metric !== kpi?.metric ? ele : el))
  }
  const filteredKpiArr = dataArr.filter((el) => !selected4KpiArr.includes(el));

  return (
    <div style={{ border: `2px solid ${bg}` }} className='kpi_card'>
      {/* <div style={{ backgroundColor: bg, borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem', height: '0.5rem' }}></div> */}
      <div ref={popupRef} className='kpi_card-name'>
        <div onClick={() => setToggle(prev => !prev)} className='kpi_card-namehover'>
          <span>{formatedMetric(kpi?.metric!)}</span>
          <span className='kpi_card-icon'><i className='bi bi-chevron-down stroke'></i></span>
        </div>
        {
          toggle &&
          <div className='kpi_card-select'>
            {filteredKpiArr.map((kpi) => {
              return (
                <div className='kpi_card-selectEl' onClick={() => handleKPI(kpi)} key={kpi?.metric}>{formatedMetric(kpi?.metric!)}</div>
              )
            })}
          </div>
        }
      </div>
      <div className='kpi_card-value'>
        <span className='kpi_card-v'>{formatedMetricSymbol(kpi?.metric!, kpi?.value?.toLocaleString('en-IN')!)}</span>
        {kpi?.percentageChange &&
          <span className={kpi?.growthIndicator === 'positive' ? 'kpi_card-p kpi_card-growth' : 'kpi_card-p kpi_card-decline'}>{kpi?.percentageChange?.toFixed(2)}<i className={kpi?.growthIndicator === 'positive' ? "bi bi-arrow-up-short" : "bi bi-arrow-down-short"}></i></span>
        }
      </div>
    </div>
  )
}

export default Card