import axios from "axios"
import { useEffect, useState } from "react"
import { BASE_URL_NEW } from "../../../appConstant"
import { useAppSelector } from "../../../redux/hook"
import { useNavigate } from "react-router-dom"
import './reportsStyle.scss';
import Loader from "../../../utils/commonComponents/Loader/Loader"

const PowerBiReports = () => {
    const client = useAppSelector(state => state.client)
    const { current_brand } = client;
    const navigate = useNavigate()

    const [list, setList] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null)

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${BASE_URL_NEW}/powerbi/reportsList/${current_brand?.client_id}`)
            .then((res) => {
                if (res.data.length === 0) {
                    setError('No dashboard list present for this brand!!')
                } else {
                    setList(res.data);
                    setError(null)
                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setIsLoading(false);
            })
    }, [current_brand?.client_id])


    const handleReport = (id: any) => {
        // console.log(id);
        navigate(`/reports/powerbi/${id}`)
    }


    if (isLoading) {
        return (
            <><Loader /></>
        )
    }
    if (error) {
        return (
            <h1 className="powerbiError">{error}</h1>
        )
    }
    return (
        <div className="powerbiContainer">
            {
                list?.map((report) => {
                    return (
                        <div key={report?._id} className="powerbiCard">
                            <div>
                                <p><b>Brand Name:</b> {report?.brand_name}</p>
                                <p><b>Dashboard:</b> {report?.dashboard_name}</p>
                            </div>
                            <div>
                                <p className="createdTime"><b>created at:</b> {new Date(report?.created_at).toLocaleString('en-GB', { hour12: true })}</p>
                                <button onClick={() => handleReport(report?._id)}>View Dashboard <i className="bi bi-box-arrow-in-up-right"></i></button>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default PowerBiReports;