import { PrimeReactProvider } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import Loader from '../../../utils/commonComponents/Loader/Loader';
import BudgetUpload from '../components/BudgetUpload';
import Tippy from '@tippyjs/react';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { saveAs } from 'file-saver';
import { InputText } from 'primereact/inputtext';
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import CustomSelect2 from '../../../utils/commonComponents/customSelect2/CustomSelect2';
import { getMontheInText } from '../../../utils/commonFunction';
import axios from 'axios';
import { BASE_URL_NEW } from '../../../appConstant';
import { useAppSelector } from '../../../redux/hook';
import { toast } from 'react-toastify';
import { ActionEnum } from '../../masters/types';
import { AccessRoleEnum, AccessTypeEnum, ModuleEnum } from '../../../utils/types.enum';
import './budgetStyle.scss';
import InfoIcon from '../../../assets/svgs/InfoIcon';
import LoaderSkeletonTable from '../../../utils/commonComponents/Loader/LoaderSkeletonTable';


const MonthlyBudgets: React.FC = () => {
    const client = useAppSelector(state => state.client);
    console.log(client);
    
    const { current_brand: { client_id, access_role, access_type, modules } } = client;
    const gridRef = useRef<any | null>(null);
    const [data, setData] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [range, setRange] = useState<any[]>(getThreeMonthRange());
    const [selectedDate, setSelectedDate] = useState<any>(range[1]);
    const [budgetType, setBudgetType] = useState<string>('Category');
    const [budgetTypeArr, setBudgetTypeArr] = useState<string[]>(['Category', 'AD Type', 'Keyword Type']);
    const [forceRerender, setForceRerender] = useState("")
    const [objective, setObjective] = useState('');

    const [action, setAction] = useState<ActionEnum>(ActionEnum.NOCHANGES)

    const canApproveReject:boolean = (
        access_type==AccessTypeEnum.AGENCY ||
        (
            access_type==AccessTypeEnum.BRAND && access_role==AccessRoleEnum.BRAND_MANAGER && modules.find((module)=>module===ModuleEnum.BUDGET)?true:false
        )
    )

    const canEdit: boolean = (
        (
            access_type==AccessTypeEnum.AGENCY || (access_role==AccessRoleEnum.BRAND_MANAGER && modules.find((module)=>module===ModuleEnum.BUDGET))?true:false
        )
    )

    function getThreeMonthRange() {
        let current = new Date();
        let prevMonth = new Date(current);
        let nextMonth = new Date(current);
        prevMonth.setDate(1);
        nextMonth.setDate(1);

        prevMonth.setMonth(prevMonth.getMonth() - 1);
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        return [prevMonth, current, nextMonth];
    }

    const columns = [
        { header: 'CATEGORY', field: 'category', width: 150 },
        { header: 'TARGET_SPEND', field: 'target_spend', width: 150 },
        { header: 'TARGET_ACOS', field: 'target_acos', width: 150 },
        { header: 'TARGET_SALES', field: 'target_sales', width: 150 },
        { header: 'OBJECTIVE', field: 'objective', width: 150 },
        { header: 'EXCLUDE', field: 'exclude', width: 150 },

    ]

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${BASE_URL_NEW}/budget/${client_id}?month=${selectedDate.getMonth() + 1}&year=${selectedDate.getFullYear()}`)
            .then((res) => {
                res.data.forEach((dt: any) => {
                    dt.target_spend = Math.round(dt.target_spend * 100) / 100
                    dt.target_acos = Math.round(dt.target_acos * 100) / 100
                    dt.target_sales = Math.round(dt.target_sales * 100) / 100
                })
                setData(res.data || []);
                if (res.data.length && res.data[0].status === 'DRAFT') { //and u have access for this client
                    setAction(ActionEnum.APPROVAL)
                } else {
                    setAction(ActionEnum.NOCHANGES)
                }
            }).catch((error) => {
                toast.error(error?.response?.data?.error || 'Unable to get budgets')
            }).finally(() => {
                setIsLoading(false);
            })

    }, [client_id, selectedDate, forceRerender])

    const onMonthChange = (e: ChangeEvent<HTMLSelectElement>) => {
        let { target: { value } } = e;
        let newDate;
        if (new Date(value).toLocaleDateString() === new Date(range[0]).toLocaleDateString()) {
            newDate = range[0];
        } else if (new Date(value).toLocaleDateString() === new Date(range[2]).toLocaleDateString()) {
            newDate = range[2];
        } else if (new Date(value).toLocaleDateString() === new Date(range[1]).toLocaleDateString()) {
            newDate = range[1];
        }
        setSelectedDate(newDate)
    }

    const exportToCSV = () => {
        let csvContent = '';
        const header = columns.map(col => col.field).join(',');
        csvContent += header + '\n';

        data.forEach((row: any) => {
            const rowData = columns.map(col => row[col.field]).join(',');
            csvContent += rowData + '\n';
        });

        // If there's no data, ensure the headers are still there
        // if (data.length === 0) {
        //     csvContent += header + '\n';
        // }

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'table.csv');
    };


    const onCellEditComplete = (e: any) => {
        if(!(canEdit || canApproveReject)) return
        setAction(ActionEnum.EDIT)
        const { rowData, newValue, field } = e;
        let val;
        if (field === 'target_spend' || field === 'target_acos') {
            val = parseFloat(newValue);
        } else {
            val = newValue
        }

        rowData[field] = val

        const target_spend = parseFloat(rowData['target_spend']) || 0;
        const target_acos = parseFloat(rowData['target_acos']) || 0;
        const target_sales = Number(target_acos) && Number(target_spend) ? Number(((target_spend / target_acos) * 100).toFixed(0)) : 0;
        rowData['target_sales'] = target_sales;
        setData((prev) => [...prev]);
    }

    const cellEditor = (options: ColumnEditorOptions) => {
        if (options.field === 'target_spend' || options.field === 'target_acos') return numEditor(options);
        // else if (options.field === 'category') return;
        else if (options.field === 'objective' || options.field === 'exclude') return selectEditor(options);
        else return textEditor(options);
    };

    const objectiveArr = [
        { label: 'ROI', value: 'ROI' },
        { label: 'Visibility', value: 'Visibility' },
        { label: 'Growth', value: 'Growth' },
    ];
    const excludeArr = [
        { label: 'No', value: 'No' },
        { label: 'Yes', value: 'Yes' },
    ];

    const selectEditor = (options: ColumnEditorOptions) => {

        // return (
        //     <Dropdown
        //         value={options.value}
        //         options={options.field === "objective" ? objectiveArr : excludeArr}
        //         onChange={(e) => options.editorCallback?.(e.target.value)}
        //         // className="custom-dropdown"
        //         style={{ fontSize: '0.8rem', height: '20px',padding:'0' }}
        //     />
        // )
        return (
            <div>
                {options.field === "objective" ?
                    <select className='custom-select5' name="" id="" value={options.value} onChange={(e) => options.editorCallback?.(e.target.value)}>
                        {
                            objectiveArr.map((el: any) => {
                                return (
                                    <option key={el.value} value={el.value}>{el.label}</option>
                                )
                            })
                        }
                    </select> :
                    <select className='custom-select5' name="" id="" value={options.value} onChange={(e) => options.editorCallback?.(e.target.value)}>
                        {
                            excludeArr.map((el: any) => {
                                return (
                                    <option key={el.value} value={el.value}>{el.label}</option>
                                )
                            })
                        }
                    </select>
                }

            </div>

        )
    };

    const textEditor = (options: ColumnEditorOptions) => {
        return <input type="text" className='custom-read-input' value={options.value} readOnly disabled onKeyDown={(e) => e.stopPropagation()} />;
    };

    const numEditor = (options: ColumnEditorOptions) => {
        return <input type="number" className='custom-input' value={options.value} onChange={(e: any) => options.editorCallback?.(e.target.value)} onKeyDown={(e) => e.stopPropagation()} />;
    };

    const tippyContent = () => {
        return (
            <div>
                <h6>Instructions</h6>
                <div>
                    <ul style={{ color: '#b8b6b6', fontFamily:'Inter', fontSize: "12px", margin: 0 }}>
                        <li>Please choose the .xlsx, .xls and .csv file. Please upload excel with exact headers and their position.</li>
                        <li>Please click on any cell to edit in-line.</li>
                        <li>Please confirm with all the details, then submit the excel file.</li>
                        <li>Please do not put any symbol(ex. ₹, %) in excel file.</li>
                        <li>If your ACOS is 42%, please write 42 only don't write 0.42 .</li>
                    </ul>
                </div>
            </div>
        )
    }


    const handlSubmitBudgets = (status: string) => {
        setIsLoading(true)
        const body = {
            month: selectedDate.getMonth() + 1,
            year: selectedDate.getFullYear(),
            budgets: data.map((dt) => {
                const { category, target_spend, target_sales, target_acos, objective, exclude } = dt
                return {
                    category, target_spend, target_sales, target_acos, objective, exclude, status
                }
            })
        }
        setIsLoading(true);
        axios.patch(`${BASE_URL_NEW}/budget/${client_id}`,
            body
        )
            .then((res) => {
                // setData(res.data.budgets);
                setIsLoading(false)
                setSelectedDate((prev: any) => new Date(prev))
                toast.success("Budgets successfully updated")
            }).catch((error) => {
                toast.error(error?.response?.data?.error || 'Unable to update budgets')
                setIsLoading(false)
            })
    }


    return (
        <PrimeReactProvider>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                        {/* <CustomSelect2
                            value={budgetType}
                            onchange={(e: any) => setBudgetType(e.target.value)}
                            className={''}
                            id={''}
                        >
                            {budgetTypeArr.map((el) => {
                                return (
                                    <option key={el} value={el}>{el}</option>
                                )
                            })}
                        </CustomSelect2> */}
                        <CustomSelect2
                            value={selectedDate}
                            onchange={onMonthChange}
                            id={''}
                        >
                            {range.map((el) => {
                                return (
                                    <option key={el} value={el}>{getMontheInText(el.getMonth() + 1) + '-' + el.getFullYear()}</option>
                                )
                            })}
                        </CustomSelect2>
                    </div>
                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <BudgetUpload
                            data={data}
                            setData={setData}
                            setAction={setAction}
                        />
                        <Tippy content='Download *.csv file'>
                            <button onClick={exportToCSV} className='downloadBtn'>Download</button>
                        </Tippy>
                    </div>
                </div>

                <div style={{ display: 'flex', gap: '0.5rem' }}>
                    {/* <span><i className="bi bi-check2-circle"></i>&nbsp;This is current month budget. You can update and submit it.</span> */}
                    {selectedDate.toLocaleDateString() === new Date(range[0]).toLocaleDateString() &&
                        <div className="notification_bar_red">
                            <span><i className="bi bi-exclamation-circle"></i>&nbsp;This is previous month budget.This is for view only.You can not update it.</span>
                        </div>
                    }
                    {selectedDate.toLocaleDateString() === new Date(range[1]).toLocaleDateString() &&
                        <div className="notification_bar_green">
                            <span><i className="bi bi-check2-circle"></i>&nbsp;This is current month budget.You can update and submit it.</span>
                        </div>
                    }
                    {selectedDate.toLocaleDateString() === new Date(range[2]).toLocaleDateString() &&
                        <div className="notification_bar_green">
                            <span><i className="bi bi-check2-circle"></i>&nbsp;This is upcoming month budget.You can update and submit it.</span>
                        </div>
                    }
                    <Tippy content={tippyContent()} placement='right-end' theme='custom-theme' arrow={false}>
                        <span><InfoIcon /></span>
                        {/* <span><InfoIcon sx={{ stroke: 'rgb(176, 186, 191)', color: '#fff', fontSize: '2rem' }} /></span> */}
                    </Tippy>
                </div>


                <div className='table_card_new'>
                {isLoading?<LoaderSkeletonTable height='58vh'/>:
                    <DataTable
                        value={data}
                        ref={gridRef}
                        size='normal'
                        resizableColumns
                        showGridlines
                        removableSort
                        scrollable
                        scrollHeight="60vh"
                        sortIcon
                        editMode=""
                    >
                        {columns.map((col, i) => (
                            <Column key={i}
                                field={col.field}
                                header={col.field}
                                sortable

                                style={{ width: col.width }}
                                // bodyClassName='custom-cell'
                                headerStyle={{ color: 'rgba(24, 24, 24, 1)', backgroundColor: 'rgba(245, 245, 247, 1)', fontSize: '0.75rem', fontWeight: 'bold' }}
                                bodyStyle={{ color: 'rgba(24, 24, 24, 1)', fontSize: '0.8rem' }}
                                editor={(options) => cellEditor(options)}
                                onCellEditComplete={onCellEditComplete}
                            />
                        ))}
                    </DataTable>
                    }
                </div>

                <div style={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end', fontSize: '0.85rem' }}>
                    <button type='button' className='cancel-btn' onClick={() => { setForceRerender('re') }}>Cancel</button>

                    {action === ActionEnum.EDIT && <button type='button' disabled={!canEdit} className='submit-btn' onClick={() => handlSubmitBudgets("DRAFT")}>Submit</button>}
                    {action == ActionEnum.APPROVAL && <>
                        <button type='button' disabled={!canApproveReject} className='submit-btn' onClick={() => handlSubmitBudgets('REJECTED')}>Reject</button>
                        <button type='button' disabled={!canApproveReject} className='submit-btn' onClick={() => handlSubmitBudgets('APPROVED')}>Approve</button>
                    </>}
                </div>
            </div>
        </PrimeReactProvider>
    )
}

export default MonthlyBudgets;