import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

export default function LoaderSkeletonOnboarding({ length='7', height = '18rem', width = '100%'}) {

  return (
    <div  className="brand-onboarding-container" style={{height:"100vh"}} >
    
    <Box sx={{ color: 'rgba(24, 24, 24, 1)', width, height }}>
    <Skeleton variant="circular" width={80} height={80} style={{marginBottom:'2rem' , marginTop:'1rem'}}/>
    <Skeleton animation="wave" />
    <Skeleton animation="wave" width='85%'/>
    <Skeleton animation="wave" width='50%'/>
    <Skeleton animation="wave" width='35%'/>
   

    <Skeleton animation="wave" style={{  marginTop:'2rem'}}/>
    <Skeleton animation="wave" width='80%'/>
    <Skeleton animation="wave" width='50%'/>
    
    <div style={{ marginTop:'2rem',marginBottom:'2rem',display:'flex',gap:'1rem'}}>
    <Skeleton animation="wave" variant="rectangular"  width={140} height={50} />
    <Skeleton animation="wave" variant="rectangular"  width={140} height={50} />
    <Skeleton animation="wave" variant="rectangular"  width={140} height={50} />
    </div>
    <Skeleton animation="wave" width='80%'/>
    <Skeleton animation="wave" width='50%'/>

    </Box>
   
    </div>
  );
}