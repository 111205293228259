import React from 'react'

const AchivementsIcons = (props) => {
  return (
    <>
      {
        props.tab === 'achievements' ?
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.55556 16V14.2222H7.11111V11.4667C6.38519 11.3037 5.73704 10.9963 5.16667 10.5444C4.5963 10.0926 4.17778 9.52593 3.91111 8.84444C2.8 8.71111 1.87037 8.22593 1.12222 7.38889C0.374074 6.55185 0 5.57037 0 4.44444V3.55556C0 3.06667 0.174074 2.64815 0.522222 2.3C0.87037 1.95185 1.28889 1.77778 1.77778 1.77778H3.55556V0H12.4444V1.77778H14.2222C14.7111 1.77778 15.1296 1.95185 15.4778 2.3C15.8259 2.64815 16 3.06667 16 3.55556V4.44444C16 5.57037 15.6259 6.55185 14.8778 7.38889C14.1296 8.22593 13.2 8.71111 12.0889 8.84444C11.8222 9.52593 11.4037 10.0926 10.8333 10.5444C10.263 10.9963 9.61481 11.3037 8.88889 11.4667V14.2222H12.4444V16H3.55556ZM3.55556 6.93333V3.55556H1.77778V4.44444C1.77778 5.00741 1.94074 5.51481 2.26667 5.96667C2.59259 6.41852 3.02222 6.74074 3.55556 6.93333ZM8 9.77778C8.74074 9.77778 9.37037 9.51852 9.88889 9C10.4074 8.48148 10.6667 7.85185 10.6667 7.11111V1.77778H5.33333V7.11111C5.33333 7.85185 5.59259 8.48148 6.11111 9C6.62963 9.51852 7.25926 9.77778 8 9.77778ZM12.4444 6.93333C12.9778 6.74074 13.4074 6.41852 13.7333 5.96667C14.0593 5.51481 14.2222 5.00741 14.2222 4.44444V3.55556H12.4444V6.93333Z" fill="#06486A" />
          </svg> : <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.55556 16V14.2222H7.11111V11.4667C6.38519 11.3037 5.73704 10.9963 5.16667 10.5444C4.5963 10.0926 4.17778 9.52593 3.91111 8.84444C2.8 8.71111 1.87037 8.22593 1.12222 7.38889C0.374074 6.55185 0 5.57037 0 4.44444V3.55556C0 3.06667 0.174074 2.64815 0.522222 2.3C0.87037 1.95185 1.28889 1.77778 1.77778 1.77778H3.55556V0H12.4444V1.77778H14.2222C14.7111 1.77778 15.1296 1.95185 15.4778 2.3C15.8259 2.64815 16 3.06667 16 3.55556V4.44444C16 5.57037 15.6259 6.55185 14.8778 7.38889C14.1296 8.22593 13.2 8.71111 12.0889 8.84444C11.8222 9.52593 11.4037 10.0926 10.8333 10.5444C10.263 10.9963 9.61481 11.3037 8.88889 11.4667V14.2222H12.4444V16H3.55556ZM3.55556 6.93333V3.55556H1.77778V4.44444C1.77778 5.00741 1.94074 5.51481 2.26667 5.96667C2.59259 6.41852 3.02222 6.74074 3.55556 6.93333ZM8 9.77778C8.74074 9.77778 9.37037 9.51852 9.88889 9C10.4074 8.48148 10.6667 7.85185 10.6667 7.11111V1.77778H5.33333V7.11111C5.33333 7.85185 5.59259 8.48148 6.11111 9C6.62963 9.51852 7.25926 9.77778 8 9.77778ZM12.4444 6.93333C12.9778 6.74074 13.4074 6.41852 13.7333 5.96667C14.0593 5.51481 14.2222 5.00741 14.2222 4.44444V3.55556H12.4444V6.93333Z" fill="#5B6871" />
          </svg>
      }
    </>
  )
}

export default AchivementsIcons