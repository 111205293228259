import { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Avatar } from '@mui/material';
import axios from 'axios';
import { BASE_URL_NEW, USER_ROLES } from '../../../appConstant';
import { getUserColor } from '../../../utils/commonFunction';

const DrawerComponent = (props) => {
    const { open, handleclose, selectedRowId } = props;
    // console.log(selectedRowId);
    const [tabs, setTabs] = useState('accountDetails');
    const [userDetails, setUserDetails] = useState({});

    const fetchDrawerData = () => {
        if (!selectedRowId) return;
        axios.get(`${BASE_URL_NEW}/user/userDetails/${selectedRowId?._id}`)
            .then((res) => {
                // console.log(res.data);
                const user = res.data
                if (!user.last_active_on) user.status = "NA"
                else if (Date.now() - user.last_active_on < 60000 * 2) user.status = 'ACTIVE'
                else if (Date.now() - user.last_active_on < 60000 * 60) user.status = Math.round((Date.now() - user.last_active_on) / 60000) + " min"
                else if (Date.now() - user.last_active_on < 60000 * 60 * 24) user.status = Math.round((Date.now() - user.last_active_on) / (60000 * 60)) + " hr"
                else user.status = Math.round((Date.now() - user.last_active_on) / (60000 * 60 * 24)) + " days"

                setUserDetails(res.data);
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                // setIsLoading(false)
            })
    }

    useEffect(() => {
        fetchDrawerData();
    }, [selectedRowId?._id])
    return (
        <div className='drawerMain'>
            <Drawer anchor="right" open={open} onClose={handleclose}>
                <Box sx={{ width: '30rem', fontFamily: 'Poppins,sans-serif' }} role="presentation" className='drawer'>
                    <div>
                        <div className='userProfileFirstBox'>
                            <Avatar sx={{
                                height: '5rem', width: '5rem',
                                backgroundColor: `${getUserColor(userDetails?.access_roles?.length && userDetails?.access_roles[0])}`
                            }}>
                                <span style={{ fontSize: '1.75rem', color: '#ffffff' }}>{userDetails?.first_name?.split(" ")[0][0]?.toUpperCase() + userDetails?.last_name?.split(" ")[0][0]?.toUpperCase()}</span>
                            </Avatar>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', gap: '3rem' }}>
                                    <div style={{ color: '#384D6C', fontSize: '0.9rem', fontWeight: 'bold', textTransform: 'capitalize' }}>
                                        {userDetails?.first_name + ' ' + userDetails?.last_name}</div>
                                    <div>
                                        {userDetails?.status === 'ACTIVE' ? <span className='userStatus1'>{userDetails?.status}</span> : <span>{userDetails?.status} ago</span>}
                                    </div>
                                </div>
                                <div style={{ color: '#6B7280', fontSize: '0.8rem', display: 'flex' }}>
                                    {userDetails?.access_roles?.map(el => USER_ROLES[el])?.join(' | ')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <section className='drawer-section2'>
                        <div className='drawer-tabs'>
                            <div onClick={() => setTabs('accountDetails')} className={tabs === 'accountDetails' ? 'drawer-activeTabs' : ''}>Account Details</div>
                            <div onClick={() => setTabs('brands')} className={tabs === 'brands' ? 'drawer-activeTabs' : ''}>Brands</div>
                        </div>
                        <div>
                            {tabs === 'accountDetails' &&
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                                    <div className='drawer-subText'>
                                        <span style={{ fontSize: '0.9rem', color: '#838383' }}>Email</span>
                                        <span style={{ fontSize: '0.9rem', color: '#000000', fontWeight: 500 }}>{userDetails?.email}</span>
                                    </div>
                                    <div className='drawer-subText'>
                                        <span style={{ fontSize: '0.9rem', color: '#838383' }}>Phone</span>
                                        <span style={{ fontSize: '0.9rem', color: '#000000', fontWeight: 500 }}>{userDetails?.phone}</span>
                                    </div>
                                    <div className='drawer-subText'>
                                        <span style={{ fontSize: '0.9rem', color: '#838383' }}>Agency</span>
                                        <span style={{ fontSize: '0.9rem', color: '#000000', fontWeight: 500 }}>{userDetails?.agency}</span>
                                    </div>

                                </div>
                            }
                            {tabs === 'brands' &&
                                <div>
                                    {
                                        userDetails?.brands?.map((el) => {
                                            return (
                                                <div key={el.access_id} className='drawer-brandList'>
                                                    <div>{el?.logo ? <img src={el?.logo} alt="" width={60} height={30} /> : el.client_name}</div>
                                                    <div style={{ borderLeft: `4px solid ${getUserColor(el.access_role)}`, paddingLeft: '0.25rem', fontSize: '0.9rem' }}>{USER_ROLES[el.access_role]}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    </section>
                </Box>
            </Drawer>
        </div >
    );
}

export default DrawerComponent