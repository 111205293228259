import React, { useState } from 'react'
import AudienceIcon from '../../../../../assets/svgs/AudienceIcon'
import { useLocation, useNavigate } from 'react-router-dom'
import { Calendar } from 'primereact/calendar';
import { ToastContainer, toast } from 'react-toastify';
import GreenTick from '../../../../../assets/GreenTick';



interface Errors {
  advertiser?: string;
  name?: string;
  description?: string;
  TimeZone?: string;
}

const AudienceQueryView = () => {

  const location = useLocation();
  const { selectedQuery,selectedDescription } = location.state || {}; // Safely access state

  const navigate = useNavigate();
  const [charCount, setCharCount] = useState(0);
  const [charCount1, setCharCount1] = useState(0);

  const [advertiser, setAdvertiser] = useState<string | null>(null);
  const [TimeZone, setTimeZone] = useState<string | null>(null);
  const maxLength = 120;
  const maxLengthTextarea = 999;

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState<Errors>({});

  const [isCardOpen, setIsCardOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isTimeZoneCardOpen, setIsTimeZoneCardOpen] = useState(false);
  const [searchTimeQuery, setTimeSearchQuery] = useState("");


  const handleInputChange = (
    field: keyof Errors,
    value: string,
    setValue: React.Dispatch<React.SetStateAction<string>>
  ) => {
    setCharCount(value.length);
    setValue(value);
    if (errors[field]) {
      setErrors((prevErrors) => {
        const { [field]: _, ...rest } = prevErrors; // Remove the error for this field
        return rest;
      });
    }
  };


  const handleInputChangeTextarea = (
    field: keyof Errors,
    value: string,
    setValue: React.Dispatch<React.SetStateAction<string>>
  ) => {
    setCharCount1(value.length);
    setValue(value);
    if (errors[field]) {
      setErrors((prevErrors) => {
        const { [field]: _, ...rest } = prevErrors; // Remove the error for this field
        return rest;
      });
    }
  };


  const [options] = useState([
    "Advertiser 1",
    "Advertiser 2",
    "Advertiser 3",
    "Advertiser 4",
    "Advertiser 5",
  ]);

  const [optionsTimeZone] = useState([
    "UTC (UTC+0:00)",
    "America/New_York (UTC-5:00)",
    "America/Los_Angeles (UTC-8:00)",
    "Europe/London (UTC+0:00)",
    "Europe/Berlin (UTC+1:00)",
    "Asia/Kolkata (UTC+5:30)",
    "Asia/Tokyo (UTC+9:00)",
    "Australia/Sydney (UTC+11:00)",
    "America/Sao_Paulo (UTC-3:00)",
    "Africa/Johannesburg (UTC+2:00)"
  ]);


  const filteredOptions = options.filter(option =>
    option.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleOptionClick = (option: any) => {
    setAdvertiser(option)
    if (errors['advertiser']) {
      setErrors((prevErrors) => {
        const { ['advertiser']: _, ...rest } = prevErrors; // Remove the error for this field
        return rest;
      });
    }
    setIsCardOpen(false);
  };

  const filteredOptionsTime = optionsTimeZone.filter(option =>
    option.toLowerCase().includes(searchTimeQuery.toLowerCase())
  );

  const handleOptionTimeClick = (option: any) => {
    setTimeZone(option)
    if (errors['TimeZone']) {
      setErrors((prevErrors) => {
        const { ['TimeZone']: _, ...rest } = prevErrors; // Remove the error for this field
        return rest;
      });
    }
    setIsTimeZoneCardOpen(false);
  };

  const showToast = () => {
    toast(
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <span style={{ fontSize: '.9rem', fontWeight: 'bold' }}>Audience Created </span>
        <div style={{ width: '24px', height: '24px' }}>
          <GreenTick />
        </div>
      </div>, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      // type: "success",
    });
  };




  const [dateRange, setDateRange] = useState<(Date | null)[] | null>(null);
  const [presetRange, setPresetRange] = useState('Last 30 Days'); // Default preset
  const [showCard, setShowCard] = useState(false);

  const handlePresetRange = (days: number, label: string) => {
    const now = new Date();
    const pastDate = new Date();
    pastDate.setDate(now.getDate() - days);

    setDateRange([pastDate, now]); // Set the date range for the calendar
    setPresetRange(label); // Update the label
    // setShowCard(false);
  };

  const toggleCard = () => {
    setShowCard((prev) => !prev);
  };




  const validateForm = () => {
    const newErrors: Errors = {};

    if (!advertiser) {
      newErrors.advertiser = "Advertiser is required*";
    }

    if (!TimeZone) {
      newErrors.TimeZone = "TimeZone is required*";
    }

    if (!name) {
      newErrors.name = "Name is required*";
    } else if (name.length < 3) {
      newErrors.name = "Name must be at least 3 characters long*";
    }

    if (!description) {
      newErrors.description = "Description is required*";
    } else if (description.length < 10) {
      newErrors.description = "Description must be at least 10 characters long*";
    }


    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };


  return (
    <div className='audience-query-container'>


      <form
        onSubmit={(e: any) => {
          e.preventDefault();
          const formData = new FormData(e.target);
          if (validateForm()) {
            const data = {
              advertiser: advertiser,
              audienceType: formData.get("audience-type"),
              name: name,
              description: description,
              autoAdjustDate: formData.get("adjustDate"),
              dateRange: formData.get("dateRange"),
              timeZone: TimeZone,
              listRegistry: formData.get("list-registry"),
              asins: formData.get("asin-box"),
            };

            console.log("Form Data:", data);
            showToast()

            // Replace with your API call or any data processing logic
            // navigate("/tools/amc-audience"); after 3 sec delay on success

          }
        }}
      >

        <div className='audience-query-header'><h3>AMC Audience</h3><AudienceIcon /></div>

        <div>
          <div className='box1'><span className='tex'>Query:</span>{selectedQuery}</div>
          <div className='box2'>{selectedDescription}</div>
        </div>

        <div className='field-container'>
          <div className='left-field-box'>Advertiser</div>
          <div style={{ position: 'relative' }}>
            <div className='single-line-box'>
              <div className='background-box' onClick={() => setIsCardOpen(!isCardOpen)}>{advertiser || 'Choose an Advertiser Dropdown'}{<i className="bi bi-chevron-down"></i>}</div>
              {errors.advertiser && (<div className="error-message">{errors.advertiser}</div>)}
            </div>
            {isCardOpen && (
              <div className="dropdown-card">
                <input
                  type="text"
                  name="advertiser"
                  className="search-input"
                  placeholder="Search advertisers ..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <div className="options-container">
                  {filteredOptions.length > 0 ? (
                    filteredOptions.map((option, index) => (
                      <div
                        key={index}
                        className="option"
                        onClick={() => handleOptionClick(option)}
                      >
                        {option}
                      </div>
                    ))
                  ) : (
                    <div className="no-options">No options found</div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='field-container'>
          <div className='left-field-box'>Audience Type</div>
          <div className='box-contain1'>
            <div> <input type='radio' name="audience-type" id="rule-based-audience" defaultChecked value="Rule-Based Audience" /><label htmlFor="rule-based-audience">Rule-Based Audience</label> </div>
            <div> <input type='radio' name="audience-type" id="lookalike-audience" value="Lookalike Audience" /><label htmlFor="lookalike-audience">Lookalike Audience</label> </div>
          </div>
        </div>

        <div className='field-container'>
          <div className='left-field-box'>Name</div>
          <div className='seperate-line-box'>
            <div className='single-line-box'>
              <div className='input-name-box'>
                <input type='text' name='name' maxLength={maxLength} onChange={(e) => handleInputChange("name", e.target.value, setName)} placeholder="Enter name here..." />
              </div>{errors.name && <div className="error-message">{errors.name}</div>}
            </div>
            <div className="char-counter">
              {maxLength - charCount} characters remaining
            </div>
          </div>
        </div>

        <div className='field-container'>
          <div className='left-field-box'>Description</div>
          <div className='seperate-line-box'>
            <div className='single-line-box'>
              <div className='description-textarea-box'><textarea name='description' maxLength={maxLengthTextarea} onChange={(e) => handleInputChangeTextarea('description', e.target.value, setDescription)} placeholder="Enter description here..." /></div>
              {errors.description && <div className="error-message">{errors.description}</div>}
            </div>
            <div className="char-counter">
              {maxLengthTextarea - charCount1} characters remaining
            </div>
          </div>
        </div>

        <div className='field-container'>
          <div className='left-field-box'>Auto Adjust Date</div>
          <div>
            <div className='box-contain1'>
              <div><input type='radio' name="adjustDate" id="yes" value="Yes" defaultChecked /><label htmlFor="yes">Yes</label></div>
              <div><input type='radio' name="adjustDate" id="no" value="No" /><label htmlFor="no">No</label></div>
            </div>
          </div>
        </div>

        <div className='field-container'>
          <div className='left-field-box'>Date Range</div>
          <div className="date-range-selector">
            <div className='background-box' onClick={toggleCard}><i className="bi bi-calendar-week"></i>{presetRange}</div>





            {showCard && (
        <div className="card-container">
          {/* Preset ranges (Left) */}
          <div className="preset-list">
            <div
              onClick={() => handlePresetRange(7, "Last 7 Days")}
              className="p-button-sm p-button-text days-block"
            >Last 7 Days</div>
            <div
              onClick={() => handlePresetRange(14, "Last 14 Days")}
              className="p-button-sm p-button-text days-block"
            >Last 14 Days</div>
            <div
              onClick={() => handlePresetRange(30, "Last 30 Days")}
              className="p-button-sm p-button-text days-block"
            >Last 30 Days</div>
            <div
              onClick={() => handlePresetRange(90, "Last 90 Days")}
              className="p-button-sm p-button-text days-block"
            >Last 90 Days</div>
            <div
              onClick={() => handlePresetRange(365, "Last 365 Days")}
              className="p-button-sm p-button-text days-block"
            >Last 365 Days</div>
          </div>

          {/* Calendar (Right) */}
          <div className="calendar-container">
            <Calendar
              value={dateRange}
              onChange={(e) => setDateRange(e.value as (Date | null)[])}
              selectionMode="range"
              readOnlyInput
              numberOfMonths={2}
              dateFormat="dd M yy"
              showButtonBar
              inline

              visible={true} // Opens the calendar by default
              // showInput={false} // Hides the default input field
              // className="custom-calendar" // Custom styling
              style={{ width: "100%" }} // Full-width calendar
              // panelStyle={{ maxWidth: "60rem" }} // Resize the panel width
            />
          </div>
        </div>
      )}






          </div>



          {/* calendar */}



        </div>

        <div className='field-container'>
          <div className='left-field-box'>TimeZone</div>
          <div style={{ position: 'relative' }}>
            <div className='single-line-box'>
              <div className='background-box' onClick={() => setIsTimeZoneCardOpen(!isTimeZoneCardOpen)} ><i className="bi bi-clock"></i>{TimeZone || 'UTC'}{<i className="bi bi-chevron-down"></i>}</div>
              {errors.TimeZone && (<div className="error-message">{errors.TimeZone}</div>)}
            </div>
            {isTimeZoneCardOpen && (
              <div className="dropdown-card">
                <input
                  type="text"
                  name='timezone'
                  className="search-input"
                  placeholder="Search TimeZone ..."
                  value={searchTimeQuery}
                  onChange={(e) => setTimeSearchQuery(e.target.value)}
                />
                <div className="options-container">
                  {filteredOptionsTime.length > 0 ? (
                    filteredOptionsTime.map((option, index) => (
                      <div
                        key={index}
                        className="option"
                        onClick={() => handleOptionTimeClick(option)}
                      >
                        {option}
                      </div>
                    ))
                  ) : (
                    <div className="no-options">No options found</div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='field-container'>
          <div className='left-field-box'><div className='flex-box'>Type of list or registry <span className='text-gray'>(Select one or more)</span></div></div>
          <div className='box-contain1'>
            <div> <input type='radio' name="list-registry" id="wishlist" value={'Wishlist'} defaultChecked /><label htmlFor="wishlist">Wishlist</label> </div>
            <div> <input type='radio' name="list-registry" id="baby-registry" value={'Baby Registry'} /><label htmlFor="baby-registry">Baby Registry</label></div>
            <div> <input type='radio' name="list-registry" id="wedding-registry" value={'Wedding Registry'} /><label htmlFor="wedding-registry">Wedding Registry</label></div>
          </div>
        </div>

        <div className='field-container'>
          <div className='left-field-box'><div><div>ASINs to include</div> <div className='text-gray'>(optional)</div> </div></div>
          <div className='asin-include-textarea-box'><textarea name='asin-box' placeholder='Provide a list of ASINs, one per line, to restrict the purchases or leave blank for all ASINs.' /></div>
        </div>



        <div className='end-box'>
          <div className='end-bottom'>Want to check your past queries ? <span className='click-here-box' onClick={() => navigate('/tools/amc-audience/history')}>Click Here</span></div>
          <div>
            <button className='create-audience' type="submit" >Create Audience</button>
            <ToastContainer />
          </div>
          <div>
          </div>

        </div>


      </form>
    </div>

  )
}

export default AudienceQueryView
