import './userstyle.scss';
import { Avatar } from '@mui/material'
import { useAppSelector } from '../../../redux/hook';

const AccountSettings = () => {
  const user = useAppSelector((state) => state.user);
  return (
    <div className='accountSettings'>
      <div className='userProfileFirstBox'>
        <Avatar sx={{ height: '5rem', width: '5rem', color: "#6c757d" }}>
          <span style={{ fontSize: '1.5rem', color: '#000000' }}>{user?.first_name?.split(" ")[0][0]?.toUpperCase() + user?.last_name?.split(" ")[0][0]?.toUpperCase()}</span>
        </Avatar>
        <div>
          <div><span style={{ color: '#384D6C', fontSize: '1rem', fontWeight: 'bold' }}>{user?.first_name + " " + user?.last_name}</span></div>
          <div style={{ color: '#384D6C', fontSize: '0.9rem' }}>Enlytical</div>
          <div style={{ color: '#6B7280', fontSize: '0.75rem' }}>Brand Manager</div>
        </div>
      </div>
      <div className='accountSettings-formbox'>
        <h6 style={{ color: '#384D6C', fontWeight: 'bold' }}>Change Password</h6>
        <form className='accountSettings-forminput'>
          <div className='userProfileFormBox'>
            <div className='userProfileInput'>
              <label className='userProfilelabel' htmlFor="">Current Password</label>
              <input className='userProfileInputBox' type="password" />
            </div>
            <div className='userProfileInput'>
              <label className='userProfilelabel' htmlFor="">New Password</label>
              <input className='userProfileInputBox' type="password" />
            </div>
          </div>
          <div className='userProfileFormBox'>
            <div className='userProfileInput'>
              <label className='userProfilelabel' htmlFor="">Confirm Password</label>
              <input className='accountSettings-confirmPass' type="password" ></input>
            </div>
          </div>
        </form>
      </div>

      <div className='accountSettings-btncontainer'>
        <button className='cancel-btn' type='reset'>Cancel</button>
        <button className='submit-btn' type='submit'>Save</button>
      </div>
    </div>
  )
}

export default AccountSettings