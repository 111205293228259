import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Card from './Card';
import { KPI } from '../types/type';
import Loader2 from '../../../utils/commonComponents/Loader/Loader2';
import LoaderSkeletonBoxes from '../../../utils/commonComponents/Loader/LoaderSkeletonBoxes';

interface Props {
  selected4KpiArr: KPI[] | any[];
  setSelected4KpiArr: Dispatch<SetStateAction<KPI[]>> | Dispatch<SetStateAction<any[]>>;
  dataArr: KPI[] | any[];
  isLoading: boolean;
}

const KpiTile: React.FC<Props> = (props) => {
  const { selected4KpiArr, setSelected4KpiArr, dataArr, isLoading } = props;

  return (
    <div className='kpi_card-all' style={{ display: 'flex', gap: '0.25rem' }}>

      <>
      <div className='kpi_card-container'>
        {isLoading ? <LoaderSkeletonBoxes /> :
          <Card
            bg="rgba(0, 178, 255, 1)"
            selectedKpi={selected4KpiArr[0]}
            selected4KpiArr={selected4KpiArr}
            setSelected4KpiArr={setSelected4KpiArr}
            dataArr={dataArr}
          />
        }
      </div>    
      <div className='kpi_card-container'>
        {isLoading ? <LoaderSkeletonBoxes /> :
          <Card
            bg="rgba(255, 199, 0, 1)"
            selectedKpi={selected4KpiArr[1]}
            selected4KpiArr={selected4KpiArr}
            setSelected4KpiArr={setSelected4KpiArr}
            dataArr={dataArr}
          />
        }
      </div>  
      <div className='kpi_card-container'>
        {isLoading ? <LoaderSkeletonBoxes /> :
          <Card
            bg="rgba(238, 52, 101, 1)"
            selectedKpi={selected4KpiArr[2]}
            selected4KpiArr={selected4KpiArr}
            setSelected4KpiArr={setSelected4KpiArr}
            dataArr={dataArr}
          />
        }
      </div>  
      <div className='kpi_card-container'>  
        {isLoading ? <LoaderSkeletonBoxes /> :
          <Card
            bg="rgba(97, 0, 255, 1)"
            selectedKpi={selected4KpiArr[3]}
            selected4KpiArr={selected4KpiArr}
            setSelected4KpiArr={setSelected4KpiArr}
            dataArr={dataArr}
          />
        }
      </div>  
      </>


    </div>
  )
}

export default KpiTile;