import { useEffect, useRef, useState } from 'react'
import { Calendar } from 'primereact/calendar';
import "./DateRange.scss"
import { ModuleEnum } from '../../../utils/types.enum';

const DateRange = ({ intervalType, setIntervalType, intervalRange, setIntervalRange, setGraphIntervalRange, report_type }: any) => {
    const popupRef = useRef<any | null>(null);
    const [isToggleOpen, setToggleOpen] = useState(false)

    const [isMonthToggleOpen, setMonthToggleOpen] = useState(false)

    const [dates, setDates] = useState(null);

    const [weeksList, setWeeksList] = useState<any[]>([])
    const [selectedWeeksList, setSelectedWeeksList] = useState<any[]>([])

    const [monthRange, setMonthRange] = useState<any[]>([]);
    const [selectedMonthsList, setSelectedMonthsList] = useState<any[]>([])


    const [formerComparisonDate, setFormerComparisonDate] = useState(null)
    const [laterComparisonDate, setLaterComparisonDate] = useState(null)


    const [weekSelectorYear, setWeekSelectorYear] = useState<number>(2024)
    const [weekSelectorMonth, setWeekSelectorMonth] = useState<number>(5)
    const [listOfWeeks, setListOfWeeks] = useState<any[]>([])


    const [monthSelectorYear, setMonthSelectorYear] = useState<number>(2024)
    const [listOfMonths, setListOfMonths] = useState<string[]>([])


    const handleWeekSelector = (interval: string, flow: number) => {
        if (interval === 'YEAR') {
            setWeekSelectorYear((prev: any) => {
                if (flow > 0) return prev + 1
                else return prev - 1
            })
        } else if (interval === 'MONTH') {
            setWeekSelectorMonth((prev: number) => {
                if (flow > 0) {
                    if (prev < 11) return prev + 1
                    return prev
                } else {
                    if (prev > 0) return prev - 1
                    return prev
                }
            })
        }
    }

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (popupRef.current && !popupRef.current?.contains(event.target)) {
                setToggleOpen(false);
                setMonthToggleOpen(false)
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const maxDateOfMonth = new Date(weekSelectorYear, weekSelectorMonth + 1, 0)
        const weeks = []
        for (let i = 0; i < Math.ceil(maxDateOfMonth.getDate() / 7); i++) {
            const weekStart = 7 * i + 1;
            const weekEnd = Math.min(7 * i + 7, maxDateOfMonth.getDate())
            const week = i + 1
            const weekMonth = weekSelectorMonth
            const weekYear = weekSelectorYear
            if (new Date(weekSelectorYear, weekSelectorMonth, weekStart).getTime() < Date.now()) {
                weeks.push({ weekStart, weekEnd, week, weekMonth, weekYear })
            }
        }
        setListOfWeeks(weeks)
    }, [weekSelectorYear, weekSelectorMonth])


    const handleMonthSelector = (flow: number) => {
        setMonthSelectorYear((prev: any) => {
            if (flow > 0) return prev + 1
            else return prev - 1
        })
    }


    useEffect(() => {
        const currDate = new Date()
        let maxMonth = -1
        if (currDate.getFullYear() === monthSelectorYear) maxMonth = currDate.getMonth()
        else if (currDate.getFullYear() > monthSelectorYear) maxMonth = 11
        else maxMonth = -1

        const monthYear = []
        for (let i = 0; i <= maxMonth; i++) {
            const month = new Date(monthSelectorYear, i, 1).toLocaleString('default', { month: 'short' });
            monthYear.push(month)
        }

        setListOfMonths(monthYear)
    }, [monthSelectorYear])

    useEffect(() => {
        handleIntervalChange("DAILY")
    }, [])



    const orderedDateList = () => {
        const currentMonthDate = new Date()
        let i = 12
        const weeksList = []
        while (i > 0) {
            const maxDateOfMonth = new Date(currentMonthDate.getFullYear(), currentMonthDate.getMonth() + 1, 0)
            for (let i = 0; i < Math.ceil(maxDateOfMonth.getDate() / 7); i++) {
                const weekStart = 7 * i + 1;
                const weekEnd = Math.min(7 * i + 7, maxDateOfMonth.getDate())
                const week = i + 1
                const weekMonth = currentMonthDate.getMonth()
                const weekYear = currentMonthDate.getFullYear()
                const weekStartDay = new Date(weekYear, weekMonth, weekStart)
                if (weekStartDay.getTime() < Date.now()) {
                    weeksList.push({ weekStart, weekEnd, week, weekMonth, weekYear })
                }
            }
            currentMonthDate.setMonth(currentMonthDate.getMonth() - 1)
            i--;
        }

        const sortedDates = weeksList.sort((a, b) => {
            if (a.weekYear !== b.weekYear) {
                return a.weekYear - b.weekYear;
            } else if (a.weekMonth !== b.weekMonth) {
                return a.weekMonth - b.weekMonth;
            } else {
                return a.week - b.week;
            }
        });

        return sortedDates
    }


    const sortedDates = orderedDateList()

    const handleIntervalChange = (interval_type: string, interval_range?: any, comparison_type?: string) => {
        setIntervalType(interval_type)
        if (interval_type === 'DAILY') {
            if (!interval_range) {
                if (dates && dates[0] && dates[1]) {
                    interval_range = [dates[0], dates[1]]
                } else {
                    var today = new Date();
                    if (report_type !== ModuleEnum.MARKETING_STREAM) today.setDate(today.getDate() - 1);
                    const yesterday = new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate()
                    )
                    interval_range = [yesterday, yesterday]
                }
            }

            interval_range = interval_range.map((date: Date) => {
                if (!date) return
                return new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate(),
                    6,
                    15,
                    0
                )
            })
            if (interval_range[0] && interval_range[1]) {
                setIntervalRange({
                    "start_date": interval_range[0].toISOString().split("T")[0],
                    "end_date": interval_range[1].toISOString().split("T")[0]
                })
                if (interval_range[0].toISOString().split("T")[0] === interval_range[1].toISOString().split("T")[0]) {
                    const l7d = new Date(interval_range[1])
                    l7d.setDate(l7d.getDate() - 7);
                    setGraphIntervalRange({
                        "start_date": l7d.toISOString().split("T")[0],
                        "end_date": interval_range[0].toISOString().split("T")[0],
                    })
                } else {
                    setGraphIntervalRange({
                        "start_date": interval_range[0].toISOString().split("T")[0],
                        "end_date": interval_range[1].toISOString().split("T")[0]
                    })
                }
            }
            setDates(interval_range)
        } else if (interval_type === 'WEEKLY') {
            let newWeeksList = [...weeksList]
            if (!interval_range && newWeeksList.length === 0) {
                const cd = new Date()
                const currentWeek = sortedDates.find((sd) => sd.weekYear === cd.getFullYear() && sd.weekMonth === cd.getMonth() && sd.weekStart <= cd.getDate() && sd.weekEnd >= cd.getDate())
                newWeeksList.push(...[currentWeek, currentWeek])
            }
            if (interval_range) {
                if (newWeeksList.length < 2) {
                    newWeeksList.push(interval_range)
                } else {
                    newWeeksList = [interval_range]
                }
            }
            setWeeksList(newWeeksList)
            if (newWeeksList[0] && newWeeksList[1]) {
                const week1 = newWeeksList[0];
                const week2 = newWeeksList[1]
                let firstSelectedWeekIndex = sortedDates.findIndex((date) => date.week === week1.week && date.weekMonth === week1.weekMonth && date.weekYear === week1.weekYear)
                let secondSelectedWeekIndex = sortedDates.findIndex((date) => date.week === week2.week && date.weekMonth === week2.weekMonth && date.weekYear === week2.weekYear)

                const array = []
                while (firstSelectedWeekIndex !== secondSelectedWeekIndex) {
                    if (firstSelectedWeekIndex < secondSelectedWeekIndex) {
                        array.push(sortedDates[firstSelectedWeekIndex])
                        firstSelectedWeekIndex++;
                    } else {
                        array.push(sortedDates[secondSelectedWeekIndex])
                        secondSelectedWeekIndex++;
                    }
                }
                array.push(sortedDates[firstSelectedWeekIndex])
                setSelectedWeeksList(array)

                setIntervalRange({
                    "start_week": {
                        month: week1.weekMonth,
                        year: week1.weekYear,
                        week: week1.week
                    },
                    "end_week": {
                        month: week2.weekMonth,
                        year: week2.weekYear,
                        week: week2.week
                    }
                })

                if (week1.week === week2.week && week1.weekYear === week2.weekYear && week1.weekMonth === week2.weekMonth) {
                    const currentWeek = week1
                    const index = sortedDates.findIndex((date) => date.week === currentWeek.week && date.weekMonth === currentWeek.weekMonth && date.weekYear === currentWeek.weekYear);

                    let last7Week = sortedDates[index - 7]
                    if (!last7Week) last7Week = sortedDates[0]


                    setGraphIntervalRange({
                        "start_week": {
                            month: week1.weekMonth,
                            year: week1.weekYear,
                            week: week1.week
                        },
                        "end_week": {
                            month: last7Week.weekMonth,
                            year: last7Week.weekYear,
                            week: last7Week.week
                        }
                    })
                } else {
                    setGraphIntervalRange({
                        "start_week": {
                            month: week1.weekMonth,
                            year: week1.weekYear,
                            week: week1.week
                        },
                        "end_week": {
                            month: week2.weekMonth,
                            year: week2.weekYear,
                            week: week2.week
                        }
                    })
                }
            }
            else if (newWeeksList[0]) {
                setSelectedWeeksList(newWeeksList)
            }


        } else if (interval_type === 'MONTHLY') {
            let newMonthRange = [...monthRange]
            if (!interval_range && newMonthRange.length === 0) {
                const cd = new Date()
                const currentMonth = {
                    month: cd.getMonth() + 1,
                    year: cd.getFullYear()
                }
                newMonthRange.push(...[currentMonth, currentMonth])
            }
            if (interval_range) {
                if (newMonthRange.length < 2) {
                    newMonthRange.push(interval_range)
                } else {
                    newMonthRange = [interval_range]
                }
            }
            setMonthRange(newMonthRange)

            if (newMonthRange[0] && newMonthRange[1]) {
                const month1 = newMonthRange[0];
                const month2 = newMonthRange[1]

                const firstMonthDate = new Date(month1.year, month1.month - 1, 1)
                const secondMonthDate = new Date(month2.year, month2.month - 1, 1)

                const months = []
                while (firstMonthDate.getTime() !== secondMonthDate.getTime()) {
                    if (firstMonthDate.getTime() < secondMonthDate.getTime()) {
                        months.push({ month: firstMonthDate.getMonth() + 1, year: firstMonthDate.getFullYear() })
                        firstMonthDate.setMonth(firstMonthDate.getMonth() + 1)
                    } else {
                        months.push({ month: secondMonthDate.getMonth() + 1, year: secondMonthDate.getFullYear() })
                        secondMonthDate.setMonth(secondMonthDate.getMonth() + 1)
                    }
                    console.log("iukhbk", firstMonthDate, secondMonthDate);

                }

                months.push({ month: firstMonthDate.getMonth() + 1, year: firstMonthDate.getFullYear() })

                setSelectedMonthsList(months)

                setIntervalRange({
                    "start_month": {
                        month: month1.month,
                        year: month1.year
                    },
                    "end_month": {
                        month: month2.month,
                        year: month2.year
                    }
                })

                if (month1.year === month2.year && month1.month === month2.month) {
                    const last7thMonth = new Date(month1.year, month1.month - 1, 1)
                    let i = 7
                    while (i > 0) {
                        last7thMonth.setMonth(last7thMonth.getMonth() - 1)
                        i--;
                    }

                    setGraphIntervalRange({
                        "start_month": {
                            month: month1.month,
                            year: month1.year
                        },
                        "end_month": {
                            month: last7thMonth.getMonth() + 1,
                            year: last7thMonth.getFullYear()
                        }
                    })
                } else {
                    setGraphIntervalRange({
                        "start_month": {
                            month: month1.month,
                            year: month1.year
                        },
                        "end_month": {
                            month: month2.month,
                            year: month2.year

                        }
                    })
                }
            }
            else if (newMonthRange[0]) {
                setSelectedMonthsList(newMonthRange)
            }
            // console.log(selectedMonthsList);

        } else if (interval_type === "COMPARISON") {
            let formerIntervalRange: any
            let laterIntervalRange: any            
            if (comparison_type === "FORMER") formerIntervalRange = interval_range
            else if (comparison_type === "LATER") laterIntervalRange = interval_range

            if (!formerIntervalRange) {
                if (formerComparisonDate && formerComparisonDate[0] && formerComparisonDate[1]) {
                    formerIntervalRange = [formerComparisonDate[0], formerComparisonDate[1]]
                } else {
                    const yesterday = new Date();
                    yesterday.setDate(yesterday.getDate() - 1);
                    formerIntervalRange = [yesterday, yesterday]
                }
            }
            if (!laterIntervalRange) {
                if (laterComparisonDate && laterComparisonDate[0] && laterComparisonDate[1]) {
                    laterIntervalRange = [laterComparisonDate[0], laterComparisonDate[1]]
                } else {
                    const yesterday = new Date();
                    yesterday.setDate(yesterday.getDate() - 0);
                    laterIntervalRange = [yesterday, yesterday]
                }
            }

            setFormerComparisonDate(formerIntervalRange)
            setLaterComparisonDate(laterIntervalRange)

            if (formerIntervalRange[0] && formerIntervalRange[1] && laterIntervalRange[0] && laterIntervalRange[1]) {
                setIntervalRange({
                    "former_start_date": formerIntervalRange[0].toLocaleDateString('en-CA').split("T")[0],
                    "former_end_date": formerIntervalRange[1].toLocaleDateString('en-CA').split("T")[0],
                    "later_start_date": laterIntervalRange[0].toLocaleDateString('en-CA').split("T")[0],
                    "later_end_date": laterIntervalRange[1].toLocaleDateString('en-CA').split("T")[0]
                })
            }
        }
    }

    return (
        <div>
            <div className='dateRange'>
                <button onClick={() => handleIntervalChange('DAILY')} className={intervalType === 'DAILY' ? 'dateRange-activeBtn' : 'dateRange-btn'}>Today</button>
                {report_type !== ModuleEnum.MARKETING_STREAM &&
                    <button onClick={() => handleIntervalChange('WEEKLY')} className={intervalType === 'WEEKLY' ? 'dateRange-activeBtn' : 'dateRange-btn'}>Week</button>
                }
                {report_type !== ModuleEnum.MARKETING_STREAM &&
                    <button onClick={() => handleIntervalChange('MONTHLY')} className={intervalType === 'MONTHLY' ? 'dateRange-activeBtn' : 'dateRange-btn'}>Month</button>
                }
                <button onClick={() => handleIntervalChange('COMPARISON')} className={intervalType === 'COMPARISON' ? 'dateRange-activeBtn' : 'dateRange-btn'}>Comparison</button>

                {intervalType === "DAILY" &&
                    <Calendar value={dates} onChange={(e: any) => handleIntervalChange('DAILY', e.value)} numberOfMonths={2}
                        selectionMode="range" readOnlyInput hideOnRangeSelection />
                }
                {
                    intervalType === "WEEKLY" &&
                    <div ref={popupRef} className='weekselector'>
                        <input className="weekselector-input" onClick={() => setToggleOpen((prev) => !prev)} type='text' value={weeksList.map((week) => `${week.weekYear}-${week.weekMonth}-${week.week}`)} />
                        {isToggleOpen &&
                            <div className='weekselector-weekpopup'>
                                <div className='weekselector-year'>
                                    <span onClick={() => { handleWeekSelector("YEAR", -1) }}><i className="bi bi-chevron-left"></i></span>
                                    <span>{weekSelectorYear}</span>
                                    <span onClick={() => { handleWeekSelector("YEAR", 1) }}><i className="bi bi-chevron-right"></i></span>
                                </div>
                                <hr style={{ margin: '0.75rem 0rem' }} />
                                <div className='weekselector-year'>
                                    <span onClick={() => { handleWeekSelector("MONTH", -1) }}><i className="bi bi-chevron-left"></i></span>
                                    <span>{new Date(2024, weekSelectorMonth + 1, 0).toLocaleString('default', { month: 'short' })}</span>
                                    <span onClick={() => { handleWeekSelector("MONTH", 1) }}><i className="bi bi-chevron-right"></i></span>
                                </div>
                                <hr style={{ margin: '0.75rem 0rem' }} />
                                <div className='weekselector-grid'>
                                    {
                                        listOfWeeks.map((week) => {
                                            return <span onClick={() => handleIntervalChange("WEEKLY", week)} className={
                                                selectedWeeksList.find((wk) => wk.weekYear === week.weekYear && wk.weekMonth === week.weekMonth && wk.week === week.week)
                                                    ? 'weekselector-gridItem weekselector-gridItem--selected' : 'weekselector-gridItem'}>{"week" + week.week}</span>
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
                {intervalType === "MONTHLY" &&
                    <div ref={popupRef} className='weekselector'>
                        <input className="weekselector-input p-inputtext p-component p-inputtext p-component" onClick={() => setMonthToggleOpen((prev) => !prev)} type='text' value={monthRange.map((week) => `${week.year}-${week.month}`)} />
                        {isMonthToggleOpen &&
                            <div className='weekselector-weekpopup'>
                                <div className='weekselector-year'>
                                    <span onClick={() => { handleMonthSelector(-1) }}><i className="bi bi-chevron-left"></i></span>
                                    <span>{monthSelectorYear}</span>
                                    <span onClick={() => { handleMonthSelector(1) }}><i className="bi bi-chevron-right"></i></span>
                                </div>
                                <hr style={{ margin: '0.75rem 0rem' }} />
                                <div className='weekselector-grid'>
                                    {
                                        listOfMonths.map((month, index) => {
                                            return <span onClick={() => handleIntervalChange("MONTHLY", { year: monthSelectorYear, month: index + 1 })} className={
                                                selectedMonthsList.find((wk) => wk.year === monthSelectorYear && wk.month - 1 === index)
                                                    ? 'weekselector-gridItem weekselector-gridItem--selected' : 'weekselector-gridItem'}>{month}</span>
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
                {intervalType === "COMPARISON" &&
                    <>
                        <Calendar value={formerComparisonDate} onChange={(e: any) => handleIntervalChange('COMPARISON', e.value, "FORMER")} numberOfMonths={1}
                            selectionMode="range" readOnlyInput hideOnRangeSelection />

                        <Calendar value={laterComparisonDate} onChange={(e: any) => handleIntervalChange('COMPARISON', e.value, "LATER")} numberOfMonths={1}
                            selectionMode="range" readOnlyInput hideOnRangeSelection />
                    </>
                }

            </div>
        </div>
    )
}

export default DateRange;