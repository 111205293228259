import { useEffect, useState } from 'react'
import ModalComponent from '../../../utils/commonComponents/Modal/ModalComponent';
import CustomSelect from '../../../utils/commonComponents/customSelect/CustomSelect';
import { BASE_URL_NEW } from '../../../appConstant';
import axios from 'axios';
import Loader from '../../../utils/commonComponents/Loader/Loader';
import { toast } from 'react-toastify';

const customStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: 'background.paper',
    borderRadius: 10,
    boxShadow: 24,
    p: 4,
};

const ModifyBrandRole = ({ modifyRole, modalOpen, setModalOpen, fetchData }) => {
    // console.log(modifyRole);
    const [selectedRole, setSelectedRole] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // console.log(selectedRole);

    const handleModifyRole = () => {
        setIsLoading(true)
        axios.patch(`${BASE_URL_NEW}/access/${modifyRole?._id}`, {
            access_type: "BRAND",
            access_role: selectedRole,
        }).then((res) => {
            // console.log(res);
            fetchData();
            toast.success("User role changed successfully in this brand")
        }).catch((error) => {
            console.log(error);
            toast.error("Somthing went wrong" || error.response.data.message)
        }).finally(() => {
            setIsLoading(false)
        })
        setModalOpen(false);
    }
    return (
        <div>
            <ModalComponent
                open={modalOpen}
                modalClose={() => setModalOpen(false)}
                customStyle={customStyle}
            ><div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '1rem 8rem' }}>
                    <h5>Modify Brand Roles</h5>
                    <p style={{ textTransform: 'capitalize', fontSize: '0.9rem' }}>{modifyRole?.user?.first_name + " " + modifyRole?.user?.last_name}</p>
                    <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
                        {/* <CustomSelect name="" id="" value={selectedBrand} onchange={(e) => setSelectedBrand(e.target.value)}>
                            <option value="">Select Brand</option>
                            {row?.brands?.map((el) => {
                                return (
                                    <option key={el?._id} value={el?.access_id}>{el.client_name}</option>
                                )
                            })}
                        </CustomSelect> */}
                        <CustomSelect className={'modifyRoleSelect'} name="" id="" value={selectedRole} onchange={(e) => setSelectedRole(e.target.value)}>
                            <option value="">Select Role</option>
                            <option value="BRAND_MEMBER">Brand Member</option>
                            <option value="BRAND_VIEWER">Brand Viewer</option>
                            <option value="EXTERNAL_BRAND_VIEWER">Guest</option>
                        </CustomSelect>

                    </div>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        {selectedRole && <button onClick={handleModifyRole} className='submit-btn'>Submit</button>}
                        <button onClick={() => setModalOpen(false)} className='cancel-btn'>cancel</button>
                    </div>
                </div>
            </ModalComponent >
            {
                isLoading && <div style={{ zIndex: 999, position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} >< Loader /></div>
            }
        </div >
    )
}

export default ModifyBrandRole