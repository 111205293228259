import React from 'react'

const CloudUploadIcon = () => {
  return (
    <svg width="128" height="88" viewBox="0 0 128 88" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path d="M34.6666 87.8457C26.5777 87.8457 19.6666 84.9683 13.9333 79.2136C8.19992 73.4588 5.33325 66.4253 5.33325 58.1129C5.33325 50.9879 7.42214 44.6395 11.5999 39.0674C15.7777 33.4954 21.2444 29.9329 27.9999 28.38C30.2221 19.9763 34.6666 13.1711 41.3333 7.96442C47.9999 2.75775 55.5555 0.154419 63.9999 0.154419C74.3999 0.154419 83.2221 3.87673 90.4666 11.3214C97.711 18.766 101.333 27.832 101.333 38.5193C107.467 39.2501 112.555 41.9676 116.6 46.6719C120.644 51.3762 122.667 56.8797 122.667 63.1825C122.667 70.0334 120.333 75.8566 115.667 80.6522C111 85.4479 105.333 87.8457 98.6666 87.8457H34.6666Z" fill="#5588F7"/>
       <path d="M61.3334 54.9614V32.6275L54.4001 39.7524L50.6667 35.7789L64.0001 22.0771L77.3334 35.7789L73.6001 39.7524L66.6668 32.6275V54.9614H61.3334ZM48.0001 65.9228C46.5334 65.9228 45.2779 65.3861 44.2334 64.3128C43.189 63.2395 42.6667 61.9493 42.6667 60.4421V52.221H48.0001V60.4421H80.0001V52.221H85.3334V60.4421C85.3334 61.9493 84.8112 63.2395 83.7668 64.3128C82.7223 65.3861 81.4668 65.9228 80.0001 65.9228H48.0001Z" fill="white"/>
    </svg>
  )
}

export default CloudUploadIcon
