import { PrimeReactProvider } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import React, { ChangeEvent, useEffect, useState } from 'react'
import Loader from '../../../utils/commonComponents/Loader/Loader';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Column } from 'primereact/column';
import CustomSelect2 from '../../../utils/commonComponents/customSelect2/CustomSelect2';
import { getMontheInText } from '../../../utils/commonFunction';
import Tippy from '@tippyjs/react';

import axios from 'axios';
import { BASE_URL_NEW } from '../../../appConstant';
import { useAppSelector } from '../../../redux/hook';
import { toast } from 'react-toastify';
import InfoIcon from '../../../assets/svgs/InfoIcon';
import LoaderSkeletonTable from '../../../utils/commonComponents/Loader/LoaderSkeletonTable';

const Achievements = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<any>();
    const [view, setView] = useState<string>('mtd');

    const client = useAppSelector(state => state.client);
    const { current_brand: { client_id } } = client;

    function getThreeMonthRange() {
        let current = new Date();
        let prevMonth = new Date(current);
        let nextMonth = new Date(current);
        prevMonth.setDate(1);
        nextMonth.setDate(1);

        prevMonth.setMonth(prevMonth.getMonth() - 1);
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        return [prevMonth, current, nextMonth];
    }

    const [range, setRange] = useState<any[]>(getThreeMonthRange());
    const [selectedDate, setSelectedDate] = useState<any>(range[1]);

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${BASE_URL_NEW}/budget/${client_id}/achievements?month=${selectedDate.getMonth() + 1}&year=${selectedDate.getFullYear()}`)
            .then((res) => {
                setData({
                    mtd: res.data.mtd_achievement,
                    dth: res.data.dth_achievement
                });
            }).catch((error) => {
                toast.error(error?.response?.data?.error || 'Unable to get budget achievements')
            }).finally(() => {
                setIsLoading(false);
            })

    }, [client_id, selectedDate])

    const totalObj = (rowData: any[]) => {
        let obj: any = {
            category: 'Total',
            asins: [],
        };
        let mtd_acos = 0;
        let mtd_sales = 0;
        let mtd_spend = 0;
        let target_acos = 0;
        let target_sales = 0;
        let target_spend = 0;
        if (rowData) {
            for (let el of rowData) {
                target_sales += el.target_sales;
                target_spend += el.target_spend;
                target_acos = Math.trunc((target_spend / target_sales) * 100)
                mtd_sales += el.mtd_sales;
                mtd_spend += el.mtd_spend;
                mtd_acos = mtd_sales ? Math.trunc((mtd_spend / mtd_sales) * 100) : 0
            }
            obj["target_sales"] = target_sales;
            obj["target_spend"] = target_spend;
            obj["target_acos"] = target_acos;
            obj["mtd_sales"] = mtd_sales;
            obj["mtd_spend"] = mtd_spend;
            obj["mtd_acos"] = mtd_acos;
        }

        return obj;
    }
    const total = totalObj(data && data?.mtd);


    const onMonthChange = (e: ChangeEvent<HTMLSelectElement>) => {
        let { target: { value } } = e;
        let newDate;
        if (new Date(value).toLocaleDateString() === new Date(range[0]).toLocaleDateString()) {
            newDate = range[0];
        } else if (new Date(value).toLocaleDateString() === new Date(range[2]).toLocaleDateString()) {
            newDate = range[2];
        } else if (new Date(value).toLocaleDateString() === new Date(range[1]).toLocaleDateString()) {
            newDate = range[1];
        }
        setSelectedDate(newDate)
    }
    function salesColor(value: any) {
        if (parseInt(value) > 95) {
            return '#05802ea7'
        } else if (parseInt(value) < 95 && parseInt(value) >= 90) {
            return '#50a76da7'
        } else if (parseInt(value) >= 85 && parseInt(value) < 90) {
            return '#d4cc3b'
        } else if (parseInt(value) < 85) {
            return '#bf3d1996'
        }
    }
    function spendColor(value: any) {
        if (parseInt(value) > 95) {
            return '#05802ea7'
        } else if (parseInt(value) < 95 && parseInt(value) >= 90) {
            return '#50a76da7'
        } else if (parseInt(value) >= 85 && parseInt(value) < 90) {
            return '#d4cc3b'
        } else if (parseInt(value) < 85) {
            return '#bf3d1996'
        }
    }
    function acosColor(value: any) {
        if (value < 0) {
            return '#05802ea7'
        } else if (value >= 0 && value <= 10) {
            return '#50a76da7'
        } else if (value > 10 && value < 15) {
            return '#d4cc3b'
        } else if (value >= 15) {
            return '#bf3d1996'
        }
    }

    function getDaysInMonth(month: number, year: number) {
        return new Date(year, month, 0).getDate()
    }

    function getMTDPercent(achivement: any, target: any) {
        let today = new Date()
        const maxDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0)
        if (today.getTime() > maxDate.getTime()) {
            today = maxDate
        }
        let todayDate = today.getDate();

        const month = today.getMonth() + 1;
        const year = today.getFullYear()
        const daysInMonth = getDaysInMonth(month, year)
        const mtdPercent = Math.round(((achivement / target) / (todayDate / daysInMonth)) * 100)
        if (isNaN(mtdPercent)) {
            return null;
        }
        if (mtdPercent === Infinity) {
            return null;
        }
        return `${mtdPercent}%`;
    }

    const mtdSpend = (props: any) => {
        const { target_spend, mtd_spend } = props;
        return (
            <div style={{ backgroundColor: `${spendColor(getMTDPercent(mtd_spend, target_spend))}`, padding: '0px 20px', minWidth: '80%' }}>{getMTDPercent(mtd_spend, target_spend)}</div>
        )
    }

    const mtdSales = (props: any) => {
        const { target_sales, mtd_sales } = props;
        return (
            <div style={{ backgroundColor: `${salesColor(getMTDPercent(mtd_sales, target_sales))}`, padding: '0px 20px', minWidth: '80%' }}>{getMTDPercent(mtd_sales, target_sales)}</div>
        )
    }
    const mtdAcos = (props: any) => {
        const { target_acos, mtd_acos } = props;
        const acosPercent = target_acos && Math.round(((mtd_acos - target_acos) / target_acos) * 100);
        return (
            <div style={{ backgroundColor: `${acosColor(acosPercent)}`, padding: '0px 20px', minWidth: '80%' }}>{!acosPercent ? null : `${acosPercent}%`}</div>
        )
    }

    const headerStyle = { color: 'rgba(24, 24, 24, 1)', backgroundColor: 'rgba(245, 245, 247, 1)', fontSize: '0.75rem', fontWeight: 'bold' }
    const bodyStyle = { color: 'rgba(24, 24, 24, 1)', fontSize: '0.8rem' }
    const headerGroup = (
        <ColumnGroup>
            <Row >
                <Column header="CATEGORY" colSpan={1} headerStyle={headerStyle} />
                {/* <Column header="ASIN" colSpan={1} headerStyle={headerStyle} /> */}
                {view === 'hourly' && <Column header="BUCKET" colSpan={1} headerStyle={headerStyle} />}
                {view === 'hourly' && <Column header="TIME" colSpan={1} headerStyle={headerStyle} />}
                <Column header="TARGETS" colSpan={3} headerStyle={headerStyle} />
                <Column header="ACHIEVEMENTS" colSpan={3} headerStyle={headerStyle} />
                <Column header="MTD ACHIEVEMENTS" colSpan={3} headerStyle={headerStyle} />
            </Row>
            <Row>
                <Column header="" colSpan={1} />
                {/* <Column header="" colSpan={1} /> */}
                {view === 'hourly' && <Column header="" colSpan={1} />}
                {view === 'hourly' && <Column header="" colSpan={1} />}
                <Column header="SPEND" colSpan={1} headerStyle={headerStyle} />
                <Column header="SALES" colSpan={1} headerStyle={headerStyle} />
                <Column header="ACOS" colSpan={1} headerStyle={headerStyle} />
                <Column header="SPEND" colSpan={1} headerStyle={headerStyle} />
                <Column header="SALES" colSpan={1} headerStyle={headerStyle} />
                <Column header="ACOS" colSpan={1} headerStyle={headerStyle} />
                <Column header="SPEND" colSpan={1} headerStyle={headerStyle} />
                <Column header="SALES" colSpan={1} headerStyle={headerStyle} />
                <Column header="ACOS" colSpan={1} headerStyle={headerStyle} />
            </Row>
        </ColumnGroup>
    )

    const tippyContent = () => {
        return (
            <div style={{ fontSize: '0.8rem' }} className='mtd_formula'>
                <ul className='mtd_formula_div'>
                    <li>
                        MTD Achievement % = ((Actual/Target) / (Today/Days of month)) * 100
                    </li>
                    <li>
                        MTD ACOS % = ((Target ACOS - Actual ACOS) / Target ACOS) * 100
                    </li>
                </ul>
                <div className='mtd_color_div'>
                    <div className='mtd_sales'>
                        <div style={{ fontWeight: '500' }}>Sales</div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>{"MTD Ach % > 95%"}</div>
                            <div style={{ backgroundColor: '#05802ea7', width: '60px' }}></div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>{"MTD Ach % = 90% - 95%"}</div>
                            <div style={{ backgroundColor: '#50a76da7', width: '60px' }}></div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>{"MTD Ach % = 85% - 90%"}</div>
                            <div style={{ backgroundColor: '#d4cc3b', width: '60px' }}></div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>{"MTD Ach % < 85%"}</div>
                            <div style={{ backgroundColor: '#bf3d1996', width: '60px' }}></div>
                        </div>
                    </div>
                    <div className='mtd_sales'>
                        <div style={{ fontWeight: '500' }}>Spend</div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>{"MTD Ach % > 95%"}</div>
                            <div style={{ backgroundColor: '#05802ea7', width: '60px' }}></div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>{"MTD Ach % = 90% - 95%"}</div>
                            <div style={{ backgroundColor: '#50a76da7', width: '60px' }}></div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>{"MTD Ach % = 85% - 90%"}</div>
                            <div style={{ backgroundColor: '#d4cc3b', width: '60px' }}></div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>{"MTD Ach % < 85%"}</div>
                            <div style={{ backgroundColor: '#bf3d1996', width: '60px' }}></div>
                        </div>
                    </div>
                    <div className='mtd_sales'>
                        <div style={{ fontWeight: '500' }}>ACOS</div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>{"ACOS % < 0%"}</div>
                            <div style={{ backgroundColor: '#05802ea7', width: '60px' }}></div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>{"ACOS % = 0% - 10%"}</div>
                            <div style={{ backgroundColor: '#50a76da7', width: '60px' }}></div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>{"ACOS % = 10% - 15%"}</div>
                            <div style={{ backgroundColor: '#d4cc3b', width: '60px' }}></div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>{"ACOS % > 15%"}</div>
                            <div style={{ backgroundColor: '#bf3d1996', width: '60px' }}></div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    const formatedBody = (props: any, field: string, symbol: string) => {
        // console.log(props,field, symbol);
        return (
            <>
                {symbol === '%' ? <div>{props[field]?.toFixed(2)}{symbol}</div> : <div>{symbol}{props[field]?.toFixed(0)}</div>}
            </>
        )
    }



    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <CustomSelect2
                        value={selectedDate}
                        onchange={onMonthChange}
                        id={''}
                    >
                        {range.map((el) => {
                            return (
                                <option value={el}>{getMontheInText(el.getMonth() + 1) + '-' + el.getFullYear()}</option>
                            )
                        })}
                    </CustomSelect2>
                    <div>
                        <Tippy content={tippyContent()} placement='right-end' theme='custom-theme' arrow={false}>
                            <span><InfoIcon /></span>
                        </Tippy>
                    </div>
                </div>
                {/* <div className='mtd_hourly_toggle'>
                    <span onClick={() => setView('mtd')} className={view === 'mtd' ? 'mtd_hourly_toggle-active_btn' : 'mtd_hourly_toggle-btn'}>MTD</span>
                    <span onClick={() => setView('hourly')} className={view === 'hourly' ? 'mtd_hourly_toggle-active_btn' : 'mtd_hourly_toggle-btn'}>Hourly</span>
                </div> */}
            </div>


            <div className='table_card_new'>
                    {isLoading ? <LoaderSkeletonTable length='8' height='58vh'/> :
                <PrimeReactProvider>
                    <div >
                        <DataTable
                            value={view === 'mtd' ? data?.mtd : data?.dth}
                            size='small'
                            resizableColumns
                            headerColumnGroup={headerGroup}
                            showGridlines
                            removableSort
                            scrollable
                            scrollHeight="60vh"
                            sortIcon
                            editMode="cell"
                            frozenValue={[total]}
                        >
                            <Column header="" field='category' />
                            {/* <Column header="" field='asins' /> */}
                            {view === 'hourly' && <Column header="" field='bucket' />}
                            {view === 'hourly' && <Column header="" field='time_period' />}
                            <Column header="SPEND" field='target_spend' body={(e) => formatedBody(e, 'target_spend', '₹')} />
                            <Column header="SALES" field='target_sales' body={(e) => formatedBody(e, 'target_sales', '₹')} />
                            <Column header="ACOS" field='target_acos' body={(e) => formatedBody(e, 'target_acos', '%')} />
                            <Column header="SPEND" field='mtd_spend' body={(e) => formatedBody(e, 'mtd_spend', '₹')} />
                            <Column header="SALES" field='mtd_sales' body={(e) => formatedBody(e, 'mtd_sales', '₹')} />
                            <Column header="ACOS" field='mtd_acos' body={(e) => formatedBody(e, 'mtd_acos', '%')} />
                            <Column header="SPEND" field='' body={mtdSpend} />
                            <Column header="SALES" field='' body={mtdSales} />
                            <Column header="ACOS" field='' body={mtdAcos} />
                        </DataTable>        
                    </div>
                </PrimeReactProvider>
                }
            </div>
            
        </div>
    )
}

export default Achievements;