import axios from 'axios';
import { PrimeReactProvider } from 'primereact/api';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { BASE_URL_NEW } from '../../../../appConstant';
import { useAppSelector } from '../../../../redux/hook';
import { formatedMetric, formatSingleDateFn, interpolateColor } from '../../../../utils/commonFunction';
import LoaderSkeletonTable from '../../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import { saveAs } from 'file-saver';

const ComparisonAsin = ({ intervalRange, selectedCategory, selectedMetric2 }: any) => {
  const client = useAppSelector(state => state.client);
  const { current_brand: { client_id } } = client;
  const [rowData, setRowData] = useState<any[]>([]);
  const time_stamps = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  const [isLoading, setIsLoading] = useState(false);


useEffect(() => {

  let queryParam = selectedCategory && selectedCategory?.map((el: any) => {
    let encodedQuery = `category=${encodeURIComponent(el.category)}&`
    return encodedQuery;
});

let query = queryParam?.join('')


  if (intervalRange && selectedMetric2 && selectedCategory) { 
      const { former_start_date, former_end_date, later_start_date, later_end_date } = intervalRange;
      setIsLoading(true);
      
      axios
          .get(`${BASE_URL_NEW}/marketing-stream/${client_id}/comparativeAsin?${query}`, {
              params: {
                  firstStartDate: former_start_date,
                  firstEndDate: former_end_date,
                  secondStartDate: later_start_date,
                  secondEndDate: later_end_date,
              },
          })
          .then((res) => {
              // console.log('response comparison asin data', res.data);

              let formattedData: any = [];

              // Group data by ASIN and week
              const groupedByAsinAndWeek = res.data.reduce((acc: any, curr: any) => {
                  const weekLabel = curr.date_range === `${former_start_date} - ${former_end_date}` ? 'Week 1' : 'Week 2';

                  if (!acc[curr.asin]) {
                      acc[curr.asin] = { 'Week 1': {}, 'Week 2': {} };
                  }

                  acc[curr.asin][weekLabel][`${curr.hour}_hour`] = Math.round(curr[selectedMetric2?.metric]);
                  acc[curr.asin][weekLabel]['asin'] = curr.asin;
                  acc[curr.asin][weekLabel]['week'] = curr.date_range;

                  return acc;
              }, {});

              // Format the data to have Week 1 followed by Week 2 for each ASIN and remove empty objects
              for (const asin in groupedByAsinAndWeek) {
                  if (Object.keys(groupedByAsinAndWeek[asin]['Week 1']).length > 2) {
                      formattedData.push(groupedByAsinAndWeek[asin]['Week 1']);
                  }
                  if (Object.keys(groupedByAsinAndWeek[asin]['Week 2']).length > 2) {
                      formattedData.push(groupedByAsinAndWeek[asin]['Week 2']);
                  }
              }

              setRowData(formattedData);
          })
          .catch((error) => {
              console.log(error);
          })
          .finally(() => {
              setIsLoading(false);
          });
  }
}, [client_id,intervalRange,selectedCategory,selectedMetric2]);  // Include metric in dependencies



  // function heatmap(value: any, el: any) {
  //   let a: any = Object.values(value)
  //   .splice(0, 2)
  //   .filter((v: any) => typeof v === 'number' && !isNaN(v)); // Filter non-numeric values

  //   if (a.length === 0) {
  //     return '#B1B1B1'; // Return default color if there's no valid numeric data
  // }

  //   let max = Math.max(...a);

  //   let color = '';
  //   if (value[`${el}_hour`] === '-') {
  //     color = '#C7C7C7';
  //   }
  //   if (value[`${el}_hour`] === max) {
  //     color = '#C7C7C7';
  //   } else if (value[`${el}_hour`] < max && value[`${el}_hour`] >= max * 0.8) {
  //     color = '#DDDDDD';
  //   } else if (value[`${el}_hour`] < max * 0.8 && value[`${el}_hour`] >= max * 0.6) {
  //     color = '#D5D5D5';
  //   } else if (value[`${el}_hour`] < max * 0.6 && value[`${el}_hour`] >= max * 0.4) {
  //     color = '#C7C7C7';
  //   } else if (value[`${el}_hour`] < max * 0.4 && value[`${el}_hour`] >= max * 0.2) {
  //     color = '#BDBDBD';
  //   } else if (value[`${el}_hour`] < max * 0.2 && value[`${el}_hour`] >= 0) {
  //     color = '#B1B1B1';
  //   }

  //   return color;
  // }


  // function heatmap(value: any, el: any) {
  //   let a: any = Object.values(value);
  //   a=a.filter((el:any)=>typeof(el)==='number')
  //   const min = Math.min(...a.filter((x:number) => x !== 0));
  //   const max = Math.max(...a.filter((x:number) => x !== 0));
  //   const currVal = value[`${el}_hour`]
  //   if (min === max || currVal===0) return '#ffffff'  

  //   const normalizedFactor = ((currVal - min) / (max - min))
  //   return interpolateColor('#EEEEEE', '#B1B1B1', normalizedFactor);
  // }




  const hoursBody = (props: any, el: any, AsinMinMax: { [key: string]: { min: number, max: number } }) => {
    return (
      <div
        style={{
          backgroundColor: heatmap(props, el, AsinMinMax),
          width: '100%',
          height: '100%',
          textAlign: 'center',
          padding: '10px 0px',
          boxSizing: 'border-box',
        }}
      >
        {props[`${el}_hour`] !== undefined ? (props[`${el}_hour`])?.toLocaleString() : '-'}
      </div>
    );
  };

  const totalBody = (props: any) => {
    let total = 0;
    for (let time of time_stamps) {
      if (props[`${time}_hour`]) {
        total = total + props[`${time}_hour`];
      }
    }
    return <div>{total?.toLocaleString()}</div>;
  };

  const performanceBody = (props: any) => {
    const { asin } = props;
    return (
        <div>
            {asin}
        </div>
    )
}


function calculateAsinMinMax(data: any[]) {
  const AsinMinMax: { [key: string]: { min: number, max: number } } = {};

  data.forEach(row => {
      const asin = row.asin;
      if (!AsinMinMax[asin]) {
         AsinMinMax[asin] = { min: Number.MAX_VALUE, max: Number.MIN_VALUE };
      }

      Object.keys(row).forEach(key => {
          if (key.includes('_hour')) {
              const value = row[key];
              if (typeof value === 'number' && value !== 0) {
                  AsinMinMax[asin].min = Math.min(AsinMinMax[asin].min, value);
                  AsinMinMax[asin].max = Math.max(AsinMinMax[asin].max, value);
              }
          }
      });
  });

  return AsinMinMax;
}



function heatmap(value: any, el: any, AsinMinMax: { [key: string]: { min: number, max: number } }) {
const asin = value.asin;
const currVal = value[`${el}_hour`];

const { min, max } = AsinMinMax[asin];

if (min === max || currVal === 0) return '#ffffff';

const normalizedFactor = ((currVal - min) / (max - min));
return interpolateColor('#EEEEEE', '#B1B1B1', normalizedFactor);
}

const AsinMinMax = calculateAsinMinMax(rowData);

const isTableDataEmpty = rowData.length === 0 || rowData.every(item => Object.keys(item).length === 0); 


const exportToCSV = () => {
  // Step 1: Create the header row
  const headers = ['Asin/Hours', 'Date Range', ...time_stamps.map(ts => `${ts}`), 'Grand Total'];
  let csvContent = headers.join(',') + '\n';

  // Step 2: Append each row's data
  rowData.forEach((row: any) => {
    let total = 0;

    for (let time of time_stamps) {
      if (row[`${time}_hour`]) {
        total += row[`${time}_hour`];
      }
    }

    if (row?.metric === 'acos' || row?.metric === 'ctr' || row?.metric === 'cpc' || row?.metric === 'roas') {
      total = total / 24;
    } else {
      total = Math.round(total);
    }

    const formattedTotal = total.toLocaleString();

    const formatWeekRange = (week: string): string => {
      const [startDate, endDate] = week.split(' - ');
      return `${formatSingleDateFn(startDate)} - ${formatSingleDateFn(endDate)}`;
    };
    

    const rowValues = [
      row['asin'],                 // category column
      formatWeekRange(row['week']),                   // Range column
      ...time_stamps.map(ts => row[`${ts}_hour`]), // Time-stamp columns
      `"${formattedTotal}"` || 0               // Grand Total column
    ];

    // Join row data as a CSV line
    csvContent += rowValues.join(',') + '\n';
  });

  
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, 'asin_wise_performance.csv');
};
 
  return (
    <div className='table_card_new'>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
      <div>
        <button className='table_btn' ><i className='bi bi-download' onClick={exportToCSV}></i></button>
      </div>
        <span className='table_kpi_name'>Asin Wise Performance</span>
      </div>
      <PrimeReactProvider>
        {isLoading ? <LoaderSkeletonTable height='350px' /> 
           : isTableDataEmpty ? (
            <div
              style={{ padding: '5rem 0', textAlign: 'center', color: '#999', fontSize: '1.2rem' }}
            >
              No Data Available
            </div>
          ) : (
          <DataTable
            value={rowData}
            size='small'
            columnResizeMode="expand"
            resizableColumns
            scrollable scrollHeight="350px"
            // virtualScrollerOptions={{ itemSize: 20 }}
            // selectionMode="single"
            // selection={clickedKPI}
            // onSelectionChange={(e) => setClickedKPI(e.value)}
            rowGroupMode="rowspan"
            groupRowsBy="asin"
            showGridlines
          >
            <Column
              field='asin'
              header='Asin/Hours'
              style={{ width: '80px' }}
              headerStyle={{
                color: 'rgba(24, 24, 24, 1)',
                textAlign: 'center',
                fontSize: '0.75rem',
                fontWeight: 'bold',
                backgroundColor: 'rgba(245, 245, 247, 1)',
              }}
              bodyStyle={{
                color: 'rgba(24, 24, 24, 1)',
                fontSize: '0.7rem',
                fontWeight: 500,
                textTransform: 'capitalize',
              }}
              body={performanceBody}
            />
            <Column
              field='week'
            //   header='Week'
              style={{ width: '80px' }}
              headerStyle={{
                color: 'rgba(24, 24, 24, 1)',
                textAlign: 'center',
                fontSize: '0.75rem',
                fontWeight: 'bold',
                backgroundColor: 'rgba(245, 245, 247, 1)',
              }}
              bodyStyle={{
                color: 'rgba(24, 24, 24, 1)',
                fontSize: '0.7rem',
                fontWeight: 500,
                textTransform: 'capitalize',        
              }}
              body={(rowData) => {
                const [startDate, endDate] = rowData.week.split(' - ');
                const formattedWeek = `${formatSingleDateFn(startDate)} - ${formatSingleDateFn(endDate)}`;
                return formattedWeek;
              }}
            />
            {time_stamps.map((el, i) => {
              return (
                <Column
                  key={i}
                  header={el}
                  field={`${el}_hour`}
                  style={{ width: '42px' }}
                  headerStyle={{
                    color: 'rgba(24, 24, 24, 1)',
                    textAlign: 'center',
                    fontSize: '0.75rem',
                    fontWeight: 'bold',
                    backgroundColor: 'rgba(245, 245, 247, 1)',
                  }}
                  bodyStyle={{
                    color: 'rgba(24, 24, 24, 1)',
                    fontSize: '0.7rem',
                    fontWeight: 500,
                    textTransform: 'capitalize',
                  }}
                  bodyClassName="no-padding"
                  body={(props) => hoursBody(props, el, AsinMinMax)}
                />
              );
            })}
            <Column
              field=""
              header='Grand Total'
              style={{ width: '80px' }}
              headerStyle={{
                color: 'rgba(24, 24, 24, 1)',
                textAlign: 'center',
                fontSize: '0.75rem',
                fontWeight: 'bold',
                backgroundColor: 'rgba(245, 245, 247, 1)',
              }}
              bodyStyle={{
                color: 'rgba(24, 24, 24, 1)',
                fontSize: '0.7rem',
                fontWeight: 500,
                textTransform: 'capitalize',
              }}
              body={totalBody}
            />
          </DataTable>
        )}
      </PrimeReactProvider>
    </div>
  );
};

export default ComparisonAsin;



