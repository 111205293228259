import React from 'react'

const KeywordTypeReportIcon = (props) => {
  return (
    <>
      {
        props.tab === 'keywordTypeReport' ?
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 2.125H16M0 6.125H9M12 6.125H16M0 10.125H5M8 10.125L16 10.125M7.6495e-08 14.125L10 14.125M0 2.125H8V4H0V2.125Z" stroke="#06486A" stroke-width="1.75" />
          </svg> : <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 2.125H16M0 6.125H9M12 6.125H16M0 10.125H5M8 10.125L16 10.125M7.6495e-08 14.125L10 14.125M0 2.125H8V4H0V2.125Z" stroke="#5B6871" stroke-width="1.75" />
          </svg>
      }
    </>
  )
}

export default KeywordTypeReportIcon