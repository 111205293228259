import React from 'react'

const GreenTick = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0C15.2024 0.0568338 18.2541 1.3461 20.4983 3.59035C22.7426 5.8346 24 8.85444 24 12C24 15.1456 22.7426 18.1654 20.4983 20.4096C18.2541 22.6539 15.2024 23.9432 12 24C8.7976 23.9432 5.74592 22.6539 3.50167 20.4096C1.25742 18.1654 0 15.1456 0 12C0 8.85444 1.25742 5.8346 3.50167 3.59035C5.74592 1.3461 8.7976 0.0568338 12 0ZM9.46248 15.8981L6.47012 12.9567C6.22518 12.7153 6.08764 12.3885 6.08764 12.0477C6.08764 11.7069 6.22518 11.38 6.47012 11.1386C6.7163 10.8983 7.04931 10.7634 7.39642 10.7634C7.74353 10.7634 8.07653 10.8983 8.32272 11.1386L10.4307 13.2114L15.6773 8.05878C15.9231 7.81822 16.2559 7.68315 16.6029 7.68315C16.9499 7.68315 17.2828 7.81822 17.5286 8.05878C17.7732 8.30033 17.9105 8.62716 17.9105 8.96784C17.9105 9.30852 17.7732 9.63534 17.5286 9.8769L11.355 15.9399C11.2297 16.0624 11.0804 16.1587 10.9163 16.2231C10.7521 16.2875 10.5764 16.3187 10.3997 16.3148C10.2229 16.3109 10.0488 16.272 9.88777 16.2004C9.72673 16.1288 9.58207 16.026 9.46248 15.8981Z" fill="#06D001"/>
    </svg>
  )
}

export default GreenTick
