
const RetailReadinessIcon = (props) => {
    return (
        <>
            {
                props.tab === 'retailReadiness' ?
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.2 8V6.4H11.6V8H5.2ZM5.2 4.8V3.2H11.6V4.8H5.2ZM3.6 9.6H9.6C9.98667 9.6 10.3467 9.68333 10.68 9.85C11.0133 10.0167 11.2933 10.2533 11.52 10.56L13.2 12.76V1.6H3.6V9.6ZM3.6 14.4H12.44L10.26 11.54C10.18 11.4333 10.0833 11.35 9.97 11.29C9.85667 11.23 9.73333 11.2 9.6 11.2H3.6V14.4ZM13.2 16H3.6C3.16 16 2.78333 15.8433 2.47 15.53C2.15667 15.2167 2 14.84 2 14.4V1.6C2 1.16 2.15667 0.783333 2.47 0.47C2.78333 0.156667 3.16 0 3.6 0H13.2C13.64 0 14.0167 0.156667 14.33 0.47C14.6433 0.783333 14.8 1.16 14.8 1.6V14.4C14.8 14.84 14.6433 15.2167 14.33 15.53C14.0167 15.8433 13.64 16 13.2 16Z" fill="#06486A" />
                    </svg> : <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.2 8V6.4H11.6V8H5.2ZM5.2 4.8V3.2H11.6V4.8H5.2ZM3.6 9.6H9.6C9.98667 9.6 10.3467 9.68333 10.68 9.85C11.0133 10.0167 11.2933 10.2533 11.52 10.56L13.2 12.76V1.6H3.6V9.6ZM3.6 14.4H12.44L10.26 11.54C10.18 11.4333 10.0833 11.35 9.97 11.29C9.85667 11.23 9.73333 11.2 9.6 11.2H3.6V14.4ZM13.2 16H3.6C3.16 16 2.78333 15.8433 2.47 15.53C2.15667 15.2167 2 14.84 2 14.4V1.6C2 1.16 2.15667 0.783333 2.47 0.47C2.78333 0.156667 3.16 0 3.6 0H13.2C13.64 0 14.0167 0.156667 14.33 0.47C14.6433 0.783333 14.8 1.16 14.8 1.6V14.4C14.8 14.84 14.6433 15.2167 14.33 15.53C14.0167 15.8433 13.64 16 13.2 16Z" fill="#5B6871" />
                    </svg>
            }
        </>
    )
}

export default RetailReadinessIcon