import React, { Dispatch, SetStateAction, useEffect, useState, ChangeEvent, useRef } from 'react'
import { PrimeReactProvider } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnFilter, Filter, PerformanceData } from '../types/type';
import { Skeleton } from 'primereact/skeleton';
import axios from 'axios';
import { BASE_URL_NEW } from '../../../appConstant';
import { useAppSelector } from '../../../redux/hook';
import Loader from '../../../utils/commonComponents/Loader/Loader';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver'
import { salesCellComponent, acosCellComponent, costCellComponent, orderComponent, clicksCellComponent, conversionComponent, ctrCellComponent, cpcCellComponent, tosCellComponent, impressionsCellComponent, roasCellComponent, unitsCellComponent } from './BodyRenderer';
import "./Table.scss"
import LoaderSkeletonTable from '../../../utils/commonComponents/Loader/LoaderSkeletonTable';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { NULL } from 'sass';

// import DownloadIcon from '../../../assets/svgs'

interface CompProps {
    selectedColumns: string[];
    selectedColumnsName: string
    fs_col: ColumnFilter
    setFs_col: Dispatch<SetStateAction<ColumnFilter>>
    firstColumn: {
        header: string,
        field: string,
        bodyStyle?: any,
        body?: any
    }[]
    intervalType: string
    performance_type: string
    filters: any[]
    selectedRow: any[]
    selectedName: string
    dependency_arr: any[]
    fs: Filter
    setFs: Dispatch<SetStateAction<Filter>>
    intervalRange: any
    name: string,
    rank: number,
    reportType: string,
    active: true,
    dependency: string[][],
}

const Table: React.FC<any> = (props) => {
    const client = useAppSelector(state => state.client);
    const { current_brand: { client_id } } = client;
    const { selectedColumns, selectedColumnsName, firstColumn, performance_type,
        filters, selectedName, name, intervalType, intervalRange, setFs, setFs_col, fs, components, setComponents, dependency_arr,
         renderOrder, handlePublish2, resetFlag} = props;

    // console.log(fs);
    // console.log(...dependency_arr);


    const [performanceData, setPerformanceData] = useState<PerformanceData[]>([])
    const [rowSelect, setRowSelect] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(false);
    const [totalRow, setTotalRow] = useState<any[]>([]);
    const [kpiSelectToggle, setKpiSelectToggle] = useState<boolean>(false);
    const popupRef = useRef<HTMLDivElement>(null);

    // const bodyStyle = { color: 'rgba(24, 24, 24, 1)', fontSize: '0.85rem' }
    const bodyStyle = { color: '#000000', fontSize: '0.85rem' }


    const [columns, setColumns] = useState<any[]>([
        { header: 'IMPRESSIONS', field: 'impressions', body: impressionsCellComponent, bodyStyle, checked: true },
        { header: 'CLICKS', field: 'clicks', body: clicksCellComponent, bodyStyle, checked: true },
        { header: 'CTR', field: 'ctr', body: ctrCellComponent, bodyStyle, checked: true },
        { header: 'CPC', field: 'cpc', body: cpcCellComponent, bodyStyle, checked: true },
        { header: 'ORDERS', field: 'orders', body: orderComponent, bodyStyle, checked: true },
        { header: 'CVR', field: 'cvr', body: conversionComponent, bodyStyle, checked: true },
        { header: 'UNITS', field: 'units', body: unitsCellComponent, bodyStyle, checked: true },
        { header: 'ACOS', field: 'acos', body: acosCellComponent, bodyStyle, checked: false },
        { header: 'SPEND', field: 'cost', body: costCellComponent, bodyStyle, checked: false },
        { header: 'SALES', field: 'sales', body: salesCellComponent, bodyStyle, checked: false },
        { header: 'ROAS', field: 'roas', body: roasCellComponent, bodyStyle, checked: false },
        { header: 'TOS_IS', field: 'tos_is', body: tosCellComponent, bodyStyle, checked: false },
    ]);

    const [expandedRows, setExpandedRows] = useState<any>(performanceData);

    useEffect(() => {
        // if (client_id && performance_type && intervalType && Object.keys(intervalRange).length > 0) {
        let body: any = {
            interval_type: intervalType,
            performance_type: performance_type,
            view: 'AGGREGATE',
            isSpot: true,
            filters: filters,
            ...intervalRange
        }

        setIsLoading(true)
        axios.post(`${BASE_URL_NEW}/report/performance/${client_id}`, body)
            .then((res) => {
                const data = res.data;
                const totalData = data.splice(0, 1);
                setTotalRow(totalData);
                setPerformanceData(data);

            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setIsLoading(false)
            })
        // }

    }, [client_id, performance_type, intervalType, intervalRange, ...filters,resetFlag])

    useEffect(() => {
       console.log('use effect running')
      setRowSelect([])
    },[resetFlag])

    // console.log('rowselect',rowSelect)

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setKpiSelectToggle(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, []);

    // useEffect(() => {
    //     const children = document.querySelectorAll('.nested-table');
    //     children.forEach(child => {
    //         const parentTd = child.closest('td');
    //         if (parentTd) {
    //             parentTd.classList.add('nested-table-parent');
    //         }
    //     });
    // }, [])

    const getColumnClassName = (field: string) => {
        // const isPercentageColumn = field.includes('percentage'); 
        return selectedColumns.includes(field) ? 'selected-column custom-header-class ' : 'custom-header-class '
    }

    const selectHeader = (performance_type: string, column: any) => {
        let arr = [...selectedColumns]
        if (!arr.includes(column.field)) {
            if (column.field !== performance_type) {
                arr.push(column.field)
            }
        } else {
            arr = arr.filter((el) => el !== column.field)
        }
        // console.log(arr);

        setFs_col((prevState: any) => ({ ...prevState, [selectedColumnsName]: arr }));
    }

    const [sortField, setSortField] = useState<any | null>(null);
    const [sortOrder, setSortOrder] = useState<any | null>(null);

    const onSortableIconClick = (field: any) => {
        let order = 1;
        if (sortField === field && sortOrder === 1) {
            order = -1;
        }
        setSortField(field);
        setSortOrder(order);
        // onSort({ sortField: field, sortOrder: order });
    };

    const headerTemplate = (column: any) => {
        return (
            <div >
                <div className="p-column-title"  onClick={() => selectHeader(performance_type, column)}>
                    {column.header}
                </div >
                {/* <i
                    className="bi bi-chevron-expand stroke"
                    onClick={() => onSortableIconClick(column.field)}
                    style={{ cursor: 'pointer', marginLeft: 'auto' }}
                /> */}
            </div>
        )
    }



    // const onRowSelect = (e: any) => {
    //     // if (performance_type === 'type') {
    //     //     const filtered_values = e?.value?.filter((el: any) => el[performance_type] === 'KT' || el[performance_type] === 'PT')
    //     //     if (filtered_values && filtered_values.length > 0) {
    //     //         filtered_values.forEach((typeData: any) => {
    //     //             if (!typeData.nestedData) {
    //     //                 let body2: any = {
    //     //                     interval_type: intervalType,
    //     //                     performance_type: 'keyword_type',
    //     //                     view: 'AGGREGATE',
    //     //                     isSpot: true,
    //     //                     filters: [{
    //     //                         performance_type: "type",
    //     //                         values: [typeData[performance_type]]
    //     //                     }, ...filters],
    //     //                     ...intervalRange
    //     //                 }
    //     //                 setIsLoading(true)
    //     //                 axios.post(`${BASE_URL_NEW}/report/performance/${client_id}`, body2)
    //     //                     .then((res) => {
    //     //                         typeData.nestedData = res.data
    //     //                     }).catch((error) => {
    //     //                         console.log(error);
    //     //                     }).finally(() => {
    //     //                         setIsLoading(false)
    //     //                     })
    //     //             }
    //     //         })
    //     //     }

    //     //     setExpandedRows(filtered_values);
    //     // }

    //     const arr = e.value.map((el: any) => {
    //         return el[performance_type]
    //     })
    //     setFs((prevState: any) => ({ ...prevState, [selectedName]: arr }));
    //     setRowSelect(e.value);
    // };

   //  using this one

    const onRowSelect = (e: any) => {
        // Extract selected values for the current table
        const selectedValues = e.value.map((el: any) => el[performance_type]);
        console.log('selected value',selectedValues)
    
        // Update `fs` state for the current table
        setFs((prevState: any) => ({ ...prevState, [selectedName]: selectedValues }));
        console.log('selected name',selectedName)

        console.log('fs',fs)

    
        // Update row selection state
        setRowSelect(e.value);
        console.log('e.value',e.value)
    
        // Find the current table's `rank` from `renderOrder`
        const currentComponent = components.find(
            (component:any) => component.performance_type === performance_type
        );
    
        if (!currentComponent) return; // Exit if the current component is not found
    
        const currentRank = currentComponent.rank;
        console.log(' in table component',currentComponent,)
    
        // Identify downstream ranks using `renderOrder`
        const downstreamRanks = renderOrder.slice(
            renderOrder.indexOf(currentRank) + 1
        );

        const upstreamRanks = renderOrder.filter(
            (rank:any) => rank !== currentRank && !downstreamRanks.includes(rank)
        );
    
        console.log('renderranks',renderOrder)
        console.log('downStreamrank',downstreamRanks)
        console.log('currentRank',currentRank)
        console.log('upstreamRanks',upstreamRanks)


        const updatedComponents = components.map((component: any) => {
            if (downstreamRanks.includes(component.rank)) {
                // Update the filters for downstream components
                const existingFilterIndex = component.filters.findIndex(
                    (filter: any) => filter.performance_type === performance_type
                );
        
                const updatedFilters = [...component.filters];
                if (existingFilterIndex !== -1) {
                    // Modify existing filter
                    updatedFilters[existingFilterIndex] = {
                        performance_type,
                        values: selectedValues,
                    };
                } else {
                    // Add a new filter
                    updatedFilters.push({
                        performance_type,
                        values: selectedValues,
                    });
                }
        
                return {
                    ...component,
                    filters: updatedFilters,
                };
            } else if (upstreamRanks.includes(component.rank)) {
                // Clear filters for upstream components
                return {
                    ...component,
                    // filters: component.filters.map((filter: any) => ({
                    //     ...filter,
                    //     values: [],
                    // })),
                    filters: [],
                };
            }
        
            // Return unrelated components unchanged
            return component;
        });
        
        
        console.log('updated component',updatedComponents);
    
        // Update components state with the new filters
        setComponents(updatedComponents);
    };

    
    
    
    
    

    const handleKpiChange = (e: ChangeEvent<HTMLInputElement>) => {
        let newArr = columns.map((el) => {
            if (el.header === e.target.name) {
                console.log('el', el.header, e.target.name)
                el.checked = !el.checked;
            }
            return el

        })
        setColumns(newArr);
    }

    const handleColumnSelect = () => {
        setKpiSelectToggle(prev => !prev);
    }


    // const headerTemplate = (column) => (
    //     <div className="p-column-title">
    //         {column.header}
    //         <i className="bi bi-chevron-expand stroke" style={{ marginLeft: '0.5rem' }}></i>
    //     </div>
    // );

    const exportToCSV = () => {
        let csvContent = '';
        const header = [...firstColumn, ...columns].map(col => col.field).join(',');
        csvContent += header + '\n';

        performanceData.forEach((row: any) => {
            const rowData = [...firstColumn, ...columns].map(col => row[col.field]).join(',');
            csvContent += rowData + '\n';
        });

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'table.csv');
    };

    // const rowExpansionTemplate = (data: any) => {
    //     const keywordTypeColumn = { header: 'KEYWORD TYPE', field: 'keyword_type', bodyStyle }
    //     // console.log([keywordTypeColumn, ...columns]);

    //     return (
    //         <>
    //             {
    //                 isLoading ?
    //                     <LoaderSkeletonTable height='350px' /> :

    //                     <DataTable className='nested-table' value={data.nestedData} >
    //                         {[keywordTypeColumn, ...columns].map((column, i) => {
    //                             return (
    //                                 <Column
    //                                     sortable
    //                                     key={i}
    //                                     // header={headerTemplate(column)}
    //                                     field={column.field}
    //                                     headerClassName={getColumnClassName(column.field)}
    //                                     bodyClassName={getColumnClassName(column.field)}
    //                                     // style={{ width: '150px' }}
    //                                     headerStyle={{ color: '#545454', textAlign: 'center', fontSize: '0.75rem', fontWeight: 500 }}
    //                                     bodyStyle={column?.bodyStyle}
    //                                     body={column.body}
    //                                 />
    //                             )
    //                         })}
    //                     </DataTable>
    //             }

    //         </>

    //     );
    // };

    return (
        <div style={{ position: 'relative' }}>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                <span className='table_kpi_name'>{name}</span>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <div className='column_select' ref={popupRef} >
                        <div className='column_select-tag' onClick={handleColumnSelect}>Select Columns <i className="bi bi-chevron-down stroke"></i></div>
                        {kpiSelectToggle &&
                            <div className='column_select-box' style={{ marginTop: '2rem' }}>
                                {
                                    columns.map((el) => {
                                        return (
                                            <div key={el.header} className="column_select-boxname" >
                                                <input onChange={handleKpiChange} type="checkbox" id={el.header} name={el.header} checked={el.checked}></input>&nbsp;&nbsp;&nbsp;
                                                <label className="" htmlFor={el.header}>{el.header}</label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                    <button className='table_btn1' onClick={exportToCSV}>
                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.1765 5.64706H9.41176V0H3.76471V5.64706H0L6.58824 12.2353L13.1765 5.64706ZM0 14.1176V16H13.1765V14.1176H0Z" fill="black" />
                        </svg>
                    </button>

                    <button className='table_btn1 bar-graph' >
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.2222 3.55556H5.33333C4.35556 3.55556 3.55556 4.35556 3.55556 5.33333V14.2222C3.55556 15.2 4.35556 16 5.33333 16H14.2222C15.2 16 16 15.2 16 14.2222V5.33333C16 4.35556 15.2 3.55556 14.2222 3.55556ZM14.2222 5.33333V7.11111H5.33333V5.33333H14.2222ZM8.88889 10.6667V8.88889H10.6667V10.6667H8.88889ZM10.6667 12.4444V14.2222H8.88889V12.4444H10.6667ZM7.11111 10.6667H5.33333V8.88889H7.11111V10.6667ZM12.4444 8.88889H14.2222V10.6667H12.4444V8.88889ZM5.33333 12.4444H7.11111V14.2222H5.33333V12.4444ZM12.4444 14.2222V12.4444H14.2222V14.2222H12.4444ZM2.66667 12.4444H1.77778C0.8 12.4444 0 11.6444 0 10.6667V1.77778C0 0.8 0.8 0 1.77778 0H10.6667C11.6444 0 12.4444 0.8 12.4444 1.77778V2.66667H10.6667V1.77778H1.77778V10.6667H2.66667V12.4444Z" fill="white" />
                        </svg>
                    </button>

                    <button className='table_btn1'>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.2222 0H1.77778C0.8 0 0 0.8 0 1.77778V14.2222C0 15.2 0.8 16 1.77778 16H14.2222C15.2 16 16 15.2 16 14.2222V1.77778C16 0.8 15.2 0 14.2222 0ZM5.33333 12.4444H3.55556V6.22222H5.33333V12.4444ZM8.88889 12.4444H7.11111V3.55556H8.88889V12.4444ZM12.4444 12.4444H10.6667V8.88889H12.4444V12.4444Z" fill="black" />
                        </svg>
                    </button>

                </div>
            </div>

            <PrimeReactProvider>
                {
                    isLoading ?
                        <LoaderSkeletonTable height='350px' /> :

                        <DataTable
                            value={performanceData}
                            size='small'
                            columnResizeMode="expand"
                            resizableColumns
                            selectionMode="multiple"
                            selection={rowSelect}
                            onSelectionChange={onRowSelect}
                            frozenValue={totalRow}
                            sortField={sortField} sortOrder={sortOrder}
                            sortIcon="bi bi-chevron-expand stroke"
                            showGridlines
                            id='unique-table'
                            className="custom-datatable"

                            // onSort={onSort}

                            // rowClassName={rowClassName}
                            scrollable scrollHeight="350px" virtualScrollerOptions={{ itemSize: 20 }}
                        // virtualScrollerOptions={{ lazy: true, delay: 200, showLoader: true, itemSize: 20, loadingTemplate }}

                        // expandedRows={expandedRows}
                        // rowExpansionTemplate={rowExpansionTemplate}
                        rowClassName={(data) => {
                            const isTotalRow = Object.values(data).some((value) => value === 'Total');
                            // if (data.category === 'Total') {
                                if (isTotalRow) {
                                return 'non-selectable-row'; // Add a custom class for styling
                            }
                            return '';
                        }}
                        >
                            {[...firstColumn, ...columns.filter((column) => column.checked)].map((column, i) => {
                                return (
                                    <Column
                                        sortable
                                        key={i}
                                        header={headerTemplate(column)}
                                        field={column.field}
                                        headerClassName={getColumnClassName(column.field)}
                                        bodyClassName={getColumnClassName(column.field)}
                                        style={{ width: '150px' }}
                                        // headerStyle={{ color: 'rgba(24, 24, 24, 1)', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                                        headerStyle={{ color: '#000000', textAlign: 'center', fontSize: '0.75rem', fontWeight: 'bold', backgroundColor: 'rgba(245, 245, 247, 1)' }}
                                        bodyStyle={column?.bodyStyle}
                                        body={column.body}

                                    />
                                )
                            }
                            )}
                        </DataTable>

                }

            </PrimeReactProvider>
        </div>
    )
}

export default Table