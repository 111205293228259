import React, { useEffect, useRef, useState } from 'react'
import { KPI } from '../../types/type'
import { formatedMetric, formatedMetricSymbol, getColorComparativeMS } from '../../../../utils/commonFunction'

interface Props {
    dataArr: KPI[] | any[]
    kpi: KPI | any
    setKpi: React.Dispatch<React.SetStateAction<KPI | undefined>>
}

const SingleCard: React.FC<Props> = (props) => {

    const popupRef = useRef<HTMLDivElement>(null);
    const { dataArr, kpi,setKpi } = props;

    const [toggle, setToggle] = useState<boolean>(false);


    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setToggle(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, []);

    const handleKPI = (el: KPI) => {
        setKpi(el);
        setToggle(prev => !prev)
    }

    return (
        <div style={{ border: `2px solid ${getColorComparativeMS(kpi?.metric as string)}` }} className='kpi_card'>
            {/* <div style={{ backgroundColor: bg, borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem', height: '0.5rem' }}></div> */}
            <div ref={popupRef} className='kpi_card-name'>
                <div onClick={() => setToggle(prev => !prev)} className='kpi_card-namehover'>
                    <span>{formatedMetric(kpi?.metric!)}</span>
                    <span className='kpi_card-icon'><i className='bi bi-chevron-down stroke'></i></span>
                </div>
                {
                    toggle &&
                    <div className='kpi_card-select2'>
                        {dataArr.map((kpi) => {
                            return (
                                <div className='kpi_card-selectEl' onClick={() => handleKPI(kpi)} key={kpi?.metric}>{formatedMetric(kpi?.metric!)}</div>
                            )
                        })}
                    </div>
                }
            </div>
            <div className='kpi_card-value'>
                <span className='kpi_card-v'>{formatedMetricSymbol(kpi?.metric!, kpi?.value?.toLocaleString('en-IN')!)}</span>
                {kpi?.percentageChange &&
                    <span className={kpi?.growthIndicator === 'positive' ? 'kpi_card-p kpi_card-growth' : 'kpi_card-p kpi_card-decline'}>{kpi?.percentageChange?.toFixed(2)}<i className={kpi?.growthIndicator === 'positive' ? "bi bi-arrow-up-short" : "bi bi-arrow-down-short"}></i></span>
                }
            </div>
        </div>
    )
}

export default SingleCard;