import { useState, useEffect, ChangeEvent, MouseEvent } from "react";
import { useNavigate, } from "react-router-dom";
import axios from "axios";
import './authStyle.scss';
import Loader from "../../../utils/commonComponents/Loader/Loader";
import EnlyticalLogo from "../../../assets/EnlyticalLogo3.png";
import { toast } from "react-toastify";
import { BASE_URL_NEW } from "../../../appConstant";

interface LogicState {
    reSendOtp: boolean,
    seconds: number | null
}

interface MainState {
    email: string | null,
    otp: string,
    password: string,
    cnfmPassword: string
}

const Otp: React.FC = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [state, setState] = useState<MainState>({
        email: "",
        otp: "",
        password: "",
        cnfmPassword: ""
    });
    const [logic, setLogic] = useState<LogicState>({
        reSendOtp: true,
        seconds: null
    });


    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        const loginEmail = localStorage.getItem("email");
        setState(prevState => ({
            ...prevState,
            email: loginEmail
        }));
    }, []);

    const counter = () => {
        setLogic(prevState => ({ ...prevState, reSendOtp: false }));
        let s = 30;
        const tick = () => {
            if (s > 0) {
                setTimeout(tick, 1000);
                s = s - 1;
                setLogic(prevState => ({ ...prevState, seconds: s }));
            } else {
                setLogic(prevState => ({ ...prevState, reSendOtp: true }));
            }
        }
        tick()
    }

    useEffect(() => {
        counter();
    }, []);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setState(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));

    }

    const onClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const { otp, password, cnfmPassword } = state;
        if (otp === "") {
            toast.error('OTP is required')
            return;
        } else if (password === "") {
            toast.error('Password is required')
            return;
        } else if (cnfmPassword === "") {
            toast.error('Password confirmation is required')
            return;
        } else if (password !== cnfmPassword) {
            toast.error('Password does not match')
            return;
        }
        setLoader(true);
        axios.post(`${BASE_URL_NEW}/auth/reset-password`, {
            email: state.email,
            otp: state.otp,
            password: state.password
        }).then((res) => {
            // console.log(res);
            navigate("/login");
            toast.success('Password updated successfully');
        }).catch((error: any) => {
            console.log(error);
            toast.error(error.response.data.error);
        }).finally(() => {
            setLoader(false);
        })
    }

    const reSendOtp = async () => {
        try {
            const res = await axios.post(`${BASE_URL_NEW}/auth/forgot-password`, {
                email: state.email
            });
            toast.success(res.data.message)
            counter()
        } catch (error) {
            toast.error('unable to fetch otp again')
            console.log(error);
        }
    }

    return (
        <div className="login" >
            <div className="login-left">

            </div>
            <div className="login-right">
                <div className="login-loginContainer">
                    <div style={{ display: "flex", justifyContent: 'center' }}>
                        <img src={EnlyticalLogo} alt="logo" height={"80px"} />
                    </div>
                    <form className="form">
                        <div style={{ color: '#384D6C' }} className="" >
                            OTP has been sent to your registered email address.  {logic.reSendOtp ? <span onClick={reSendOtp} className="resendOtpBtn" >Resend OTP</span> : <span className="resendOtpBtn" >Fetching OTP:{logic.seconds} s</span>}
                        </div>
                        <hr />
                        <div className="mb-1"  >
                            <label className="mb-1 login-label">OTP</label>
                            <input
                                placeholder="Enter OTP"
                                className="form-control login-input"
                                type="text"
                                onChange={onChange}
                                name={"otp"}
                                value={state.otp}
                            />
                        </div>
                        <div style={{ position: 'relative' }} className="mb-1" >
                            <label className="mb-1 login-label">Set Password</label>
                            <input
                                placeholder="Set Password"
                                className="form-control login-input"
                                type={showPassword ? 'text' : 'password'}
                                onChange={onChange}
                                name={"password"}
                                value={state.password}
                            />
                            <span
                                onClick={togglePasswordVisibility}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '70%',
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer',
                                    color: '#384D6C'
                                }}
                            >
                                {showPassword ? <i className="bi bi-eye"></i> : <i className="bi bi-eye-slash"></i>}
                            </span>
                        </div>
                        <div style={{ position: 'relative' }} className=""  >
                            <label className="mb-1 login-label">Confirm Password</label>
                            <input
                                placeholder="Confirm Password"
                                className="form-control login-input"
                                type={showPassword ? 'text' : 'password'}
                                onChange={onChange}
                                name={"cnfmPassword"}
                                value={state.cnfmPassword}
                            />
                            <span
                                onClick={togglePasswordVisibility}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '70%',
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer',
                                    color: '#384D6C'
                                }}
                            >
                                {showPassword ? <i className="bi bi-eye"></i> : <i className="bi bi-eye-slash"></i>}
                            </span>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }} className="mt-3">
                            <button
                                type="button"
                                className="login-btn"
                                onClick={onClick}>
                                Confirm
                            </button>
                        </div>

                    </form>
                    {
                        loader && <div style={{ position: 'absolute', top: '50%', right: '25%' }} >
                            <Loader />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Otp;